import { styled, Typography } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginLeft: theme.spacing(2)
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginLeft: theme.spacing(2),
  color: theme.palette.custom.neutral[900]
}));

export const StyledVerificationResultInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('tablet')]: {
    svg: {
      minWidth: theme.spacing(3)
    }
  }
}));
