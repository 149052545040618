import { IconButton, AppBar, styled, Typography } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  padding: theme.spacing(0),
  '.MuiToolbar-root': {
    margin: theme.spacing(2, 0)
  }
}));

export const StyledToggleButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(0, 5, 0, 2),
  [theme.breakpoints.only('tablet')]: {
    marginRight: theme.spacing(2.5)
  },
  [theme.breakpoints.only('mobile')]: {
    width: theme.spacing(2.25),
    marginRight: theme.spacing(1)
  }
}));

export const StyledStageInfo = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontWeight: 600
}));

export const StyledInfoContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center'
}));

export const StyledPanelInfoContent = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1, 2),
  backgroundColor: '#fff'
}));
