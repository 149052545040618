import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import postAddEvent from 'src/modules/DocumentsPrivate/api/postAddEvent';
import {
  AddEventRequestType,
  AddEventResponseType
} from 'src/modules/DocumentsPrivate/types';

const useAddEvent = (
  options?: UseMutationOptions<
    AddEventResponseType,
    AxiosError,
    AddEventRequestType
  >
) =>
  useMutation<AddEventResponseType, AxiosError, AddEventRequestType>(
    postAddEvent,
    options
  );

export default useAddEvent;
