import { Buffer } from 'buffer';

import { EncryptedCekDLTIdentity } from 'src/modules/DocumentsPrivate/types';

import decryptPassword from './decryptPassword';
import decryptSensitiveData from './decryptSensitiveData';
import decryptCEK from './decryptCEK';
import convertCEK from './convertCEK';

type MakeSensitiveDataBufferAndCekProps = {
  privateKeys: ArrayBufferLike;
  encryptedPassword: ArrayBufferLike;
  kek: ArrayBufferLike;
  encryptedCekDLTIdentity: EncryptedCekDLTIdentity;
  encryptedSensitiveContent: string;
};

const makeSensitiveDataBufferAndCek = async ({
  encryptedCekDLTIdentity,
  encryptedPassword,
  kek,
  privateKeys,
  encryptedSensitiveContent
}: MakeSensitiveDataBufferAndCekProps) => {
  const encryptedCek = Buffer.from(encryptedCekDLTIdentity.data, 'base64');
  //const { privateKeys, kek, encryptedPassword } = loadKeysFromLocalStorage();

  const password = await decryptPassword(encryptedPassword, kek);

  const cek = await decryptCEK(
    encryptedCek,
    privateKeys,
    kek,
    String.fromCharCode.apply(
      null,
      new Uint8Array(password) as unknown as Array<number>
    )
  );

  const convertedCEK = convertCEK(Buffer.from(cek));

  const buffer = Buffer.from(encryptedSensitiveContent, 'base64');
  const sensitiveData = await decryptSensitiveData(convertedCEK, buffer);
  return { sensitiveData, buffer, convertedCEK };
};

export default makeSensitiveDataBufferAndCek;
