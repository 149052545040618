import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: () => ({
      minHeight: 'auto',
      '& .MuiTabs-indicator': {
        height: 2
      }
    })
  }
};

export default componentConfiguration;
