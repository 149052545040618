import Hex from './Hex';
import Base64 from './Base64';
import ASN1 from './ASN1';

const decodeText = val => {
  const reHex = /^\s*(?:[0-9A-Fa-f][0-9A-Fa-f]\s*)+$/;

  const der = reHex.test(val) ? Hex.decode(val) : Base64.unarmor(val);

  const asn1 = ASN1.decode(der);
  const ASN1DecodedArray = asn1.toData();

  return {
    protocolVersionNumber: ASN1DecodedArray[1],
    firstString: ASN1DecodedArray[2].substring(10, ASN1DecodedArray[2].length),
    secondString: ASN1DecodedArray[3].substring(10, ASN1DecodedArray[3].length)
  };
};

export default decodeText;
