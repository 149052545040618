import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownloadIcon } from 'src/assets/tauron/downloadBtn.svg';

import { StyledDownloadButton } from './styles';

type DownloadButtonPropsType = {
  documentBlob: Blob | undefined | null;
  documentTitle: string;
};

const DownloadButton = ({
  documentBlob,
  documentTitle
}: DownloadButtonPropsType) => {
  const { t } = useTranslation();

  const handleDownloadClick = useCallback(() => {
    if (!documentBlob) {
      return;
    }

    const link = window.document.createElement('a');

    link.href = URL.createObjectURL(documentBlob);
    link.download = documentTitle;
    window.document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href));
  }, [documentBlob, documentTitle]);

  return (
    <StyledDownloadButton
      size="large"
      variant="contained"
      onClick={handleDownloadClick}
      startIcon={<DownloadIcon />}
    >
      &nbsp;
      {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON')}
    </StyledDownloadButton>
  );
};

export default DownloadButton;
