import { Button, Divider, Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, Trans } from 'react-i18next';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import FormTextField from 'src/components/form/formTextField';
import FormAlert from 'src/components/formAlert';
import useLoginUser from 'src/modules/UserIdentity/hooks/useLoginUser';
import routes from 'src/constants/routes';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { StepperHeader } from 'src/components/stepper';
import { PrivacyPolicy, TermsOfUse } from 'src/components/regulations';
import { ReactComponent as SmsSentSvg } from 'src/assets/smsSent.svg';
import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';
import useAgreementResendCode from 'src/modules/UserIdentity/hooks/useAgreementResendCode';
import useQueryParam from 'src/hooks/userQueryParam';
import useMaintenanceSettings from 'src/modules/UserIdentity/hooks/useMaintenanceSettings';
import { RegulationsContainer } from 'src/components/regulationContainer';
import StyledForm from 'src/components/stepper/components/stepperFormContent';

import {
  StyledContainer,
  StyledContent,
  StyledDividerContainer,
  StyledFormContainer,
  StyledHeaderContainer,
  StyledRegisterContainer,
  StyledRegisterLink,
  StyledStepperControls,
  StyledResendCode,
  StyledSmsSendContainer
} from './styles';

type VerifyForm = {
  smsCode: string;
};

const schema = yup.object({
  smsCode: yup.string().required(ERROR_EMPTY_FIELD)
});

const SmsVerificationPage = () => {
  const { data: maintenanceSettings } = useMaintenanceSettings();
  const [isSmsLocked, setIsSmsLocked] = useState(false);
  const processId = useQueryParam('processId');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formProps = useForm<VerifyForm>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const {
    mutate: requestResendSmsCode,
    isLoading: isLoadingSmsCode,
    error: resetPasswordError
  } = useAgreementResendCode({
    onSuccess: () => {
      setIsSmsLocked(true);
      const oneMinute = 1000 * 60;
      const timeout =
        Number(
          maintenanceSettings?.parameters.VERIFICATION_CODE_EXPIRATION_TIME
        ) * oneMinute;

      setTimeout(() => {
        setIsSmsLocked(false);
      }, timeout);
    },
    onError: () => setIsSmsLocked(false)
  });
  const { state } = useContext(UserIdentityContext);

  const { mutate, isLoading, error } = useLoginUser();
  const { register, handleSubmit } = formProps;
  const handleResendSmsCode = useCallback(() => {
    if (isLoadingSmsCode) {
      return;
    }

    if (processId) {
      setIsSmsLocked(true);
      requestResendSmsCode({ processId });
    }
  }, [isLoadingSmsCode, processId, requestResendSmsCode]);

  const handleLoginClick = useCallback(
    ({ smsCode }: VerifyForm) => {
      if (state.login) {
        mutate({
          email: state.login?.email,
          password: state.login?.password,
          documentNumber: state.login?.document,
          smsCode
        });
      }
    },
    [mutate, state.login]
  );

  return (
    <StyledContainer>
      <FormProvider {...formProps}>
        <form onSubmit={handleSubmit(handleLoginClick)}>
          <StyledForm>
            <FormAlert error={error || resetPasswordError} />
            <StyledContent>
              <StyledHeaderContainer>
                <StepperHeader
                  subtitleKey="SMS_LOG_IN_DESCRIPTION"
                  titleKey="LOG_IN_TITLE"
                />
              </StyledHeaderContainer>
              <StyledFormContainer>
                <FormTextField
                  fullWidth
                  variant="outlined"
                  label={t('REGISTRATION_ENTER_DATA_RESET_PASSWORD_SMS')}
                  InputProps={{ ...register('smsCode') }}
                />
                {isSmsLocked && (
                  <StyledSmsSendContainer>
                    <SmsSentSvg />
                    <Typography variant="subtitle2">
                      {t('REGISTRATION_VERIFICATION_RESEND_INFO')}
                    </Typography>
                  </StyledSmsSendContainer>
                )}
                {!isSmsLocked && (
                  <StyledResendCode
                    variant="body2"
                    onClick={handleResendSmsCode}
                  >
                    {t('REGISTRATION_VERIFICATION_RESEND')}
                  </StyledResendCode>
                )}
              </StyledFormContainer>

              <StyledStepperControls>
                <Button
                  onClick={() => navigate(routes.documentAccessType)}
                  startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
                  color="secondary"
                >
                  {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
                </Button>

                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  variant="contained"
                >
                  <span>{t('LOG_IN_LOG_IN_BUTTON')}</span>
                </LoadingButton>
              </StyledStepperControls>

              <StyledDividerContainer>
                <Divider />
                <StyledRegisterContainer>
                  <StyledRegisterLink to={routes.documentAccessTypeRegister}>
                    <Typography variant="subtitle2">
                      <Trans>LOG_IN_REGISTRATION</Trans>
                    </Typography>
                  </StyledRegisterLink>
                </StyledRegisterContainer>
              </StyledDividerContainer>
            </StyledContent>
          </StyledForm>
        </form>
        <RegulationsContainer>
          <PrivacyPolicy /> <TermsOfUse />
        </RegulationsContainer>
      </FormProvider>
    </StyledContainer>
  );
};

export default SmsVerificationPage;
