/* eslint-disable */
import asn1creators from './asn1creators';

export default async (res, password) =>
  asn1creators.encryptedPrivateKeyInfo(
    {
      password,
      salt: crypto.getRandomValues(new Uint8Array(8)),
      cost: 16384,
      blockSize: 8,
      parallel: 1,
      convertToBER: false,
      nakedPrivateKeyStructure: res
    },
    'rsa'
  );
