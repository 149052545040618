import { Box, styled } from '@mui/material';

export const CenterContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'maxWidth' && prop !== 'spacing'
})<{
  maxWidth?: number;
  spacing?: number;
}>(({ theme, maxWidth = 650, spacing = 4 }) => ({
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  gap: theme.spacing(spacing),
  maxWidth: maxWidth
}));
