import {
  ActionStatesEnum,
  SignatureFormStepsEnum
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/constants';

export type SignatureFormStateType = {
  step: SignatureFormStepsEnum;
  action: ActionStatesEnum;
  signatureError: string | null;
};

export enum SignFormActionTypesEnum {
  SET_STEP = 'SET_STEP',
  SET_ACTION = 'SET_ACTION',
  SET_ERROR = 'SET_ERROR'
}

export type ActionType = {
  type: SignFormActionTypesEnum;
  payload: SignatureFormStepsEnum | ActionStatesEnum | string | null;
};
export const signFormReducer = (
  state: SignatureFormStateType,
  action: ActionType
): SignatureFormStateType => {
  switch (action.type) {
    case SignFormActionTypesEnum.SET_STEP:
      return { ...state, step: action.payload as SignatureFormStepsEnum };
    case SignFormActionTypesEnum.SET_ACTION:
      return { ...state, action: action.payload as ActionStatesEnum };
    case SignFormActionTypesEnum.SET_ERROR:
      return { ...state, signatureError: action.payload as string | null };
    default:
      throw new Error();
  }
};
