import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CopyToClipboard from 'src/components/copyToClipboard';
import { StyledBlockchainAddress } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/styles';
import useDocumentPublicHistoryContext from 'src/modules/DocumentsPrivate/hooks/useDocumentPublicHistoryContext';
import {
  PublicDocumentHistoryEntry,
  PublicationModeEnum
} from 'src/modules/DocumentsPrivate/types';
import { localeDayFormatter } from 'src/lib/formatters';
import routes from 'src/constants/routes';

import {
  StyledBlockchainAddressTitle,
  StyledDateWrapper,
  StyledDetailsLink
} from './styles';
import { mapPublicationModeToChipVariant } from './DocumentHistory';

const PublicDocumentHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { documentHistory } = useDocumentPublicHistoryContext();

  const sortedDocumentHistory = documentHistory.sort(
    (a, b) => Date.parse(b.publicationDate) - Date.parse(a.publicationDate)
  );
  const hasRelatedDocuments = documentHistory.length > 1;

  const documentModes = [
    PublicationModeEnum.CORRECTED,
    PublicationModeEnum.NEW,
    PublicationModeEnum.UPDATED
  ];

  const filteredDocumentsFromHistory = sortedDocumentHistory.filter(
    (historyEntry: PublicDocumentHistoryEntry) =>
      documentModes.includes(historyEntry.publicationMode)
  );

  const latestDocumentVersion = filteredDocumentsFromHistory[0];

  return (
    <div>
      {sortedDocumentHistory.map((historyEntry: PublicDocumentHistoryEntry) => {
        const {
          publicationDate,
          title,
          documentBlockchainAddress,
          publicationMode,
          versionName
        } = historyEntry;

        const formattedDate = localeDayFormatter(publicationDate);
        return (
          <Accordion key={documentBlockchainAddress}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <StyledDateWrapper>
                <Typography>{formattedDate}</Typography>
              </StyledDateWrapper>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" fontWeight={600} marginBottom={0.5}>
                {t('PRIVATE_DOCUMENT_DETAILS_HISTORY_PUBLICATION_DATE')}
              </Typography>
              <Typography variant="body1" marginBottom={2}>
                {formattedDate}
              </Typography>
              <Typography variant="body2" fontWeight={600} marginBottom={0.5}>
                {t('PRIVATE_DOCUMENTS_LIST_TITLE_COLUMN')}
              </Typography>
              <Typography variant="body1" marginBottom={2}>
                {title}
              </Typography>
              <Typography variant="body2" fontWeight={600} marginBottom={0.5}>
                {t('PRIVATE_DOCUMENT_PREVIEW_HISTORY_TYPE')}
              </Typography>
              <Typography variant="body1" marginBottom={2}>
                {mapPublicationModeToChipVariant(
                  publicationMode,
                  t,
                  historyEntry === latestDocumentVersion,
                  hasRelatedDocuments
                )}
              </Typography>
              <Typography variant="body2" fontWeight={600} marginBottom={0.5}>
                {t('PRIVATE_DOCUMENT_VERSION')}
              </Typography>
              <Typography variant="body1" marginBottom={2}>
                {versionName}
              </Typography>
              <StyledBlockchainAddressTitle>
                <Typography variant="body2" marginTop={2} fontWeight={600}>
                  {t('PRIVATE_DOCUMENT_DETAILS_HISTORY_BLOCKCHAIN_ADDRESS')}
                </Typography>
                <CopyToClipboard text={documentBlockchainAddress} />
              </StyledBlockchainAddressTitle>
              <StyledBlockchainAddress variant="body1">
                {documentBlockchainAddress}
              </StyledBlockchainAddress>
              <StyledDetailsLink
                variant="body1"
                onClick={() => {
                  navigate(
                    routes.publicDocumentBlockchainAddress.replace(
                      ':address',
                      documentBlockchainAddress
                    )
                  );
                }}
              >
                {t('PRIVATE_DOCUMENTS_LIST_SEE_DEATAILS')}
              </StyledDetailsLink>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default PublicDocumentHistory;
