import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import ImageInvalidURL from 'src/assets/tauron/URL-invalid.png';

import {
  StyledErrorWrapper,
  StyledImageWrapper,
  StyledImage,
  StyledTextWrapper,
  StyledPrimaryEmphasis
} from './styles';

const TauronIncorrectDocumentPage = () => {
  const { t } = useTranslation();
  return (
    <StyledErrorWrapper>
      <StyledImageWrapper>
        <StyledImage
          src={ImageInvalidURL}
          alt={`${t('INCORRECT_URL_TITLE_PART_ONE')} ${t(
            'INCORRECT_URL_TITLE_PART_TWO'
          )}`}
        />
      </StyledImageWrapper>

      <StyledTextWrapper>
        <Typography variant="h1" fontWeight={800} textAlign="center">
          <StyledPrimaryEmphasis>
            {t('INCORRECT_URL_TITLE_PART_ONE')}{' '}
          </StyledPrimaryEmphasis>
          {t('INCORRECT_URL_TITLE_PART_TWO')}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={500}
          width="100%"
          textAlign="center"
        >
          {t('INCORRECT_URL_SUBTITLE')}
        </Typography>
      </StyledTextWrapper>
    </StyledErrorWrapper>
  );
};

export default TauronIncorrectDocumentPage;
