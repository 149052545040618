import { styled, Box, CardContent, Card } from '@mui/material';

export const StyledContent = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '0px'
});

export const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid`,
  borderColor: theme.palette.custom.textColors[500],
  backgroundColor: theme.palette.custom.shades[200],
  opacity: 1,
  borderRadius: '32px'
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(6)
}));
