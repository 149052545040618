/* eslint-disable */
import Int10 from './Int10';

export default class {
  constructor(stream) {
    let buf = stream.get();
    this.tagClass = buf >> 6;
    this.tagConstructed = (buf & 0x20) !== 0;
    this.tagNumber = buf & 0x1f;
    if (this.tagNumber === 0x1f) {
      // long tag
      const n = new Int10();
      do {
        buf = stream.get();
        n.mulAdd(128, buf & 0x7f);
      } while (buf & 0x80);
      this.tagNumber = n.simplify();
    }
  }

  isUniversal() {
    return this.tagClass === 0x00;
  }

  isEOC() {
    return this.tagClass === 0x00 && this.tagNumber === 0x00;
  }
}
