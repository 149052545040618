import { Alert, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSignatureForm } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/SignatureFormProvider';
import { SignFormActionTypesEnum } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/reducers/SignFormReducer';
import { StyledFormContainer } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/styles';
import useAcceptAgreement from 'src/modules/DocumentsPrivate/hooks/useAcceptAgreement';
import { ProcessStatus } from 'src/modules/DocumentsPrivate/hooks/useDocumentSignatureSmsCode';
import useRejectAgreement from 'src/modules/DocumentsPrivate/hooks/useRejectAgreement';
import SignatureProgressStatus from 'src/modules/DocumentsPrivate/components/documentSignatureForm/components/SignatureInProgress/SignatureProgressStatus';
import SignatureStepLoader from 'src/modules/DocumentsPrivate/components/documentSignatureForm/components/steps/SignatureStepLoader';

import SignatureStepAction from './components/steps/SignatureStepAction';
import SignatureStepInitial from './components/steps/SignatureStepInitial';
import {
  ActionStatesEnum,
  SignatureProgressModesEnum,
  SignatureFormStepsEnum
} from './constants';
import { HandleStepType, SignatureRequestType } from './types';

type SignFormComponentProps = {
  handleSignDocument: (action: string) => Promise<SignatureRequestType>;
  blockchainAddress: string;
  sendAcknowledgementEvent: () => void;
};

const SignFormComponent = ({
  handleSignDocument,
  blockchainAddress,
  sendAcknowledgementEvent
}: SignFormComponentProps) => {
  const { t } = useTranslation();

  const { formProps, signatureFormState, documentSignatureSmsCode } =
    useSignatureForm();
  const { signatureProcessState, dispatch } = signatureFormState;
  const { step, action, signatureError } = signatureProcessState;
  const {
    handleSubmit,
    reset: resetForm,
    setError,
    getValues,
    clearErrors
  } = formProps;
  const {
    requestSmsCode,
    resendSmsCode,
    confirmSmsCode,
    processStatus,
    resetSmsVerificationProcess,
    isResendTimeout,
    resendTimeoutDuration
  } = documentSignatureSmsCode;

  const handleSignatureServerError = () =>
    dispatch({
      type: SignFormActionTypesEnum.SET_ERROR,
      payload: t('PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_SIGNATURE_ERROR')
    });

  const { mutateAsync: acceptAgreement } = useAcceptAgreement({
    onError: handleSignatureServerError
  });
  const { mutateAsync: rejectAgreement } = useRejectAgreement({
    onError: handleSignatureServerError
  });

  const handleStep: HandleStepType = useCallback(
    ({ step, action }) => {
      dispatch({ type: SignFormActionTypesEnum.SET_STEP, payload: step });
      if (action) {
        dispatch({ type: SignFormActionTypesEnum.SET_ACTION, payload: action });
      }
    },
    [dispatch]
  );

  const signAndSendDocument = useCallback(
    async (data: FieldValues) => {
      const req = await handleSignDocument(action);

      if (signatureError) {
        dispatch({ type: SignFormActionTypesEnum.SET_ERROR, payload: null });
      }

      if (req) {
        if (action === ActionStatesEnum.REJECT) {
          const result = await rejectAgreement({
            ...req,
            rejectionReason: data.rejectionReason
          });
          dispatch({
            type: SignFormActionTypesEnum.SET_STEP,
            payload: SignatureFormStepsEnum.RESULT
          });
          if (result.status === 'PUBLISHING-OK') {
            handleStep({
              step: SignatureFormStepsEnum.RESULT,
              action: ActionStatesEnum.REJECT
            });
          } else {
            dispatch({
              type: SignFormActionTypesEnum.SET_ERROR,
              payload: result.status
            });
            dispatch({
              type: SignFormActionTypesEnum.SET_STEP,
              payload: SignatureFormStepsEnum.ACTION
            });
          }
        }

        if (action === ActionStatesEnum.ACCEPT) {
          const result = await acceptAgreement({
            ...req
          });
          dispatch({
            type: SignFormActionTypesEnum.SET_STEP,
            payload: SignatureFormStepsEnum.RESULT
          });
          if (result.status === 'PUBLISHING-OK') {
            handleStep({
              step: SignatureFormStepsEnum.RESULT,
              action: ActionStatesEnum.ACCEPT
            });
          } else {
            dispatch({
              type: SignFormActionTypesEnum.SET_ERROR,
              payload: result.status
            });
            dispatch({
              type: SignFormActionTypesEnum.SET_STEP,
              payload: SignatureFormStepsEnum.ACTION
            });
          }
        }
      }
    },
    [
      acceptAgreement,
      action,
      dispatch,
      handleSignDocument,
      handleStep,
      rejectAgreement,
      signatureError
    ]
  );

  const onSubmit: SubmitHandler<FieldValues> = data => {
    clearErrors();
    confirmSmsCode(data.verificationCode);
  };

  const handleResetForm = () => {
    handleStep({
      step: SignatureFormStepsEnum.INITIAL,
      action: ActionStatesEnum.NONE
    });
    dispatch({ type: SignFormActionTypesEnum.SET_ERROR, payload: null });
    resetForm();
  };

  useEffect(() => {
    if (processStatus === ProcessStatus.SUCCESS) {
      const formValues = getValues();
      dispatch({
        type: SignFormActionTypesEnum.SET_STEP,
        payload: SignatureFormStepsEnum.LOADER
      });
      resetSmsVerificationProcess(ProcessStatus.CODE_SENT);
      setTimeout(async () => {
        await signAndSendDocument(formValues);
      }, 300);
    }
    if (processStatus === ProcessStatus.ERROR_WRONG_CODE) {
      setError('verificationCode', {
        type: 'invalid_code',
        message: 'ERROR_INVALID_CODE'
      });
    }
    if (processStatus === ProcessStatus.ERROR_PROCESS_ALREADY_FINISHED) {
      setError('verificationCode', {
        type: 'invalid_code',
        message: 'ERROR_PROCESS_ALREADY_FINISHED'
      });
    }
  }, [
    dispatch,
    getValues,
    processStatus,
    resetSmsVerificationProcess,
    setError,
    signAndSendDocument
  ]);

  return (
    <FormProvider {...formProps}>
      <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
        {signatureError && (
          <Alert severity="error">
            <Typography variant="subtitle1" fontWeight={600}>
              {t('FILE_UPLOAD_STATUS_FAILED')}
            </Typography>
            <Typography variant="body2">{signatureError}</Typography>
          </Alert>
        )}
        <StyledFormContainer>
          {step === SignatureFormStepsEnum.INITIAL && (
            <SignatureStepInitial
              handleStep={handleStep}
              requestSmsCode={requestSmsCode}
            />
          )}
          {step === SignatureFormStepsEnum.ACTION && (
            <SignatureStepAction
              action={action}
              resendSmsCode={() => {
                clearErrors('verificationCode');
                resendSmsCode();
              }}
              isResendTimeout={isResendTimeout}
              resendTimeoutDuration={resendTimeoutDuration}
              handleBack={handleResetForm}
              sendAcknowledgementEvent={sendAcknowledgementEvent}
            />
          )}
          {step === SignatureFormStepsEnum.LOADER && <SignatureStepLoader />}

          {step === SignatureFormStepsEnum.RESULT && (
            <SignatureProgressStatus
              blockchainAddress={blockchainAddress}
              mode={
                action === ActionStatesEnum.REJECT
                  ? SignatureProgressModesEnum.REJECTED
                  : SignatureProgressModesEnum.ACCEPTED
              }
            />
          )}
        </StyledFormContainer>
      </form>
    </FormProvider>
  );
};

export default SignFormComponent;
