import {
  AddFullIdentityStep2StatusResponse,
  ProcessId
} from 'src/modules/UserIdentity/types/api';
import getFullIdentityStep2Status from 'src/modules/UserIdentity/api/getFullIdentityStep2Status';
import INTERVAL_THRESHOLD_MS from 'src/modules/UserIdentity/constants/api';
import ApiError from 'src/lib/apiError';

const tick = (
  processId: ProcessId,
  resolve: (
    value:
      | PromiseLike<AddFullIdentityStep2StatusResponse>
      | AddFullIdentityStep2StatusResponse
  ) => void,
  reject: (reason?: unknown) => void
) => {
  const timeout = setTimeout(async () => {
    try {
      const { data: response } = await getFullIdentityStep2Status(processId);

      if (response.status === 'ADD_FULL_ID_STEP2_FINISHED') {
        clearTimeout(timeout);
        resolve(response);
      } else if (response.status === 'ADD_FULL_ID_STEP2_ERROR') {
        clearTimeout(timeout);
        reject(new ApiError(response.statusDetails, response.status));
      } else {
        tick(processId, resolve, reject);
      }
    } catch (error) {
      clearTimeout(timeout);
      reject(error);
    }
  }, INTERVAL_THRESHOLD_MS);
};

const waitForFullIdentity = (processId: ProcessId) =>
  new Promise<AddFullIdentityStep2StatusResponse>((resolve, reject) => {
    tick(processId, resolve, reject);
  });

export default waitForFullIdentity;
