import { Typography, useMediaQuery, Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import {
  EMAIL_SESSION_KEY,
  IDENTITY_SESSION_KEY
} from 'src/modules/UserIdentity/constants/session';
import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import FormAlert from 'src/components/formAlert';
import routes from 'src/constants/routes';
import useVerifyUser from 'src/modules/UserIdentity/hooks/useCreateUserIdentity';
import PasswordField from 'src/components/form/passwordField';
import FormContainer from 'src/components/form/formContainer';
import { WEAK_PASSWORD_REGEX } from 'src/modules/UserIdentity/constants/form';
import {
  Stepper,
  StepperHeader,
  StepperContainer,
  StepperContent,
  StepperControls,
  StepperHeaderContainer
} from 'src/components/stepper';
import FormPasswordStrengthIndicator from 'src/modules/UserIdentity/components/formPasswordStrengthIndicator';
import { ChainLoader } from 'src/components/loader';
import StyleFormContainer from 'src/components/stepper/components/stepperFormContent';
import useSkippedSteps from 'src/modules/UserIdentity/hooks/useSkippedSteps';
import useNationalNodeLogoutUser from 'src/modules/UserIdentity/hooks/useNationalNodeLogoutUser';

import {
  StyledBannerContainer,
  StyledLoadingInfo,
  StyledPasswordContainer,
  StyledPasswordInfo,
  StyledInfoOutlinedIcon
} from './styles';

interface VerifyForm {
  password: string;
  rePassword: string;
  email: string;
}

const schema = yup.object({
  password: yup.string().matches(new RegExp(WEAK_PASSWORD_REGEX)).required(),
  rePassword: yup
    .string()
    .oneOf(
      [yup.ref('password')],
      'REGISTRATION_CREATE_PASSWORD_REPEAT_PASSWORD_VALIDATION'
    )
});

const VerifyPage = () => {
  const [sessionEmail] = useSessionStorage<string | null>(
    EMAIL_SESSION_KEY,
    null
  );
  const [sessionId, setSessionId] = useSessionStorage<string | null>(
    IDENTITY_SESSION_KEY,
    null
  );

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { processId } = useParams<{ processId: string }>();
  const formProps = useForm<VerifyForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: sessionEmail as string
    }
  });
  const { mutate: onLogout, isLoading: isNationalNodeLogoutUserLoading } =
    useNationalNodeLogoutUser({
      onSuccess: () => setSessionId(null)
    });

  const {
    mutate,
    isLoading: isVerifyUserLoading,
    error
  } = useVerifyUser({
    onSuccess: userUuid => {
      if (sessionId) {
        onLogout({ sessionId });
      }
      navigate(
        `${routes.documentAccessTypeSuccess.replace(':userUuid', userUuid)}`
      );
    }
  });

  const isLoading = isVerifyUserLoading || isNationalNodeLogoutUserLoading;

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));
  const { register } = formProps;
  const handleSetUserPassword = useCallback(
    (values: VerifyForm) => {
      if (sessionEmail && processId) {
        mutate({
          processId,
          email: sessionEmail,
          password: values.password
        });
      }
    },
    [mutate, processId, sessionEmail]
  );

  useEffect(() => {
    if (!sessionEmail) {
      navigate(routes.documentAccessTypeRegister);
    }
  }, [navigate, sessionEmail]);

  const skippedSteps = useSkippedSteps();

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep: RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_CREATE_PASSWORD,
    skippedSteps
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleSetUserPassword)}>
        <StyleFormContainer>
          <FormAlert error={error} />
          <StepperContainer>
            {!isHorizontalStepper && (
              <Stepper {...stepperProps} orientation="vertical" />
            )}
            <StepperContent>
              <Box>
                <StepperHeaderContainer>
                  <StepperHeader
                    titleKey="USER_REGISTER"
                    subtitleKey={
                      isLoading
                        ? 'REGISTRATION_CONFIGURATION_DESCRIPTION'
                        : undefined
                    }
                  />
                  {isHorizontalStepper && (
                    <Stepper {...stepperProps} orientation="horizontal" />
                  )}
                </StepperHeaderContainer>
                {isLoading && (
                  <>
                    <StyledLoadingInfo variant="subtitle1">
                      <Trans>REGISTRATION_CONFIGURATION_INFO</Trans>
                    </StyledLoadingInfo>
                    <StyledBannerContainer>
                      <ChainLoader />
                    </StyledBannerContainer>
                  </>
                )}
                {!isLoading && (
                  <FormContainer>
                    <TextField
                      InputProps={{ ...register('email'), readOnly: true }}
                      label="login"
                      disabled
                    />
                    <StyledPasswordContainer>
                      <FormPasswordStrengthIndicator name="password">
                        <PasswordField
                          fullWidth
                          variant="outlined"
                          disabled={isLoading}
                          helperText=""
                          label={t('REGISTRATION_CREATE_PASSWORD_PASSWORD')}
                          InputProps={{ ...register('password') }}
                        />
                      </FormPasswordStrengthIndicator>
                    </StyledPasswordContainer>
                    <StyledPasswordInfo>
                      <StyledInfoOutlinedIcon />
                      <Typography variant="subtitle2">
                        {t(
                          'REGISTRATION_CREATE_PASSWORD_PASSWORD_REQUIREMENTS'
                        )}
                      </Typography>
                    </StyledPasswordInfo>

                    <PasswordField
                      fullWidth
                      helperText={
                        formProps.formState.errors?.rePassword?.message
                      }
                      variant="outlined"
                      disabled={isLoading}
                      label={t('REGISTRATION_CREATE_PASSWORD_REPEAT_PASSWORD')}
                      InputProps={{ ...register('rePassword') }}
                    />
                  </FormContainer>
                )}
              </Box>
              <StepperControls>
                <LoadingButton
                  loading={isLoading}
                  disabled={isLoading}
                  endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                  variant="contained"
                  type="submit"
                >
                  <span>
                    {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}
                  </span>
                </LoadingButton>
              </StepperControls>
            </StepperContent>
          </StepperContainer>
        </StyleFormContainer>
      </form>
    </FormProvider>
  );
};

export default VerifyPage;
