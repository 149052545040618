import { styled } from '@mui/material';

const StyledCardsRow = styled('div')<{ featureCount?: number }>(
  ({ theme, featureCount }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: featureCount === 1 ? 'center' : 'flex-start',
    [theme.breakpoints.only('tablet')]: {
      flexDirection: 'column',
      gap: theme.spacing(3)
    },
    [theme.breakpoints.up('desktop')]: {
      '.MuiPaper-root': featureCount === 1 && {
        maxWidth: theme.spacing(75),
        minHeight: 'unset'
      },
      '.MuiCardContent-root': featureCount === 1 && {
        padding: theme.spacing(7, 7.25),
        '> div': {
          flexDirection: 'row',
          gap: theme.spacing(1)
        }
      }
    }
  })
);

export default StyledCardsRow;
