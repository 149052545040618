import { ProcessId } from 'src/modules/UserIdentity/types/api';
import INTERVAL_THRESHOLD_MS from 'src/modules/UserIdentity/constants/api';
import postFullIdentityLoadUserKeys from 'src/modules/UserIdentity/api/postFullIdentityLoadUserKeys';
import ApiError from 'src/lib/apiError';

const WAIT_STATUS_IGNORE_RETRY = 1000;

const tick = (
  uuid: ProcessId,
  hashFromHashFromPassword: string,
  resolve: () => void,
  reject: (reason?: unknown) => void
) => {
  const timeout = setTimeout(async () => {
    try {
      const response = await postFullIdentityLoadUserKeys(
        {
          uuid,
          hashFromHashFromPassword,
          referenceId: Date.now().toString()
        },
        WAIT_STATUS_IGNORE_RETRY
      );

      if (response.status === 'OK') {
        clearTimeout(timeout);
        resolve();
      } else if (response.status === 'ERROR') {
        clearTimeout(timeout);
        reject(new ApiError(response.status, response.status));
      } else {
        tick(uuid, hashFromHashFromPassword, resolve, reject);
      }
    } catch (error) {
      clearTimeout(timeout);
      reject(error);
    }
  }, INTERVAL_THRESHOLD_MS);
};

const waitForIdentity = (uuid: ProcessId, hashFromHashFromPassword: string) =>
  new Promise<void>((resolve, reject) => {
    tick(uuid, hashFromHashFromPassword, resolve, reject);
  });

export default waitForIdentity;
