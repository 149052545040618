/* eslint-disable */

import ASN1Decoder from './ASN1Decoder';
import bufferToHex from './bufferToHex';
import { decodeEcies } from './ciphers/ecies-customized/ecies';
import decryptSafeContent from './decryptSafeContent';
import hexToUInt8Array from './hexToUInt8Array';
import ObjectCaster from './objectCaster';
import { throwDecryptCekFailedError } from './errors';

export default async (encryptedCEK, privateKeys, kek, password) => {
  console.log('===== DECRYPTING CEK =====');
  const encryptedCEKHex = bufferToHex(encryptedCEK);
  const encryptedCEKNormalized = ASN1Decoder.decodeHexString(encryptedCEKHex);

  const kekNormalized = ASN1Decoder.decodeHexString(bufferToHex(kek));
  const kekIV = kekNormalized.SEQUENCE_0.OCTET_STRING_0;
  const kekKey = kekNormalized.SEQUENCE_0.OCTET_STRING_1;

  console.log('----- 1. Decrypting safe content -----');
  const decryptedPrivateKeys = await decryptSafeContent(
    privateKeys,
    hexToUInt8Array(kekIV),
    hexToUInt8Array(kekKey)
  );
  const decryptedPrivateKeysNormalized = ASN1Decoder.decodeHexString(
    decryptedPrivateKeys.decryptedHex
  );

  const chEl = encryptedCEKNormalized.SEQUENCE_0.SEQUENCE_0;
  const foundSb = ObjectCaster.getSequenceByKeyName(
    decryptedPrivateKeysNormalized,
    {
      shortcutName: chEl.OCTET_STRING_0,
      keyVersion: chEl.INTEGER_0
    }
  );
  const filteredSafebag =
    (await ObjectCaster.getFormattedDataFromSequence(foundSb, password)) || {};

  let safeBagNormalized = ASN1Decoder.decodeHexString(
    filteredSafebag.decPrivKeyHex
  );
  let privateKey = ASN1Decoder.decodeHexString(
    safeBagNormalized.SEQUENCE_0.OCTET_STRING_0
  );

  /* decode challenge */
  const eciesDecoderData = {
    cipherHex: encryptedCEKNormalized.SEQUENCE_0.OCTET_STRING_0,
    privateKey: privateKey.SEQUENCE_0.OCTET_STRING_0,
    curveType: filteredSafebag.curvatureType.curvature
  };
  console.log('----- 2. Decode ECIES -----');
  const result = await decodeEcies(eciesDecoderData);

  console.log('----- 3. CEK decrypted -----', result);

  if (typeof result === 'undefined') {
    return throwDecryptCekFailedError();
  }

  return hexToUInt8Array(result);
};
