import { styled, Typography } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginLeft: theme.spacing(2)
}));

export const StyledItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.custom.neutral[900]
}));

export const StyledVerificationResultInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  [theme.breakpoints.down('tablet')]: {
    svg: {
      minWidth: theme.spacing(3)
    }
  }
}));

export const StyledDocumentPropertySection = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));

export const StyledPropertyName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.custom.textColors[800]
}));

export const StyledPropertyValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[800],
  wordBreak: 'break-all'
}));
