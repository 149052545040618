/* eslint-disable */
// source: RemoteSignatureResponse.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var ErrorCode_pb = require('./ErrorCode_pb.js');
goog.object.extend(proto, ErrorCode_pb);
var SignatureAuthenticationType_pb = require('./SignatureAuthenticationType_pb.js');
goog.object.extend(proto, SignatureAuthenticationType_pb);
var SignatureType_pb = require('./SignatureType_pb.js');
goog.object.extend(proto, SignatureType_pb);
goog.exportSymbol('proto.Billon.RemoteSignatureResponseProto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Billon.RemoteSignatureResponseProto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Billon.RemoteSignatureResponseProto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Billon.RemoteSignatureResponseProto.displayName =
    'proto.Billon.RemoteSignatureResponseProto';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Billon.RemoteSignatureResponseProto.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Billon.RemoteSignatureResponseProto.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Billon.RemoteSignatureResponseProto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Billon.RemoteSignatureResponseProto.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        documentAddress:
          (f = msg.getDocumentAddress()) &&
          google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
        forwardedDocument:
          (f = msg.getForwardedDocument()) &&
          google_protobuf_wrappers_pb.BytesValue.toObject(includeInstance, f),
        errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
        date:
          (f = msg.getDate()) &&
          google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
        signerName:
          (f = msg.getSignerName()) &&
          google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
        signerSurname:
          (f = msg.getSignerSurname()) &&
          google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
        deviceIp:
          (f = msg.getDeviceIp()) &&
          google_protobuf_wrappers_pb.BytesValue.toObject(includeInstance, f),
        deviceId:
          (f = msg.getDeviceId()) &&
          google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
        signatureType: jspb.Message.getFieldWithDefault(msg, 9, 0),
        publicKey:
          (f = msg.getPublicKey()) &&
          google_protobuf_wrappers_pb.BytesValue.toObject(includeInstance, f),
        signatureAuthenticationType: jspb.Message.getFieldWithDefault(
          msg,
          11,
          0
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Billon.RemoteSignatureResponseProto}
 */
proto.Billon.RemoteSignatureResponseProto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Billon.RemoteSignatureResponseProto();
  return proto.Billon.RemoteSignatureResponseProto.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Billon.RemoteSignatureResponseProto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Billon.RemoteSignatureResponseProto}
 */
proto.Billon.RemoteSignatureResponseProto.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
        case 1:
          var value = new google_protobuf_wrappers_pb.StringValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader
          );
          msg.setDocumentAddress(value);
          break;
        case 2:
          var value = new google_protobuf_wrappers_pb.BytesValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BytesValue.deserializeBinaryFromReader
          );
          msg.setForwardedDocument(value);
          break;
        case 3:
          var value = /** @type {!proto.Billon.ErrorCode.ErrorCode} */ (
            reader.readEnum()
          );
          msg.setErrorCode(value);
          break;
        case 4:
          var value = new google_protobuf_wrappers_pb.Int64Value();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader
          );
          msg.setDate(value);
          break;
        case 5:
          var value = new google_protobuf_wrappers_pb.StringValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader
          );
          msg.setSignerName(value);
          break;
        case 6:
          var value = new google_protobuf_wrappers_pb.StringValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader
          );
          msg.setSignerSurname(value);
          break;
        case 7:
          var value = new google_protobuf_wrappers_pb.BytesValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BytesValue.deserializeBinaryFromReader
          );
          msg.setDeviceIp(value);
          break;
        case 8:
          var value = new google_protobuf_wrappers_pb.StringValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader
          );
          msg.setDeviceId(value);
          break;
        case 9:
          var value = /** @type {!proto.Billon.SignatureType.SignatureType} */ (
            reader.readEnum()
          );
          msg.setSignatureType(value);
          break;
        case 10:
          var value = new google_protobuf_wrappers_pb.BytesValue();
          reader.readMessage(
            value,
            google_protobuf_wrappers_pb.BytesValue.deserializeBinaryFromReader
          );
          msg.setPublicKey(value);
          break;
        case 11:
          var value =
            /** @type {!proto.Billon.SignatureAuthenticationType.SignatureAuthenticationType} */ (
              reader.readEnum()
            );
          msg.setSignatureAuthenticationType(value);
          break;
        default:
          reader.skipField();
          break;
      }
    }
    return msg;
  };

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter();
    proto.Billon.RemoteSignatureResponseProto.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  };

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Billon.RemoteSignatureResponseProto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Billon.RemoteSignatureResponseProto.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDocumentAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getForwardedDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BytesValue.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getSignerName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSignerSurname();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDeviceIp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BytesValue.serializeBinaryToWriter
    );
  }
  f = message.getDeviceId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSignatureType();
  if (f !== 0.0) {
    writer.writeEnum(9, f);
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BytesValue.serializeBinaryToWriter
    );
  }
  f = message.getSignatureAuthenticationType();
  if (f !== 0.0) {
    writer.writeEnum(11, f);
  }
};

/**
 * optional google.protobuf.StringValue document_address = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getDocumentAddress =
  function () {
    return /** @type{?proto.google.protobuf.StringValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.StringValue,
        1
      )
    );
  };

/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setDocumentAddress =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearDocumentAddress =
  function () {
    return this.setDocumentAddress(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasDocumentAddress =
  function () {
    return jspb.Message.getField(this, 1) != null;
  };

/**
 * optional google.protobuf.BytesValue forwarded_document = 2;
 * @return {?proto.google.protobuf.BytesValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getForwardedDocument =
  function () {
    return /** @type{?proto.google.protobuf.BytesValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.BytesValue,
        2
      )
    );
  };

/**
 * @param {?proto.google.protobuf.BytesValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setForwardedDocument =
  function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearForwardedDocument =
  function () {
    return this.setForwardedDocument(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasForwardedDocument =
  function () {
    return jspb.Message.getField(this, 2) != null;
  };

/**
 * optional ErrorCode.ErrorCode error_code = 3;
 * @return {!proto.Billon.ErrorCode.ErrorCode}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getErrorCode = function () {
  return /** @type {!proto.Billon.ErrorCode.ErrorCode} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};

/**
 * @param {!proto.Billon.ErrorCode.ErrorCode} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setErrorCode = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional google.protobuf.Int64Value date = 4;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getDate = function () {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.Int64Value,
      4
    )
  );
};

/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setDate = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearDate = function () {
  return this.setDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasDate = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional google.protobuf.StringValue signer_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getSignerName =
  function () {
    return /** @type{?proto.google.protobuf.StringValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.StringValue,
        5
      )
    );
  };

/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setSignerName = function (
  value
) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearSignerName =
  function () {
    return this.setSignerName(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasSignerName =
  function () {
    return jspb.Message.getField(this, 5) != null;
  };

/**
 * optional google.protobuf.StringValue signer_surname = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getSignerSurname =
  function () {
    return /** @type{?proto.google.protobuf.StringValue} */ (
      jspb.Message.getWrapperField(
        this,
        google_protobuf_wrappers_pb.StringValue,
        6
      )
    );
  };

/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setSignerSurname =
  function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
  };

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearSignerSurname =
  function () {
    return this.setSignerSurname(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasSignerSurname =
  function () {
    return jspb.Message.getField(this, 6) != null;
  };

/**
 * optional google.protobuf.BytesValue device_ip = 7;
 * @return {?proto.google.protobuf.BytesValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getDeviceIp = function () {
  return /** @type{?proto.google.protobuf.BytesValue} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.BytesValue,
      7
    )
  );
};

/**
 * @param {?proto.google.protobuf.BytesValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setDeviceIp = function (
  value
) {
  return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearDeviceIp =
  function () {
    return this.setDeviceIp(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasDeviceIp = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional google.protobuf.StringValue device_id = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getDeviceId = function () {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.StringValue,
      8
    )
  );
};

/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setDeviceId = function (
  value
) {
  return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearDeviceId =
  function () {
    return this.setDeviceId(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasDeviceId = function () {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional SignatureType.SignatureType signature_type = 9;
 * @return {!proto.Billon.SignatureType.SignatureType}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getSignatureType =
  function () {
    return /** @type {!proto.Billon.SignatureType.SignatureType} */ (
      jspb.Message.getFieldWithDefault(this, 9, 0)
    );
  };

/**
 * @param {!proto.Billon.SignatureType.SignatureType} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setSignatureType =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 9, value);
  };

/**
 * optional google.protobuf.BytesValue public_key = 10;
 * @return {?proto.google.protobuf.BytesValue}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getPublicKey = function () {
  return /** @type{?proto.google.protobuf.BytesValue} */ (
    jspb.Message.getWrapperField(
      this,
      google_protobuf_wrappers_pb.BytesValue,
      10
    )
  );
};

/**
 * @param {?proto.google.protobuf.BytesValue|undefined} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setPublicKey = function (
  value
) {
  return jspb.Message.setWrapperField(this, 10, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.clearPublicKey =
  function () {
    return this.setPublicKey(undefined);
  };

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.hasPublicKey = function () {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional SignatureAuthenticationType.SignatureAuthenticationType signature_authentication_type = 11;
 * @return {!proto.Billon.SignatureAuthenticationType.SignatureAuthenticationType}
 */
proto.Billon.RemoteSignatureResponseProto.prototype.getSignatureAuthenticationType =
  function () {
    return /** @type {!proto.Billon.SignatureAuthenticationType.SignatureAuthenticationType} */ (
      jspb.Message.getFieldWithDefault(this, 11, 0)
    );
  };

/**
 * @param {!proto.Billon.SignatureAuthenticationType.SignatureAuthenticationType} value
 * @return {!proto.Billon.RemoteSignatureResponseProto} returns this
 */
proto.Billon.RemoteSignatureResponseProto.prototype.setSignatureAuthenticationType =
  function (value) {
    return jspb.Message.setProto3EnumField(this, 11, value);
  };

goog.object.extend(exports, proto.Billon);
