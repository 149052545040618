class ApiError extends Error {
  code: string;
  constructor(
    message: string = 'Unknown error',
    code: string = 'UNKNOWN_CODE'
  ) {
    super(message);

    Object.setPrototypeOf(this, ApiError.prototype);

    this.code = code;
  }
}

export default ApiError;
