import { Components, Theme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const componentConfiguration: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    icon: {
      marginRight: '34px'
    },
    message: ({ theme }) => ({
      color: theme.palette.custom.neutral[900]
    })
  },
  defaultProps: {
    iconMapping: {
      success: <CheckCircleOutlineIcon />
    }
  }
};

export default componentConfiguration;
