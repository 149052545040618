import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { AxiosError } from 'axios';

import StepperControls from 'src/components/stepper/components/stepperControls';
import routes from 'src/constants/routes';
import getCatalogByBlockchainAddress from 'src/modules/DocumentsPrivate/api/getCatalogByBlockchainAddress';
import { ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND } from 'src/modules/DocumentsPrivate/constants/errors';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import ApiError from 'src/lib/apiError';

const DocumentVerificationPositiveStepperControls = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state, startLoading, setCatalog, setError, setAccessCode } =
    useContext(PrivateDocumentContext);

  const handleLoadDocumentCatalogClick = useCallback(() => {
    (async () => {
      if (!state.blockchainAddress) {
        return;
      }

      startLoading();
      setAccessCode('');

      try {
        const { data } = await getCatalogByBlockchainAddress(
          state.blockchainAddress
        );

        if (!data?.encryptedSensitiveContent) {
          setError(new ApiError(ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND));
          return;
        }
        setCatalog(data);

        navigate(
          routes.verifyBlockchainAddressAccessCode.replace(
            ':address',
            state.blockchainAddress
          )
        );
      } catch (err) {
        const error = err as AxiosError;
        setError(new ApiError(error.message));
      }
    })();
  }, [
    navigate,
    setAccessCode,
    setCatalog,
    setError,
    startLoading,
    state.blockchainAddress
  ]);

  return (
    <StepperControls>
      <Button onClick={() => navigate(routes.index)} color="secondary">
        {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_CANCEL_BUTTON')}
      </Button>
      <LoadingButton
        onClick={handleLoadDocumentCatalogClick}
        loadingPosition="end"
        endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
        variant="contained"
      >
        <span>
          {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON')}
        </span>
      </LoadingButton>
    </StepperControls>
  );
};

export default DocumentVerificationPositiveStepperControls;
