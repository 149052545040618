import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiInputAdornment'] = {
  styleOverrides: {
    positionEnd: ({ theme }) => ({
      svg: {
        color: theme.palette.custom.neutral[700]
      }
    })
  }
};

export default componentConfiguration;
