import { AlertColor, Typography } from '@mui/material';
import { useEffect } from 'react';
import { DefaultTFuncReturn } from 'i18next';

import StyledAlert from './styles';

type FormAlertProps = {
  info?: DefaultTFuncReturn;
  severity?: AlertColor;
};

const FormNotifier = ({ info, severity = 'error' }: FormAlertProps) => {
  useEffect(() => {
    if (info) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [info]);

  if (!info) {
    return null;
  }

  return (
    <StyledAlert severity={severity}>
      <Typography
        variant="body1"
        fontWeight={600}
        sx={{ whiteSpace: 'pre-line' }}
      >
        {info}
      </Typography>
    </StyledAlert>
  );
};

export default FormNotifier;
