import { useFormContext } from 'react-hook-form';
import { useContext, useEffect } from 'react';

import StepTextFieldWithLabel from 'src/components/stepper/components/stepperTextFieldWithLabel';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';

const blockchainAddressFieldName = 'blockchainAddress';

const SetBlockchainAddressStep = () => {
  const { setBlockchainAddress, state } = useContext(PrivateDocumentContext);
  const { getValues, formState } = useFormContext();

  useEffect(() => {
    if (formState.isValid) {
      const { blockchainAddress } = getValues();

      state.blockchainAddress !== blockchainAddress &&
        setBlockchainAddress(blockchainAddress);
    }
  }, [
    getValues,
    formState.isValid,
    setBlockchainAddress,
    state.blockchainAddress
  ]);

  return (
    <StepTextFieldWithLabel
      isDisabled={state.isLoading}
      fieldName={blockchainAddressFieldName}
      labelKey="PRIVATE_DOCUMENT_ENTER_BLOCKCHAIN_ADDRESS_TEXTFIELD_LABEL"
      inputLabelKey="PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_BLOCKCHAIN_ADDRESS"
    />
  );
};

export default SetBlockchainAddressStep;
