import { Stepper, styled } from '@mui/material';

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('desktop')]: {
    width: 268
  },
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(8)
  },
  [theme.breakpoints.down('tablet')]: {
    marginBottom: theme.spacing(4)
  }
}));
