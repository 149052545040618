import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledPrimaryEmphasis = styled('span')(({ theme }) => ({
  color: theme.palette.custom.gradient[900]
}));

export const StyledErrorWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 'calc(100vh - 300px)',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('tablet')]: {
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '1rem'
  }
}));

export const StyledCenterWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
