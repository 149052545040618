import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.custom.neutral[900],
      backgroundColor: theme.palette.custom.textColors[50]
    }),
    notchedOutline: ({ theme }) => ({
      borderColor: theme.palette.custom.neutral[400]
    })
  }
};

export default componentConfiguration;
