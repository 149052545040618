import {
  ProcessId,
  SaveKeysStatusResponse
} from 'src/modules/UserIdentity/types/api';
import INTERVAL_THRESHOLD_MS from 'src/modules/UserIdentity/constants/api';
import getSaveKeysStatus from 'src/modules/UserIdentity/api/getSaveKeysStatus';
import ApiError from 'src/lib/apiError';

const tick = (
  processId: ProcessId,
  resolve: (
    value: PromiseLike<SaveKeysStatusResponse> | SaveKeysStatusResponse
  ) => void,
  reject: (reason?: unknown) => void
) => {
  const timeout = setTimeout(async () => {
    try {
      const response = await getSaveKeysStatus(processId);

      if (response.status === 'SAVE_KEYS_FINISHED') {
        clearTimeout(timeout);
        resolve(response);
      } else if (response.status === 'SAVE_KEYS_ERROR') {
        clearTimeout(timeout);
        reject(new ApiError(response.status, response.status));
      } else {
        tick(processId, resolve, reject);
      }
    } catch (error) {
      clearTimeout(timeout);
      reject(error);
    }
  }, INTERVAL_THRESHOLD_MS);
};

const waitForSaveUserKeys = (processId: ProcessId) =>
  new Promise<SaveKeysStatusResponse>((resolve, reject) => {
    tick(processId, resolve, reject);
  });

export default waitForSaveUserKeys;
