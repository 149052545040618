import { Controller, useFormContext } from 'react-hook-form';
import { useContext, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { DocumentNumberTypeEnum } from 'src/modules/UserIdentity/constants/identityParams';

import { StyledContent, StyledInput, StyledErrorLabel } from './styles';
import { getInputMapBase, initiateEventListeners } from './helpers';
import InputTitle from './InputTitle';

type SetDocumentNumberStepProps = {
  orderNumber?: string;
  documentNumberType?: DocumentNumberTypeEnum;
  infoToolTipTitle?: string;
  onlyDigits?: boolean;
  fullLength: number;
  codeLength: number;
  randomIndices?: boolean;
  name: string;
};

const SetOptDocumentNumberStep = ({
  orderNumber,
  documentNumberType,
  infoToolTipTitle,
  onlyDigits = false,
  fullLength = 10,
  codeLength = 5,
  randomIndices = false,
  name = 'documentNumber'
}: SetDocumentNumberStepProps) => {
  const { setDocumentNumber, state } = useContext(PrivateDocumentContext);
  const { getValues, formState, control, setValue } = useFormContext();

  useEffect(() => {
    if (formState.isValid) {
      const { documentNumber } = getValues();

      state.documentNumber !== documentNumber &&
        setDocumentNumber(documentNumber);
    }
  }, [getValues, formState.isValid, setDocumentNumber, state.documentNumber]);

  const onClearSingleInput = (name: string) => {
    setValue(name, undefined);
  };

  const inputs = document.querySelectorAll('input');
  inputs.forEach(input =>
    initiateEventListeners(input, fullLength, onlyDigits, onClearSingleInput)
  );

  const inputMapBase = useMemo(
    () => getInputMapBase(fullLength, codeLength, randomIndices),
    [codeLength, fullLength, randomIndices]
  );

  const groupError = formState.errors[name];

  return (
    <>
      <InputTitle
        orderNumber={orderNumber}
        documentNumberType={documentNumberType}
        infoToolTipTitle={infoToolTipTitle}
      />
      <StyledContent>
        {inputMapBase.map((v, i) => (
          <Controller
            name={v ? `${name}[${i}]` : `unassigned['${i}']`}
            control={control}
            render={({ field }) => (
              <>
                <StyledInput
                  {...field}
                  autoComplete="off"
                  error={!!groupError}
                  type="tel"
                  pattern="[0-9]"
                  disabled={!v}
                  maxLength={1}
                  placeholder={!v ? 'X' : ''}
                />
              </>
            )}
          />
        ))}
      </StyledContent>
      {!!groupError && (
        <StyledErrorLabel>
          <Trans>{groupError.message}</Trans>
        </StyledErrorLabel>
      )}
    </>
  );
};

export default SetOptDocumentNumberStep;
