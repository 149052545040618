import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

import hasFeature from 'src/lib/hasFeature';
import { ReactComponent as FilterListIcon } from 'src/assets/filterList.svg';
import useDocumentCategories from 'src/modules/DocumentsPrivate/hooks/useDocumentCategories';
import {
  StyledClearButton,
  StyledClearIcon,
  StyledFiltersButtonsContainer,
  StyledFiltersCard,
  StyledFiltersRow,
  StyledSearchButton
} from 'src/modules/DocumentsPrivate/components//documentList/filtersCard/styles';
import { PUBLICATION_DATE_FROM_ZERO_VALUE } from 'src/modules/DocumentsPrivate/components/documentListPublic/utils';

import { StyledBlockchainAddressFilter, StyledRangeGroup } from './styles';
import { PublicDocumentListForm } from './form';
import {
  DatePickerInputAdornmentPublicationDateFrom,
  DatePickerInputAdornmentPublicationDateTo,
  DatePickerInputAdornmentValidSince,
  DatePickerInputAdornmentValidUntil
} from './inputAdornments';

type FiltersCardProps = {
  onSearch: () => void;
  isLoading: boolean;
};

const FiltersCard = ({ onSearch, isLoading }: FiltersCardProps) => {
  const { t } = useTranslation();
  const { data: categories } = useDocumentCategories();
  const { watch, control, setValue, reset, resetField, register } =
    useFormContext<PublicDocumentListForm>();
  const blockchainAddressValue = watch('blockchainAddress');

  return (
    <StyledFiltersCard>
      <StyledFiltersRow>
        <StyledRangeGroup>
          <Controller
            control={control}
            name="publicationDateFrom"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                slots={{
                  inputAdornment: DatePickerInputAdornmentPublicationDateFrom
                }}
                label={t('PRIVATE_DOCUMENT_LIST_INPUT_PUBLISH_DATE_FROM')}
                value={value ? dayjs(value) : null}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    onChange(newValue?.format('YYYY-MM-DD'));
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="publicationDateTo"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                slots={{
                  inputAdornment: DatePickerInputAdornmentPublicationDateTo
                }}
                label={t('PRIVATE_DOCUMENT_LIST_INPUT_PUBLISH_DATE_TO')}
                value={value ? dayjs(value) : null}
                onChange={(newValue: Dayjs | null) => {
                  if (newValue) {
                    onChange(newValue?.format('YYYY-MM-DD'));
                  }
                }}
              />
            )}
          />
        </StyledRangeGroup>
        <StyledBlockchainAddressFilter
          label={t('PRIVATE_DOCUMENTS_LIST_TITLE_COLUMN')}
          placeholder={t('DOCUMENT_LIST_TITLE_INPUT_PLACEHOLDER') || ''}
          variant="outlined"
          InputProps={{
            ...register('title'),
            endAdornment: blockchainAddressValue && (
              <StyledClearIcon
                onClick={() => {
                  resetField('title');
                }}
              >
                <CloseIcon />
              </StyledClearIcon>
            )
          }}
        />

        {hasFeature('documentPrivateCategories') && (
          <FormControl>
            <InputLabel>
              {t('PRIVATE_DOCUMENT_LIST_SELECT_CATEGORY')}
            </InputLabel>
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value, onBlur, name } }) => (
                <Select
                  onClose={() => {
                    setTimeout(() => {
                      if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur();
                      }
                      onBlur();
                    }, 0);
                  }}
                  name={name}
                  onBlur={onBlur}
                  onChange={e => {
                    setTimeout(() => {
                      onChange(e.target.value);
                    }, 0);
                  }}
                  value={value}
                  label={t('PRIVATE_DOCUMENT_LIST_SELECT_CATEGORY')}
                  endAdornment={
                    value && (
                      <StyledClearIcon onClick={() => setValue('category', '')}>
                        <CloseIcon />
                      </StyledClearIcon>
                    )
                  }
                >
                  {(Array.isArray(categories) ? categories : []).map(
                    category => (
                      <MenuItem key={category.hash} value={category.name}>
                        {category.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        )}
      </StyledFiltersRow>
      <StyledFiltersRow>
        <StyledBlockchainAddressFilter
          label={t('PRIVATE_DOCUMENT_DETAILS_BLOCKCHAIN_ADDRESS')}
          placeholder={
            t('PRIVATE_DOCUMENT_LIST_INPUT_BLOCKCHAIN_ADDRESS') || ''
          }
          variant="outlined"
          InputProps={{
            ...register('blockchainAddress'),
            endAdornment: blockchainAddressValue && (
              <StyledClearIcon
                onClick={() => {
                  resetField('blockchainAddress');
                }}
              >
                <CloseIcon />
              </StyledClearIcon>
            )
          }}
        />
        <Controller
          control={control}
          name="validSince"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              slots={{
                inputAdornment: DatePickerInputAdornmentValidSince
              }}
              label={t('DOCUMENT_LIST_VALID_SINCE_INPUT_PLACEHOLDER')}
              value={value ? dayjs(value) : null}
              onChange={(newValue: Dayjs | null) => {
                if (newValue) {
                  onChange(newValue?.format('YYYY-MM-DD'));
                }
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="validUntil"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              slots={{
                inputAdornment: DatePickerInputAdornmentValidUntil
              }}
              label={t('DOCUMENT_LIST_VALID_UNTIL_INPUT_PLACEHOLDER')}
              value={value ? dayjs(value) : null}
              onChange={(newValue: Dayjs | null) => {
                if (newValue) {
                  onChange(newValue?.format('YYYY-MM-DD'));
                }
              }}
            />
          )}
        />
      </StyledFiltersRow>
      <StyledFiltersButtonsContainer>
        <StyledClearButton
          disabled={isLoading}
          size="medium"
          onClick={() => {
            reset({
              publicationDateFrom: PUBLICATION_DATE_FROM_ZERO_VALUE,
              publicationDateTo: '',
              title: '',
              category: '',
              blockchainAddress: '',
              validSince: '',
              validUntil: ''
            });
            onSearch();
          }}
        >
          {t('PRIVATE_DOCUMENT_LIST_CLEAR_ALL')}
        </StyledClearButton>
        <StyledSearchButton
          disabled={isLoading}
          onClick={onSearch}
          startIcon={<FilterListIcon />}
          size="medium"
          variant="outlined"
        >
          {t('PRIVATE_DOCUMENT_LIST_FILTERS_SEARCH_BUTTON')}
        </StyledSearchButton>
      </StyledFiltersButtonsContainer>
    </StyledFiltersCard>
  );
};

export default FiltersCard;
