import { Buffer } from 'buffer';

import { sha256 } from 'js-sha256';

import bufferToHex from './bufferToHex';
import decodeText from './decodeText';
import stringToByteArray from './stringToByteArray';

const convertCEK = CEKinBuffer => {
  const hexResult = bufferToHex(CEKinBuffer);
  const decodedASNObject = decodeText(hexResult);
  const byteArray = stringToByteArray(decodedASNObject.firstString);
  const IV = decodedASNObject.firstString;

  let IVinSHA256 = sha256(byteArray);
  IVinSHA256 = IVinSHA256.toString().slice(0, IVinSHA256.length / 2);

  let IVinDoubleSHA256 = sha256(Buffer.from(IVinSHA256, 'hex'));
  IVinDoubleSHA256 = IVinDoubleSHA256.toString().slice(
    0,
    IVinDoubleSHA256.length / 2
  );

  const rawKey = decodedASNObject.secondString.match(/[\da-f]{2}/gi);
  const key = !!rawKey && new Uint8Array(rawKey.map(h => parseInt(h, 16)));

  return {
    key,
    IV,
    IVinSHA256,
    IVinDoubleSHA256
  };
};

export default convertCEK;
