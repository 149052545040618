import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ReactComponent as DocumentListHeader } from 'src/assets/documentListHeader.svg';
import getPublishers from 'src/modules/DocumentsPrivate/api/getPublishers';
import { Publisher } from 'src/modules/DocumentsPrivate/types';
import { PrivateDocumentListForm } from 'src/modules/DocumentsPrivate/components//documentList/filtersCard/form';

import {
  StyledDocumentsListHeader,
  StyledDocumentsListHeaderContent,
  StyledHeadingSearch,
  StyledHeadingSearchContainer
} from './styles';

type Props = {
  isLoading: boolean;
  onSearch: () => void;
};

const Header = ({ onSearch, isLoading }: Props) => {
  const { t } = useTranslation();
  const [publisherList, setPublisherList] = useState<Publisher[]>([]);
  const { control } = useFormContext<PrivateDocumentListForm>();

  useEffect(() => {
    const getPublishersWrapper = async () => {
      const { data: publisherList } = await getPublishers();

      setPublisherList(publisherList.publishers);
    };

    void getPublishersWrapper();
  }, []);

  return (
    <StyledDocumentsListHeader>
      <DocumentListHeader />
      <StyledDocumentsListHeaderContent>
        <Typography variant="h2" fontWeight={600}>
          {t('PRIVATE_DOCUMENT_LIST_SEARCH_PUBLISHER')}
        </Typography>
        <StyledHeadingSearchContainer>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <StyledHeadingSearch
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(newValue ? (newValue as Publisher).name : '');
                }}
                value={publisherList.find(p => p.name === value) || null}
                disablePortal
                noOptionsText={t('DOCUMENT_LIST_NO_DATA_TITLE')}
                options={publisherList}
                getOptionLabel={option => (option as Publisher).name}
                size="small"
                renderInput={props => (
                  <TextField
                    {...props}
                    label={t('PRIVATE_DOCUMENT_LIST_SELECT_OR_INPUT_PUBLISHER')}
                  />
                )}
              />
            )}
            name="publisherName"
          ></Controller>
          <Button variant="contained" onClick={onSearch} disabled={isLoading}>
            {t('PRIVATE_DOCUMENTS_LIST_SEARCH_PUBLISHER_BUTTON')}
          </Button>
        </StyledHeadingSearchContainer>
      </StyledDocumentsListHeaderContent>
    </StyledDocumentsListHeader>
  );
};

export default Header;
