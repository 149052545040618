import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { ReactComponent as SuccessIcon } from 'src/assets/success.svg';
import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import {
  Stepper,
  StepperContainer,
  StepperContent,
  StepperControls
} from 'src/components/stepper';
import { ChainLoader } from 'src/components/loader';
import CenterContainer from 'src/components/centerContainer';
import { StyledHeader, StyledIcon } from 'src/components/pageBanner/styles';
import routes from 'src/constants/routes';
import useNationalNodeResolve from 'src/modules/UserIdentity/hooks/useNationalNodeResolve';
import { IDENTITY_DETAILS_KEY } from 'src/modules/UserIdentity/constants/session';
import { NationalNodeIdentityDetails } from 'src/modules/UserIdentity/types/session';
import { NationalNodeResponseStatus } from 'src/modules/UserIdentity/constants/errors';
import useQueryParams from 'src/hooks/useQueryParams';

const NationalNodeSuccess = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { sessionId, SAMLart } = useQueryParams();

  const [_, setIdentityDetails] =
    useSessionStorage<NationalNodeIdentityDetails | null>(
      IDENTITY_DETAILS_KEY,
      null
    );

  const navigateToNationalNodeFailure = () =>
    navigate(routes.documentAccessTypeNationalNodeFailure);

  const { mutate, data } = useNationalNodeResolve({
    onSuccess: ({ status, identityResponse }) => {
      if (status !== NationalNodeResponseStatus.SUCCESS) {
        navigateToNationalNodeFailure();
      }

      setIdentityDetails({
        firstName: identityResponse.currentGivenName,
        lastName: identityResponse.currentFamilyName,
        documentNumber: identityResponse.personIdentifier
      });
    },
    onError: navigateToNationalNodeFailure
  });

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep:
      RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION
  };

  const navigateToRegisterDetails = useCallback(
    () =>
      navigate(
        `${routes.documentAccessTypeRegisterDetails}?sessionId=${sessionId}&SAMLart=${SAMLart}`
      ),
    [SAMLart, navigate, sessionId]
  );

  const isSuccess =
    data?.status && data.status === NationalNodeResponseStatus.SUCCESS;

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        navigateToRegisterDetails();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, navigateToRegisterDetails]);

  useEffect(() => {
    mutate({ sessionId, encryptedData: SAMLart });
  }, [SAMLart, mutate, sessionId]);

  return (
    <StepperContainer>
      {!isHorizontalStepper && (
        <Stepper {...stepperProps} orientation="vertical" />
      )}
      <StepperContent>
        {isHorizontalStepper && (
          <Stepper {...stepperProps} orientation="horizontal" />
        )}

        <CenterContainer maxWidth={500}>
          {!isSuccess ? (
            <ChainLoader width={156} height={152} />
          ) : (
            <Stack spacing={2} alignItems="center">
              <StyledIcon>
                <SuccessIcon />
              </StyledIcon>
              <StyledHeader variant="h4">
                {t('NATIONAL_NODE_POSITIVE_RESULT')}
              </StyledHeader>
            </Stack>
          )}
        </CenterContainer>
        {isSuccess && (
          <StepperControls>
            <Button onClick={navigateToRegisterDetails} variant="contained">
              <span>{t('NATIONAL_NODE_POSITIVE_RESULT_BUTTON')}</span>
            </Button>
          </StepperControls>
        )}
      </StepperContent>
    </StepperContainer>
  );
};

export default NationalNodeSuccess;
