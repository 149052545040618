/* eslint-disable */
import { Buffer } from 'buffer';
import ASN1Decoder from './ASN1Decoder';
import ObjectCaster from './objectCaster';
import asn1creators from './asn1creators';

const generateEciesPrivObjects = (data, stateObject, privateKey) => {
  if (data.curvatureType === 'ecies') {
    stateObject.privateKeys.ecies[data.curvatureName] = {
      value: Buffer.from(privateKey.SEQUENCE_0.OCTET_STRING_0, 'hex'),
      shaName: data.safebagSHA
    };
  }
};

export default (data, stateObject) => {
  const sequenceStructure = ASN1Decoder.decodeHexString(data.decPrivKeyHex);
  const curve = ObjectCaster.renderCurveByName(data.curvatureName);
  const privateKey = ASN1Decoder.decodeHexString(
    sequenceStructure.SEQUENCE_0.OCTET_STRING_0
  );

  generateEciesPrivObjects(data, stateObject, privateKey);
  const publicKey = curve.curvature.getPublic(
    Buffer.from(privateKey.SEQUENCE_0.OCTET_STRING_0, 'hex')
  );
  const objectToSaveInAsn1 = {
    publicKey,
    id: sequenceStructure.SEQUENCE_0.SEQUENCE_0.OBJECT_IDENTIFIER_0.value,
    number: sequenceStructure.SEQUENCE_0.SEQUENCE_0.OBJECT_IDENTIFIER_1.value,
    safeBagSha: data.safebagSHA
  };

  return asn1creators.createECIESECDSAPublicKeysDataWithKeyIdSequence(
    objectToSaveInAsn1
  );
};
