import { Autocomplete, styled } from '@mui/material';

export const StyledDocumentsListHeader = styled('div')(() => ({
  position: 'relative',
  svg: {
    borderRadius: '16px'
  }
}));

export const StyledDocumentsListHeaderContent = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}));

export const StyledHeadingSearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(7),
  marginLeft: '-51px'
}));

export const StyledHeadingSearch = styled(Autocomplete)(() => ({
  width: '437px'
}));
