/* eslint-disable */

import importKey from './importKey';

export default async (
  IV,
  key,
  encoded,
  algorithm = 'AES-GCM',
  keyImported = false,
  algorithmData = {}
) => {
  let cryptoKey = key;

  if (!keyImported) {
    cryptoKey = await importKey(key, algorithm);
  }

  return crypto.subtle.encrypt(
    {
      name: algorithm,
      iv: IV,
      ...algorithmData
    },
    cryptoKey,
    encoded
  );
};
