import { Trans } from 'react-i18next';

import { ChainLoader } from 'src/components/loader';

import { StyledContainer, StyledTypography } from './styles';

const TableLoader = () => (
  <StyledContainer>
    <ChainLoader height={50} width={50} />
    <StyledTypography variant="buttonLarge">
      <Trans>LOADING_DATA_IN_PROGRESS</Trans>
    </StyledTypography>
  </StyledContainer>
);

export default TableLoader;
