import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledContent, StyledHeader, StyledIcon } from './styles';

type PageBannerProps = {
  titleKey: string;
  subtitleKey: string;
  icon: ReactNode;
};
const PageBanner = ({ titleKey, subtitleKey, icon }: PageBannerProps) => {
  const { t } = useTranslation();

  return (
    <StyledContent>
      <StyledIcon>{icon}</StyledIcon>
      <StyledHeader variant="h4">{t(titleKey)}</StyledHeader>
      <Typography variant="subtitle2">{t(subtitleKey)}</Typography>
    </StyledContent>
  );
};

export default PageBanner;
