/* eslint-disable */

import decrypt from './decrypt';
import importKey from './importKey';

const decryptCEKLite = async (key, iv, encryptedCEK) => {
  const importedKey = await importKey(key, 'AES-CBC');

  return decrypt(encryptedCEK, iv, importedKey, 'AES-CBC');
};

export default decryptCEKLite;
