import { Check } from '@mui/icons-material';
import { FormControlLabel, styled, Typography } from '@mui/material';

export const StyledSignatureProgressMessageTitle = styled(Typography)(() => ({
  fontWeight: 400,
  maxWidth: 480,
  i: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 600
  }
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));

export const StyledCheckmark = styled(Check)(({ theme }) => ({
  color: theme.palette.custom.success[500],
  width: theme.spacing(2.5),
  height: theme.spacing(2.5)
}));

export const StyledCodeResendMessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1)
}));
