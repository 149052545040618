import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.custom.neutral[500],
      padding: theme.spacing(1.25),
      marginTop: theme.typography.pxToRem(-9.5),
      marginBottom: theme.typography.pxToRem(9.5),
      '&.Mui-checked': {
        color: theme.palette.primary.main
      },
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(20)
      }
    })
  }
};

export default componentConfiguration;
