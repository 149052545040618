import { Avatar, Typography } from '@mui/material';
import { useContext } from 'react';

import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';

import { StyledTypographyContainer, StyledContainer } from './styles';

const UserDetails = () => {
  const { state } = useContext(UserIdentityContext);

  if (!state.user?.firstName || !state.user.lastName) {
    return null;
  }
  return (
    <StyledContainer>
      <Avatar>
        {state.user?.firstName[0]}
        {state.user?.lastName[0]}
      </Avatar>
      <StyledTypographyContainer>
        <Typography variant="body2" fontWeight="600">
          {state.user.firstName} {state.user.lastName}
        </Typography>
        <Typography variant="caption2">{state.user.email}</Typography>
      </StyledTypographyContainer>
    </StyledContainer>
  );
};

export default UserDetails;
