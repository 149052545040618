import AppConfiguration from 'src/types/appConfiguration';

export default {
  siteTitle: 'SITE_TITLE_BILLON_UNIFIED_BLOCKCHAIN',
  documentPrivate: true,
  documentPublic: true,
  documentVerification: true,
  documentRemoteSigning: false,
  userLogin: true,
  userRegistration: true,
  userResetPassword: false,
  mockEndpoints: false,
  diploma: false,
  envPrefix: 'DEMO'
} as AppConfiguration;
