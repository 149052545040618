import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from 'src/constants/routes';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';

const useNavigateToDocument = () => {
  const navigate = useNavigate();

  const { state: userIdentityState } = useContext(UserIdentityContext);
  const { uuid } = userIdentityState;

  const navigateToDocument = (blockchainAddress: string) => {
    navigate(
      routes.documentBlockchainAddress
        .replace(':uuid', uuid as string)
        .replace(':address', blockchainAddress as string)
    );
  };

  return navigateToDocument;
};

export default useNavigateToDocument;
