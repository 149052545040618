import { Components, Theme } from '@mui/material';

import hasFeature from 'src/lib/hasFeature';

const componentConfiguration: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: hasFeature('tauron')
        ? theme.palette.custom.textColors[100]
        : theme.palette.primary.main,
      borderBottomWidth: '1px'
    })
  }
};

export default componentConfiguration;
