import getApiClient from 'src/lib/getApiClient';
import { GetPrivateDocumentCatalogResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const getCatalogByBlockchainAddress = (blockchainAddress: string) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetPrivateDocumentCatalogResponse>(
    apiRoutes.privateDocumentCatalog.replace(
      '{blockchainAddress}',
      blockchainAddress
    )
  );
};

export default getCatalogByBlockchainAddress;
