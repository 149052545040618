import { LoadingButton } from '@mui/lab';
import { Box, Button, styled } from '@mui/material';

export const StyledRowWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'stretch',
  marginBottom: '35px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));

export const StyledTitleWrapper = styled(Box)({
  width: '100%',
  alignSelf: 'center'
});

export const StyledDownloadButton = styled(Button)({
  minWidth: '280px',
  minHeight: '68px',
  padding: '8px 20px 8px 20px',
  fontSize: '22px'
});

export const StyledDownloadingButton = styled(LoadingButton)({
  minWidth: '280px',
  minHeight: '68px',
  padding: '8px 20px 8px 20px',
  fontSize: '22px'
});
