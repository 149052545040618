import { useTranslation } from 'react-i18next';
import { useContext, useCallback, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import useDownloadDocument from 'src/modules/DocumentsPrivate/hooks/useDownloadDocument';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { ReactComponent as DownloadIcon } from 'src/assets/fileDownload.svg';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import StepTextFieldWithLabel from 'src/components/stepper/components/stepperTextFieldWithLabel';
import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';

import {
  StyledLinkWrapper,
  StyledLink,
  StyledDownloadContainer,
  StyledButtonContainer
} from './styles';

type Props = {
  blockchainAddress: string;
};

type CodeForm = { code: string };

export const schema = yup.object({
  code: yup
    .string()

    .required(ERROR_EMPTY_FIELD)
});

const DocumentHistoryDownload = ({ blockchainAddress }: Props) => {
  const { t } = useTranslation();
  const { mutate: downloadDocument, isLoading, error } = useDownloadDocument();
  const { state: privateDocumentState } = useContext(PrivateDocumentContext);
  const { state: userIdentityContext } = useContext(UserIdentityContext);
  const canDownload =
    userIdentityContext.keys ||
    blockchainAddress === privateDocumentState.blockchainAddress;

  const formProps = useForm<CodeForm>({
    resolver: yupResolver(schema)
  });
  const onCodeSubmit = useCallback(
    (form: CodeForm) => {
      downloadDocument({ blockchainAddress, code: form.code });
    },
    [blockchainAddress, downloadDocument]
  );

  useEffect(() => {
    if (error) {
      formProps.setError('code', {
        message: t('ERROR_INVALID_ACCESS_CODE') as string
      });
    } else {
      formProps.clearErrors();
    }
  }, [error, formProps, t]);

  if (canDownload) {
    return (
      <StyledLinkWrapper>
        <LoadingButton
          size="small"
          loadingPosition="end"
          loading={isLoading}
          onClick={() =>
            downloadDocument({
              blockchainAddress,
              code: privateDocumentState.accessCode
            })
          }
          endIcon={<DownloadIcon />}
        >
          <StyledLink variant="buttonSmall">
            {t(
              isLoading
                ? 'LOADING_DATA_BUTTON_LABEL'
                : 'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON'
            )}
          </StyledLink>
        </LoadingButton>
      </StyledLinkWrapper>
    );
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(onCodeSubmit)}>
        <StyledDownloadContainer>
          <StepTextFieldWithLabel
            isDisabled={isLoading}
            fieldName="code"
            labelKey="PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_DOWNLOAD_INFO"
            inputLabelKey="PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_ACCESS_CODE"
          />
          <StyledButtonContainer>
            <LoadingButton
              loading={isLoading}
              size="small"
              loadingPosition="end"
              endIcon={<DownloadIcon />}
              type="submit"
            >
              <StyledLink variant="buttonSmall">
                {t(
                  isLoading
                    ? 'LOADING_DATA_BUTTON_LABEL'
                    : 'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON'
                )}
              </StyledLink>
            </LoadingButton>
          </StyledButtonContainer>
        </StyledDownloadContainer>
      </form>
    </FormProvider>
  );
};

export default DocumentHistoryDownload;
