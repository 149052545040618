import { styled, Typography } from '@mui/material';

export const StyledFileInput = styled('div')<{
  isDragging: boolean;
  isError: boolean;
}>(({ theme, isDragging, isError }) => ({
  cursor: 'pointer',
  padding: theme.spacing(10, 10),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(10, 5)
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px dashed',
  borderColor: isDragging
    ? theme.palette.primary.main
    : isError
    ? theme.palette.error.main
    : theme.palette.custom.neutral[200],
  borderRadius: '4px',
  gap: theme.spacing(1),
  background: isDragging
    ? theme.palette.custom.primary[50]
    : isError
    ? theme.palette.error.contrastText
    : 'inherit'
}));

export const StyledFileExtensionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[700]
}));

export const StyledFileLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  [theme.breakpoints.only('mobile')]: {
    display: 'flex',
    flexDirection: 'column'
  },
  i: {
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    textDecoration: 'underline',
    textAlign: 'center'
  }
}));

export const StyledErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main
}));
