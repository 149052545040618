/* eslint-disable */
import { Buffer } from 'buffer';
import ASN1Decoder from './ASN1Decoder';
import bufferToHex from './bufferToHex';
import encrypt from './encrypt';
import hexToUInt8Array from './hexToUInt8Array';

export default async (password, kek) => {
  const kekNormalized = ASN1Decoder.decodeHexString(bufferToHex(kek));
  const kekIV = kekNormalized.SEQUENCE_0.OCTET_STRING_0;
  const kekKey = kekNormalized.SEQUENCE_0.OCTET_STRING_1;

  return encrypt(
    hexToUInt8Array(kekIV),
    hexToUInt8Array(kekKey),
    Buffer.from(password)
  );
};
