import { Chip, ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';

import { MapStatusToPropsType } from './types';

type StatusChipProps = ChipProps & {
  signingStatus?: AgreementStatusEnum;
};

const StatusChip = ({ signingStatus, ...props }: StatusChipProps) => {
  const { t } = useTranslation();

  const mapPropsToSignStatus: MapStatusToPropsType = {
    [AgreementStatusEnum.WAITING_FOR_RECEIVER]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_TO_SIGN'),
      color: 'primary'
    },
    [AgreementStatusEnum.ACCEPTED]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_ACCEPTED'),
      color: 'success'
    },
    [AgreementStatusEnum.ACCEPTED_DEPRECATED]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_ACCEPTED'),
      color: 'success'
    },
    [AgreementStatusEnum.REJECTED]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_REJECTED'),
      color: 'error'
    },
    [AgreementStatusEnum.REJECTED_DEPRECATED]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_REJECTED'),
      color: 'error'
    },
    [AgreementStatusEnum.ACCEPTED_WAITING_FOR_PUBLISHING]: {
      label: t('ACCEPTED_WAITING_FOR_PUBLISHING'),
      color: 'primary'
    },
    [AgreementStatusEnum.REJECTED_WAITING_FOR_PUBLISHING]: {
      label: t('REJECTED_WAITING_FOR_PUBLISHING'),
      color: 'error'
    },
    [AgreementStatusEnum.ERROR]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_ERROR'),
      color: 'error'
    },
    [AgreementStatusEnum.DEFAULT]: {
      label: t('PRIVATE_DOCUMENT_LIST_STATUS_DEFAULT'),
      color: 'success'
    }
  };

  return (
    <Chip
      {...props}
      {...(signingStatus && mapPropsToSignStatus[signingStatus])}
    />
  );
};

export default StatusChip;
