import { styled } from '@mui/material';

const StyledFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

export default StyledFormContainer;
