import { TextFieldProps } from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useWatch } from 'react-hook-form';

import { StyledFormControl, StyledFormHelperText } from './styles';

export const PasswordField = (props: TextFieldProps) => {
  if (!props.InputProps?.name) {
    throw new Error('`props.InputProps name not set');
  }

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const passwordValue = useWatch({ name: props.InputProps.name });
  const [displayPasswordAdornment, setDisplayPasswordAdornment] =
    useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <StyledFormControl variant="outlined">
      <InputLabel
        htmlFor={`id-${props.InputProps?.name}`}
        error={Boolean(props.helperText)}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        error={Boolean(props.helperText)}
        onFocus={() => setDisplayPasswordAdornment(true)}
        id={`id-${props.InputProps?.name}`}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          displayPasswordAdornment && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
        label={props.label}
        {...props.InputProps}
        onBlur={e => {
          !passwordValue && setDisplayPasswordAdornment(false);
          if (props.InputProps?.onBlur) {
            props.InputProps.onBlur(e);
          }
        }}
      />
      {props.helperText && (
        <StyledFormHelperText error>
          {t(props.helperText as string)}
        </StyledFormHelperText>
      )}
    </StyledFormControl>
  );
};

export default PasswordField;
