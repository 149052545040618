import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import { MaintenanceSettingsResponse } from 'src/modules/UserIdentity/types/api';

const getMaintenanceSettings = () => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  return apiClient.get<MaintenanceSettingsResponse>(
    apiRoutes.maintenanceSettings
  );
};

export default getMaintenanceSettings;
