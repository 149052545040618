import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import {
  DocumentSettings,
  GetSettingsResponse
} from 'src/modules/DocumentsPrivate/types';
import { parseMaxFileSizeString } from 'src/modules/DocumentsPrivate/utils';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const getDocumentSettings = () => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetSettingsResponse>(apiRoutes.settings);
};

const useDocumentSettings = (
  options?: UseQueryOptions<DocumentSettings, AxiosError>
) =>
  useQuery<DocumentSettings, AxiosError>(
    [queryKeys.getDocumentSettings],
    async () => {
      const { data: response } = await getDocumentSettings();

      return parseMaxFileSizeString(response.MAX_SIZE_VERIFY_FILE);
    },
    options
  );

export default useDocumentSettings;
