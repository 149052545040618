import { Box, Button } from '@mui/material';
import { styled } from '@mui/material';

export const StyledPrimaryEmphasis = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const StyledErrorWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 'calc(100vh - 450px)',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('tablet')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '1rem'
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.textColors[100],
    '& svg path': {
      color: theme.palette.custom.textColors[100],
      fill: theme.palette.custom.textColors[100]
    }
  }
}));

export const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '500px',
  [theme.breakpoints.only('mobile')]: {
    width: '100%'
  }
}));
