import { useTranslation } from 'react-i18next';

import { ReactComponent as OutageIcon } from 'src/assets/outage.svg';

import { StyledContainer, StyledTitle, StyledDescription } from './styles';

const ServiceOutage = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <OutageIcon />
      <StyledTitle variant="h4">{t('OUTAGE_TITLE')}</StyledTitle>
      <StyledDescription variant="subtitle1">
        {t('OUTAGE_DESCRIPTION')}
      </StyledDescription>
    </StyledContainer>
  );
};

export default ServiceOutage;
