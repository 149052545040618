import * as yup from 'yup';

import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';

import { BlockchainDocumentAddressRegex, VerifyDocumentEnum } from './document';
import { ERROR_WRONG_BLOCKCHAIN_ADDRESS_LENGTH } from './errors';

export interface VerifyDocumentForm {
  file?: File | null;
  blockchainAddress?: string;
  verifyType: VerifyDocumentEnum;
  documentNumber?: string;
}

export const validatedBlockChainAddress = yup
  .string()
  .required(ERROR_EMPTY_FIELD)
  .matches(new RegExp(BlockchainDocumentAddressRegex), {
    message: ERROR_WRONG_BLOCKCHAIN_ADDRESS_LENGTH
  });
