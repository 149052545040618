import {
  EncryptedCEK,
  EncryptedCekAuthorizationCode,
  GetPrivateDocumentCatalogResponse
} from 'src/modules/DocumentsPrivate/types';
import decodeDocument from 'src/modules/Crypto/lib/decodeDocument';
import getPrivateDocument from 'src/modules/Crypto/lib/getPrivateDocument';
import {
  AgreementStatusEnum,
  DocumentTypes
} from 'src/modules/DocumentsPrivate/constants/document';
import {
  ERROR_AUTHORIZATION_CODE_NOT_FOUND,
  ERROR_WRONG_AUTHORIZATION_CODE
} from 'src/modules/DocumentsPrivate/constants/errors';
import getDocumentByBlockChainAddress from 'src/modules/DocumentsPrivate/api/getDocumentByBlockchainAddress';
import hasFeature from 'src/lib/hasFeature';

type UseCatalogByBlockchainAddressParams = {
  blockchainAddress: string;
  authorizedCode: string;
  catalog: GetPrivateDocumentCatalogResponse;
};

const useDocumentByBlockchainAddress =
  () =>
  async ({
    blockchainAddress,
    authorizedCode,
    catalog
  }: UseCatalogByBlockchainAddressParams) => {
    if (hasFeature('mockEndpoints')) {
      return {
        documentType: DocumentTypes.PDF,
        documentData: new ArrayBuffer(1),
        title: 'testTitle',
        additionalDetails: {
          agreementStatus: AgreementStatusEnum.WAITING_FOR_RECEIVER
        },
        legalValidityFinishDate: null,
        legalValidityStartDate: new Date(),
        fullCategory: 'Test-category',
        mainCategory: 'Test-category'
      };
    }
    const { encryptedSensitiveContent, encryptedCekList } = catalog;
    const encryptedCekAuthorizationCode = (
      encryptedCekList as EncryptedCEK[]
    ).find(i =>
      Boolean((i as EncryptedCekAuthorizationCode).initializationVector)
    ) as EncryptedCekAuthorizationCode;

    if (!encryptedCekAuthorizationCode) {
      throw new Error(ERROR_AUTHORIZATION_CODE_NOT_FOUND);
    }

    let privateDocument;

    try {
      privateDocument = await getPrivateDocument({
        encryptedSensitiveContent,
        authorizedCode,
        encryptedCekAuthorizationCode
      });
    } catch (error) {
      throw new Error(ERROR_WRONG_AUTHORIZATION_CODE);
    }

    const { data: encodedDocument } = await getDocumentByBlockChainAddress({
      catalogOfDocumentContent: privateDocument.sensitiveData.catalogue,
      blockchainAddress
    });

    const document = await decodeDocument(
      privateDocument.convertedCEK,
      encodedDocument.encryptedDocumentContent
    );

    return {
      documentType: DocumentTypes.PDF,
      documentData: document,
      title: privateDocument.sensitiveData.title,
      fullCategory: privateDocument.sensitiveData.fullCategory,
      mainCategory: privateDocument.sensitiveData.mainCategory,
      legalValidityFinishDate:
        privateDocument.sensitiveData.legalValidityFinishDate,
      legalValidityStartDate:
        privateDocument.sensitiveData.legalValidityStartDate
    };
  };

export default useDocumentByBlockchainAddress;
