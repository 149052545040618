import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import getMaintenanceSelfTest from 'src/modules/UserIdentity/api/getMaintenanceSelfTest';
import { UserCredentials } from 'src/modules/UserIdentity/types/session';
import {
  FullIdentityLoadUserKeysResponse,
  ServicePayloadResponse
} from 'src/modules/UserIdentity/types/api';

type UseRestoreSessionResponse = {
  kycGwId: string;
  uuid: string;
  keys: FullIdentityLoadUserKeysResponse;
  firstName: string;
  lastName: string;
  email: string;
  hashFromHashFromPassword: string;
};

const useRestoreSession = (
  options: UseMutationOptions<
    UseRestoreSessionResponse,
    AxiosError,
    UserCredentials
  > = {}
) =>
  useMutation<UseRestoreSessionResponse, AxiosError, UserCredentials>(
    async sessionCredentials => {
      const {
        data: { service }
      } = await getMaintenanceSelfTest();
      const kycGwId = (
        service.payload.find(
          s => s.name === 'KYCIdentityManagementService'
        ) as ServicePayloadResponse
      )?.id;

      if (!kycGwId) {
        throw new Error('KYC_GW_ERROR');
      }
      return {
        kycGwId,
        hashFromHashFromPassword: sessionCredentials.hashFromHashFromPassword,
        uuid: sessionCredentials.uuid,
        keys: sessionCredentials.response,
        firstName: sessionCredentials.firstName,
        lastName: sessionCredentials.lastName,
        email: sessionCredentials.email
      };
    },
    options
  );

export default useRestoreSession;
