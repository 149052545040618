import { useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { ClipboardEventHandler } from 'react';

import { getErrorText } from 'src/components/form/utils';

export const FormTextField = (props: TextFieldProps & { trim?: boolean }) => {
  const { t } = useTranslation();
  const { formState, setValue, trigger } = useFormContext();
  const { trim, ...textFieldProps } = props;
  const error = getErrorText(
    formState.errors,
    props.name || props.InputProps?.name
  );

  const helperText = props.helperText ? t(props.helperText as string) : '';
  const handlePaste: ClipboardEventHandler = e => {
    let pastedText = '';

    if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData('text/plain');
    }

    setTimeout(() => {
      if (props.InputProps?.name) {
        setValue(props.InputProps.name, pastedText.split(' ').join(''));
        trigger(props.InputProps.name);
      }
    });
  };

  return (
    <TextField
      onPaste={trim ? handlePaste : undefined}
      error={Boolean(!formState.isValid && error)}
      helperText={
        !formState.isValid && t((error?.message || helperText) as string)
      }
      {...textFieldProps}
    />
  );
};

export default FormTextField;
