import { appConfig } from 'src/constants/appConfig';
import { DocumentNumberTypeEnum } from 'src/modules/UserIdentity/constants/identityParams';

export const getDocumentNumberTypeLength = (
  dcumentNumberType: DocumentNumberTypeEnum
) => {
  if (appConfig.documentNumberAlgorithm) {
    switch (dcumentNumberType) {
      case DocumentNumberTypeEnum.NIP:
        return (
          appConfig.documentNumberAlgorithm.NIP.codeLength +
            appConfig.documentNumberAlgorithm.NIP.dashesCount || 0
        );

      case DocumentNumberTypeEnum.POSTAL_CODE:
        return appConfig.documentNumberAlgorithm.POSTAL_CODE.codeLength;

      default:
        return appConfig.documentNumberAlgorithm.PESEL.codeLength;
    }
  }
};
