import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import { AgreementSignatureResponse } from 'src/modules/DocumentsPrivate/types';
import {
  SendDownloadingEventResponse,
  UseSendDownloadingEventParams
} from 'src/modules/DocumentsPrivate/types/downloadingEvent';

const postSendDownloadingEvent = async ({
  downloadToken,
  blockchainAddress
}: UseSendDownloadingEventParams): Promise<SendDownloadingEventResponse> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const { data: response } = await apiClient.post<AgreementSignatureResponse>(
    `${apiRoutes.privateDocumentDownloadEvent.replace(
      '{blockchainAddress}',
      blockchainAddress
    )}?downloadToken=${downloadToken.toString()}`
  );

  return response;
};

export default postSendDownloadingEvent;
