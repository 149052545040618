import {
  TauronPrivateDocumentFirstStepKeyEnum,
  TauronPrivateDocumentStepInputLabel
} from 'src/modules/DocumentsPrivate/constants/stepper';

export enum SstEnum {
  P = 'P',
  N = 'N',
  PC = 'PC'
}

export enum DocumentNumberTypeEnum {
  PESEL = 'PESEL',
  NIP = 'NIP',
  POSTAL_CODE = 'POSTAL_CODE'
}

export const parseSstToRequestData = (sst: string | null) => {
  switch (sst) {
    case SstEnum.N:
      return DocumentNumberTypeEnum.NIP;
    case SstEnum.PC:
      return DocumentNumberTypeEnum.POSTAL_CODE;
    case SstEnum.P:
    default:
      return DocumentNumberTypeEnum.PESEL;
  }
};

export const parseDocumentNumberTypeToStepTitle = (
  documentNumberType: DocumentNumberTypeEnum | null
) => {
  switch (documentNumberType) {
    case DocumentNumberTypeEnum.NIP:
      return TauronPrivateDocumentFirstStepKeyEnum.NIP;
    case DocumentNumberTypeEnum.POSTAL_CODE:
      return TauronPrivateDocumentFirstStepKeyEnum.POSTAL_CODE;
    case DocumentNumberTypeEnum.PESEL:
    default:
      return TauronPrivateDocumentFirstStepKeyEnum.PESEL;
  }
};

export const parseDocumentNumberTypeToInputLabel = (
  documentNumberType: DocumentNumberTypeEnum | null
) => {
  switch (documentNumberType) {
    case DocumentNumberTypeEnum.NIP:
      return TauronPrivateDocumentStepInputLabel.NIP;
    case DocumentNumberTypeEnum.POSTAL_CODE:
      return TauronPrivateDocumentStepInputLabel.POSTAL_CODE;
    case DocumentNumberTypeEnum.PESEL:
    default:
      return TauronPrivateDocumentStepInputLabel.PESEL;
  }
};
