import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  AgreementResendConfirmationCodeResponse,
  AgreementResendConfirmationCodeRequest,
  AgreementConfirmationErrorType
} from 'src/modules/UserIdentity/types/api';
import postAgreementResendConfirmationCode from 'src/modules/UserIdentity/api/postAgreementResendConfirmationCode';

const useAgreementResendCode = (
  options: UseMutationOptions<
    AgreementResendConfirmationCodeResponse,
    AxiosError<AgreementConfirmationErrorType>,
    AgreementResendConfirmationCodeRequest
  >
) =>
  useMutation<
    AgreementResendConfirmationCodeResponse,
    AxiosError<AgreementConfirmationErrorType>,
    AgreementResendConfirmationCodeRequest
  >(postAgreementResendConfirmationCode, options);

export default useAgreementResendCode;
export const useResend2FACode = useAgreementResendCode;
