import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import {
  StyledContainer,
  StyledLevel,
  StyledLevels,
  StyledLevelText
} from './styles';
import {
  MEDIUM_WEAK_PASSWORD_GROUPS,
  MIN_WEAK_PASSWORD_GROUPS,
  STRONG_WEAK_PASSWORD_GROUPS
} from './constants';

const firstGroup = /.*[0-9]/;
const secondGroup = /.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/;
const thirdGroup = /.*[a-z]/;
const fourthGroup = /.*[A-Z]/;
const fifthGroup = /(.*).{6,}/;

const groups = [firstGroup, secondGroup, thirdGroup, fourthGroup, fifthGroup];

type PasswordStrengthIndicatorProps = {
  value: string;
};

const PasswordStrengthIndicator = ({
  value
}: PasswordStrengthIndicatorProps) => {
  const { t } = useTranslation();

  const result = groups
    .map(group => value && group.test(value))
    .filter(Boolean).length;

  const isStrongPassword = result >= STRONG_WEAK_PASSWORD_GROUPS;
  const isMediumPassword = result >= MEDIUM_WEAK_PASSWORD_GROUPS;
  const isWeakPassword = result >= MIN_WEAK_PASSWORD_GROUPS;
  const secondLevelVariant = isStrongPassword
    ? 'STRONG'
    : isMediumPassword && 'MEDIUM';
  const firstLevelVariant = isStrongPassword
    ? 'STRONG'
    : isMediumPassword
    ? 'MEDIUM'
    : isWeakPassword && 'WEAK';

  return (
    <StyledContainer>
      <StyledLevels>
        <StyledLevel variant={firstLevelVariant} />
        <StyledLevel variant={secondLevelVariant} />
        <StyledLevel variant={isStrongPassword && 'STRONG'} />
      </StyledLevels>
      <StyledLevelText variant={firstLevelVariant}>
        <Typography variant="caption2" fontWeight="600">
          {firstLevelVariant && t(`PASSWORD_VALIDATION_${firstLevelVariant}`)}
        </Typography>
      </StyledLevelText>
    </StyledContainer>
  );
};

export default PasswordStrengthIndicator;
