import encodeEventData from 'src/modules/Crypto/lib/activeDeliveryEvents/encodeEventData';
import encryptEventData from 'src/modules/Crypto/lib/activeDeliveryEvents/encryptEventData';

const createActiveDeliveryEvent = async ({
  tokenIdBase58,
  documentHash,
  uuid,
  partnerEncryptionKey
}) => {
  const publisherPublicKey = partnerEncryptionKey.keyInfo?.keyData;
  const keyId = partnerEncryptionKey?.keyInfo?.keyId;
  const keyNumber = partnerEncryptionKey?.keyInfo?.keyNumber;

  const eventData = await encodeEventData({
    token_id_base58: tokenIdBase58,
    document_id_base58: documentHash,
    event_issuer_identity_base58: uuid
  });

  return await encryptEventData({
    publisherPublicKey,
    data: eventData,
    keyId,
    keyNumber
  });
};

export default createActiveDeliveryEvent;
