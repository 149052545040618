import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { KeyboardArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Checkbox, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  StyledCheckmark,
  StyledCodeResendMessageWrapper,
  StyledFormControlLabel
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/components/steps/styles';
import { ActionStatesEnum } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/constants';
import { useActiveDeliveryState } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import {
  StyledButtonContainer,
  StyledFieldsSection,
  StyledFormGroup,
  StyledFormHelperText,
  StyledLink,
  StyledSignTitle
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/styles';
import FormTextField from 'src/components/form/formTextField';

type StepSigningPropsType = {
  action: ActionStatesEnum;
  resendSmsCode: () => void;
  handleBack: () => void;
  isResendTimeout: boolean;
  resendTimeoutDuration: number;
  sendAcknowledgementEvent: () => void;
};

const SignatureStepAction = ({
  action,
  resendSmsCode,
  handleBack,
  isResendTimeout,
  resendTimeoutDuration,
  sendAcknowledgementEvent
}: StepSigningPropsType) => {
  const {
    register,
    watch,
    formState: { errors },
    clearErrors
  } = useFormContext();
  const { t } = useTranslation();

  const { isDocumentAcknowledged } = useActiveDeliveryState();
  const isDocumentAcknowledgedChecked = watch('checkDocument');
  const [eventSent, setEventSent] = useState(false);

  useEffect(() => {
    if (
      !isDocumentAcknowledged &&
      isDocumentAcknowledgedChecked &&
      !eventSent
    ) {
      setEventSent(true);
      sendAcknowledgementEvent();
    }
  }, [
    eventSent,
    isDocumentAcknowledged,
    isDocumentAcknowledgedChecked,
    sendAcknowledgementEvent
  ]);

  const clearErrorsIfPresent = (fieldName: string) => {
    if (!!errors?.[fieldName]) {
      clearErrors(fieldName);
    }
  };

  const ResendSmsCodeTimeoutMessage = () => (
    <StyledCodeResendMessageWrapper>
      <StyledCheckmark />
      <Typography variant="body2">
        {t('PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_CODE_RESEND_TIMEOUT', {
          timeout: resendTimeoutDuration
        })}
      </Typography>
    </StyledCodeResendMessageWrapper>
  );

  return (
    <>
      <StyledSignTitle>
        <Typography variant="subtitle1" fontWeight={600}>
          {action === ActionStatesEnum.ACCEPT
            ? t(
                'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_SINGING_TITLE'
              )
            : t(
                'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECTING_TITLE'
              )}
        </Typography>
      </StyledSignTitle>
      <Typography variant="subtitle2">
        {action === ActionStatesEnum.ACCEPT
          ? t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_SINGING_DESCRIPTION'
            )
          : t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECTING_DESCRIPTION'
            )}
      </Typography>
      <StyledFieldsSection>
        <FormTextField
          fullWidth
          variant="outlined"
          label={t(
            'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_SINGING_VERIFICATION_CODE'
          )}
          helperText={
            (errors?.['verificationCode']?.type === 'required' &&
              t('ERROR_EMPTY_FIELD')) ||
            (errors?.['verificationCode']?.type === 'invalid_code' &&
              t('ERROR_INVALID_CODE'))
          }
          InputProps={{
            ...register('verificationCode', { required: true })
          }}
          onChange={() => clearErrorsIfPresent('verificationCode.invalid_code')}
          error={!!errors?.['verificationCode']}
        />
        {isResendTimeout ? (
          <ResendSmsCodeTimeoutMessage />
        ) : (
          <StyledLink variant="body2" onClick={resendSmsCode}>
            {t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECTING_VERIFICATION_CODE_RESEND'
            )}
          </StyledLink>
        )}

        {action === ActionStatesEnum.REJECT && (
          <FormTextField
            fullWidth
            variant="outlined"
            label={t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECTING_REJECTING_REASON'
            )}
            helperText={
              errors?.rejectionReason?.type === 'required' &&
              t('ERROR_EMPTY_FIELD')
            }
            InputProps={{
              ...register('rejectionReason', { required: true })
            }}
          />
        )}
      </StyledFieldsSection>
      {action === ActionStatesEnum.ACCEPT && (
        <StyledFormGroup>
          <StyledFormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body1" fontWeight={400}>
                {t(
                  'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_CONFIRMATION_OF_ACKNOWLEDGEMENT'
                )}
              </Typography>
            }
            {...register('checkDocument', { required: true })}
            checked={watch('checkDocument') || false}
          />
          {errors['checkDocument'] && (
            <StyledFormHelperText>
              {t('ERROR_EMPTY_FIELD')}
            </StyledFormHelperText>
          )}
        </StyledFormGroup>
      )}
      <StyledButtonContainer>
        <Button onClick={handleBack} color="secondary">
          {t(
            'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_CANCEL_BUTTON'
          )}
        </Button>
        <LoadingButton
          type="submit"
          form="my-form"
          variant="outlined"
          endIcon={<KeyboardArrowRight />}
          color={action === ActionStatesEnum.REJECT ? 'error' : 'primary'}
          loadingPosition="end"
        >
          {action === ActionStatesEnum.REJECT
            ? t(
                'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECT_BUTTON'
              )
            : t(
                'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_SIGN_BUTTON'
              )}
        </LoadingButton>
      </StyledButtonContainer>
    </>
  );
};

export default SignatureStepAction;
