import { useTranslation } from 'react-i18next';

import { ReactComponent as NoDataIcon } from 'src/assets/noData.svg';

import { StyledContainer, StyledDescription, StyledTitle } from './styles';

const NoResults = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <NoDataIcon />
      <StyledTitle variant="subtitle2">
        {t('DOCUMENT_LIST_NO_DATA_TITLE')}
      </StyledTitle>
      <StyledDescription variant="caption1">
        {t('DOCUMENT_LIST_NO_DATA_DESCRIPTION')}
      </StyledDescription>
    </StyledContainer>
  );
};

export default NoResults;
