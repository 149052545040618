import { TabList, TabPanel } from '@mui/lab';
import { Button, Divider, styled, TextField, Typography } from '@mui/material';

export const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.custom.neutral[200]
}));

export const StyledTabList = styled(TabList)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  '.MuiTab-root': {
    textTransform: 'none'
  },
  '.MuiTabs-flexContainer': {
    justifyContent: 'center',
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'flex-start'
    }
  }
}));

export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(4)
}));

export const StyledDocumentDetailsHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
    gap: 0
  },
  gap: theme.spacing(3),
  alignItems: 'center'
}));

export const StyledPaginationWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

export const StyledHeaderRightSide = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  alignItems: 'center'
}));

export const StyledDocumentWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.custom.neutral[50],
  boxShadow: 'inset 0px 4px 20px #CFD6E0',
  padding: theme.spacing(2, 0),
  display: 'flex',
  justifyContent: 'center',
  '.react-pdf__Page': {
    marginBottom: theme.spacing(4)
  },
  overflow: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('hd')]: {
    padding: theme.spacing(6, 0)
  },
  bottom: 0,
  left: 0,
  right: 0,
  top: 0
}));

export const StyledTauronDocumentWrapper = styled('div')(({ theme }) => ({
  border: 'solid 1px #a5a6a6',
  borderRadius: '25px',
  background: theme.palette.custom.neutral[50],
  padding: theme.spacing(2, 0),
  display: 'flex',
  justifyContent: 'center',
  '.react-pdf__Page': {
    marginBottom: theme.spacing(4)
  },
  overflow: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('hd')]: {
    padding: theme.spacing(6, 0)
  }
}));

export const StyledDocumentDetailsWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  [theme.breakpoints.up('hd')]: {
    padding: theme.spacing(6, 4, 6, 0)
  },
  [theme.breakpoints.up('tablet')]: {
    position: 'absolute',
    overflow: 'auto',
    paddingRight: theme.spacing(4)
  },
  bottom: 0,
  left: 0,
  right: 0,
  top: 0
}));

export const StyledRelativeContainer = styled('div')({
  flex: 1,
  position: 'relative'
});

export const StyledTabWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  [theme.breakpoints.up('desktop')]: {
    width: '35%'
  },
  [theme.breakpoints.up('hd')]: {
    flex: 1
  }
}));

export const StyledContentWrapper = styled('div')(({ theme }) => ({
  minHeight: 'calc(100vh - 300px)',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '64px',
  [theme.breakpoints.up('desktop')]: {
    paddingBottom: 0
  }
}));

export const StyledActivePageNumberTextField = styled(TextField)(
  ({ theme }) => ({
    marginInline: theme.spacing(1)
  })
);

export const StyledBlockchainAddress = styled(Typography)({
  wordBreak: 'break-all',
  maxWidth: '300px'
});

export const StyledDocumentInformations = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

export const StyledDetailGroup = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

export const StyledDocumentInformation = styled('div')({
  display: 'flex',
  whiteSpace: 'pre-wrap'
});

export const StyledDocumentInformationTitle = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.custom.textColors[800]
  })
);

export const StyledDocumentInformationValue = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.custom.neutral[900]
  })
);

export const StyledPreviewContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 366px)',
  '.react-pdf__Page:last-child': {
    paddingBottom: '64px'
  }
});

export const StyledControlsWrapper = styled('div')<{
  borderDisabled?: boolean;
}>(({ theme, borderDisabled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderLeft: borderDisabled ? '0' : '1px',
  borderRight: borderDisabled ? '0' : '1px',
  borderBottom: borderDisabled ? '0' : '1px',
  borderTop: 0,
  borderColor: theme.palette.custom.neutral[200],
  borderStyle: 'solid',
  [theme.breakpoints.up('desktop')]: {
    margin: 0,
    border: 0
  }
}));

export const StyledLoader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(12)
}));

export const StyledBackButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.custom.textColors[600]
}));
