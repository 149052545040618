import { styled, Typography } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  gap: theme.spacing(0.5),
  svg: {
    marginBottom: theme.spacing(2)
  }
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  textAlign: 'center'
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[800],
  maxWidth: theme.spacing(70),
  textAlign: 'center'
}));
