import { createContext, useCallback, useMemo, useReducer } from 'react';
import { Outlet } from 'react-router-dom';

import {
  PublicDocumentsStepperState,
  PublicDocumentsContextProps,
  PublicDocumentsWizardActions,
  PublicDocument
} from 'src/modules/DocumentsPrivate/publicTypes';
import publicDocumentWizardReducer from 'src/modules/DocumentsPrivate/publicReducers';
import ApiError from 'src/lib/apiError';

export const PublicDocumentContext = createContext<PublicDocumentsContextProps>(
  {
    state: {
      isLoading: false
    },
    setDocument: () => {},
    setError: () => {},
    setBlockchainAddress: () => {},
    startLoading: () => {},
    stopLoading: () => {},
    setInfo: () => {},
    setToken: () => {},
    setSum: () => {}
  }
);

const usePrivateDocumentWizardState = (
  initialState: PublicDocumentsStepperState
) =>
  useReducer<
    (
      prevState: PublicDocumentsStepperState,
      action: PublicDocumentsWizardActions
    ) => PublicDocumentsStepperState
  >(publicDocumentWizardReducer, initialState);

export const PublicDocumentContextProvider = () => {
  const [current, dispatch] = usePrivateDocumentWizardState({
    isLoading: false
  });

  const setBlockchainAddress = useCallback(
    (blockchainAddress: string) => {
      dispatch({
        ACTION: 'SET_BLOCKCHAIN_ADDRESS',
        blockchainAddress
      });
    },
    [dispatch]
  );

  const setError = useCallback(
    (error?: ApiError) => {
      dispatch({
        ACTION: 'SET_ERROR',
        error
      });
    },
    [dispatch]
  );

  const setDocument = useCallback(
    (document: PublicDocument) => {
      dispatch({
        ACTION: 'SET_DOCUMENT',
        document
      });
    },
    [dispatch]
  );

  const startLoading = useCallback(
    () =>
      dispatch({
        ACTION: 'LOADING'
      }),
    [dispatch]
  );

  const stopLoading = useCallback(
    () =>
      dispatch({
        ACTION: 'STOP_LOADING'
      }),
    [dispatch]
  );

  const setInfo = useCallback(
    () =>
      dispatch({
        ACTION: 'SET_INFO'
      }),
    [dispatch]
  );

  const setToken = useCallback(
    (token?: string) => {
      dispatch({
        ACTION: 'SET_TOKEN',
        token
      });
    },
    [dispatch]
  );

  const setSum = useCallback(
    (sum?: string) => {
      dispatch({
        ACTION: 'SET_SUM',
        sum
      });
    },
    [dispatch]
  );

  const state = useMemo(
    () => ({
      isLoading: current.isLoading,
      blockchainAddress: current.blockchainAddress,
      document: current.document,
      error: current.error,
      info: current.info,
      token: current.token,
      sum: current.sum
    }),
    [
      current.blockchainAddress,
      current.document,
      current.isLoading,
      current.error,
      current.info,
      current.token,
      current.sum
    ]
  );

  return (
    <PublicDocumentContext.Provider
      value={{
        setBlockchainAddress,
        state,
        startLoading,
        setError,
        setDocument,
        stopLoading,
        setInfo,
        setSum,
        setToken
      }}
    >
      <Outlet />
    </PublicDocumentContext.Provider>
  );
};
