import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import FormTextField from 'src/components/form/formTextField';
import hasFeature from 'src/lib/hasFeature';
import InfoTooltip from 'src/components/infoTooltip';

import StyledContainer from './styles';

type StepTextFieldWithLabelProps = {
  labelKey?: string;
  inputLabelKey: string;
  fieldName?: string;
  isDisabled?: boolean;
  inputMaxlength?: number;
  orderNumber?: string;
  trim?: boolean;
  autoCompleteDisabled?: boolean;
  infoToolTipTitle?: string;
};

const StepTextFieldWithLabel = ({
  labelKey,
  inputLabelKey,
  fieldName,
  isDisabled,
  inputMaxlength,
  orderNumber,
  trim,
  autoCompleteDisabled,
  infoToolTipTitle
}: StepTextFieldWithLabelProps) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const labelVariant = hasFeature('tauron') ? 'h6' : 'subtitle1';
  const labelFontWeight = hasFeature('tauron') ? '400' : '600';

  return (
    <StyledContainer>
      {labelKey && (
        <Typography variant={labelVariant} fontWeight={labelFontWeight}>
          {orderNumber} {t(labelKey)}{' '}
          {!!infoToolTipTitle && (
            <InfoTooltip infoToolTipTitle={infoToolTipTitle} />
          )}
        </Typography>
      )}
      <FormTextField
        inputProps={
          inputMaxlength
            ? {
                maxLength: inputMaxlength
              }
            : undefined
        }
        disabled={isDisabled}
        variant="outlined"
        label={t(inputLabelKey)}
        InputProps={{ ...(fieldName ? register(fieldName) : {}) }}
        trim={trim}
        autoComplete={autoCompleteDisabled ? 'off' : 'on'}
      />
    </StyledContainer>
  );
};

export default StepTextFieldWithLabel;
