/* eslint-disable */
import { Buffer } from 'buffer';

export default key =>
  crypto.subtle.importKey(
    'pkcs8',
    Buffer.from(key, 'hex'),
    {
      name: 'RSA-PSS',
      hash: 'SHA-256'
    },
    true,
    ['sign']
  );
