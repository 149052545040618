/* eslint-disable */
import protobuf from 'protobufjs';

  const encodeEventData = async ({token_id_base58, document_id_base58, event_issuer_identity_base58 }) => {

  let oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const dateNowInMicroseconds = Date.now()*1000;
  const yearFromNowInMicroseconds = oneYearFromNow.getTime()*1000;

  const payload = {
    eventTriggerDate: { value: dateNowInMicroseconds },
    eventRetentionDate: { value: yearFromNowInMicroseconds },
    tokenIdBase58: { value: token_id_base58 },
    documentIdBase58: { value: document_id_base58 } ,
    eventIssuerIdentityBase58: { value: event_issuer_identity_base58 }
  };

  const eventDataRoot = await protobuf.load('/assets/HorcruxPeiEventData.proto');

    const signedEventDataRoot = await protobuf.load(
      '/assets/HorcruxPeiSignedEventData.proto'
    );

    const PeiEventData = eventDataRoot.lookupType(
      'Billon.HorcruxPeiEventDataProto'
    );

    const PeiSignedEventData = signedEventDataRoot.lookupType(
      'Billon.HorcruxPeiSignedEventDataProto'
    );

    const peiEvent = PeiEventData.create(payload);

    const peiSignedEvent = PeiSignedEventData.create({eventData: peiEvent});

    return PeiSignedEventData.encode(peiSignedEvent).finish();
};

export default encodeEventData;