import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import putVerifySecretCode from 'src/modules/UserIdentity/api/putVerifySecretCode';
import {
  VerifySecretCodeResponse,
  VerifySecretCode
} from 'src/modules/UserIdentity/types/api';

const useSendSmsCode = (
  options: UseMutationOptions<
    VerifySecretCodeResponse,
    AxiosError,
    VerifySecretCode
  >
) =>
  useMutation<VerifySecretCodeResponse, AxiosError, VerifySecretCode>(
    putVerifySecretCode,
    options
  );

export default useSendSmsCode;
