import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  VerifyDocumentStepKeysNegative,
  VerifyDocumentStepKeysPositive,
  VerifyDocumentWizardSteps
} from 'src/modules/DocumentsPrivate/constants/stepper';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import routes from 'src/constants/routes';
import DocumentVerificationNegativeBanner from 'src/modules/DocumentsPrivate/components/documentVerificationNegativeBanner';
import DocumentVerificationPositiveBanner from 'src/modules/DocumentsPrivate/components/documentVerificationPositiveBanner';
import DocumentVerificationPositiveStepperControls from 'src/modules/DocumentsPrivate/components/documentVerificationPositiveStepperControls';
import DocumentVerificationNegativeStepperControls from 'src/modules/DocumentsPrivate/components/documentVerificationNegativeStepperControls';
import {
  Stepper,
  StepperHeaderContainer,
  StepperContainer,
  StepperHeader,
  StepperContent
} from 'src/components/stepper';

const VerifyDocumentAddressPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));
  const { state } = useContext(PrivateDocumentContext);
  const isVerificationStateUndefined =
    typeof state.verificationResult === 'undefined';

  useEffect(() => {
    if (!state.isLoading && isVerificationStateUndefined) {
      navigate(routes.verifyIndex);
    }
  }, [navigate, state.isLoading, isVerificationStateUndefined]);

  if (isVerificationStateUndefined) {
    return null;
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" href="/">
          Home
        </Link>
        <Typography>{t('HOME_DOCUMENT_VERIFICATION')}</Typography>
      </Breadcrumbs>
      <StepperContainer>
        {!isHorizontalStepper && (
          <Stepper
            stepTitleKeys={
              state.verificationResult
                ? VerifyDocumentStepKeysPositive
                : VerifyDocumentStepKeysNegative
            }
            orientation="vertical"
            activeStep={
              state.verificationResult
                ? VerifyDocumentWizardSteps.VERIFICATION_RESULT_POSITIVE
                : VerifyDocumentWizardSteps.VERIFICATION_RESULT_NEGATIVE
            }
          />
        )}
        <StepperContent>
          <Box>
            <StepperHeaderContainer>
              <StepperHeader
                subtitleKey={
                  state.verificationResult
                    ? 'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_DESCRIPTION'
                    : 'DOCUMENT_VERIFICATION_NEGATIVE_VERIFICATION_RESULT_DESCRIPTION'
                }
                titleKey={
                  state.verificationResult
                    ? 'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_TITLE'
                    : 'DOCUMENT_VERIFICATION_NEGATIVE_VERIFICATION_RESULT_TITLE'
                }
              />
              {isHorizontalStepper && (
                <Stepper
                  stepTitleKeys={
                    state.verificationResult
                      ? VerifyDocumentStepKeysPositive
                      : VerifyDocumentStepKeysNegative
                  }
                  orientation="horizontal"
                  activeStep={
                    state.verificationResult
                      ? VerifyDocumentWizardSteps.VERIFICATION_RESULT_POSITIVE
                      : VerifyDocumentWizardSteps.VERIFICATION_RESULT_NEGATIVE
                  }
                />
              )}
            </StepperHeaderContainer>
            {state.verificationResult ? (
              <DocumentVerificationPositiveBanner
                document={state.verificationResult}
              />
            ) : (
              <DocumentVerificationNegativeBanner />
            )}
          </Box>
          {state.verificationResult ? (
            <DocumentVerificationPositiveStepperControls />
          ) : (
            <DocumentVerificationNegativeStepperControls />
          )}
        </StepperContent>
      </StepperContainer>
    </>
  );
};

export default VerifyDocumentAddressPage;
