import { styled } from '@mui/material';
import { Document, Page } from 'react-pdf';

export const StyledTauronDocumentWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.custom.neutral[50],
  flex: 1,
  position: 'relative',
  border: 'solid 1px #a5a6a6',
  borderRadius: '25px',
  minHeight: '500px'
}));

export const StyledTauronDocumentContent = styled('div')(({ theme }) => ({
  background: theme.palette.custom.neutral[50],
  padding: theme.spacing(0, 0),
  display: 'flex',
  justifyContent: 'center',
  '.react-pdf__Page': {
    marginBottom: theme.spacing(4)
  },
  overflow: 'auto',
  position: 'absolute',
  bottom: '20px',
  left: 0,
  right: 0,
  top: '20px'
}));

export const StyledDocument = styled(Document)<{
  scale: number;
}>(({ theme, scale }) => ({
  [theme.breakpoints.down(1100)]: {
    paddingLeft: scale > 1 ? theme.spacing(8) : 0
  }
}));

export const StyledPage = styled(Page)<{
  scale: number;
}>(({ theme, scale }) => ({
  [theme.breakpoints.up(1440)]: {
    paddingLeft: scale > 1 ? theme.spacing(8) : 0
  }
}));
