const breakpoints = {
  values: {
    mobile: 0,
    tablet: 768,
    desktop: 1440,
    hd: 1920
  }
};

export default breakpoints;
