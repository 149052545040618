import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

import AppBar from 'src/components/appbar';
import NavBar from 'src/components/navbar/NavBar';
import PoweredBy from 'src/components/poweredBy';
import { LayoutContextProvider } from 'src/contexts/LayoutContext';
import { UserIdentityContextProvider } from 'src/modules/UserIdentity/contexts/UserIdentityContext';

import { StyledContentContainer, StyledBaseLayout } from './styles';

const BaseLayout = () => (
  <LayoutContextProvider>
    <StyledBaseLayout>
      <UserIdentityContextProvider>
        <CssBaseline />
        <AppBar />
        <NavBar />
        <StyledContentContainer>
          <Outlet />
        </StyledContentContainer>
        <PoweredBy />
      </UserIdentityContextProvider>
    </StyledBaseLayout>
  </LayoutContextProvider>
);

export default BaseLayout;
