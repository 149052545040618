const transformStringIntoMap = (value = '', startIndex = 0) =>
  [...value].reduce(
    (prev, value, i) => ({
      ...prev,
      [startIndex + i]: value
    }),
    {}
  );

export default transformStringIntoMap;
