import { styled } from '@mui/material';

import circleSvg from 'src/assets/tauron/circle.svg';

export const StyledBaseLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.grey[50],
  gap: theme.spacing(2),
  minHeight: '100vh',
  overflow: 'hidden'
}));

export const StyledBaseLayoutTauron = styled(StyledBaseLayout)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100]
}));

export const StyledContentContainerTauron = styled('div')(({ theme }) => ({
  backgroundImage: `url(${circleSvg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left 0 top 30%',
  minWidth: '100%',
  flex: 1,
  marginTop: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('desktop')]: {
    '> form': {
      height: '100%',
      '> div:not(.MuiAlert-root)': {
        height: '100%'
      }
    }
  },
  [theme.breakpoints.up(970)]: {
    marginTop: theme.spacing(18)
  }
}));

export const StyledContentContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(11),
  flex: 1,
  '> div': {
    backgroundColor: theme.palette.grey[50]
  },
  [theme.breakpoints.up('tablet')]: {
    '> form': {
      height: '100%',
      '> div:not(.MuiAlert-root)': {
        height: '100%'
      }
    }
  },
  [theme.breakpoints.down('desktop')]: {
    '> div': {
      paddingRight: theme.spacing(2)
    },
    '> form > div': {
      paddingRight: theme.spacing(2)
    }
  },
  [theme.breakpoints.only('mobile')]: {
    marginLeft: theme.spacing(2)
  },
  '> *': {
    paddingBottom: theme.spacing(10) // Space for Powered by Billon
  }
}));
