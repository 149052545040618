import { styled, Theme } from '@mui/material';

import { LevelVariant } from './types';

const levelBackgroundColorMixin = (theme: Theme, variant?: LevelVariant) => {
  switch (variant) {
    case 'MEDIUM':
      return theme.palette.warning.main;
    case 'WEAK':
      return theme.palette.error.main;
    case 'STRONG':
      return theme.palette.success.main;
    default:
      return theme.palette.custom.neutral[200];
  }
};

export const StyledLevel = styled('div')<{
  variant?: LevelVariant;
}>(({ theme, variant }) => ({
  backgroundColor: levelBackgroundColorMixin(theme, variant),
  flex: 1,
  height: theme.spacing(0.5),
  borderRadius: theme.spacing(1)
}));

export const StyledLevelText = styled('div')<{
  variant?: LevelVariant;
}>(({ theme, variant }) => ({
  color: levelBackgroundColorMixin(theme, variant)
}));

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: theme.spacing(1)
}));

export const StyledLevels = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  display: 'flex',
  gap: theme.spacing(1)
}));
