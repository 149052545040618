import { styled, Button, Typography } from '@mui/material';

export const StyledGoBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.custom.textColors[700],
  fontSize: theme.typography.caption1.fontSize
}));

export const StyledErrorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  gap: theme.spacing(0.5),
  maxWidth: theme.spacing(69),
  margin: '0 auto',
  a: {
    cursor: 'pointer',
    color: theme.palette.custom.primary[600],
    textDecoration: 'none'
  }
}));

export const AccessErrorTitle = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.custom.neutral[900],
  fontWeight: 600,
  textAlign: 'center'
}));

export const AccessErrorDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[800],
  textAlign: 'center'
}));
