import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { StyledErrorWrapper, StyledCenterWrapper } from './styles';

export type CatalogErrorProps = {
  onBackClick: () => void;
};

const CatalogError = ({ onBackClick }: CatalogErrorProps) => {
  const { t } = useTranslation();
  return (
    <StyledErrorWrapper>
      <Box>
        <Typography variant="h1" fontWeight={700} textAlign="center">
          {t('PRIVATE_DOCUMENT_CATALOG_ERROR')}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={500}
          width="100%"
          textAlign="center"
          marginBottom="2rem"
        >
          {t('INCORRECT_URL_SUBTITLE')}
        </Typography>
        <StyledCenterWrapper marginBottom="3rem">
          <Button
            onClick={onBackClick}
            startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
            color="primary"
          >
            {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
          </Button>
        </StyledCenterWrapper>
      </Box>
    </StyledErrorWrapper>
  );
};

export default CatalogError;
