import { useCallback, useContext, useEffect, useState } from 'react';

import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import useAgreementSendCode from 'src/modules/UserIdentity/hooks/useAgreementSendCode';
import useAgreementResendCode from 'src/modules/UserIdentity/hooks/useAgreementResendCode';
import useAgreementConfirmSmsCode from 'src/modules/UserIdentity/hooks/useAgreementConfirmSmsCode';
import { AgreementSendConfirmationCodeResponse } from 'src/modules/UserIdentity/types/api';

enum ProcessStatus {
  READY = 1,
  CODE_SENT,
  PENDING,
  CODE_RE_SENT,
  SUCCESS,
  ERROR_WRONG_CODE,
  ERROR_PROCESS_ALREADY_FINISHED,
  ERROR
}

const useDocumentSignatureSmsCode = () => {
  const resendTimeoutDuration = 3;
  const { state: userData } = useContext(UserIdentityContext);
  const { uuid } = userData;
  const [processId, setProcessId] = useState<string>('');
  const [processStatus, setProcessStatus] = useState<ProcessStatus>(
    ProcessStatus.READY
  );
  const [isResendTimeout, setIsResendTimeout] = useState<boolean>(false);

  useEffect(() => {
    if (isResendTimeout) {
      const timeout = setTimeout(() => {
        setIsResendTimeout(false);
      }, resendTimeoutDuration * 60 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [isResendTimeout]);

  const onCodeSent = (response: AgreementSendConfirmationCodeResponse) => {
    const { processId, status } = response;
    setProcessId(processId);
    setProcessStatus(
      status === 'OK' ? ProcessStatus.CODE_SENT : ProcessStatus.ERROR
    );
  };

  const { mutate: requestSendSmsCode } = useAgreementSendCode({
    onSuccess: onCodeSent
  });

  const { mutate: requestResendSmsCode } = useAgreementResendCode({
    onSuccess: () => {
      setIsResendTimeout(true);
      setProcessStatus(ProcessStatus.CODE_RE_SENT);
    },
    onError: e => {
      if (e?.response?.data.statusCode === 'PROCESS_ALREADY_FINISHED') {
        requestSmsCode();
      } else {
        setProcessStatus(ProcessStatus.ERROR);
      }
    }
  });

  const { mutate: requestConfirmSmsCode } = useAgreementConfirmSmsCode({
    onSuccess: () => setProcessStatus(ProcessStatus.SUCCESS),
    onError: e => {
      if (e?.response?.data.statusCode === 'PROCESS_ALREADY_FINISHED') {
        setProcessStatus(ProcessStatus.ERROR_PROCESS_ALREADY_FINISHED);
      }
      if (e?.response?.data.statusCode === 'INVALID_CODE') {
        setProcessStatus(ProcessStatus.ERROR_WRONG_CODE);
      } else {
        setProcessStatus(ProcessStatus.ERROR);
      }
    }
  });

  const requestSmsCode = useCallback(() => {
    if (uuid) {
      requestSendSmsCode({ uuid });
    }
  }, [requestSendSmsCode, uuid]);

  const resendSmsCode = useCallback(() => {
    if (processId && !isResendTimeout) {
      requestResendSmsCode({ processId });
    }
  }, [processId, requestResendSmsCode, isResendTimeout]);

  const confirmSmsCode = useCallback(
    (confirmationCode: string) => {
      setProcessStatus(ProcessStatus.PENDING);
      if (processId) {
        requestConfirmSmsCode({ processId, confirmationCode });
      }
    },
    [processId, requestConfirmSmsCode]
  );

  const resetProcess = useCallback(
    (stage: ProcessStatus = ProcessStatus.READY) => {
      setProcessStatus(stage);
      if (stage === ProcessStatus.READY) {
        setProcessId('');
      }
    },
    []
  );

  return {
    requestSmsCode,
    resendSmsCode,
    confirmSmsCode,
    processStatus,
    resetSmsVerificationProcess: resetProcess,
    isResendTimeout,
    resendTimeoutDuration
  };
};

export { useDocumentSignatureSmsCode, ProcessStatus };
