import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'src/assets/warning.svg';

import {
  StyledTypography,
  StyledContainer,
  StyledVerificationResultInfo
} from './styles';

const infos = [
  'DOCUMENT_VERIFICATION_NEGATIVE_VERIFICATION_RESULT_FIRST_CHECK',
  'DOCUMENT_VERIFICATION_NEGATIVE_VERIFICATION_RESULT_SECOND_CHECK',
  'DOCUMENT_VERIFICATION_NEGATIVE_VERIFICATION_RESULT_THIRD_CHECK'
];

const DocumentVerificationNegativeBanner = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {infos.map(info => (
        <StyledVerificationResultInfo key={info}>
          <WarningIcon />
          <StyledTypography>{t(info)}</StyledTypography>
        </StyledVerificationResultInfo>
      ))}
    </StyledContainer>
  );
};

export default DocumentVerificationNegativeBanner;
