import { Button, styled } from '@mui/material';

export const StyledCardContentTextWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down('tablet')]: {
    marginBottom: theme.spacing(3)
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    ...theme.typography.buttonMedium
  }
}));
