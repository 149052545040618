import { useTranslation } from 'react-i18next';

import { PrivacyPolicy, TermsOfUse } from 'src/components/regulations';

const AcceptTermsLabel = () => {
  const { t } = useTranslation();

  return (
    <>
      {t('TERMS_ACCEPT_LABEL')} <PrivacyPolicy /> {t('TERMS_ACCEPT_AND')}{' '}
      <TermsOfUse />
    </>
  );
};

export default AcceptTermsLabel;
