import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { Category } from 'src/modules/DocumentsPrivate/types';
import getCategories from 'src/modules/DocumentsPrivate/api/getCategories';

const useDocumentCategories = (
  options?: UseQueryOptions<Category[] | undefined, AxiosError>
) =>
  useQuery<Category[] | undefined, AxiosError>(
    [queryKeys.useDocumentCategories],
    async () => {
      const { data: response } = await getCategories();

      return response.categoriesList;
    },
    options
  );

export default useDocumentCategories;
