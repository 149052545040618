import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { GetApiHealthCheck } from 'src/modules/DocumentsPrivate/types';
import getApiHealth from 'src/modules/DocumentsPrivate/api/getApiHealth';

const useApiHealth = (
  options?: UseQueryOptions<GetApiHealthCheck | undefined, AxiosError>
) =>
  useQuery<GetApiHealthCheck | undefined, AxiosError>(
    [queryKeys.apiHealth],
    async () => {
      const { data: response } = await getApiHealth();

      return response;
    },
    options
  );

export default useApiHealth;
