import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { StyledErrorWrapper, StyledPrimaryEmphasis } from './styles';

const DefaultIncorrectDocumentPage = () => {
  const { t } = useTranslation();
  return (
    <StyledErrorWrapper>
      <Box>
        <Typography variant="h1" fontWeight={700} textAlign="center">
          <StyledPrimaryEmphasis>
            {t('INCORRECT_URL_TITLE_PART_ONE')}{' '}
          </StyledPrimaryEmphasis>
          {t('INCORRECT_URL_TITLE_PART_TWO')}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={500}
          width="100%"
          textAlign="center"
        >
          {t('INCORRECT_URL_SUBTITLE')}
        </Typography>
      </Box>
    </StyledErrorWrapper>
  );
};

export default DefaultIncorrectDocumentPage;
