import { useState } from 'react';
import { TabContext } from '@mui/lab';
import { Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CopyToClipboard from 'src/components/copyToClipboard';
import { ChangeFunctionType } from 'src/components/tabs/types';
import theme from 'src/constants/theme';
import { DocumentHashTabEnum } from 'src/modules/DocumentsPrivate/constants/document';
import { VerifyDocumentResponse } from 'src/modules/DocumentsPrivate/types';

import {
  IconButtonStyled,
  StyledHashTitle,
  StyledBlockchainHashSection,
  StyledPropertyName,
  StyledPropertyValue,
  StyledTabPanel,
  StyledTooltip,
  StyledCopyWrapper,
  StyledTitleGroup,
  StyledTabList,
  StyledTab
} from './styles';

type DocumentHashTabsProps = {
  document: VerifyDocumentResponse;
};

const DocumentHashTabs = ({ document }: DocumentHashTabsProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<DocumentHashTabEnum>(
    DocumentHashTabEnum.base58
  );

  const isSeparateLineCopy = useMediaQuery(theme.breakpoints.down('tablet'));

  const handleChange: ChangeFunctionType = (_event, newValue) => {
    setValue(newValue as DocumentHashTabEnum);
  };
  const [first, last] = document.documentBlockchainAddress.split(
    document.base58
  );

  return (
    <>
      <StyledBlockchainHashSection>
        <StyledHashTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledTitleGroup>
            <StyledPropertyName variant="body2">
              {t(
                'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_FIRST_CHECK_ADDRESS_BLOCKCHAIN'
              )}
            </StyledPropertyName>
            <StyledTooltip
              color="blue"
              placement="right"
              title={t(
                'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_HASH_INFO'
              )}
            >
              <IconButtonStyled>
                <InfoOutlinedIcon />
              </IconButtonStyled>
            </StyledTooltip>
          </StyledTitleGroup>
          {!isSeparateLineCopy && (
            <CopyToClipboard
              text={document.documentBlockchainAddress}
              description={t('PRIVATE_DOCUMENT_DETAILS_COPY')}
            />
          )}
        </StyledHashTitle>
        <StyledPropertyValue variant="caption1">
          {first}
          <StyledPropertyValue variant="caption1" fontWeight="bold">
            {document.base58}
          </StyledPropertyValue>
          {last}
        </StyledPropertyValue>

        {isSeparateLineCopy && (
          <StyledCopyWrapper>
            <CopyToClipboard
              text={document.documentBlockchainAddress}
              description={t('PRIVATE_DOCUMENT_DETAILS_COPY')}
            />
          </StyledCopyWrapper>
        )}
      </StyledBlockchainHashSection>
      <TabContext value={value}>
        <Box>
          <StyledTabList onChange={handleChange}>
            {Object.keys(DocumentHashTabEnum).map(hashKey => (
              <StyledTab label={hashKey} key={hashKey} value={hashKey} />
            ))}
          </StyledTabList>
        </Box>
        {Object.keys(DocumentHashTabEnum).map(hashKey => (
          <StyledTabPanel value={hashKey} key={hashKey}>
            <StyledHashTitle sx={{ display: 'flex', alignItems: 'center' }}>
              <StyledTitleGroup>
                <StyledPropertyName variant="body2">
                  {t(
                    'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_FIRST_CHECK_DOCUMENT_HASH'
                  )}
                </StyledPropertyName>
                <StyledTooltip
                  color="blue"
                  placement="right"
                  title={t(
                    'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_HASH_INFO'
                  )}
                >
                  <IconButtonStyled>
                    <InfoOutlinedIcon />
                  </IconButtonStyled>
                </StyledTooltip>
              </StyledTitleGroup>
              {!isSeparateLineCopy && (
                <StyledCopyWrapper>
                  <CopyToClipboard
                    text={document[hashKey as DocumentHashTabEnum]}
                    description={t('PRIVATE_DOCUMENT_DETAILS_COPY')}
                  />
                </StyledCopyWrapper>
              )}
            </StyledHashTitle>
            <span>
              <StyledPropertyValue variant="caption1">
                {document[hashKey as DocumentHashTabEnum]}
              </StyledPropertyValue>
              <StyledCopyWrapper>
                {isSeparateLineCopy && (
                  <StyledCopyWrapper>
                    <CopyToClipboard
                      text={document[hashKey as DocumentHashTabEnum]}
                      description={t('PRIVATE_DOCUMENT_DETAILS_COPY')}
                    />
                  </StyledCopyWrapper>
                )}
              </StyledCopyWrapper>
            </span>
          </StyledTabPanel>
        ))}
      </TabContext>
    </>
  );
};

export default DocumentHashTabs;
