import { ChangeEvent, FC, useState } from 'react';
import { Box } from '@mui/material';

import encodeEventData from 'src/modules/Crypto/lib/activeDeliveryEvents/encodeEventData';

import handleEncryption from './handleEncryption';
import handleDecryption from './handleDecryption';

const FileReaderComponent: FC = () => {
  const [fileBlob, setFileBlob] = useState<ArrayBuffer | null>(null);
  const [keyBlob, setKeyBlob] = useState<ArrayBuffer | null>(null);
  const [hexKey, setHexKey] = useState<string | null>('');

  const [privateKeyBlob, setPrivateKeyBlob] = useState<ArrayBuffer | null>(
    null
  );
  const [encryptedFileBlob, setEncryptedFileBlob] =
    useState<ArrayBuffer | null>(null);
  const [encryptedFile, setEncryptedFile] = useState<string | null>(null);

  const downloadFile = (arraybuffer: Uint8Array, filename: string) => {
    // Create a blob from the ArrayBuffer
    var blob = new Blob([arraybuffer]);

    // Create a URL for the blob
    var url = URL.createObjectURL(blob);

    // Create a link element
    var link = document.createElement('a');
    link.href = url;

    // Specify the name of the file to be downloaded
    link.download = filename;

    // This is necessary as link.click() does not work on the latest firefox
    link.style.display = 'none';
    document.body.appendChild(link);

    // Simulate click
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };
  const handleFileRead = (
    file: File,
    setStateFunction: React.Dispatch<React.SetStateAction<ArrayBuffer | null>>
  ) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result instanceof ArrayBuffer) {
        setStateFunction(e.target.result);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleHexKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHexKey(event.target.value);
  };

  const handleEncryptedBase64StringChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setEncryptedFile(event.target.value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      handleFileRead(file, setFileBlob);
    }
  };

  const handleEncryptedFileBlobChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      handleFileRead(file, setEncryptedFileBlob);
    }
  };

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      handleFileRead(file, setKeyBlob);
    }
  };

  const handlePrivKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      handleFileRead(file, setPrivateKeyBlob);
    }
  };

  const handleEncryptASN1 = async (download: Boolean) => {
    const res = await handleEncryption({
      encryptionANS1key: keyBlob,
      document: fileBlob,
      hexKey: null
    });

    if (download) {
      downloadFile(new Uint8Array(res), 'encrypted_by_frontend.bin');
    }
  };

  const handleEncryptHEX = async () => {
    await handleEncryption({
      encryptionANS1key: null,
      document: fileBlob,
      hexKey: hexKey
    });
  };

  const handleDecryptBase64 = async (download: Boolean, file: Boolean) => {
    const res = file
      ? await handleDecryption({
          cipherText: null,
          privateKey: privateKeyBlob,
          cipherFile: encryptedFileBlob
        })
      : await handleDecryption({
          cipherText: encryptedFile,
          privateKey: privateKeyBlob,
          cipherFile: null
        });

    if (download) {
      downloadFile(res, 'decrypted_by_frontend.bin');
    }
  };

  encodeEventData();

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
        <h2>protbugg debuggrer</h2>
        <button
          onClick={() =>
            encodeEventData({
              token_id_base58: 'VWpK6p8JP9org7cpdKirZz',
              document_id_base58: 'AmM1h5LfQ2M5avXCaNudDUzYU1k1LRyp2UQzLSA4RaC',
              event_issuer_identity_base58: '2UskJL3hCNQawmoU5AmFiXAD'
            })
          }
        >
          TRIGGER PROTOBUFF COMPILER
        </button>
        <h2>Encryption</h2>
        <label htmlFor="keyInput">ASN.1 Key:</label>
        <input type="file" id="fileInput" onChange={handleKeyChange} />
        <label htmlFor="fileInput">File:</label>
        <input type="file" id="fileInput" onChange={handleFileChange} />
        <label htmlFor="hexKey">Hex key (81 bytes)</label>
        <input
          type="text"
          name="hexKey"
          id="hexKey"
          onChange={handleHexKeyChange}
        />
        {fileBlob && keyBlob && (
          <>
            <button onClick={() => handleEncryptASN1(false)}>
              Encrypt with ASN.1 key and log results
            </button>
            <button onClick={() => handleEncryptASN1(true)}>
              Encrypt with ASN.1 key and download
            </button>
          </>
        )}
        {fileBlob && hexKey && (
          <button onClick={handleEncryptHEX}>
            Encrypt with HEX key and log results
          </button>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '300px',
          marginTop: '50px'
        }}
      >
        <h2>Decryption</h2>
        <label htmlFor="privKeyInput">ASN.1 PrivateKey:</label>
        <input type="file" id="fileInput" onChange={handlePrivKeyChange} />
        <label htmlFor="privKeyInput">ASN.1 Encrypted File:</label>
        <input
          type="file"
          id="fileInput"
          onChange={handleEncryptedFileBlobChange}
        />
        <label htmlFor="hexKey">Encrypted Base64</label>
        <input
          type="text"
          name="encryptedBase64"
          id="encryptedBase64"
          onChange={handleEncryptedBase64StringChange}
        />

        {privateKeyBlob && encryptedFile && (
          <>
            <button onClick={() => handleDecryptBase64(false, false)}>
              Decrypt base64 and log
            </button>
            <button onClick={() => handleDecryptBase64(true, false)}>
              Decrypt base64 and download
            </button>
          </>
        )}
        {privateKeyBlob && encryptedFileBlob && (
          <>
            <button onClick={() => handleDecryptBase64(false, true)}>
              Decrypt file and log
            </button>
            <button onClick={() => handleDecryptBase64(true, true)}>
              Decrypt file and download
            </button>
          </>
        )}
      </Box>
    </div>
  );
};

export default FileReaderComponent;
