import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

type BackButtonProps = {
  handleBackClick: () => void;
  disabled: boolean;
};

const BackButton = ({ handleBackClick, disabled }: BackButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={handleBackClick}
      disabled={disabled}
      startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
      color="secondary"
    >
      {t('RETURN_BUTTON')}
    </Button>
  );
};

export default BackButton;
