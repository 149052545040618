import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    outlined: ({ theme }) => ({
      color: theme.palette.custom.neutral[700]
    }),
    root: ({ theme }) => ({
      '&.Mui-error:not(.MuiInputLabel-shrink)': {
        color: theme.palette.custom.darkMode[300]
      }
    })
  }
};

export default componentConfiguration;
