import { alpha, Components, Theme } from '@mui/material';

import { fontSizeSmall } from 'src/constants/theme/fonts';

const componentConfiguration: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: fontSizeSmall,
      '&.Mui-selected': {
        fontWeight: 'bold',
        backgroundColor: alpha(theme.palette.primary.main, 0.04)
      },
      color: theme.palette.custom.neutral[900]
    })
  }
};

export default componentConfiguration;
