import { useContext } from 'react';

import {
  DocumentHistoryContext,
  DocumentHistoryContextValue
} from 'src/modules/DocumentsPrivate/contexts/DocumentHistoryContext';

const useDocumentHistoryContext = (): DocumentHistoryContextValue => {
  const context = useContext(DocumentHistoryContext);
  if (!context) {
    throw new Error(
      'useDocumentHistoryContext must be used within a DocumentHistoryProvider'
    );
  }
  return context;
};

export default useDocumentHistoryContext;
