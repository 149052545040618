import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/UserIdentity/constants/queryKeys';
import {
  NationalNodeLoginResponse,
  SessionId
} from 'src/modules/UserIdentity/types/api';
import getNationalNodeLogin from 'src/modules/UserIdentity/api/getNationalNodeLogin';

const useNationalNodeLoginUser = (
  sessionId: SessionId,
  options?: UseQueryOptions<NationalNodeLoginResponse | undefined, AxiosError>
) =>
  useQuery<NationalNodeLoginResponse | undefined, AxiosError>(
    [queryKeys.nationalNodeLogin],
    async () => {
      const { data: response } = await getNationalNodeLogin(sessionId);

      return response;
    },
    options
  );

export default useNationalNodeLoginUser;
