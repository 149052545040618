import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  AddFullIdentityStep2,
  AddFullIdentityStep2Response
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';

const postFullIdentityStep2 = async (request: AddFullIdentityStep2) => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } = await apiClient.post<AddFullIdentityStep2Response>(
    apiRoutes.addFullIdentityStep2,
    request
  );

  if (response.statusCode === 'ERROR') {
    throw new ApiError(response.statusDetails, response.statusDetails);
  }

  return response;
};

export default postFullIdentityStep2;
