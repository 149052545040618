import { Buffer } from 'buffer';

import asn1jsDecoder from 'src/modules/Crypto/lib/ASN1Decoder';
import { decodeEcies } from 'src/modules/Crypto/lib/ciphers/ecies-customized/ecies';
import hexToUInt8Array from 'src/modules/Crypto/lib/hexToUInt8Array';

const decrypt = async ({ cipherText, privateKey, cipherFile }) => {
  //Decode Private Key
  const decodedPrivateKey = Buffer.from(privateKey, 'binary').toString('hex');
  const private_key_structure =
    asn1jsDecoder.decodeHexString(decodedPrivateKey);
  const private_key = asn1jsDecoder.decodeHexString(
    private_key_structure.SEQUENCE_0.OCTET_STRING_0
  ).SEQUENCE_0.OCTET_STRING_0;

  //Decode cipher
  const cipherStructure = cipherFile
    ? Buffer.from(cipherFile, 'binary').toString('hex')
    : Buffer.from(cipherText, 'base64').toString('hex');

  const cipher =
    asn1jsDecoder.decodeHexString(cipherStructure).SEQUENCE_0.OCTET_STRING_0;

  const res = await decodeEcies({ cipherHex: cipher, privateKey: private_key });

  // eslint-disable-next-line no-console
  console.groupCollapsed('ODKODOWANY PLIK');
  // eslint-disable-next-line no-console
  console.log('Decrypted file:', Buffer.from(res, 'hex').toString('Base64'));
  // eslint-disable-next-line no-console
  console.groupEnd();
  return hexToUInt8Array(res);
};

export default decrypt;
