/* eslint-disable */
import moment from 'moment';

import hexToDecimal from './hexToDecimal';
import hexToAscii from './hexToAscii';
import ObjectCaster from './objectCaster';

export default class asn1jsDecoder {
  static decodeHexString(hexString) {
    return this.decodeElement(hexString);
  }

  static decodeElement(hexString) {
    let struct = {};
    let code = JSON.parse(JSON.stringify(hexString));

    while (code) {
      const elementType = this.determineASNElement(code[0] + code[1]);
      const detElResult = this.determineElementSize(
        code,
        elementType.container
      );

      /* determine number */
      const elementName = this.determineElementName(struct, elementType.name);

      /* determine type of element */
      if (
        !elementType.container // &&
        // !this.checkIfContainer(code, elementType.type)
      ) {
        // if its primitive
        struct[elementName] = this.determineValue(
          code.slice(detElResult.headerLength * 2, detElResult.fullSizeInDec),
          detElResult.typeInHex
        );
      } else {
        // if its expandable
        struct[elementName] = this.decodeElement(
          code.slice(detElResult.headerLength * 2, detElResult.fullSizeInDec)
        );
      }

      code = code.slice(detElResult.fullSizeInDec);
    }

    return struct;
  }

  static determineValue(primitiveValue, type) {
    switch (type.toString().toLowerCase()) {
      case '03':
        // BIT STRING
        return primitiveValue;
      case '05':
        return null;
      case '06':
        return this.determineOid(primitiveValue);
      case '04':
        return primitiveValue;
      case '02':
        return primitiveValue.length < 12
          ? parseInt(primitiveValue, 16)
          : hexToDecimal(primitiveValue);
      case '16':
        return hexToAscii(primitiveValue);
      case '17':
        return moment(
          this.decodeDateFromHexString(primitiveValue),
          'YYMMDDhhmmss'
        ).toDate();
      default:
        return {};
    }
  }

  static decodeDateFromHexString(hexString) {
    let dateString = '';
    for (let i = 0; i < hexString.length / 2; i++) {
      let val = parseInt(hexString[i * 2 + 1]);
      if (!Number.isNaN(val)) dateString += hexString[i * 2 + 1];
    }
    return dateString;
  }

  static checkIfContainer(code, elementType) {
    if (elementType === 'octet_string') {
      switch (code.slice(2, 4).toString().toLowerCase()) {
        case '81':
          let val1 = parseInt(code.slice(4, 6), 16);
          let val2 = parseInt(code.slice(10, 12), 16);
          return val1 - val2 === 2;
        case '82':
          let val3 = parseInt(code.slice(4, 8), 16);
          let val4 = parseInt(code.slice(12, 16), 16);
          return val3 - val4 === 4;
        default:
          var val7 = parseInt(code.slice(2, 4).toString(), 16);
          const val8 = parseInt(code.slice(6, 8).toString(), 16);
          return val7 - val8 === 2;
      }
    } else {
      return false;
    }
  }

  static determineOid(hexString) {
    /* hexString<string> - oid in hexstring
     * */
    // OID - create function to examine oids
    switch (hexString.toString().toUpperCase()) {
      case '2B06010401DA47040B':
        return {
          name: 'id-scrypt',
          value: '1.3.6.1.4.1.11591.4.11'
        };
      case '2A864886F70D01050D':
        return {
          name: '(PKCS #5 v2.0)',
          value: '1.2.840.113549.1.5.13'
        };
      case '60864801650304012A':
        return {
          name: 'aes256-CBC (NIST Algorithm)',
          value: '2.16.840.1.101.3.4.1.42'
        };
      case '2A8648CE3D0201':
        return {
          name: 'ecPublicKey (ANSI X9.62 public key type)',
          value: '1.2.840.10045.2.1'
        };
      case '2B2403030208010107':
        return {
          name: 'brainpoolP256r1 (ECC Brainpool Standard Curves and Curve Generation)',
          value: '1.3.36.3.3.2.8.1.1.7'
        };
      case '2B2403030208010109':
        return {
          name: 'brainpoolP320r1 (ECC Brainpool Standard Curves and Curve Generation)',
          value: '1.3.36.3.3.2.8.1.1.9'
        };
      case '2B240303020801010B':
        return {
          name: 'brainpoolP384r1 (ECC Brainpool Standard Curves and Curve Generation)',
          value: '1.3.36.3.3.2.8.1.1.11'
        };
      case '2B240303020801010D':
        return {
          name: 'brainpoolP512r1 (ECC Brainpool Standard Curves and Curve Generation)',
          value: '1.3.36.3.3.2.8.1.1.13'
        };
      case '2A864886F70D010914':
        return {
          name: 'friendlyName (for PKCS #12) (PKCS #9 via PKCS #12)',
          value: '1.2.840.113549.1.9.20'
        };
      case '2A864886F70D010915':
        return {
          name: 'localKeyID (for PKCS #12) (PKCS #9 via PKCS #12)',
          value: '1.2.840.113549.1.9.21'
        };
      case '2A864886F70D01091904':
        return {
          name: 'sequenceNumber (PKCS #9/RFC 2985 attribute)',
          value: '1.2.840.113549.1.9.25.4'
        };
      case '2B06010401956364010109':
        return {
          name: 'dateOfBirth(9)',
          value: '1.3.6.1.4.1.2787.100.1.1.9'
        };
      case '2A864886F70D010101':
        return {
          name: 'rsaEncryption (PKCS #1)',
          value: '1.2.840.113549.1.1.1'
        };
      case '2A864886F70D010C0A0102':
        return {
          name: 'pkcs-12-pkcs-8ShroudedKeyBag (PKCS #12 BagIds)',
          value: ' 1.2.840.113549.1.12.10.1.2'
        };
    }
  }

  static async findSafebagByKeyIdProps(
    safeContentNormalized,
    dataWithKeyId,
    scryptPassword
  ) {
    /*
     * safeContentNormalized - sequence of safebags
     * dataWithKeyId = { shortcutName<string>: shaFromSafebagsName, keyVersion<integer>: versionOfSafebagForExample1}
     * scryptPassword - custom user password
     * */
    if (!dataWithKeyId || !safeContentNormalized || !scryptPassword) {
      alert(
        'Jeden albo więcej z parametrów nie został podany [dataWithKeyId, safeContentNormalized, scryptPassword]'
      );
      return false;
    } else {
      let selectedSafebag = ObjectCaster.getSequenceByKeyName(
        safeContentNormalized,
        {
          shortcutName: dataWithKeyId.shortcutName,
          keyVersion: dataWithKeyId.keyVersion
        }
      );
      if (selectedSafebag) {
        const formattedSafebag =
          await ObjectCaster.getFormattedDataFromSequence(
            selectedSafebag,
            scryptPassword
          ).then(res => {
            /* generate private key */
            res.privateKeyStructured = asn1jsDecoder.decodeHexString(
              res.decPrivKeyHex
            );
            const temp = asn1jsDecoder.decodeHexString(
              res.privateKeyStructured.SEQUENCE_0.OCTET_STRING_0
            );
            res.privateKey = temp.SEQUENCE_0.OCTET_STRING_0;
            res.privateKeyHex = Buffer.from(res.privateKey, 'hex');
            return res;
          });
        return formattedSafebag;
      } else {
        return false;
      }
    }
  }

  static getCurvatureNameByPrivateKey(privateKeyHex) {
    const privKeyStruct = asn1jsDecoder.decodeHexString(privateKeyHex);
    if (privKeyStruct.SEQUENCE_0.SEQUENCE_0.OBJECT_IDENTIFIER_1) {
      switch (privKeyStruct.SEQUENCE_0.SEQUENCE_0.OBJECT_IDENTIFIER_1.value) {
        case '1.3.36.3.3.2.8.1.1.7':
          return { curvature: 'brainpool256', rsa: false };
        case '1.3.36.3.3.2.8.1.1.9':
          return { curvature: 'brainpool320', rsa: false };
        case '1.3.36.3.3.2.8.1.1.11':
          return { curvature: 'brainpool384', rsa: false };
        case '1.3.36.3.3.2.8.1.1.13':
          return { curvature: 'brainpool512', rsa: false };
        default:
          break;
      }
    } else {
      return {
        curvature: 'rsa2k',
        rsa: true
      };
    }
  }

  static determineElementName(structure, nameOfElement) {
    let number = 0;
    while (structure[nameOfElement + number]) {
      number++;
    }
    return nameOfElement + number;
  }

  static determineASNElement(el) {
    /*
    type - shortcut of the name
    name - name of the element
    container - informations if it can be expandable(can have primitive elements inside)
     */
    switch (el.toString().toLowerCase()) {
      case '30':
        return {
          type: 'sequence',
          name: 'SEQUENCE_',
          container: true
        };
      case '05':
        return {
          type: 'null',
          name: 'asn_1_null_',
          container: false
        };
      case '06':
        return { type: 'oid', name: 'OBJECT_IDENTIFIER_', container: false };
      case 'a0':
        return {
          type: 'app_context',
          name: 'APPLICATION_CONTEXT_',
          container: true
        };
      case '04':
        return {
          type: 'octet_string',
          name: 'OCTET_STRING_',
          container: false
        };
      case '03':
        return {
          type: 'bit_string',
          name: 'BIT_STRING_',
          container: false
        };
      case '02':
        return { type: 'integer', name: 'INTEGER_', container: false };
      case '31':
        return { type: 'set', name: 'SET_', container: true };
      case '16':
        return { type: 'ia5string', name: 'IA5STRING_', container: false };
      case '17':
        return { type: 'utctime', name: 'UTCTIME_', container: false };
      default:
        return {};
    }
  }

  static determineElementSize(el) {
    /*
    we have hexstring '30 82 0A 9C 30'
    30 is ID of sequence
    next value determines which value we need to take
    if it is < 0x7F this value is the size
    if 0x81 < next value is the size (for example - 8180 - 80 is the size)
    if 0x82 < next two values (for example - 820FBC - 0FBC is the size)
     */
    let code = el.slice(2); // we're deleting first ID
    let size = '';
    let headerLength = 0;
    let valueOfFirstElementInside = '';
    const codeCmd = parseInt(`${code[0]}${code[1]}`.toString(), 16);
    if (codeCmd <= 127) {
      size = codeCmd;
      headerLength = 2;
      valueOfFirstElementInside = code.slice(2, 4);
    } else {
      if (codeCmd === 129) {
        // 0x81 - take next value
        size = parseInt(`${code[2]}${code[3]}`.toString(), 16);
        valueOfFirstElementInside = code.slice(4, 6);
        headerLength = 3;
      } else if (codeCmd === 130) {
        // 0x82 - take next two values
        size = parseInt(`${code.slice(2, 6)}`.toString(), 16);
        valueOfFirstElementInside = code.slice(6, 8);
        headerLength = 4;
      } else {
        // 0x83 - fallback for next three values
        // it never happens - FFFF is equal to 65535 in dec, its more than a half mb
        size = parseInt(`${code.slice(2, 8)}`.toString(), 16);
        valueOfFirstElementInside = code.slice(8, 10);
        headerLength = 10;
      }
    }

    return {
      typeInHex: el[0] + el[1],
      headerLength: headerLength,
      firstOccuredValueInside: valueOfFirstElementInside,
      valueSizeInDec: size,
      fullSizeInDec: (headerLength + size) * 2
    };
  }
}
