import { Components, Theme } from '@mui/material';

import hasFeature from 'src/lib/hasFeature';

const componentConfiguration: Components<Theme>['MuiStepLabel'] = {
  styleOverrides: {
    iconContainer: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        padding: 0
      }
    }),
    vertical: ({ theme }) => ({
      gap: theme.spacing(2)
    }),
    horizontal: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
      }
    }),
    labelContainer: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        textAlign: 'center'
      }
    }),
    label: ({ theme }) => {
      if (hasFeature('tauron')) {
        return {
          ...theme.typography.subtitle2,
          color: theme.palette.custom.textColors[900],
          minWidth: '100px',
          '&.Mui-active': {
            ...theme.typography.subtitle2,
            color: theme.palette.primary.main,
            [theme.breakpoints.down('desktop')]: {
              fontSize: theme.typography.caption2.fontSize
            }
          },
          [theme.breakpoints.down('desktop')]: {
            fontSize: theme.typography.caption2.fontSize
          }
        };
      } else {
        return {
          ...theme.typography.subtitle2,
          color: theme.palette.custom.textColors[800],
          maxWidth: '87px',
          '&.Mui-active, &.Mui-completed': {
            ...theme.typography.subtitle2,
            color: theme.palette.custom.textColors[800],
            fontWeight: 600,
            [theme.breakpoints.down('desktop')]: {
              fontSize: theme.typography.caption2.fontSize
            }
          },
          [theme.breakpoints.down('desktop')]: {
            fontSize: theme.typography.caption2.fontSize
          }
        };
      }
    }
  }
};

export default componentConfiguration;
