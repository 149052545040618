import {
  StyledDetailGroup,
  StyledDocumentInformation,
  StyledDocumentInformationTitle,
  StyledDocumentInformationValue
} from './styles';

export type DetailsElementType = {
  title: string;
  value: string | undefined;
};

type DetailDataSectionPropsType = {
  detailsGroup: Array<DetailsElementType>;
};

const DetailDataSection = ({ detailsGroup }: DetailDataSectionPropsType) => (
  <StyledDetailGroup>
    {detailsGroup.map(({ title, value }) => (
      <StyledDocumentInformation>
        <StyledDocumentInformationTitle variant="body1" fontWeight={800}>
          {`${title}: `}
        </StyledDocumentInformationTitle>
        <StyledDocumentInformationValue variant="body1">
          {value}
        </StyledDocumentInformationValue>
      </StyledDocumentInformation>
    ))}
  </StyledDetailGroup>
);

export default DetailDataSection;
