import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import generateKeysFromPassword from 'src/modules/UserIdentity/utils/generateKeysFromPassword';
import postFullIdentityStep1 from 'src/modules//UserIdentity/api/postFullIdentityStep1';
import bufferToBase64 from 'src/modules/Crypto/lib/bufferToBase64';
import waitForBinaryDataToSign from 'src/modules/UserIdentity/utils/waitForBinaryDataToSign';
import waitForIdentity from 'src/modules/UserIdentity/utils/waitForIdentity';
import ApiError from 'src/lib/apiError';
import resolveChallenge from 'src/modules/Crypto/lib/resolveChallenge';
import postFullIdentityStep2 from 'src/modules/UserIdentity/api/postFullIdentityStep2';
import waitForFullIdentity from 'src/modules/UserIdentity/utils/waitForFullIdentity';
import waitForSaveUserKeys from 'src/modules/UserIdentity/utils/waitForSaveUserKeys';
import postSaveKeys from 'src/modules/UserIdentity/api/postSaveKeys';
import { UserUuid } from 'src/modules/UserIdentity/types/api';

type UseGenerateKeysFromPasswordProps = {
  email: string;
  password: string;
  processId: string;
};

const useVerifyUser = (
  options: UseMutationOptions<
    UserUuid,
    AxiosError,
    UseGenerateKeysFromPasswordProps
  >
) =>
  useMutation<UserUuid, AxiosError, UseGenerateKeysFromPasswordProps>(
    async ({ email, password, processId }) => {
      const result = await generateKeysFromPassword({
        email,
        password
      });

      if (!result) {
        throw new Error('GENERATE_KEYS_FROM_PASSWORD_FAILED');
      }

      const { kek, privateKeys, publicKeys, hashFromHashFromPassword } = result;

      try {
        await postFullIdentityStep1({
          kek: bufferToBase64(kek),
          privateKeys: bufferToBase64(privateKeys),
          hashFromHashFromPassword,
          processId,
          publicKeys: bufferToBase64(publicKeys)
        });
      } catch (error) {
        const axiosError = error as AxiosError<{ statusCode: string }>;

        if (axiosError?.response) {
          const response = axiosError.response;

          if (response) {
            throw new ApiError(
              response.data.statusCode,
              response.data.statusCode
            );
          }
        }

        if (
          !(
            error instanceof ApiError &&
            error.code === 'The verification process is in wrong status'
          )
        ) {
          throw error;
        }
      }

      const { binaryDataToSign } = await waitForBinaryDataToSign(processId);
      const challengeResponse = await resolveChallenge(
        binaryDataToSign,
        privateKeys,
        kek,
        hashFromHashFromPassword
      );

      await postFullIdentityStep2({
        processId,
        signedBinaryData: bufferToBase64(challengeResponse)
      });

      const { uuid } = await waitForFullIdentity(processId);

      const { processId: saveKeysProcessId } = await postSaveKeys({
        hashFromHashFromPassword,
        uuid,
        kek: bufferToBase64(kek),
        privateKeys: bufferToBase64(privateKeys),
        referenceId: Date.now().toString(),
        recoveryInformation: []
      });

      await waitForSaveUserKeys(saveKeysProcessId);

      await waitForIdentity(uuid, hashFromHashFromPassword);

      return uuid;
    },
    options
  );

export default useVerifyUser;
