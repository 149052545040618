import { Button, Divider, Typography } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, Trans } from 'react-i18next';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import FormTextField from 'src/components/form/formTextField';
import FormAlert from 'src/components/formAlert';
import useLoginUser from 'src/modules/UserIdentity/hooks/useLoginUser';
import routes from 'src/constants/routes';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { StepperHeader } from 'src/components/stepper';
import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';
import {
  StyledContent,
  StyledDividerContainer,
  StyledFormContainer,
  StyledHeaderContainer,
  StyledRegisterContainer,
  StyledRegisterLink,
  StyledStepperControls
} from 'src/pages/user-identity/login/styles';

type LoginForm = {
  document: string;
};

const schema = yup
  .object()
  .shape({
    document: yup.string().required(ERROR_EMPTY_FIELD)
  })
  .required(ERROR_EMPTY_FIELD);

const LoginWithDocumentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formProps = useForm<LoginForm>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { state, saveLogin } = useContext(UserIdentityContext);

  const { mutate, isLoading, error } = useLoginUser();
  const { register } = formProps;

  const handleLoginClick = useCallback(() => {
    const values = formProps.getValues();

    if (state.login) {
      saveLogin(state.login.email, state.login.password, values.document);

      mutate({
        email: state.login?.email,
        password: state.login?.password,
        documentNumber: values.document
      });
    }
  }, [formProps, mutate, saveLogin, state.login]);

  useEffect(() => {
    if (!state.login) {
      navigate(routes.documentAccessTypeLogin);
    }
  }, [navigate, state]);

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleLoginClick)}>
        <FormAlert
          error={
            error?.message === 'MORE_THAN_ONE_FULL_IDENTITY_FOUND'
              ? { message: 'PESEL_NUMBER_INVALID' }
              : error
          }
        />
        <StyledContent>
          <StyledHeaderContainer>
            <StepperHeader
              subtitleKey="LOG_IN_DESCRIPTION"
              titleKey="LOG_IN_TITLE"
            />
          </StyledHeaderContainer>
          <StyledFormContainer>
            <Typography variant="subtitle1" fontWeight="600">
              {t('PRIVATE_DOCUMENT_LOGIN_MULTIPLE_IDENTITIES')}
            </Typography>
            <FormTextField
              fullWidth
              variant="outlined"
              label={t('REGISTRATION_ENTER_DATA_PESEL')}
              disabled={isLoading}
              InputProps={{ ...register('document') }}
            />
          </StyledFormContainer>
          <StyledStepperControls>
            <Button
              onClick={() => navigate(routes.documentAccessTypeLogin)}
              startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
              color="secondary"
            >
              {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
            </Button>

            <LoadingButton
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
            >
              <span>{t('LOG_IN_LOG_IN_BUTTON')}</span>
            </LoadingButton>
          </StyledStepperControls>
          <StyledDividerContainer>
            <Divider />
            <StyledRegisterContainer>
              <StyledRegisterLink to={routes.documentAccessTypeRegister}>
                <Typography variant="subtitle2">
                  <Trans>LOG_IN_REGISTRATION</Trans>
                </Typography>
              </StyledRegisterLink>
            </StyledRegisterContainer>
          </StyledDividerContainer>
        </StyledContent>
      </form>
    </FormProvider>
  );
};

export default LoginWithDocumentPage;
