import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledRegisterLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  gap: theme.spacing(1),
  color: theme.palette.custom.neutral[900],
  i: {
    fontStyle: 'normal',
    textDecoration: 'underline',
    color: theme.palette.custom.textColors[700]
  }
}));

export const StyledContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2),
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  button: {
    width: '100%'
  }
}));
