import LANGUAGES from 'src/constants/i18n';
import plFlag from 'src/assets/flag/pl.png';
import ukFlag from 'src/assets/flag/uk.png';

import FlagProps from './types';

const Flag = ({ language, width = '21px', height = '15px' }: FlagProps) => {
  switch (language) {
    case LANGUAGES.EN:
      return <img src={ukFlag} width={width} height={height} alt="uk" />;
    case LANGUAGES.PL:
      return <img src={plFlag} width={width} height={height} alt="pl" />;
  }

  return null;
};

export default Flag;
