import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import {
  GetPublicDocumentListParams,
  UsePublicDocumentListResponse
} from 'src/modules/DocumentsPrivate/types';

const getPublicDocumentList = async ({
  page,
  limit,
  publisherName,
  publicationDateFrom,
  publicationDateTo,
  title,
  category,
  blockchainAddress,
  validSince,
  validUntil,
  order,
  orderBy
}: GetPublicDocumentListParams) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const query = {
    page: page.toString(),
    limit: limit.toString(),
    publisherName,
    publicationDateFrom,
    publicationDateTo,
    title,
    category,
    blockchainAddress,
    validSince,
    validUntil,
    orderBy,
    order
  };
  const params = new URLSearchParams(query);
  Object.entries(query).forEach(([key, value]) => {
    if (value === '' || typeof value === 'undefined') {
      params.delete(key);
    }
  });

  return apiClient.get<UsePublicDocumentListResponse>(
    `${DOCUMENT_API_URL}${apiRoutes.publicDocuments}?${params.toString()}`
  );
};

export default getPublicDocumentList;
