import { Button, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MenuIcon } from 'src/assets/menuIcon.svg';
import { LayoutContext } from 'src/contexts/LayoutContext';
import BillonLogoLink from 'src/components/billonLogoLink';
import StyledGradient from 'src/components/layoutGradient';
import { appConfig } from 'src/constants/appConfig';

import AppBarActions from './components/AppBarActions/AppBarActions';
import Divider from './components/AppbarDivider';
import {
  StyledAppBar,
  StyledInfoContainer,
  StyledPanelInfoContent,
  StyledStageInfo,
  StyledToggleButton
} from './styles';

const AppBar = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { toggleNavbar } = useContext(LayoutContext);
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  return (
    <StyledAppBar>
      <StyledGradient />
      <Toolbar>
        {!isMobile && (
          <StyledToggleButton
            onClick={toggleNavbar}
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </StyledToggleButton>
        )}
        <BillonLogoLink />
        {!isMobile && <Divider orientation="vertical" flexItem />}
        <StyledInfoContainer>
          {!isMobile && (
            <StyledStageInfo variant="body2">
              {t(`STAGE_${appConfig.envPrefix}`)}
            </StyledStageInfo>
          )}
          <Button variant="gradient">
            <StyledPanelInfoContent>{t('PANEL_NAME')}</StyledPanelInfoContent>
          </Button>
        </StyledInfoContainer>
        {!isMobile && <AppBarActions />}
        {isMobile && (
          <StyledToggleButton
            onClick={toggleNavbar}
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </StyledToggleButton>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBar;
