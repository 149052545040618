import { CircularProgress, styled, Typography } from '@mui/material';

import { FileUploadIconProps } from 'src/components/fileUpload/types';

export const StyledFileUploadStatus = styled('div')<FileUploadIconProps>(
  ({ theme, isError }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: isError ? theme.palette.error.main : 'inherit'
  })
);

export const StyledFileInfo = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const StyledFileInfoName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.custom.textColors[700]
}));

export const StyledFileInfoMetadata = styled('div')(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap'
}));

export const StyledFileInfoMetadataText = styled(Typography)<{
  error?: boolean;
}>(({ theme, error }) => ({
  color: error ? theme.palette.error.main : theme.palette.custom.textColors[700]
}));

export const StyledFileUploadStatusContent = styled('div')(({ theme }) => ({
  gap: theme.spacing(3),
  display: 'flex',
  alignItems: 'center'
}));

export const StyledCircularProgress = styled(CircularProgress)`
  min-width: 40px;
`;
