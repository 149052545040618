const queryKeys = {
  getDocumentSettings: 'getDocumentSettings',
  getSettings: 'getSettings',
  getDocumentPublishers: 'getDocumentPublishers',
  getDocumentCatalog: 'getDocumentCatalog',
  useDocumentByKeys: 'useDocumentByKeys',
  usePublicDocument: 'usePublicDocument',
  usePublicDocumentFile: 'usePublicDocumentFile',
  usePostVerifyDocumentByBlockchainAddress:
    'usePostVerifyDocumentByBlockchainAddress',
  usePrivateDocumentList: 'usePrivateDocumentList',
  usePublicDocumentList: 'usePublicDocumentList',
  useDocumentCategories: 'useDocumentCategories',
  useDocumentPublishers: 'useDocumentPublishers',
  useDocumentHistory: 'useDocumentHistory',
  apiHealth: 'apiHealth'
};

export default queryKeys;
