import { Components, Theme } from '@mui/material';
import { plPL } from '@mui/x-data-grid';

import { ReactComponent as ArrowDropdown } from 'src/assets/arrowDropdown.svg';
import { ReactComponent as ArrowDropup } from 'src/assets/arrowDropup.svg';

const componentConfiguration: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    autoHeight: true,
    disableColumnFilter: true,
    localeText: plPL.components.MuiDataGrid.defaultProps.localeText,
    slots: {
      columnSortedDescendingIcon: () => <ArrowDropup />,
      columnSortedAscendingIcon: () => <ArrowDropdown />,
      columnUnsortedIcon: () => null
    },
    getRowClassName: params =>
      params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'odd',
    pageSizeOptions: [5, 10, 25]
  },
  styleOverrides: {
    root: ({ theme }) => ({
      border: 0,

      '.MuiDataGrid-columnHeaderTitleContainerContent': {
        ...theme.typography.body2,
        fontWeight: 600
      },
      '.odd': {
        background: theme.palette.custom.textColors[100]
      },

      '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell':
        {
          overflow: 'visible'
        }
    }),
    iconButtonContainer: ({ theme }) => ({
      '.MuiButtonBase-root': {
        color: theme.palette.custom.neutral[500],
        visibility: 'visible'
      }
    }),
    cell: {
      border: 0,
      '&:focus, &:focus-within': {
        outline: 'none'
      }
    },
    cellContent: ({ theme }) => ({
      color: theme.palette.custom.neutral[900]
    }),
    footerContainer: {
      border: 0
    },
    columnHeaders: ({ theme }) => ({
      '&.MuiDataGrid-withBorderColor': {
        borderColor: theme.palette.custom.neutral[200]
      }
    }),
    columnHeader: {
      '&:focus, &:focus-within': {
        outline: 'none'
      },
      '&--sorted svg': {
        transform: 'rotate(180deg)'
      }
    },
    columnHeaderTitle: ({ theme }) => ({
      ...theme.typography.body2,
      fontWeight: 600,
      color: theme.palette.custom.textColors[800]
    }),
    columnSeparator: {
      display: 'none'
    },
    menu: {
      '.MuiDivider-root': {
        backgroundColor: 'unset'
      }
    }
  }
};

export default componentConfiguration;
