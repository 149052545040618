import { Buffer } from 'buffer';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { FullIdentityLoadUserKeysResponse } from 'src/modules/UserIdentity/types/api';
import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { decryptDocument } from 'src/modules/DocumentsPrivate/utils';
import getCatalogByBlockchainAddress from 'src/modules/DocumentsPrivate/api/getCatalogByBlockchainAddress';
import {
  DecodedDocument,
  GetPrivateDocumentCatalogResponse
} from 'src/modules/DocumentsPrivate/types';
import hasFeature from 'src/lib/hasFeature';
import {
  AgreementStatusEnum,
  DocumentTypes
} from 'src/modules/DocumentsPrivate/constants/document';
import { default as mockPdf } from 'src/modules/DocumentsPrivate/mockPdf.json';

type UseCatalogByBlockchainAddressParams = {
  blockchainAddress?: string;
  hashFromHashFromPassword?: string;
  keys?: FullIdentityLoadUserKeysResponse;
};

type UseDocumentByKeysResponse = {
  document: DecodedDocument;
  catalog: GetPrivateDocumentCatalogResponse;
};

const useDocumentByKeys = (
  {
    keys,
    blockchainAddress,
    hashFromHashFromPassword
  }: UseCatalogByBlockchainAddressParams,
  options?: UseQueryOptions<UseDocumentByKeysResponse, AxiosError>
) =>
  useQuery<UseDocumentByKeysResponse, AxiosError>(
    [queryKeys.useDocumentByKeys, blockchainAddress],
    async () => {
      if (!keys || !blockchainAddress || !hashFromHashFromPassword) {
        throw new Error('KEYS_OR_BLOCKCHAIN_ADDRESS_NOT_SET');
      }

      if (hasFeature('mockEndpoints')) {
        // TODO move this to json

        return {
          document: {
            documentData: Buffer.from(mockPdf.content, 'base64'),
            documentType: DocumentTypes.PDF,
            title: 'title',
            legalValidityFinishDate: null,
            legalValidityStartDate: new Date(),
            fullCategory: 'Test-category',
            mainCategory: 'Test-category'
          },
          catalog: {
            documentInfo: {
              documentData: {
                category: 'test',
                retentionDate: 'test',
                creationDate: new Date().toString(),
                extension: 'test'
              },
              documentBlockchainData: {
                documentBlockchainAddress: 'test',
                documentStatus: 'test',
                publicationDate: 'test',
                publicationMode: 'test',
                publisherId: 'test'
              }
            },
            encryptedCekList: [],
            encryptedSensitiveContent: '',
            ...(blockchainAddress === 'test-agreement-address' && {
              signingStatus: AgreementStatusEnum.WAITING_FOR_RECEIVER
            }),
            ...(blockchainAddress === 'test-rejected-agreement-address' && {
              signingStatus: AgreementStatusEnum.REJECTED
            }),
            ...(blockchainAddress === 'test-accepted-agreement-address' && {
              signingStatus: AgreementStatusEnum.ACCEPTED
            }),
            ...(blockchainAddress ===
              'test-rejected-waiting-for-publishing' && {
              signingStatus: AgreementStatusEnum.REJECTED_WAITING_FOR_PUBLISHING
            }),
            ...(blockchainAddress ===
              'test-accepted-waiting-for-publishing' && {
              signingStatus: AgreementStatusEnum.ACCEPTED_WAITING_FOR_PUBLISHING
            })
          }
        };
      }

      const { data: documentCatalog } = await getCatalogByBlockchainAddress(
        blockchainAddress
      );

      const decryptedDocument = await decryptDocument({
        userKeys: keys,
        documentCatalog,
        blockchainAddress,
        hashFromHashFromPassword
      });

      return {
        document: decryptedDocument,
        catalog: documentCatalog
      };
    },
    options
  );

export default useDocumentByKeys;
