import { styled } from '@mui/material';

export const StyledContainer = styled('div')<{ hasNavbar: boolean }>(
  ({ theme, hasNavbar }) => ({
    display: 'flex',
    position: 'absolute',
    bottom: theme.spacing(3),
    width: '100%',
    color: theme.palette.custom.neutral[300],
    svg: {
      margin: theme.spacing(0, 1, 0, 1)
    },
    paddingLeft: theme.spacing(hasNavbar ? 32 : 8),
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: 0,
      bottom: `${theme.spacing(2)}`
    }
  })
);

export const StyledContent = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;
