import vector from './Vector.svg';
import { StyledAnimation, StyledAnimationDiv } from './styles';

type Props = {
  width?: number;
  height?: number;
};

const Animation = ({ width = 200, height = 200 }: Props) => (
  <StyledAnimation width={width} height={height}>
    <img src={vector} alt="chain animation" />
    <StyledAnimationDiv />
  </StyledAnimation>
);

export default Animation;
