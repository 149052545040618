import getApiClient from 'src/lib/getApiClient';
import { VerifyDocumentResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const postVerifyDocumentByBlockchainAddress = (blockchainAddress: string) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.post<VerifyDocumentResponse>(
    apiRoutes.verifyDocument.replace('{blockchainAddress}', blockchainAddress)
  );
};

export default postVerifyDocumentByBlockchainAddress;
