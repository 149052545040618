import { CardContent, Theme, Typography, useMediaQuery } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { PropsWithChildren } from 'react';

import CardTitle from 'src/components/cardTitle';
import {
  StyledButton,
  StyledCardContentTextWrapper
} from 'src/components/card/styles';
import { CardContentProps } from 'src/components/card/types';

const PrimaryCardContent = ({
  title,
  onButtonClick,
  buttonText,
  children
}: PropsWithChildren<CardContentProps>) => {
  const smallerThanDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );

  return (
    <CardContent>
      <CardTitle title={title} />
      <StyledCardContentTextWrapper>
        <Typography
          variant={smallerThanDesktop ? 'subtitle2' : 'subtitle1'}
          fontWeight={400}
        >
          {children}
        </Typography>
      </StyledCardContentTextWrapper>
      <StyledButton
        variant="contained"
        onClick={onButtonClick}
        endIcon={<KeyboardArrowRight />}
      >
        {buttonText}
      </StyledButton>
    </CardContent>
  );
};

export default PrimaryCardContent;
