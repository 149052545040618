import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
  Box
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import * as yup from 'yup';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import StepperControls from 'src/components/stepper/components/stepperControls';
import useDocumentSettings from 'src/modules/DocumentsPrivate/hooks/useDocumentSettings';
import {
  VerifyDocumentStepKeysInitial,
  VerifyDocumentWizardSteps
} from 'src/modules/DocumentsPrivate/constants/stepper';
import { VerifyDocumentEnum } from 'src/modules/DocumentsPrivate/constants/document';
import SelectDocumentStep from 'src/modules/DocumentsPrivate/components/selectDocumentStep';
import {
  validatedBlockChainAddress,
  VerifyDocumentForm
} from 'src/modules/DocumentsPrivate/constants/form';
import routes from 'src/constants/routes';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import postVerifyDocumentByBlockchainAddress from 'src/modules/DocumentsPrivate/api/postVerifyDocumentByBlockchainAddress';
import {
  Stepper,
  StepperHeaderContainer,
  StepperContainer,
  StepperHeader,
  StepperContent
} from 'src/components/stepper';
import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';
import postVerifyDocumentByFile from 'src/modules/DocumentsPrivate/api/postVerifyDocumentByFile';

const schema = yup.object({
  verifyType: yup.string().required(),
  file: yup.lazy((value, options) => {
    if (options.parent.verifyType === VerifyDocumentEnum.BLOCKCHAIN_ADDRESS) {
      return yup.object().nullable();
    }

    return yup.mixed().required(ERROR_EMPTY_FIELD);
  }),
  blockchainAddress: yup.lazy((value, options) => {
    if (options.parent.verifyType === VerifyDocumentEnum.FILE) {
      return yup.string();
    }

    return validatedBlockChainAddress;
  })
});

const VerifyDocumentPageIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useDocumentSettings();
  const theme = useTheme();
  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));
  const { state, startLoading, setVerificationResult, setBlockchainAddress } =
    useContext(PrivateDocumentContext);
  const [uploadProgress, setUploadProgress] = useState(0);

  const formProps = useForm<VerifyDocumentForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      file: null,
      verifyType: VerifyDocumentEnum.FILE
    }
  });

  const handleVerifyDocumentClick = useCallback(
    (form: VerifyDocumentForm) => {
      (async () => {
        startLoading();

        try {
          const { data } = form.blockchainAddress
            ? await postVerifyDocumentByBlockchainAddress(
                form.blockchainAddress
              )
            : await postVerifyDocumentByFile(form.file || null, {
                onUploadProgress: uploadProgress => {
                  if (uploadProgress.progress) {
                    setUploadProgress(
                      Math.round(uploadProgress.progress * 100)
                    );
                  }
                }
              });

          setVerificationResult(data);
          setBlockchainAddress(data.documentBlockchainAddress);
        } catch {
          setVerificationResult(null);
        }

        navigate(routes.verifyBlockchainAddressResult);
      })();
    },
    [navigate, setBlockchainAddress, setVerificationResult, startLoading]
  );

  if (!data) {
    return null;
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleVerifyDocumentClick)}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href="/">
            Home
          </Link>
          <Typography>{t('HOME_DOCUMENT_VERIFICATION')}</Typography>
        </Breadcrumbs>
        <StepperContainer>
          {!isHorizontalStepper && (
            <Stepper
              stepTitleKeys={VerifyDocumentStepKeysInitial}
              orientation="vertical"
              activeStep={VerifyDocumentWizardSteps.VERIFY_DOCUMENT}
            />
          )}
          <StepperContent>
            <Box>
              <StepperHeaderContainer>
                <StepperHeader
                  subtitleKey="DOCUMENT_VERIFICATION_VERIFY_DOCUMENT_DESCRIPTION"
                  titleKey="DOCUMENT_VERIFICATION_VERIFY_DOCUMENT_TITLE"
                />
                {isHorizontalStepper && (
                  <Stepper
                    stepTitleKeys={VerifyDocumentStepKeysInitial}
                    orientation="horizontal"
                    activeStep={VerifyDocumentWizardSteps.VERIFY_DOCUMENT}
                  />
                )}
              </StepperHeaderContainer>
              <SelectDocumentStep uploadProgress={uploadProgress} />
            </Box>
            <StepperControls>
              <LoadingButton
                type="submit"
                loading={state.isLoading}
                disabled={state.isLoading}
                loadingPosition="end"
                endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                variant="contained"
              >
                <span>
                  {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}
                </span>
              </LoadingButton>
            </StepperControls>
          </StepperContent>
        </StepperContainer>
      </form>
    </FormProvider>
  );
};

export default VerifyDocumentPageIndex;
