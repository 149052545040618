import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledPrimaryEmphasis = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const StyledErrorWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  minHeight: 'calc(100vh - 400px)'
});

export const StyledImageWrapper = styled(Box)({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  paddingBottom: '2rem'
});

export const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '500px',
  [theme.breakpoints.only('mobile')]: {
    width: '100%'
  }
}));

export const StyledTextWrapper = styled(Box)({
  flex: '1 1 auto'
});
