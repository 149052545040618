import { styled, Typography, SvgIcon } from '@mui/material';

export const StyledContent = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1),
  textAlign: 'center',
  h6: {
    color: theme.palette.custom.textColors[800]
  }
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.darkMode[900],
  fontWeight: 600,
  textAlign: 'center'
}));

export const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.custom.textColors[800],
  width: 60,
  height: 60
}));
