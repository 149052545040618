import { pdfjs } from 'react-pdf';
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';

import { handleOnScrollDebounced } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/utils';

import {
  StyledTauronDocumentWrapper,
  StyledTauronDocumentContent,
  StyledDocument,
  StyledPage
} from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

type DocumentPreviewType = {
  documentBlob: Blob | undefined | null;
  inputRef: MutableRefObject<HTMLInputElement | undefined>;
  documentScale: number;
  pagesTotalNumber: number;
  setPagesTotalNumber: Dispatch<SetStateAction<number>>;
};

const DocumentPreview = ({
  documentBlob,
  inputRef,
  documentScale,
  pagesTotalNumber,
  setPagesTotalNumber
}: DocumentPreviewType) => {
  const onPageChange = useCallback(
    (page: number | null) => {
      if (page === null) {
        return;
      }

      if (inputRef.current) {
        inputRef.current.value = page.toString();
      }
    },
    [inputRef]
  );

  const handleOnScrollPreview = () => handleOnScrollDebounced(onPageChange);

  const handleDocumentSuccessLoad = useCallback(
    ({ numPages }: { numPages: number }) => setPagesTotalNumber(numPages),
    [setPagesTotalNumber]
  );

  return (
    <StyledTauronDocumentWrapper sx={{ minHeight: '500px' }}>
      <StyledTauronDocumentContent
        id="pdf-preview"
        onScroll={handleOnScrollPreview}
      >
        <StyledDocument
          scale={documentScale}
          file={documentBlob}
          onLoadSuccess={handleDocumentSuccessLoad}
        >
          {[...Array(pagesTotalNumber)].map((_, index) => (
            <StyledPage
              key={`page-${index}`}
              pageIndex={index}
              scale={documentScale}
            />
          ))}
        </StyledDocument>
      </StyledTauronDocumentContent>
    </StyledTauronDocumentWrapper>
  );
};

export default DocumentPreview;
