import { useEffect } from 'react';
import DOMPurify from 'dompurify';

const RedirectFormWrapper = ({
  responseHtmlForm
}: {
  responseHtmlForm: string;
}) => {
  useEffect(() => {
    if (document.forms.length > 0) {
      document.forms[0].submit();
    }
  }, [responseHtmlForm]);

  const safeHTML = DOMPurify.sanitize(responseHtmlForm);

  return <div dangerouslySetInnerHTML={{ __html: safeHTML }} />;
};

export default RedirectFormWrapper;
