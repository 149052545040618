import { Button, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { plPL } from '@mui/x-data-grid';

import hasFeature from 'src/lib/hasFeature';
import routes from 'src/constants/routes';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import { ReactComponent as RefreshIcon } from 'src/assets/refreshIcon.svg';
import { ReactComponent as FiltersIcon } from 'src/assets/filters.svg';
import FiltersCard from 'src/modules/DocumentsPrivate/components/documentListPublic/filtersCard';
import { TableLoader } from 'src/components/loader';
import { SortModel } from 'src/modules/DocumentsPrivate/types';
import NoResults from 'src/modules/DocumentsPrivate/components/documentList/noResults';
import useDocumentPublishers from 'src/modules/DocumentsPrivate/hooks/useDocumentPublishers';
import TableRowsLoader from 'src/modules/DocumentsPrivate/components/documentList/tableRowsLoader';
import { UsePublicDocumentListResponse } from 'src/modules/DocumentsPrivate/types';
import {
  StyledCloseButton,
  StyledDataGrid,
  StyledDetailsLink,
  StyledHeaderContainer,
  StyledRefreshText,
  StyledTableContainer,
  StyledTableContainerHeader
} from 'src/modules/DocumentsPrivate/components/documentList/table/styles';
import { PublicDocumentListForm } from 'src/modules/DocumentsPrivate/components/documentListPublic/filtersCard/form';

import { columns } from './columns';

const filteredColumns = columns.filter(
  column =>
    column.field !== 'category' || hasFeature('documentPrivateCategories')
);

type Props = {
  onSearch: () => void;
  documentListData?: UsePublicDocumentListResponse;
  isLoading: boolean;
  isFetching: boolean;
  sortModel: SortModel;
  paginationModel: GridPaginationModel;
  searchForm: PublicDocumentListForm;
  setSortModel: (model: SortModel) => void;
  setPaginationModel: (model: GridPaginationModel) => void;
};

const Table = ({
  onSearch,
  documentListData,
  isLoading,
  isFetching,
  sortModel,
  paginationModel,
  searchForm,
  setPaginationModel,
  setSortModel
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage }
  } = useTranslation();
  const navigate = useNavigate();
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const { data: publishers } = useDocumentPublishers({
    enabled: Boolean(documentListData)
  });
  const privateDocumentList = useMemo(
    () => documentListData?.rows,
    [documentListData]
  );

  const documentListMap = useMemo(
    () =>
      privateDocumentList?.map((document, index) => ({
        id: index,
        title: document.title,
        publicationDate: dayjs(document.publicationDate).format(
          'DD.MM.YYYY, HH:mm:ss'
        ),
        category: document.category,
        publisher: (publishers || []).find(
          publisher =>
            publisher.publisherId === document.publisherId ||
            (hasFeature('mockEndpoints') &&
              publisher.publisherId === 'test-address')
        )?.name,
        validSince: dayjs(document.validSince).format('DD.MM.YYYY'),
        validUntil: dayjs(document.validUntil).format('DD.MM.YYYY'),
        preview: (
          <StyledDetailsLink
            variant="body2"
            onClick={e => {
              e.stopPropagation();
              navigate(
                routes.publicDocumentBlockchainAddress.replace(
                  ':address',
                  document.documentBlockchainAddress as string
                )
              );
            }}
          >
            {t('PRIVATE_DOCUMENTS_LIST_SEE_DEATAILS')}
          </StyledDetailsLink>
        )
      })) || [],
    [privateDocumentList, publishers, t, navigate]
  );
  const isTableReady = !isLoading && !isFetching;

  return (
    <StyledTableContainer>
      <StyledTableContainerHeader>
        <StyledHeaderContainer>
          <Typography variant="h6" fontWeight={600}>
            {t('PUBLIC_DOCUMENTS_LIST_TITLE')}{' '}
            {searchForm.publisherName && `“${searchForm.publisherName}”`}
          </Typography>
          <Button
            onClick={() => onSearch()}
            variant="help"
            startIcon={<RefreshIcon width="24px" height="24px" />}
          >
            <StyledRefreshText variant="buttonSmall">
              {t('PRIVATE_DOCUMENTS_DETAILS_SIGNATURE_REFRESH')}
            </StyledRefreshText>
          </Button>
        </StyledHeaderContainer>
        <StyledCloseButton
          size="small"
          onClick={() => setIsFiltersVisible(prevState => !prevState)}
          startIcon={isFiltersVisible ? <CloseIcon /> : <FiltersIcon />}
          isOpened={isFiltersVisible}
          variant="text"
        >
          {t(
            isFiltersVisible
              ? 'PRIVATE_DOCUMENTS_LIST_FITERS_CLOSE_FILTERS'
              : 'PRIVATE_DOCUMENTS_LIST_FILTERS'
          )}
        </StyledCloseButton>
      </StyledTableContainerHeader>

      {isFiltersVisible && (
        <FiltersCard isLoading={isLoading || isFetching} onSearch={onSearch} />
      )}

      <StyledDataGrid
        slots={{
          noRowsOverlay: NoResults,
          noResultsOverlay: NoResults,
          loadingOverlay:
            isTableReady || documentListMap.length > 0
              ? TableLoader
              : TableRowsLoader
        }}
        localeText={
          resolvedLanguage === 'pl'
            ? {
                ...plPL.components.MuiDataGrid.defaultProps.localeText,
                columnMenuManageColumns: t('TABLE_MANAGE_COLUMNS')
              }
            : undefined
        }
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={documentListData?.count || 0}
        onSortModelChange={sort => {
          if (isLoading) {
            return;
          }

          const sortItem = sort[0];

          if (sortItem && sortItem.sort) {
            setSortModel({
              order: sortItem.sort,
              orderBy: sortItem.field
            });
          } else {
            setSortModel({
              order: sortModel.order === 'asc' ? 'desc' : 'asc',
              orderBy: sortModel.orderBy
            });
          }
        }}
        sortModel={[
          {
            sort: sortModel.order,
            field: sortModel.orderBy
          }
        ]}
        sortingMode="server"
        paginationMode="server"
        loading={!isTableReady}
        onRowClick={({ row }) => {
          if (privateDocumentList && privateDocumentList[row.id]) {
            const document = privateDocumentList[row.id];
            navigate(
              routes.publicDocumentBlockchainAddress.replace(
                ':address',
                document.documentBlockchainAddress as string
              )
            );
          }
        }}
        columns={filteredColumns}
        rows={documentListMap || []}
      />
    </StyledTableContainer>
  );
};

export default Table;
