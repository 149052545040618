import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'src/assets/warning.svg';
import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import {
  Stepper,
  StepperContainer,
  StepperContent,
  StepperControls
} from 'src/components/stepper';
import CenterContainer from 'src/components/centerContainer';
import { StyledHeader, StyledIcon } from 'src/components/pageBanner/styles';
import routes from 'src/constants/routes';
import useSettings from 'src/modules/DocumentsPrivate/hooks/useSettings';
import useQueryParam from 'src/hooks/userQueryParam';

import { StyledLink } from './styles';

const NationalNodeFailure = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reason = useQueryParam('reason');

  const { data } = useSettings();

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep:
      RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION,
    erroredSteps: [
      RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION
    ]
  };

  const handleNavigateToRegister = () =>
    navigate(`${routes.documentAccessTypeRegister}`);

  const handleNavigateToNationalNodeLaunch = () =>
    navigate(`${routes.documentAccessTypeNationalNodeLaunch}`);

  return (
    <StepperContainer>
      {!isHorizontalStepper && (
        <Stepper {...stepperProps} orientation="vertical" />
      )}
      <StepperContent>
        {isHorizontalStepper && (
          <Stepper {...stepperProps} orientation="horizontal" />
        )}

        <CenterContainer>
          <Stack spacing={2} alignItems="center">
            <StyledIcon>
              <WarningIcon />
            </StyledIcon>
            <StyledHeader variant="h4">
              {t(
                reason === 'launch'
                  ? 'NATIONAL_NODE_NEGATIVE_LAUNCH_RESULT'
                  : 'NATIONAL_NODE_NEGATIVE_RESULT'
              )}
            </StyledHeader>
            <Typography variant="subtitle2" align="center">
              <Trans i18nKey="HELP_DESCRIPTION">
                Write to us{' '}
                <StyledLink href={`mailto:${data?.SUPPORT_EMAIL}`}>
                  {data?.SUPPORT_EMAIL}
                </StyledLink>{' '}
                we can help you find a solution.
              </Trans>
            </Typography>
          </Stack>
        </CenterContainer>
        <StepperControls>
          <Button onClick={handleNavigateToRegister} color="secondary">
            {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_CANCEL_BUTTON')}
          </Button>
          <Button
            onClick={handleNavigateToNationalNodeLaunch}
            variant="contained"
          >
            <span>{t('TRY_AGAIN')}</span>
          </Button>
        </StepperControls>
      </StepperContent>
    </StepperContainer>
  );
};

export default NationalNodeFailure;
