import { AxiosError } from 'axios';

import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  SendTauronSmsCode,
  SendTauronSmsCodeResponse
} from 'src/modules/UserIdentity/types/api';

const postSendTauronSmsCode = async (
  request: SendTauronSmsCode
): Promise<SendTauronSmsCodeResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  try {
    const { data: response } = await apiClient.post<SendTauronSmsCodeResponse>(
      apiRoutes.sharedSecretVerify,
      request
    );

    return response;
  } catch (err) {
    const error = err as AxiosError<{
      status: string;
      blockedUntil: string;
    }>;

    if (error.response?.data.status) {
      throw new Error(error.response?.data.status);
    }

    throw error;
  }
};

export default postSendTauronSmsCode;
