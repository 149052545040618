const apiRoutes = {
  maintenanceSelfTest: '/maintenance/selfTest',
  maintenanceSettings: '/maintenance/settings',
  verifyFullIdentity: '/fullIdentity/verify',
  verifyResendCode: '/fullIdentity/verify/resendCode',
  verifySecretCode: '/fullIdentity/verify/secretCode',
  addFullIdentityStep1: '/fullIdentity/add/step1',
  addFullIdentityStep2: '/fullIdentity/add/step2',
  fullIdentityStep1Status: '/fullIdentity/add/step1/{processId}',
  fullIdentityStep2Status: '/fullIdentity/add/step2/{processId}',
  fullIdentitySaveKeys: '/fullIdentity/saveKeys',
  fullIdentitySaveKeysStatus: '/fullIdentity/saveKeys/{processId}',
  fullIdentityUserUuid: '/fullIdentity/getUUID',
  fullIdentityLoadUserKeys: '/fullIdentity/loadKeys',
  fullIdentityTwoFactorAuthorizationCheck:
    '/fullIdentity/twoFactorAuthorization/check',
  fullIdentityTwoFactorAuthorizationSend:
    '/fullIdentity/twoFactorAuthorization/send',
  fullIdentityTwoFactorAuthorizationResend:
    '/fullIdentity/twoFactorAuthorization/resend',
  nationalNodeLogin: '/auth/login',
  nationalNodeResolve: '/auth/resolve',
  nationalNodeLogout: '/auth/logout',
  sharedSecretVerify: '/sharedSecret/verify'
};

export default apiRoutes;
