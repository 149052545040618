import {
  PublicDocumentsStepperState,
  PublicDocumentsWizardActions
} from './publicTypes';

export const publicDocumentWizardReducer = (
  state: PublicDocumentsStepperState,
  action: PublicDocumentsWizardActions
): PublicDocumentsStepperState => {
  switch (action.ACTION) {
    case 'SET_ERROR':
      return {
        ...state,

        isLoading: false,
        error: action.error
      };
    case 'LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'SET_BLOCKCHAIN_ADDRESS':
      return {
        ...state,
        blockchainAddress: action.blockchainAddress
      };
    case 'SET_DOCUMENT':
      return {
        ...state,
        document: action.document,
        isLoading: false
      };
    case 'STOP_LOADING':
      return {
        ...state,
        isLoading: false
      };
    case 'SET_INFO':
      return {
        ...state,
        info: action.info
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token
      };
    case 'SET_SUM':
      return {
        ...state,
        sum: action.sum
      };
  }

  return state;
};

export default publicDocumentWizardReducer;
