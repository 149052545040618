import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import { MaintenanceSelfTestResponse } from 'src/modules/UserIdentity/types/api';

const getMaintenanceSelfTest = () => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  return apiClient.get<MaintenanceSelfTestResponse>(
    apiRoutes.maintenanceSelfTest
  );
};

export default getMaintenanceSelfTest;
