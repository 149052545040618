import { Box, styled, TextField } from '@mui/material';

export const StyledBlockchainAddressFilter = styled(TextField)({
  '.MuiButtonBase-root.MuiIconButton-root': {
    marginRight: '-2px',
    paddingRight: 0
  }
});

export const StyledRangeGroup = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  '& > div': {
    flex: 0.5
  }
});
