import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import { ReactComponent as BillonLogo } from 'src/assets/billonLogo.svg';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import routes from 'src//constants/routes';

import { StyledLogoContainer } from './styles';

const BillonLogoLink = () => {
  const navigate = useNavigate();
  const { state } = useContext(UserIdentityContext);

  return (
    <StyledLogoContainer
      onClick={() =>
        navigate(
          state.status === 'AUTHORIZED' && state.uuid
            ? routes.documentAccessUserIndex.replace(':uuid', state.uuid)
            : '/'
        )
      }
    >
      <BillonLogo />
    </StyledLogoContainer>
  );
};

export default BillonLogoLink;
