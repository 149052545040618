/* eslint-disable */
import ASN1Tag from './ASN1Tag';
import stringCut from './stringCut';
import Stream from './Stream';
import oids from './oids';

export default class ASN1 {
  static lineLength = 80;
  static contentLength = 8 * ASN1.lineLength;
  static ellipsis = '\u2026';

  constructor(stream, header, length, tag, sub) {
    if (!(tag instanceof ASN1Tag)) throw 'Invalid tag value.';
    this.stream = stream;
    this.header = header;
    this.length = length;
    this.tag = tag;
    this.sub = sub;
  }

  typeName() {
    switch (this.tag.tagClass) {
      case 0: // universal
        switch (this.tag.tagNumber) {
          case 0x00:
            return 'EOC';
          case 0x01:
            return 'BOOLEAN';
          case 0x02:
            return 'INTEGER';
          case 0x03:
            return 'BIT_STRING';
          case 0x04:
            return 'OCTET_STRING';
          case 0x05:
            return 'NULL';
          case 0x06:
            return 'OBJECT_IDENTIFIER';
          case 0x07:
            return 'ObjectDescriptor';
          case 0x08:
            return 'EXTERNAL';
          case 0x09:
            return 'REAL';
          case 0x0a:
            return 'ENUMERATED';
          case 0x0b:
            return 'EMBEDDED_PDV';
          case 0x0c:
            return 'UTF8String';
          case 0x10:
            return 'SEQUENCE';
          case 0x11:
            return 'SET';
          case 0x12:
            return 'NumericString';
          case 0x13:
            return 'PrintableString'; // ASCII subset
          case 0x14:
            return 'TeletexString'; // aka T61String
          case 0x15:
            return 'VideotexString';
          case 0x16:
            return 'IA5String'; // ASCII
          case 0x17:
            return 'UTCTime';
          case 0x18:
            return 'GeneralizedTime';
          case 0x19:
            return 'GraphicString';
          case 0x1a:
            return 'VisibleString'; // ASCII subset
          case 0x1b:
            return 'GeneralString';
          case 0x1c:
            return 'UniversalString';
          case 0x1e:
            return 'BMPString';
        }
        return 'Universal_' + this.tag.tagNumber.toString();
      case 1:
        return 'Application_' + this.tag.tagNumber.toString();
      case 2:
        return '[' + this.tag.tagNumber.toString() + ']'; // Context
      case 3:
        return 'Private_' + this.tag.tagNumber.toString();
    }
  }

  content(maxLength) {
    // a preview of the content (intended for humans)
    if (this.tag === undefined) return null;
    if (maxLength === undefined) maxLength = Infinity;
    const content = this.posContent();
    const len = Math.abs(this.length);

    if (!this.tag.isUniversal()) {
      if (this.sub !== null) return '(' + this.sub.length + ' elem)';
      return this.stream.parseOctetString(content, content + len, maxLength);
    }

    switch (this.tag.tagNumber) {
      case 0x01: // BOOLEAN
        return this.stream.get(content) === 0 ? 'false' : 'true';
      case 0x02: // INTEGER
        return this.stream.parseInteger(content, content + len);
      case 0x03: // BIT_STRING
        return this.sub
          ? '(' + this.sub.length + ' elem)'
          : this.stream.parseBitString(content, content + len, maxLength);
      case 0x04: // OCTET_STRING
        return this.sub
          ? '(' + this.sub.length + ' elem)'
          : this.stream.parseOctetString(content, content + len, maxLength);
      //case 0x05: // NULL
      case 0x06: // OBJECT_IDENTIFIER
        return this.stream.parseOID(content, content + len, maxLength);
      //case 0x07: // ObjectDescriptor
      //case 0x08: // EXTERNAL
      //case 0x09: // REAL
      //case 0x0A: // ENUMERATED
      //case 0x0B: // EMBEDDED_PDV
      case 0x10: // SEQUENCE
      case 0x11: // SET
        if (this.sub !== null) return '(' + this.sub.length + ' elem)';
        else return '(no elem)';
      case 0x0c: // UTF8String
        return stringCut(
          this.stream.parseStringUTF(content, content + len),
          maxLength
        );
      case 0x12: // NumericString
      case 0x13: // PrintableString
      case 0x14: // TeletexString
      case 0x15: // VideotexString
      case 0x16: // IA5String
      //case 0x19: // GraphicString
      case 0x1a: // VisibleString
        //case 0x1B: // GeneralString
        //case 0x1C: // UniversalString
        return stringCut(
          this.stream.parseStringISO(content, content + len),
          maxLength
        );
      case 0x1e: // BMPString
        return stringCut(
          this.stream.parseStringBMP(content, content + len),
          maxLength
        );
      case 0x17: // UTCTime
      case 0x18: // GeneralizedTime
        return this.stream.parseTime(
          content,
          content + len,
          this.tag.tagNumber === 0x17
        );
    }
    return null;
  }

  toString() {
    return (
      this.typeName() +
      '@' +
      this.stream.pos +
      '[header:' +
      this.header +
      ',length:' +
      this.length +
      ',sub:' +
      (this.sub === null ? 'null' : this.sub.length) +
      ']'
    );
  }

  toPrettyString(indent) {
    if (indent === undefined) indent = '';
    let s = indent + this.typeName() + ' @' + this.stream.pos;
    if (this.length >= 0) s += '+';
    s += this.length;
    if (this.tag.tagConstructed) s += ' (constructed)';
    else if (
      this.tag.isUniversal() &&
      (this.tag.tagNumber === 0x03 || this.tag.tagNumber === 0x04) &&
      this.sub !== null
    )
      s += ' (encapsulates)';
    const content = this.content();
    if (content) s += ': ' + content.replace(/\n/g, '|');
    s += '\n';
    if (this.sub !== null) {
      indent += '  ';
      for (let i = 0, max = this.sub.length; i < max; ++i)
        s += this.sub[i].toPrettyString(indent);
    }
    return s;
  }

  posStart() {
    return this.stream.pos;
  }

  posContent() {
    return this.stream.pos + this.header;
  }

  posEnd() {
    return this.stream.pos + this.header + Math.abs(this.length);
  }

  toHexString() {
    return this.stream.hexDump(this.posStart(), this.posEnd(), true);
  }

  toB64String() {
    return this.stream.b64Dump(this.posStart(), this.posEnd());
  }

  static decodeLength(stream) {
    let buf = stream.get();
    const len = buf & 0x7f;
    if (len === buf) return len;
    if (len > 6) {
      // no reason to use Int10, as it would be a huge buffer anyways
      throw 'Length over 48 bits not supported at position ' + (stream.pos - 1);
    }
    if (len === 0) return null; // undefined
    buf = 0;
    for (let i = 0; i < len; ++i) buf = buf * 256 + stream.get();
    return buf;
  }

  static decode(stream) {
    if (!(stream instanceof Stream)) stream = new Stream(stream, 0);
    const streamStart = new Stream(stream);
    const tag = new ASN1Tag(stream);
    let len = ASN1.decodeLength(stream);
    const start = stream.pos;
    const header = start - streamStart.pos;
    let sub = null;
    const getSub = () => {
      sub = [];
      if (len !== null) {
        // definite length
        const end = start + len;
        if (end > stream.enc.length)
          throw (
            'Container at offset ' +
            start +
            ' has a length of ' +
            len +
            ', which is past the end of the stream'
          );
        while (stream.pos < end) sub[sub.length] = ASN1.decode(stream);
        if (stream.pos !== end)
          throw 'Content size is not correct for container at offset ' + start;
      } else {
        // undefined length
        try {
          for (;;) {
            const s = ASN1.decode(stream);
            if (s.tag.isEOC()) break;
            sub[sub.length] = s;
          }
          len = start - stream.pos; // undefined lengths are represented as negative values
        } catch (e) {
          throw (
            'Exception while decoding undefined length content at offset ' +
            start +
            ': ' +
            e
          );
        }
      }
    };
    if (tag.tagConstructed) {
      // must have valid content
      getSub();
    } else if (
      tag.isUniversal() &&
      (tag.tagNumber === 0x03 || tag.tagNumber === 0x04)
    ) {
      // sometimes BitString and OctetString are used to encapsulate ASN.1
      try {
        if (tag.tagNumber === 0x03)
          if (stream.get() !== 0) {
            // noinspection ExceptionCaughtLocallyJS
            throw 'BIT STRINGs with unused bits cannot encapsulate.';
          }
        getSub();
        for (let i = 0; i < sub.length; ++i)
          if (sub[i].tag.isEOC())
            // noinspection ExceptionCaughtLocallyJS
            throw 'EOC is not supposed to be actual content.';
      } catch (e) {
        // but silently ignore when they don't
        sub = null;
        //DEBUG console.log('Could not decode structure at ' + start + ':', e);
      }
    }
    if (sub === null) {
      if (len === null)
        throw (
          "We can't skip over an invalid tag with undefined length at offset " +
          start
        );
      stream.pos = start + Math.abs(len);
    }
    return new ASN1(streamStart, header, len, tag, sub);
  }

  toData(spaces = '') {
    const isOID =
      typeof oids === 'object' &&
      this.tag.isUniversal() &&
      this.tag.tagNumber === 0x06;
    let output = [];
    let content = this.content(ASN1.contentLength);
    if (content !== null) {
      if (isOID) {
        content = content.split('\n', 1)[0];
      }
      const shortContent =
        content.length > ASN1.lineLength
          ? content.substring(0, ASN1.lineLength) + ASN1.ellipsis
          : content;
      output.push(shortContent);
    }

    if (this.sub !== null) {
      for (let i = 0, max = this.sub.length; i < max; ++i) {
        output = output.concat(this.sub[i].toData(spaces));
      }
    }

    return output;
  }
}
