import * as yup from 'yup';

import { BlockchainDocumentPasswordLength } from 'src/modules/DocumentsPrivate/constants/document';
import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';
import {
  ERROR_WRONG_ACCESS_CODE,
  SMS_VERIFICATION_CODE_LENGTH
} from 'src/modules/DocumentsPrivate/constants/errors';

export const blockchainAddressPasswordSchema = yup.object({
  blockchainAddressPassword: yup
    .string()
    .length(BlockchainDocumentPasswordLength, ERROR_WRONG_ACCESS_CODE)
    .required(ERROR_EMPTY_FIELD)
});

export const tauronBlockchainAddressPasswordSchema = yup.object({
  blockchainAddressPassword: yup
    .string()
    .required(ERROR_EMPTY_FIELD)
    .min(BlockchainDocumentPasswordLength, SMS_VERIFICATION_CODE_LENGTH)
});
