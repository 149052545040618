import { useMediaQuery } from '@mui/material';
import { Trans } from 'react-i18next';

import { ChainLoader } from 'src/components/loader';
import theme from 'src/constants/theme';
import {
  StyledSignatureProgressMessageSection,
  StyledSignatureLoaderWrapper
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/styles';

import { StyledSignatureProgressMessageTitle } from './styles';

const SignatureStepLoader = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const loaderDimmentions = isMobile ? 94 : 172;

  return (
    <StyledSignatureLoaderWrapper>
      <ChainLoader width={loaderDimmentions} height={loaderDimmentions} />
      <StyledSignatureProgressMessageSection>
        <StyledSignatureProgressMessageTitle variant="h6">
          <Trans>PRIVATE_DOCUMENTS_DETAILS_SIGNATURE_IN_PROGRESS_TITLE</Trans>
        </StyledSignatureProgressMessageTitle>
      </StyledSignatureProgressMessageSection>
    </StyledSignatureLoaderWrapper>
  );
};

export default SignatureStepLoader;
