import { styled } from '@mui/material';

const StepperContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(7),
  gap: theme.spacing(14),
  minHeight: '60%',
  flex: 1,
  height: `calc(100% - ${theme.spacing(14)})`
}));

export default StepperContainer;
