import { Button, styled } from '@mui/material';

export const StyledStepperControls = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(6),
  [theme.breakpoints.up('desktop')]: {
    marginTop: 0
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 4),
  flexWrap: 'wrap',
  '& .MuiButton-endIcon': {
    position: 'absolute',
    right: theme.spacing(1),
    margin: 0
  }
}));
