import { Button, MenuItem, styled } from '@mui/material';

export const StyledMenuItem = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex'
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 'unset',
  '.MuiButton-endIcon': {
    color: theme.palette.custom.neutral[500],
    svg: {
      fontSize: theme.typography.pxToRem(18)
    }
  }
}));

export const StyledDivider = styled('hr')(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.custom.neutral[200],
  height: '1px',
  border: 'none'
}));

export const StyledMenuContainer = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2)
}));

export const StyledSkeletonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center'
}));

export const StyledSkeletonColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));
