import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { TFunction } from 'i18next';

import CopyToClipboard from 'src/components/copyToClipboard';
import { StyledBlockchainAddress } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/styles';
import useDocumentHistoryContext from 'src/modules/DocumentsPrivate/hooks/useDocumentHistoryContext';
import {
  DocumentHistoryEntry,
  PublicationModeEnum
} from 'src/modules/DocumentsPrivate/types';
import DocumentHistoryDownload from 'src/modules/DocumentsPrivate/components/documentHistoryDownload';
import TauronLoader from 'src/components/loader/tauronLoader';
import HistoryError from 'src/components/returnableError/historyError';

import { StyledBlockchainAddressTitle, StyledDateWrapper } from './styles';

export const mapPublicationModeToChipVariant = (
  publicationMode: PublicationModeEnum,
  t: TFunction,
  isLast: boolean,
  hasRelatedDocuments: boolean
) => {
  let variant:
    | 'warning'
    | 'errata'
    | 'outdated'
    | 'success'
    | 'approved'
    | null = null;
  let label;

  // jak publicationMode = CORRECTED  zawsze wyświetlamy etykietę errata
  if (publicationMode === PublicationModeEnum.CORRECTED) {
    variant = 'errata';
    label = t('PRIVATE_DOCUMENT_DETAILS_HISTORY_ERRATA_TYPE');
  } else if (publicationMode === PublicationModeEnum.INCIDENT) {
    variant = 'warning';
    label = t('PRIVATE_DOCUMENT_DETAILS_HISTORY_INCIDENT_TYPE');
  } else if (hasRelatedDocuments) {
    if (isLast) {
      if (publicationMode === PublicationModeEnum.UPDATED) {
        // nowa wersja - dokument ma powiązane dokumenty w historii & publicationMode = UPDATED & jest najnowszym dokumentem w historii
        variant = 'success';
        label = t('PRIVATE_DOCUMENT_DETAILS_HISTORY_NEW_VERSION_TYPE');
      }
    } else {
      // poprzednia wersja - dokument ma powiązane dokumenty w historii & nie jest najnowszym dokumentem
      variant = 'outdated';
      label = t('PRIVATE_DOCUMENT_DETAILS_HISTORY_OLD_VERSION_TYPE');
    }
  } else if (publicationMode === PublicationModeEnum.NEW) {
    // zatwierdzony - dokument nie ma powiązanych dokumentów w historii & publicationMode = NEW
    variant = 'approved';
    label = t('PRIVATE_DOCUMENT_LIST_STATUS_DEFAULT');
  }

  if (!variant) {
    return null;
  }

  return <Chip variant={variant} label={label} />;
};

const DocumentHistory = () => {
  const { t } = useTranslation();

  const { documentHistory, isLoading, isError } = useDocumentHistoryContext();

  const sortedDocumentHistory = documentHistory.sort(
    (a, b) => Date.parse(b.publicationDate) - Date.parse(a.publicationDate)
  );
  const hasRelatedDocuments = documentHistory.length > 1;

  const documentModes = [
    PublicationModeEnum.CORRECTED,
    PublicationModeEnum.NEW,
    PublicationModeEnum.UPDATED
  ];

  const filteredDocumentsFromHistory = sortedDocumentHistory.filter(
    (historyEntry: DocumentHistoryEntry) =>
      documentModes.includes(historyEntry.publicationMode)
  );

  const latestDocumentVersion = filteredDocumentsFromHistory[0];

  if (isLoading) {
    return <TauronLoader />;
  }

  if (isError) {
    return <HistoryError />;
  }

  return (
    <div>
      {sortedDocumentHistory.map((historyEntry: DocumentHistoryEntry) => {
        const { publicationDate, blockchainAddress, publicationMode } =
          historyEntry;

        const formattedDate = moment
          .utc(publicationDate)
          .local()
          .format('DD.MM.YYYY, HH:mm:ss');

        return (
          <Accordion key={blockchainAddress}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <StyledDateWrapper>
                <Typography>{formattedDate}</Typography>
              </StyledDateWrapper>
              {mapPublicationModeToChipVariant(
                publicationMode,
                t,
                historyEntry === latestDocumentVersion,
                hasRelatedDocuments
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" fontWeight={600} marginBottom={0.5}>
                {t('PRIVATE_DOCUMENT_DETAILS_HISTORY_PUBLICATION_DATE')}
              </Typography>
              <Typography variant="body1" marginBottom={2}>
                {formattedDate}
              </Typography>
              <StyledBlockchainAddressTitle>
                <Typography variant="body2" marginTop={2} fontWeight={600}>
                  {t('PRIVATE_DOCUMENT_DETAILS_HISTORY_BLOCKCHAIN_ADDRESS')}
                </Typography>
                <CopyToClipboard text={blockchainAddress} />
              </StyledBlockchainAddressTitle>
              <StyledBlockchainAddress variant="body1">
                {blockchainAddress}
              </StyledBlockchainAddress>
              <DocumentHistoryDownload blockchainAddress={blockchainAddress} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default DocumentHistory;
