import { DefaultTFuncReturn } from 'i18next';

import ApiError from 'src/lib/apiError';
import { FullIdentityLoadUserKeysResponse } from 'src/modules/UserIdentity/types/api';

import { AgreementStatusEnum, DocumentTypes } from './constants/document';

export type GetPrivateDocumentHistoryResponse = {
  relatedDocuments: Array<DocumentHistoryEntry>;
};

export type GetPublicDocumentHistoryResponse = {
  relatedDocuments: Array<PublicDocumentHistoryEntry>;
};

export type AgreementAcceptRequestType = {
  blockchainAddress: string;
  encryptedSignedDocument: string;
  responseSignature: string;
  publisherId: string;
};

export type AgreementRejectRequestType = AgreementAcceptRequestType & {
  rejectionReason: string;
};

export type AgreementSignatureResponse = {
  signingStatus?: string;
  status: string;
};

export type AddEventResponseType = {
  status: string;
};

export type AddEventRequestType = {
  publisherId: string;
  retentionDate: string;
  signedEventToken: string;
  encryptedSensitiveEventData: string;
  blockchainAddress: string;
  eventType: string;
};

export enum PublicationModeEnum {
  NEW = 'NEW',
  UPDATED = 'UPDATED',
  CORRECTED = 'CORRECTED',
  INCIDENT = 'INCIDENT',
  TEST = 'test'
}

export type DocumentHistoryEntry = {
  blockchainAddress: string;
  publicationDate: string;
  publicationMode: PublicationModeEnum;
  signingStatus?: AgreementStatusEnum;
};

export type PublicDocumentHistoryEntry = {
  documentBlockchainAddress: string;
  publicationDate: string;
  title: string;
  versionName: string;
  publicationMode: PublicationModeEnum;
};

export type EncryptedCekAuthorizationCode = {
  data: string;
  type: 'AUTHORIZATION-CODE';
  initializationVector: string;
};

export type EncryptedCekDLTIdentity = {
  data: string;
  type: 'DLT-IDENTITY';
};

export type EncryptedCEK =
  | EncryptedCekDLTIdentity
  | EncryptedCekAuthorizationCode;

export type DecryptedCEK = {
  key: Uint8Array;
  IVinSHA256: string;
  IV: string;
  IVinDoubleSHA256: string;
};

type DocumentData = {
  retentionDate: string;
  creationDate: string;
  extension: string;
  category: string;
  decryptedData?: DecryptedDocument;
};

type DocumentBlockchainData = {
  documentBlockchainAddress: string;
  documentStatus: string;
  publicationDate: string;
  publicationMode: string;
  publisherId: string;
};

type DocumentInfo = {
  documentData: DocumentData;
  documentBlockchainData: DocumentBlockchainData;
};

export type GetPrivateDocumentCatalogResponse = {
  CONFIRMATION_OF_ACKNOWLEDGEMENT?: boolean;
  CONFIRMATION_OF_RECEIPT?: boolean;
  documentInfo: DocumentInfo;
  encryptedSensitiveContent: string;
  encryptedCekList: EncryptedCEK[];
  signingStatus?: AgreementStatusEnum;
  downloadToken?: string;
};

export type VerifyDocumentResponse = {
  documentBlockchainAddress: string;
  publicationDate: string;
  publisherId: string;
  base58: string;
  base64: string;
  hex: string;
};

export type GetPrivateDocumentResponse = {
  encryptedDocumentContent: string;
};

export type DocumentResponse = {
  publicationDate: string;
  encryptedDocumentData: string;
  publisherId: string;
  publicationMode: string;
  encryptedCek: string;
  documentHash: string;
  signingStatus?: AgreementStatusEnum;
  blockchainAddress?: string;
};

export type DecryptedDocument = {
  blockchainAddress: string;
  title: string;
  categoryHash: string;
  decryptionFailed?: boolean;
};

export type DocumentList = (DocumentData & DocumentResponse)[];

export type GetPrivateDocumentListResponse = {
  indexEntriesList: DocumentList;
  count: number;
};

export type PrivateDocumentsStepperState = {
  error?: ApiError;
  isLoading: boolean;
  blockchainAddress?: string;
  accessCode?: string;
  document?: DecodedDocument;
  catalog?: GetPrivateDocumentCatalogResponse;
  verificationResult?: VerifyDocumentResponse | null;
  documentNumber?: string;
  sharedSecretType?: string;
  auth?: string;
  info?: DefaultTFuncReturn;
};

export interface PrivateDocumentsContextProps {
  setVerificationResult: (
    verificationResult: VerifyDocumentResponse | null
  ) => void;
  setBlockchainAddress: (blockchainAddress: string) => void;
  setDocument: (document: DecodedDocument) => void;
  setError: (error?: ApiError) => void;
  setAccessCode: (accessCode: string) => void;
  setCatalog: (catalog: GetPrivateDocumentCatalogResponse) => void;
  startLoading: () => void;
  stopLoading: () => void;
  setDocumentNumber: (documentNumber: string) => void;
  setSharedSecretType: (sharedSecretType: string) => void;
  setAuth: (sst: string) => void;
  setInfo: (info?: DefaultTFuncReturn) => void;
  state: {
    error?: ApiError;
    isLoading: boolean;
    blockchainAddress?: string;
    accessCode?: string;
    document?: DecodedDocument;
    catalog?: GetPrivateDocumentCatalogResponse;
    verificationResult?: VerifyDocumentResponse | null;
    documentNumber?: string;
    sharedSecretType?: string;
    auth?: string;
    info?: DefaultTFuncReturn;
  };
}

export type PrivateDocumentsWizardActions =
  | {
      ACTION: 'SET_CATALOG';
      catalog: GetPrivateDocumentCatalogResponse;
    }
  | {
      ACTION: 'SET_DOCUMENT';
      document: DecodedDocument;
    }
  | {
      ACTION: 'SET_ACCESS_CODE';
      accessCode: string;
    }
  | {
      ACTION: 'SET_BLOCKCHAIN_ADDRESS';
      blockchainAddress: string;
    }
  | {
      ACTION: 'SET_VERIFICATION_RESULT';
      verificationResult: VerifyDocumentResponse | null;
    }
  | {
      ACTION: 'LOADING';
    }
  | {
      ACTION: 'SET_ERROR';
      error?: ApiError;
    }
  | {
      ACTION: 'SET_DOCUMENT_NUMBER';
      documentNumber: string;
    }
  | {
      ACTION: 'SET_SHARED_SECRET_TYPE';
      sharedSecretType: string;
    }
  | {
      ACTION: 'SET_AUTH';
      auth: string;
    }
  | {
      ACTION: 'STOP_LOADING';
    }
  | {
      ACTION: 'SET_INFO';
      info?: DefaultTFuncReturn;
    };

export type DecryptDocumentMeta = {
  userKeys: FullIdentityLoadUserKeysResponse;
  mergedKeys: ArrayBuffer;
  document: DocumentResponse;
  hashFromHashFromPassword: string;
};

export type DecodedDocument = {
  documentData: ArrayBuffer;
  title: string;
  documentType: DocumentTypes;
  mainCategory: string;
  fullCategory: string;
  legalValidityFinishDate: Date | null;
  legalValidityStartDate: Date | null;
  tokenData?: TokenEntry[];
};

export type TokenEntry = {
  tokenCategory: {
    name: {
      value: string;
    };
    active: {
      value: boolean;
    };
    hash: {
      value: string;
    };
    parentPath: {
      value: string;
    };
  };
  tokenBody: {
    value: string;
  };
  tokenIdBase58: {
    value: string;
  };
};

export type GetSettingsResponse = {
  VERIFY_CHECKSUM: boolean;
  MAX_SIZE_VERIFY_FILE: string;
  SUPPORT_EMAIL: string;
};

export type DocumentSettings = {
  maxFileSize: number;
  maxFileSizeUnit: 'MB' | 'KB';
};

export type Publisher = {
  publisherId: string;
  name: string;
  logo: string;
};

export type GetPublisherResponse = {
  publishers: Publisher[];
};

export type Category = {
  active: string;
  name: string;
  hash: string;
  parentPath: string;
  publisherId: string;
};

export type GetCategoriesResponse = {
  categoriesList: Category[];
};

export type DecryptDocumentType = {
  userKeys: FullIdentityLoadUserKeysResponse;
  documentCatalog: GetPrivateDocumentCatalogResponse;
  blockchainAddress: string;
  hashFromHashFromPassword: string;
};

export type SortModel = {
  order: 'asc' | 'desc';
  orderBy: string;
};
export type GetPartnerEncryptionKeyParams = {
  publisherId: string;
};
export type GetPartnerEncryptionKeyResponse = {
  status: string;
  keyInfo: {
    keyData: string;
    keyValidityPeriod: string;
    keyName: string;
    keyId: string;
    keyNumber: string;
  };
};

export type GetApiHealthCheck = {
  status: string;
};

export type GetApiDeviceIp = {
  deviceIP: string;
};

export type GetPublicDocumentListParams = {
  page: number;
  limit: number;
  publisherName: string;
  publicationDateFrom: string;
  publicationDateTo: string;
  title: string;
  category: string;
  blockchainAddress: string;
  validSince: string;
  validUntil: string;
  order: string;
  orderBy: string;
};

export type UsePublicDocumentListParams = {
  page?: number;
  limit?: number;
  publisherName: string;
  publicationDateFrom: string;
  publicationDateTo: string;
  title: string;
  category: string;
  blockchainAddress: string;
  validSince: string;
  validUntil: string;
  order: string;
  orderBy: string;
};

export type UsePublicDocumentListResponse = {
  count: number;
  rows: PublicDocumentResponse[];
};

export type PublicDocumentResponse = {
  additionalDetails: string;
  category: string;
  documentBlockchainAddress: string;
  documentPublicationStatus: string;
  documentType: string;
  publicationDate: string;
  publicationMode: string;
  publisherId: string;
  publisherName: string;
  retainUntil: string;
  title: string;
  validSince: string;
  validUntil: string;
  versionName: string;
};

export enum DOCUMENT_TYPE {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export enum DOWNLOADING_EVENT_STATUS {
  OK = 'OK',
  TOO_OFTEN = 'TOO_OFTEN',
  INVALID_TOKEN = 'INVALID_TOKEN'
}
