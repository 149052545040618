import getApiClient from 'src/lib/getApiClient';
import { GetCategoriesResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const getCategories = () => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetCategoriesResponse>(apiRoutes.categories);
};

export default getCategories;
