import { styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const StyledArrowIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  '&.MuiSelect-icon': {
    color: theme.palette.custom.neutral[500]
  }
}));

export const StyledMenuItem = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex'
}));
