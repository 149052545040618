enum KeysEnum {
  backspace = 8,
  arrowLeft = 37,
  arrowRight = 39,
  zero = 48,
  nine = 57
}

enum SiblingDirectionEnum {
  previous = 'previous',
  next = 'next'
}

const focusAndSelectEnabledSiblingInput = (
  e: Event,
  siblingDirection: string,
  siblingLimit: number,
  clearValueOnEvent: boolean,
  valueRequired: boolean,
  isCharPatternCalled: boolean,
  onClearSingleInput?: (name: string) => void
) => {
  const input = e.target as HTMLInputElement;

  let siblingInput: HTMLInputElement | null =
    siblingDirection === SiblingDirectionEnum.previous
      ? (input.previousElementSibling as HTMLInputElement)
      : (input.nextElementSibling as HTMLInputElement);

  if (clearValueOnEvent && onClearSingleInput && input.value) {
    input.value = '';
    onClearSingleInput(input.name);
  }

  if (isCharPatternCalled && onClearSingleInput) {
    const charCode = input.value.charCodeAt(0);
    if (!(charCode >= KeysEnum.zero && charCode <= KeysEnum.nine)) {
      input.value = '';
      onClearSingleInput(input.name);
      return;
    }
  }

  for (let i = 0; siblingInput !== null && i < siblingLimit; i++) {
    if (
      (valueRequired && input.value && !siblingInput.disabled) ||
      (!valueRequired && !siblingInput.disabled)
    ) {
      siblingInput.focus();
      if (siblingInput.value) {
        siblingInput.select();
      }
      break;
    }
    const siblingSiblingInputInput: HTMLInputElement | null =
      siblingDirection === SiblingDirectionEnum.previous
        ? (siblingInput.previousElementSibling as HTMLInputElement)
        : (siblingInput.nextElementSibling as HTMLInputElement);
    if (siblingSiblingInputInput) {
      siblingInput = siblingSiblingInputInput;
    } else {
      break;
    }
  }
};

const handleInput = (
  e: Event,
  siblingLimit: number,
  onlyDigits: boolean,
  onClearSingleInput?: (name: string) => void
) =>
  focusAndSelectEnabledSiblingInput(
    e,
    SiblingDirectionEnum.next,
    siblingLimit,
    false,
    true,
    onlyDigits,
    onClearSingleInput
  );

const handleBackspace = (e: Event, siblingLimit: number) =>
  focusAndSelectEnabledSiblingInput(
    e,
    SiblingDirectionEnum.previous,
    siblingLimit,
    true,
    false,
    false
  );

const handleArrowLeft = (e: Event, siblingLimit: number) =>
  focusAndSelectEnabledSiblingInput(
    e,
    SiblingDirectionEnum.previous,
    siblingLimit,
    false,
    false,
    false
  );

const handleArrowRight = (e: Event, siblingLimit: number) =>
  focusAndSelectEnabledSiblingInput(
    e,
    SiblingDirectionEnum.next,
    siblingLimit,
    false,
    false,
    false
  );

export const initiateEventListeners = (
  input: HTMLInputElement,
  siblingLimit: number,
  onlyDigits: boolean,
  onClearSingleInput?: (name: string) => void
) => {
  input.addEventListener('focus', (e: Event) => {
    const input = e.target as HTMLInputElement;
    setTimeout(() => {
      if (!!input) {
        input.select();
      }
    }, 0);
  });

  input.addEventListener('input', (e: Event) =>
    handleInput(e, siblingLimit, onlyDigits, onClearSingleInput)
  );

  input.addEventListener('keydown', (e: KeyboardEvent) => {
    switch (e.keyCode) {
      case KeysEnum.backspace:
        handleBackspace(e, siblingLimit);
        break;
      case KeysEnum.arrowLeft:
        handleArrowLeft(e, siblingLimit);
        break;
      case KeysEnum.arrowRight:
        handleArrowRight(e, siblingLimit);
        break;
      default:
    }
  });
};

export const getInputMapBase = (
  fullLength: number,
  codeLength: number,
  randomIndices: boolean
) => {
  const indices = Array.from({ length: fullLength }, (_, i) => i++);

  const secondGroupStartIndex = fullLength - codeLength;

  const secondGroupRange = Array.from(
    { length: codeLength },
    (_, i) => (i += secondGroupStartIndex)
  );

  if (!randomIndices) {
    return indices.map((_, i) => secondGroupRange.includes(i));
  }

  const firstGroupRange = Array.from(
    { length: secondGroupStartIndex },
    (_, i) => i++
  );

  const secondGroupElementCount = 2 + Math.floor(Math.random() * 3);
  const selectedIndices: number[] = [];

  const randomizedFirstGroupIndices = [...firstGroupRange]
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  const randomizedSecondGroupIndices = [...secondGroupRange]
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  for (let i = 0; i < codeLength - secondGroupElementCount; i++) {
    const selectedElement = Number(randomizedFirstGroupIndices.pop());
    selectedIndices.push(selectedElement);
  }

  for (let i = 0; i < secondGroupElementCount; i++) {
    const selectedElement = Number(randomizedSecondGroupIndices.pop());
    selectedIndices.push(selectedElement);
  }

  return indices.map((_, i) =>
    selectedIndices.sort((a, b) => a - b).includes(i)
  );
};
