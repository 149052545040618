import { NATIONAL_NODE_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  NationalNodeLogoutRequest,
  NationalNodeLogoutResponse
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';
import { NationalNodeResponseStatus } from 'src/modules/UserIdentity/constants/errors';

const postNationalNodeLogout = async (
  request: NationalNodeLogoutRequest
): Promise<NationalNodeLogoutResponse> => {
  const apiClient = getApiClient(NATIONAL_NODE_API_URL);

  const { data: response } = await apiClient.post<NationalNodeLogoutResponse>(
    apiRoutes.nationalNodeLogout,
    request,
    {
      params: {
        sessionId: request.sessionId
      }
    }
  );

  if (
    [
      NationalNodeResponseStatus.ERROR,
      NationalNodeResponseStatus.FINISHED_ERR,
      NationalNodeResponseStatus.INVALID_REQUEST
    ].includes(response.status)
  ) {
    throw new ApiError(response.status);
  }

  return response;
};

export default postNationalNodeLogout;
