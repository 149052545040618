import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { DefaultTFuncReturn, TFuncReturn } from 'i18next';
import { MutableRefObject } from 'react';

import {
  StyledActivePageNumberTextField,
  StyledControlsWrapper,
  StyledPaginationWrapper
} from 'src/modules/DocumentsPrivate/components/documentDetailsStep/styles';

type DocumentScaleControllType = {
  t: TFuncReturn<'translation', string, DefaultTFuncReturn, undefined>;
  inputRef: MutableRefObject<HTMLInputElement | undefined>;
  max: number;
  value: number;
  handleScaleChange: (event: SelectChangeEvent<number>) => void;
  borderDisabled?: boolean;
  bold?: boolean;
  scaleValues?: Array<number>;
};

const DocumentScaleControll = ({
  t,
  inputRef,
  max,
  value,
  handleScaleChange,
  borderDisabled = false,
  bold = false,
  scaleValues = [0.25, 0.5, 0.75, 1, 1.5, 2]
}: DocumentScaleControllType) => (
  <StyledControlsWrapper borderDisabled={borderDisabled}>
    <StyledPaginationWrapper>
      <Typography variant="body1" fontWeight={bold ? 600 : 400}>
        {t}
      </Typography>
      <StyledActivePageNumberTextField
        type="number"
        inputRef={inputRef}
        defaultValue={1}
        onChange={e => {
          requestAnimationFrame(() => {
            window.document
              .querySelector(`[data-page-number="${e.target.value}"]`)
              ?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
          });
        }}
        InputProps={{
          inputProps: { min: 1, max }
        }}
        size="small"
      />
      <Typography variant="body1">{`/ ${max}`}</Typography>
    </StyledPaginationWrapper>
    <Select
      size="small"
      value={value}
      onChange={handleScaleChange}
      inputProps={{ MenuProps: { disableScrollLock: true } }}
    >
      {scaleValues.map(scale => (
        <MenuItem value={scale} key={scale}>{`${scale * 100}%`}</MenuItem>
      ))}
    </Select>
  </StyledControlsWrapper>
);

export default DocumentScaleControll;
