import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { DocumentHistoryEntry } from 'src/modules/DocumentsPrivate/types';
import getDocumentHistory from 'src/modules/DocumentsPrivate/api/getDocumentHistory';

const useDocumentHistory = (
  blockchainAddress: string,
  publisherId: string,
  documentType: string,
  options?: UseQueryOptions<DocumentHistoryEntry[] | undefined, AxiosError>
) =>
  useQuery<DocumentHistoryEntry[] | undefined, AxiosError>(
    [queryKeys.useDocumentHistory, blockchainAddress],
    async () => {
      const { data: response } = await getDocumentHistory({
        blockchainAddress,
        publisherId,
        documentType
      });

      return response.relatedDocuments;
    },
    options
  );

export default useDocumentHistory;
