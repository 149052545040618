import Long from 'long';
import protobuf from 'protobufjs';

import decrypt from './decrypt';
import importKey from './importKey';
import stringToByteArray from './stringToByteArray';

const decryptSensitiveData = async (convertCEK, encryptedSensitiveData) => {
  const key = await importKey(convertCEK.key);

  const decryptedData = await decrypt(
    encryptedSensitiveData,
    stringToByteArray(convertCEK.IVinSHA256),
    key
  );
  const root = await protobuf.load(
    '/assets/HorcruxPrivateDocSensitiveData.proto'
  );
  const MetadataMessage = root.lookupType(
    'Billon.HorcruxPrivateDocSensitiveDataProto'
  );

  const buffer = new Uint8Array(decryptedData);

  const temp = MetadataMessage.decode(buffer).toJSON();

  const legalValidityStartDate = temp.metadata.legalValidityStartDate
    ? new Date(
        Long.fromString(temp.metadata.legalValidityStartDate.value, true)
          .div(1000) // Convert to miliseconds
          .toNumber()
      )
    : null;

  const legalValidityFinishDate = temp.metadata.legalValidityFinishDate
    ? new Date(
        Long.fromString(temp.metadata.legalValidityFinishDate.value, true)
          .div(1000) // Convert to miliseconds
          .toNumber()
      )
    : null;

  const mainCategory = temp.metadata.mainCategory.name.value;
  const parentCategory = (temp.metadata.mainCategory.parentPath || {}).value;
  const fullCategory = [mainCategory];
  if (parentCategory) {
    fullCategory.unshift(parentCategory);
  }

  return {
    catalogue: temp.catalogue.value,
    title: temp.metadata.documentTitle.value,
    versionName: temp.metadata.documentVersionName.value,
    mainCategory: temp.metadata.mainCategory.name.value,
    parentCategory,
    fullCategory: fullCategory.join('/'),
    legalValidityStartDate: legalValidityStartDate,
    legalValidityFinishDate: legalValidityFinishDate,
    tokenData: temp.tokenData ? temp.tokenData.values : null
  };
};

export default decryptSensitiveData;
