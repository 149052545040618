import { styled, Box } from '@mui/material';

export const StyledContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    minHeight: `calc(100vh - 200px)`
  },
  [theme.breakpoints.up('tablet')]: {
    minHeight: `calc(100vh - 300px)`
  },
  [theme.breakpoints.up('desktop')]: {
    minHeight: `calc(100vh - 400px)`
  },
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
}));
