import {
  FormControlLabel,
  FormControlLabelProps,
  Typography,
  useRadioGroup,
  Radio
} from '@mui/material';

const RadioControlLabel = ({
  label,
  ...props
}: Omit<FormControlLabelProps, 'control'>) => {
  const radioGroup = useRadioGroup();
  const checked = Boolean(radioGroup && radioGroup.value === props.value);

  return (
    <FormControlLabel
      label={
        <Typography variant="body1" fontWeight={checked ? 600 : 400}>
          {label}
        </Typography>
      }
      control={<Radio />}
      {...props}
    />
  );
};

export default RadioControlLabel;
