import { Components, Theme } from '@mui/material';

import hasFeature from 'src/lib/hasFeature';

const componentConfiguration: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    lineVertical: {
      height: '100%',
      marginLeft: hasFeature('tauron') ? '12px' : 0
    }
  }
};

export default componentConfiguration;
