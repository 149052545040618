/* eslint-disable */
import { Buffer } from 'buffer';
import * as asn1js from 'asn1js';
import { sha256 } from 'js-sha256';

import generateIv from './generateIv';
import hexStringToUInt8Array from './hexToUInt8Array';
import importKey from './importKey';
import ObjectCaster from './objectCaster';
import decrypt from './decrypt';
import encrypt from './encrypt';

const switchOidByType = (type, OID) => {
  switch (type) {
    case 'p256':
      OID = '1.2.840.10045.3.1.7';
      break;
    case 'p384':
      OID = '1.3.132.0.34';
      break;
    case 'p521':
      OID = '1.3.132.0.35';
      break;
    case 'b256':
      OID = ' 1.3.36.3.3.2.8.1.1.7';
      break;
    case 'b320':
      OID = '1.3.36.3.3.2.8.1.1.9';
      break;
    case 'b384':
      OID = '1.3.36.3.3.2.8.1.1.11';
      break;
    case 'b512':
      OID = '1.3.36.3.3.2.8.1.1.13';
      break;
    default:
      OID = '1.2.840.10045.3.1.5';
      break;
  }
  return OID;
};

const asn1creators = {
  createKEKSequence: (key, iv) => {
    const KEK = {
      KEY: key,
      IV: iv,
      VER: 1
    };
    return new asn1js.Sequence({
      name: 'KEK',
      value: [
        new asn1js.Integer({
          name: 'VER',
          value: KEK.VER
        }),
        new asn1js.OctetString({
          name: 'IV',
          valueHex: KEK.IV
        }),
        new asn1js.OctetString({
          name: 'KEY',
          valueHex: KEK.KEY
        })
      ]
    });
  },
  createCOSTSequence: value => {
    return new asn1js.Integer({
      name: 'costParameter',
      value: value || 16384
    });
  },
  createBLOCK_SIZESequence: size => {
    return new asn1js.Integer({
      name: 'blockSize',
      value: size || 8
    });
  },
  createPARALLELSequence: value => {
    return new asn1js.Integer({
      name: 'parallelizationParameter',
      value: value || 1
    });
  },
  createSALTSequence: salt => {
    return new asn1js.OctetString({
      name: 'salt',
      valueHex: salt
        ? typeof salt === 'string'
          ? hexStringToUInt8Array(salt)
          : salt
        : crypto.getRandomValues(new Uint8Array(8))
    });
  },
  createSUBJECTPUBLICKEYSequence: (key, type) => {
    let OID = null;
    OID = switchOidByType(type, OID);
    return new asn1js.Sequence({
      name: 'publicKeyStructure',
      value: [
        new asn1js.Sequence({
          name: 'identifierSequence',
          value: [
            new asn1js.ObjectIdentifier({
              value: ' 1.2.840.10045.2.1'
            }),
            new asn1js.ObjectIdentifier({
              value: OID
            })
          ]
        }),
        new asn1js.BitString({
          name: 'publicKey',
          valueHex: key
        })
      ]
    });
  },
  createDATAWITHKEYIDSequence: data => {
    /*
      value<UIntArray> - structure of subjectPublicKeyInfo
      version<int> - version of key
      name<UIntArray> - name of the key
      counter<int> - increments +1 each time new key is added
      convertNameHexToUInt<bool> - convert name from data to UInt8Array
    */
    return new asn1js.Sequence({
      name: 'dataWithKeyIdSequence',
      value: [
        new asn1js.Integer({
          name: 'counter',
          value: data.counter
        }),
        new asn1js.Sequence({
          name: 'informationSequence',
          value: [
            new asn1js.OctetString({
              name: 'SHAfromTheName',
              valueHex: data.convertNameHexToUInt
                ? hexStringToUInt8Array(data.name)
                : data.name
            }),
            new asn1js.Integer({
              name: 'version',
              value: data.version
            })
          ]
        }),
        new asn1js.OctetString({
          name: 'publicKeyInfo',
          valueHex: data.value
        })
      ]
    });
  },
  createDATAWITHKEYIDSequenceECIESECDSARSA: data => {
    /*
      publicKeyInfo - structure of subjectPublicKeyInfo
      version - version of key
      name - name of the key
      counter - increments +1 each time new key is added
    */
    return new asn1js.Sequence({
      name: 'dataWithKeyIdSequence',
      value: [
        new asn1js.Sequence({
          name: 'Sequence',
          value: [
            new asn1js.OctetString({
              name: 'SHAfromTheName',
              valueHex: hexStringToUInt8Array(data.safebagSHA)
            }),
            new asn1js.Integer({
              name: 'version',
              value: data.version
            })
          ]
        }),
        new asn1js.OctetString({
          name: 'publicKeyInfo',
          valueHex: data.publicKeyInfo
        })
      ]
    });
  },

  encryptedPrivateKeyInfo: async data => {
    /* dataStructure:
     password<string> - password for scrypt
     salt<string> - salt for scrypt
     cost<number> - cost parameter for scrypt
     blockSize<number> - blocksize parameter for scrypt
     parallel<number> - parallel parameter for scrypt
     iv<string> - iv hex string
     convertToBER<boolean> - convert to UIntArray if it is Sequence
     nakedPrivateKeyStructure<string> - structure of private key in hexdecimal form
     oldPassword<string> - if set on true, it will treat nakedPrivateKeyStructure as encoded hex string and will try to decode it
    */
    /* its only for for generating purposes */

    let tempData = {
      keyAsUintArray: null,
      iv: null,
      scryptKey: null
    };
    if (data.oldPassword) {
      tempData.iv = Buffer.from(data.iv, 'hex');
      let scryptDataObj = {
        ...(data.scryptData ? data.scryptData : data),
        password: data.oldPassword
      };
      let oldScryptKey = ObjectCaster.generateScryptKey(scryptDataObj);
      const cryptoKey = await importKey(oldScryptKey, 'AES-CBC');
      tempData.keyAsUintArray = await decrypt(
        Buffer.from(data.encPrivKey, 'hex'),
        tempData.iv,
        cryptoKey,
        'AES-CBC'
      );
      tempData.scryptKey = ObjectCaster.generateScryptKey({
        ...scryptDataObj,
        password: data.password
      });
    } else {
      tempData.keyAsUintArray = data.convertToBER
        ? data.nakedPrivateKeyStructure.toBER()
        : data.nakedPrivateKeyStructure;
      tempData.iv = generateIv();
      tempData.scryptKey = ObjectCaster.generateScryptKey(data);
    }

    const enc = await encrypt(
      tempData.iv,
      tempData.scryptKey,
      tempData.keyAsUintArray,
      'AES-CBC'
    );

    return new asn1js.Sequence({
      name: 'encrypt',
      value: [
        asn1creators.createPKCS5Sequence(data.scryptData || data, tempData.iv),
        new asn1js.OctetString({
          name: 'encryptedPrivateKeyInfoStructure',
          valueHex: enc
        })
      ]
    });
  },
  createPKCS5Sequence: (data, IV) => {
    return new asn1js.Sequence({
      name: 'pkcs5PBES2',
      value: [
        new asn1js.ObjectIdentifier({
          value: '1.2.840.113549.1.5.13'
        }),
        new asn1js.Sequence({
          name: 'pkcs5pbes2data',
          value: [
            new asn1js.Sequence({
              name: 'params',
              value: [
                new asn1js.ObjectIdentifier({
                  value: '1.3.6.1.4.1.11591.4.11'
                }),
                new asn1js.Sequence({
                  name: 'scrypt params',
                  value: [
                    asn1creators.createSALTSequence(data.salt),
                    asn1creators.createCOSTSequence(data.cost),
                    asn1creators.createBLOCK_SIZESequence(data.blockSize),
                    asn1creators.createPARALLELSequence(data.parallel)
                  ]
                })
              ]
            }),
            new asn1js.Sequence({
              name: 'nakedKeys',
              value: [
                new asn1js.ObjectIdentifier({
                  value: '2.16.840.1.101.3.4.1.42'
                }),
                new asn1js.OctetString({
                  name: 'iv',
                  valueHex:
                    typeof IV === 'string' ? hexStringToUInt8Array(IV) : IV
                })
              ]
            })
          ]
        })
      ]
    });
  },
  createPKCS8Sequence: data => {
    /* data {
     * scryptData<object> - contains salt, parallel, blocksize, cost, password, oldPassword
     * iv<string> - contains IV as a hex string
     * encKey<string> - contains encKey as a hex string
     * }
     */
    /* creates PKCS8 Sequence only, for re-generate purposes only */
    return new asn1js.Sequence({
      name: 'encrypt',
      value: [
        asn1creators.createPKCS5Sequence(data.scryptData, data.iv),
        new asn1js.OctetString({
          name: 'encryptedPrivateKeyInfoStructure',
          valueHex:
            typeof data.encPrivKey === 'string'
              ? hexStringToUInt8Array(data.encPrivKey)
              : data.encPrivKey
        })
      ]
    });
  },
  createPRIVPASSSequence: (seqVersion, pass, passVersion, type) => {
    let OID = null;
    OID = switchOidByType(type, OID);
    const passSequence = new asn1js.Sequence({
      name: 'passwordOnlySequence',
      value: [
        new asn1js.Integer({
          value: passVersion
        }),
        new asn1js.OctetString({
          valueHex: pass,
          name: 'nakedPassword'
        })
      ]
    });

    return new asn1js.Sequence({
      name: 'privatePasswordSequence',
      value: [
        new asn1js.Integer({
          value: seqVersion
        }),
        new asn1js.Sequence({
          name: 'idSequence',
          value: [
            new asn1js.ObjectIdentifier({
              value: '1.2.840.10045.2.1'
            }),
            new asn1js.ObjectIdentifier({
              value: OID
            })
          ]
        }),
        new asn1js.OctetString({
          valueHex: passSequence.toBER()
        })
      ]
    });
  },
  createSAFEBAGSequence: async (data, pkcs8, reEncodeSafebags) => {
    /* data: {
     * name<string> - name of safebag in ascii
     * sha<string> - sha from the name, as a hex string
     * version<integer> - version of safebag
     * data<js DateObject>- creation date; as Javascript Date Object
     * }
     * pkcs8<seq or obj> - new generated sequence or object with properties from previously generated key(useful for connecting keys). For obj properties look at 'createPKCS8Sequence' above
     * reEncodeSafebags<boolean> - if true, it will re-encode each of safebags with new data
     * */

    const hash = sha256(data.name);
    return new asn1js.Sequence({
      name: 'safebagContainer',
      value: [
        new asn1js.ObjectIdentifier({
          name: 'pkcs-12-pkcs-8ShroudedKeyBag (PKCS#12 BagIds)',
          value: ' 1.2.840.113549.1.12.10.1.2'
        }),
        new asn1js.Constructed({
          value: [
            reEncodeSafebags
              ? pkcs8.oldPassword
                ? await asn1creators.encryptedPrivateKeyInfo(pkcs8)
                : asn1creators.createPKCS8Sequence(pkcs8)
              : pkcs8
          ],
          idBlock: {
            tagClass: 3,
            tagNumber: 0
          }
        }),
        new asn1js.Set({
          name: 'pkcs8',
          value: [
            new asn1js.Sequence({
              name: 'friendlyNameContainer',
              value: [
                new asn1js.ObjectIdentifier({
                  name: 'friendlyName (for PKCS #12) (PKCS #9 via PKCS #12)',
                  value: '1.2.840.113549.1.9.20'
                }),
                new asn1js.Set({
                  name: 'nameOfShroudedBag',
                  value: [
                    new asn1js.IA5String({
                      value: data.name
                    })
                  ]
                })
              ]
            }),
            new asn1js.Sequence({
              name: 'localKeyIdContainer',
              value: [
                new asn1js.ObjectIdentifier({
                  name: 'localKeyID (for PKCS #12) (PKCS #9 via PKCS #12)',
                  value: '1.2.840.113549.1.9.21'
                }),
                new asn1js.Set({
                  name: 'nameOfShroudedBag',
                  value: [
                    new asn1js.OctetString({
                      name: 'shaFromName',
                      valueHex: data.sha
                        ? typeof data.sha === 'string'
                          ? hexStringToUInt8Array(data.sha)
                          : data.sha
                        : hexStringToUInt8Array(hash.slice(0, 16))
                    })
                  ]
                })
              ]
            }),
            new asn1js.Sequence({
              name: 'sequenceNumberContainer',
              value: [
                new asn1js.ObjectIdentifier({
                  name: 'sequenceNumber (PKCS #9/RFC 2985 attribute)',
                  value: '1.2.840.113549.1.9.25.4'
                }),
                new asn1js.Set({
                  name: 'sequenceNumber',
                  value: [
                    new asn1js.Integer({
                      value: data.version
                    })
                  ]
                })
              ]
            }),
            new asn1js.Sequence({
              name: 'sequenceNumberContainer',
              value: [
                new asn1js.ObjectIdentifier({
                  name: 'date of creation',
                  value: '1.3.6.1.4.1.2787.100.1.1.9'
                }),
                new asn1js.Set({
                  name: 'sequenceNumber',
                  value: [
                    new asn1js.UTCTime({
                      valueDate: data.date || new Date()
                    })
                  ]
                })
              ]
            })
          ]
        })
      ]
    });
  },
  createSAFECONTENTSequence: sequences => {
    return new asn1js.Sequence({
      name: 'keySequence',
      value: [...sequences]
    });
  },
  createECIESECDSAPublicKeysDataWithKeyIdSequence: data => {
    return new asn1js.Sequence({
      value: [
        new asn1js.Sequence({
          value: [
            new asn1js.ObjectIdentifier({
              name: 'ecPublicKey (ANSI X9.62 public key type)',
              value: data.id
            }),
            new asn1js.ObjectIdentifier({
              name: 'number',
              value: data.number
            })
          ]
        }),
        new asn1js.BitString({
          name: 'bit string',
          valueHex: data.publicKey
        })
      ]
    });
  },
  createSignSequence: data => {
    return new asn1js.Sequence({
      value: [
        new asn1js.Sequence({
          value: [
            new asn1js.OctetString({
              valueHex: data.shaName,
              name: 'shaName'
            }),
            new asn1js.Integer({
              value: data.version
            })
          ]
        }),
        new asn1js.OctetString({
          name: 'sign',
          valueHex: data.sign
        })
      ]
    });
  },
  createPublicKeysDataWithIdSequence: data => {
    return new asn1js.Sequence({
      value: [
        data.ecies.brainpool256,
        data.ecies.brainpool320,
        data.ecies.brainpool384,
        data.ecies.brainpool512,
        data.rsa.rsa2k,
        data.ecdsa.brainpool256,
        data.ecdsa.brainpool320,
        data.ecdsa.brainpool384,
        data.ecdsa.brainpool512
      ]
    });
  },
  createUserPublicKeys: data => {
    return new asn1js.Sequence({
      value: [
        new asn1js.Sequence({
          value: [
            data.ecies.brainpool256,
            data.ecies.brainpool320,
            data.ecies.brainpool384,
            data.ecies.brainpool512,
            data.rsa.rsa2k,
            data.ecdsa.brainpool256,
            data.ecdsa.brainpool320,
            data.ecdsa.brainpool384,
            data.ecdsa.brainpool512
          ]
        }),
        data.signs.ECDSA.brainpool256,
        data.signs.ECDSA.brainpool320,
        data.signs.ECDSA.brainpool384,
        data.signs.ECDSA.brainpool512,
        data.signs.RSA
      ]
    });
  }
};

export default asn1creators;
