export const enum SignatureFormStepsEnum {
  INITIAL = 'initial',
  ACTION = 'action',
  LOADER = 'loader',
  RESULT = 'result'
}

export const enum ActionStatesEnum {
  NONE = 'none',
  REJECT = 'reject',
  ACCEPT = 'accept'
}

export enum SignatureProgressModesEnum {
  ACCEPTED = 1,
  REJECTED = 2
}
