import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  VerifyResendCodeResponse,
  VerifyResendCode
} from 'src/modules/UserIdentity/types/api';
import postVerifyResendCode from 'src/modules/UserIdentity/api/postVerifyResendCode';

const useResendSmsCode = (
  options: UseMutationOptions<
    VerifyResendCodeResponse,
    AxiosError,
    VerifyResendCode
  > = {}
) =>
  useMutation<VerifyResendCodeResponse, AxiosError, VerifyResendCode>(
    postVerifyResendCode,
    options
  );

export default useResendSmsCode;
