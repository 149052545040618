import moment from 'moment';

export const localeDateFormatter = (date: string) =>
  moment.utc(date).local().format('DD.MM.YYYY, HH:mm:ss');

export const localeDateFormatterWithUTC = (date: string) =>
  moment.utc(date).local().format('DD.MM.YYYY, HH:mm:ss UTC');

export const localeDayFormatter = (date: string) =>
  moment.utc(date).local().format('DD.MM.YYYY');
