import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      ...theme.typography.caption2,
      fontWeight: 600,
      backgroundColor: theme.palette.custom.darkMode[50],
      color: theme.palette.custom.darkMode[500],
      padding: theme.spacing(0.5, 1),
      maxWidth: '244px'
    })
  }
};

export default componentConfiguration;
