import { styled, Drawer, IconButton } from '@mui/material';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import PublicIcon from '@mui/icons-material/Public';

export const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: theme.spacing(32),
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  height: '100%',
  '& .MuiDrawer-paper': {
    overflowX: 'hidden',
    boxSizing: 'border-box',
    height: '100%',
    backgroundColor: theme.palette.grey[50],
    justifyContent: 'space-between',
    ...(!open && {
      overflowX: 'hidden',
      width: `calc(${theme.spacing(8)} + 1px)`
    })
  },
  ...(!open && {
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`
  }),
  [theme.breakpoints.only('mobile')]: {
    '& .MuiDrawer-paper': {
      width: '100%'
    }
  }
}));

export const VerificationIcon = styled(GppGoodOutlinedIcon)(({ theme }) => ({
  color: theme.palette.custom.neutral[700]
}));

export const AccessIcon = styled(PrivacyTipOutlinedIcon)(({ theme }) => ({
  color: theme.palette.custom.neutral[700]
}));

export const PublicDocumentIcon = styled(PublicIcon)(({ theme }) => ({
  color: theme.palette.custom.neutral[700]
}));

export const MobileHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(3, 2, 0, 2),
  justifyContent: 'space-between'
}));

export const StyledIconButton = styled(IconButton)(() => ({
  margin: 0,
  padding: 0
}));

export const StyledDrawerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4)
}));
