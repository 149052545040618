import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiBreadcrumbs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.down('tablet')]: {
        marginLeft: theme.spacing(2)
      },
      '.MuiBreadcrumbs-li': {
        '.MuiTypography-root': {
          ...theme.typography.body2,
          fontWeight: 400,
          color: theme.palette.custom.textColors[600],

          [theme.breakpoints.down('tablet')]: {
            ...theme.typography.caption2,
            fontWeight: 400
          }
        },
        '&:last-child .MuiTypography-root': {
          fontWeight: 600,
          color: theme.palette.custom.textColors[700],

          [theme.breakpoints.down('tablet')]: {
            fontWeight: 600
          }
        }
      }
    }),
    ol: {
      alignItems: 'baseline'
    },
    separator: ({ theme }) => ({
      ...theme.typography.body1,
      color: theme.palette.custom.textColors[500],
      fontWeight: 400,
      width: '7px',
      height: '24px'
    })
  }
};

export default componentConfiguration;
