/* eslint-disable */
export default async () =>
  crypto.subtle
    .generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: { name: 'SHA-256' }
      },
      true,
      ['encrypt', 'decrypt']
    )
    .then(key => key)
    .catch(err => {
      console.error(err);
    });
