import AppConfiguration from 'src/types/appConfiguration';
import { appConfig, AppName } from 'src/constants/appConfig';

if (!appConfig) {
  throw new Error(
    `'REACT_APP_CONFIG' configured properly use one of ${Object.values(
      AppName
    )}`
  );
}

const hasFeature = (flag: keyof AppConfiguration) => appConfig[flag];

export default hasFeature;
