import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSessionStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import { CREDENTIALS_SESSION_KEY } from 'src/modules/UserIdentity/constants/session';
import postFullIdentityUserUuid from 'src/modules/UserIdentity/api/postFullIdentityUserUuid';
import { generatePasswordEmailHash } from 'src/modules/Crypto/lib/ciphers/scryptWithASCII';
import postFullIdentityLoadUserKeys from 'src//modules/UserIdentity/api/postFullIdentityLoadUserKeys';
import getMaintenanceSelfTest from 'src/modules/UserIdentity/api/getMaintenanceSelfTest';
import getMaintenanceSettings from 'src/modules/UserIdentity/api/getMaintenanceSettings';
import { UserCredentials } from 'src/modules/UserIdentity/types/session';
import {
  FullIdentityLoadUserKeys,
  FullIdentityLoadUserKeysResponse,
  ServicePayloadResponse,
  UserUuid
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';
import useQueryParam from 'src/hooks/userQueryParam';
import routes from 'src/constants/routes';
import {
  LOGIN_TWO_FACTOR_AUTHORIZATION,
  MORE_THAN_ONE_FULL_IDENTITY_FOUND
} from 'src/modules/DocumentsPrivate/constants/errors';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { postFullIdentityTwoFactorAuthorizationSend } from 'src/modules/UserIdentity/api/postAgreementSendConfirmationCode';

type UseLoginUserProps = {
  email: string;
  password: string;
  documentNumber?: string;
  smsCode?: string;
};

type UseLoginUserError = AxiosError | ApiError;

const useLoginUser = (
  options: UseMutationOptions<
    { response: FullIdentityLoadUserKeysResponse } & {
      uuid: UserUuid;
      kycGwId: string;
    },
    UseLoginUserError,
    UseLoginUserProps
  > = {}
) => {
  const blockchainAddress = useQueryParam('blockchainAddress');
  const processId = useQueryParam('processId');
  const navigate = useNavigate();
  const { saveKeys } = useContext(UserIdentityContext);
  const [_, setSessionCredentials] = useSessionStorage<UserCredentials | null>(
    CREDENTIALS_SESSION_KEY,
    null
  );

  return useMutation<
    { response: FullIdentityLoadUserKeysResponse } & {
      uuid: UserUuid;
      kycGwId: string;
      firstName: string;
      lastName: string;
      email: string;
      hashFromHashFromPassword: string;
    },
    UseLoginUserError,
    UseLoginUserProps
  >(
    async ({ email, password, documentNumber, smsCode }) => {
      const has2faFlowCheck = smsCode && processId;

      const { uuid, firstName, lastName } = await postFullIdentityUserUuid({
        idValue: email,
        idType: 'EMAIL_ADDRESS',
        documentNumber
      });
      const {
        data: { parameters }
      } = await getMaintenanceSettings();

      if (
        !has2faFlowCheck &&
        parameters.LOGIN_TWO_FACTOR_AUTHORIZATION === 'true'
      ) {
        const { processId } = await postFullIdentityTwoFactorAuthorizationSend({
          uuid
        });

        throw new AxiosError(processId, LOGIN_TWO_FACTOR_AUTHORIZATION);
      }

      const hashFromHashFromPassword = generatePasswordEmailHash(
        email,
        password
      ) as unknown as string;
      const postFullIdentityLoadKeys: FullIdentityLoadUserKeys = {
        hashFromHashFromPassword,
        uuid,
        referenceId: Date.now().toString()
      };

      if (has2faFlowCheck) {
        postFullIdentityLoadKeys.twoFactorAuthorization = {
          processId,
          code: smsCode
        };
      }

      const response = await postFullIdentityLoadUserKeys(
        postFullIdentityLoadKeys
      );

      const {
        data: { service }
      } = await getMaintenanceSelfTest();
      const kycGwId = (
        service.payload.find(
          s => s.name === 'KYCIdentityManagementService'
        ) as ServicePayloadResponse
      )?.id;

      if (!kycGwId) {
        throw new Error('KYC_GW_ERROR');
      }

      setSessionCredentials({
        firstName,
        lastName,
        uuid,
        hashFromHashFromPassword,
        email,
        response
      });
      return {
        hashFromHashFromPassword,
        kycGwId,
        uuid,
        response,
        firstName,
        lastName,
        email
      };
    },
    {
      onSuccess: data => {
        saveKeys({
          uuid: data.uuid,
          kycGwId: data.kycGwId,
          keys: data.response,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          hashFromHashFromPassword: data.hashFromHashFromPassword
        });
        const address = blockchainAddress
          ? routes.documentBlockchainAddress.replace(
              ':address',
              blockchainAddress
            )
          : routes.documentAccessUserIndex;
        navigate(address.replace(':uuid', data.uuid));
      },
      onError: error => {
        const apiError = error as ApiError;

        if (apiError?.code) {
          const apiError = error as ApiError;
          if (apiError?.code === MORE_THAN_ONE_FULL_IDENTITY_FOUND) {
            navigate(
              blockchainAddress
                ? `${routes.documentAccessTypeLoginSelectDocument}?blockchainAddress=${blockchainAddress}`
                : routes.documentAccessTypeLoginSelectDocument
            );
          } else if (apiError?.code === LOGIN_TWO_FACTOR_AUTHORIZATION) {
            const queryUrl = blockchainAddress
              ? `?blockchainAddress=${blockchainAddress}&processId=${apiError?.message}`
              : `?processId=${apiError?.message}`;
            navigate(`${routes.documentAccessType2Sms}${queryUrl}`);
          }
        }
      },
      ...options
    }
  );
};

export default useLoginUser;
