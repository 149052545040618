import { Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMatches, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import routes from 'src/constants/routes';
import SelectLanguage from 'src/components/selectLanguage';
import UserMenu from 'src/components/userMenu';

import { StyledActionsMenu } from './styles';

const loginFlowRoutes = [
  routes.documentAccessTypeLoginSelectDocument,
  routes.documentAccessTypeLogin,
  routes.documentAccessType2Sms
];
const registerFlowRoutes = [
  routes.documentAccessTypeRegister,
  routes.documentAccessTypeVerify,
  routes.documentAccessTypePassword,
  routes.documentAccessTypeSuccess,
  routes.documentAccessTypeFailure
];

const AppBarActions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const userAdditionActions = useMediaQuery(theme.breakpoints.up('tablet'));

  const matches = useMatches();
  const isLoginFlow = matches.some(path =>
    loginFlowRoutes.includes(path.pathname)
  );
  const isRegisterFlow = matches.some(path =>
    registerFlowRoutes.includes(path.pathname)
  );

  return (
    <StyledActionsMenu>
      {userAdditionActions && isLoginFlow && (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => navigate(routes.documentAccessTypeRegister)}
        >
          {t('LOG_IN_CREATE_ACCOUNT')}
        </Button>
      )}
      {userAdditionActions && isRegisterFlow && (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => navigate(routes.documentAccessTypeLogin)}
        >
          {t('LOG_IN_LOG_IN_BUTTON')}
        </Button>
      )}
      {!isLoginFlow && <UserMenu />}
      <SelectLanguage />
    </StyledActionsMenu>
  );
};

export default AppBarActions;
