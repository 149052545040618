import decrypt from './decrypt';
import importKey from './importKey';
import stringToByteArray from './stringToByteArray';

const decryptDocumentContent = async (convertCEK, encryptedSensitiveData) => {
  const key = await importKey(convertCEK.key);

  return decrypt(encryptedSensitiveData, stringToByteArray(convertCEK.IV), key);
};

export default decryptDocumentContent;
