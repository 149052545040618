/* eslint-disable */

import { Buffer } from 'buffer';
import ASN1Decoder from './ASN1Decoder';
import base64ToHex from './base64ToHex';
import bufferToHex from './bufferToHex';
import decryptSafeContent from './decryptSafeContent';
import encrypt from './encrypt';
import hexToUInt8Array from './hexToUInt8Array';
import ObjectCaster from './objectCaster';
import { decodeEcies } from './ciphers/ecies-customized/ecies';
import hasFeature from 'src/lib/hasFeature';

export default async (challenge, privateKeys, kek, password) => {
  if (hasFeature('mockEndpoints')) {
    return '';
  }

  console.log('===== RESOLVING CHALLENGE =====');
  let normalized = ASN1Decoder.decodeHexString(bufferToHex(kek));
  const stateObject = {
    sfKek: {
      IV: normalized.SEQUENCE_0.OCTET_STRING_0,
      key: normalized.SEQUENCE_0.OCTET_STRING_1
    }
  };

  console.log('------ 1. DECODING SAFECONTENT ------');
  let decrypted = await decryptSafeContent(
    privateKeys,
    Buffer.from(stateObject.sfKek.IV, 'hex'),
    Buffer.from(stateObject.sfKek.key, 'hex')
  );

  normalized = ASN1Decoder.decodeHexString(decrypted.decryptedHex);

  const challengeNormalized = ASN1Decoder.decodeHexString(
    base64ToHex(challenge)
  );

  const result = [];
  const filteredSafebags = [];

  console.log('------ 2. RESOLVING CHALLENGE ------');
  for (let i = 0; i < Object.keys(challengeNormalized.SEQUENCE_0).length; i++) {
    const chEl = challengeNormalized.SEQUENCE_0[`SEQUENCE_${i}`];
    const foundSb = ObjectCaster.getSequenceByKeyName(normalized, {
      shortcutName: chEl.SEQUENCE_0.OCTET_STRING_0,
      keyVersion: chEl.SEQUENCE_0.INTEGER_0
    });

    filteredSafebags.push(
      (await ObjectCaster.getFormattedDataFromSequence(foundSb, password)) || {}
    );
    const safebagNormalized = ASN1Decoder.decodeHexString(
      filteredSafebags[i].decPrivKeyHex
    );
    const privateKey = ASN1Decoder.decodeHexString(
      safebagNormalized.SEQUENCE_0.OCTET_STRING_0
    );
    /* decode challenge */
    const eciesDecoderData = {
      cipherHex: chEl.OCTET_STRING_0,
      privateKey: privateKey.SEQUENCE_0.OCTET_STRING_0,
      curveType: filteredSafebags[i].curvatureType.curvature
    };
    const res = await decodeEcies(eciesDecoderData);

    result.push(res || 'invalid_mac');
  }

  const iv = hexToUInt8Array(result[0]);
  const key = hexToUInt8Array(result[1]);
  const authData = hexToUInt8Array(result[2]);
  const encrypted = hexToUInt8Array(result[3]);
  const tagLength = 128;

  console.log('------ 3. CHALLENGE RESOLVED ------');
  return encrypt(iv, key, encrypted, 'AES-GCM', false, {
    additionalData: authData,
    tagLength
  });
};
