/* eslint-disable */

import asn1creators from './asn1creators';
import ASN1Decoder from './ASN1Decoder';
import base64ToHex from './base64ToHex';
import bufferToHex from './bufferToHex';
import { decodeEcies } from './ciphers/ecies-customized/ecies';
import decryptSafeContent from './decryptSafeContent';
import encrypt from './encrypt';
import ObjectCaster from './objectCaster';
import hexToUInt8Array from './hexToUInt8Array';

const getFormattedAndFilteredDataFromSafeContent = async (
  safeContent,
  password,
  password25
) => {
  /* safeContent<array> = contains safecontents to be combined and filtered(from duplicates) */
  const filteredSafebags = [];
  const safebagSequences = [];
  const oldPasswordArr = [password, hexToUInt8Array(password25)];

  for (const [index, sf] of safeContent.entries()) {
    for (let i = 0; i < Object.keys(sf).length; i++) {
      const currSeq = sf[`SEQUENCE_${i}`];
      const formattedSeq = await ObjectCaster.getFormattedDataFromSequence(
        currSeq
      );
      if (
        filteredSafebags.find(
          x =>
            x.sequenceVersion === formattedSeq.sequenceVersion &&
            x.safebagSHA === formattedSeq.safebagSHA
        ) === undefined
      ) {
        const safebagData = {
          name: formattedSeq.safabagName,
          sha: formattedSeq.safebagSHA,
          version: formattedSeq.sequenceVersion,
          date: formattedSeq.creationDate
        };
        // TODO - refactor combiner (changes from the daily call - 24.04.20 -> replace dec_safe_content with enc_safe_content + kek)
        const pkcs8Data = {
          scryptData: {
            ...formattedSeq.scryptData,
            scryptPassword: oldPasswordArr[index]
          },
          password,
          oldPassword: oldPasswordArr[index],
          iv: formattedSeq.iv,
          encPrivKey: formattedSeq.encPrivKey
        };

        filteredSafebags.push(formattedSeq);

        safebagSequences.push(
          await asn1creators.createSAFEBAGSequence(safebagData, pkcs8Data, true)
        );
      }
    }
  }

  return safebagSequences;
};

export default async (
  encryptedKek,
  encryptedPrivateKeys,
  encryptedPassword,
  privateKeys,
  kek,
  password
) => {
  console.log('===== MERGE KEYS =====');
  const encryptedKekHex = base64ToHex(encryptedKek);
  const encryptedKekNormalized = ASN1Decoder.decodeHexString(encryptedKekHex);
  const kekNormalized = ASN1Decoder.decodeHexString(bufferToHex(kek));
  const kekIV = kekNormalized.SEQUENCE_0.OCTET_STRING_0;
  const kekKey = kekNormalized.SEQUENCE_0.OCTET_STRING_1;

  const decryptedPrivateKeys275 = await decryptSafeContent(
    privateKeys,
    hexToUInt8Array(kekIV),
    hexToUInt8Array(kekKey)
  );
  const decryptedPrivateKeys275Normalized = ASN1Decoder.decodeHexString(
    decryptedPrivateKeys275.decryptedHex
  );
  console.log('------ 1. DECODING KEK 2.5 ------');
  let chEl = encryptedKekNormalized.SEQUENCE_0.SEQUENCE_0;
  let foundSb = ObjectCaster.getSequenceByKeyName(
    decryptedPrivateKeys275Normalized,
    {
      shortcutName: chEl.OCTET_STRING_0,
      keyVersion: chEl.INTEGER_0
    }
  );
  let filteredSafebag =
    (await ObjectCaster.getFormattedDataFromSequence(foundSb, password)) || {};

  let safeBagNormalized = ASN1Decoder.decodeHexString(
    filteredSafebag.decPrivKeyHex
  );
  let privateKey = ASN1Decoder.decodeHexString(
    safeBagNormalized.SEQUENCE_0.OCTET_STRING_0
  );

  /* decode challenge */
  let eciesDecoderData = {
    cipherHex: encryptedKekNormalized.SEQUENCE_0.OCTET_STRING_0,
    privateKey: privateKey.SEQUENCE_0.OCTET_STRING_0,
    curveType: filteredSafebag.curvatureType.curvature
  };

  const result = await decodeEcies(eciesDecoderData);

  const decryptedKekNormalized = ASN1Decoder.decodeHexString(result);
  const kekIV2 = decryptedKekNormalized.SEQUENCE_0.OCTET_STRING_0;
  const kekKey2 = decryptedKekNormalized.SEQUENCE_0.OCTET_STRING_1;

  console.log('------ 2. DECODING PRIVATE KEYS 2.5 ------');
  const decryptedPrivateKeys25 = await decryptSafeContent(
    hexToUInt8Array(base64ToHex(encryptedPrivateKeys)),
    hexToUInt8Array(kekIV2),
    hexToUInt8Array(kekKey2)
  );
  const decryptedPrivateKeys25Normalized = ASN1Decoder.decodeHexString(
    decryptedPrivateKeys25.decryptedHex
  );

  console.log('------ 3. DECODING PASSWORD 2.5 ------');
  const encryptedPasswordHex = base64ToHex(encryptedPassword);
  const encryptedPasswordNormalized =
    ASN1Decoder.decodeHexString(encryptedPasswordHex);

  chEl = encryptedPasswordNormalized.SEQUENCE_0.SEQUENCE_0;
  foundSb = ObjectCaster.getSequenceByKeyName(
    decryptedPrivateKeys275Normalized,
    {
      shortcutName: chEl.OCTET_STRING_0,
      keyVersion: chEl.INTEGER_0
    }
  );
  filteredSafebag =
    (await ObjectCaster.getFormattedDataFromSequence(foundSb, password)) || {};

  safeBagNormalized = ASN1Decoder.decodeHexString(
    filteredSafebag.decPrivKeyHex
  );
  privateKey = ASN1Decoder.decodeHexString(
    safeBagNormalized.SEQUENCE_0.OCTET_STRING_0
  );

  /* decode challenge */
  eciesDecoderData = {
    cipherHex: encryptedPasswordNormalized.SEQUENCE_0.OCTET_STRING_0,
    privateKey: privateKey.SEQUENCE_0.OCTET_STRING_0,
    curveType: filteredSafebag.curvatureType.curvature
  };
  const decryptedPassword = await decodeEcies(eciesDecoderData);

  console.log('------ 4. MERGING KEYS ------');
  const filteredSequences = await getFormattedAndFilteredDataFromSafeContent(
    [
      decryptedPrivateKeys275Normalized.SEQUENCE_0,
      decryptedPrivateKeys25Normalized.SEQUENCE_0
    ],
    password,
    decryptedPassword
  );

  const keySequence = asn1creators.createSAFECONTENTSequence([
    ...filteredSequences
  ]);

  console.log(keySequence, filteredSequences);

  const keySequenceAsArrayBuffer = keySequence.toBER(false);

  console.log('------ 5. ENCRYPTING MERGED KEYS ------');
  const encryptedMergedKeys = await encrypt(
    hexToUInt8Array(kekIV),
    hexToUInt8Array(kekKey),
    keySequenceAsArrayBuffer
  );

  console.log('------ 6. KEYS MERGED ------');

  return encryptedMergedKeys;
};
