import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';

import { SortModel } from 'src/modules/DocumentsPrivate/types';

import { PublicDocumentListForm } from './filtersCard/form';

export const parseNumber = (
  searchParams: URLSearchParams,
  name: string,
  defaultValue: number
): number => {
  const searchParamsValue = searchParams.get(name);
  const searchParamMaybeNumber = Number(searchParamsValue);

  return !searchParamsValue || isNaN(searchParamMaybeNumber)
    ? defaultValue
    : searchParamMaybeNumber;
};

export const mapSearchParamsToDefaultFormParams = (
  searchParams: URLSearchParams
): {
  form: Partial<PublicDocumentListForm>;
  pagination: GridPaginationModel;
  sort: SortModel;
} => ({
  sort: {
    order: (searchParams.get('order') as SortModel['order']) || 'desc',
    orderBy: searchParams.get('orderBy') || 'publicationDate'
  },
  pagination: {
    page: parseNumber(searchParams, 'page', 0),
    pageSize: parseNumber(searchParams, 'limit', 5)
  },
  form: {
    publisherName: searchParams.get('publisherName') || '',
    publicationDateFrom:
      searchParams.get('publicationDateFrom') ||
      PUBLICATION_DATE_FROM_ZERO_VALUE,
    publicationDateTo: searchParams.get('publicationDateTo') || '',
    title: searchParams.get('title') || '',
    category: searchParams.get('category') || '',
    blockchainAddress: searchParams.get('blockchainAddress') || '',
    validSince: searchParams.get('validSince') || '',
    validUntil: searchParams.get('validUntil') || ''
  }
});

export const PUBLICATION_DATE_FROM_ZERO_VALUE = '2001-01-01';
