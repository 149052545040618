import { ReactComponent as UploadIcon } from 'src/assets/fileUpload.svg';
import { FileUploadIconProps } from 'src/components/fileUpload/types';

import StyledIcon from './styles';

const FileUploadIcon = ({ isError }: FileUploadIconProps) => (
  <StyledIcon isError={isError}>
    <UploadIcon />
  </StyledIcon>
);

export default FileUploadIcon;
