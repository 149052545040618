import AppConfiguration from 'src/types/appConfiguration';

import palette from './palette';

export default {
  siteTitle: 'SITE_TITLE_TAURON_POLISH_ENERGY',
  documentPrivate: true,
  documentPublic: false,
  documentVerification: false,
  documentRemoteSigning: true,
  userLogin: true,
  userRegistration: true,
  userResetPassword: false,
  mockEndpoints: false,
  diploma: false,
  documentPrivateCategoryFilter: false,
  documentPrivateCategories: true,
  envPrefix: 'DEV',
  tauron: true,
  brandURL: 'https://tauron.pl/',
  brandContactURL:
    'https://www.tauron.pl/dla-domu/kontakt/formularz-blockchain',
  fontFamily: '"Titillium Web",Arial,sans-serif',
  copyright: 'Copyright ® 2023 TAURON Polska Energia',
  blockchainDocumentPasswordLength: 8,
  palette,
  documentNumberAlgorithm: {
    PESEL: {
      fullLength: 11,
      codeLength: 5,
      randomIndices: true
    },
    NIP: {
      codeLength: 10,
      dashesCount: 3
    },
    POSTAL_CODE: {
      codeLength: 6,
      dashesCount: 1
    }
  },
  initLanguage: 'pl'
} as AppConfiguration;
