import { styled } from '@mui/material';

const StepperContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(5, 14, 2, 0)
  },
  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(7)
  },
  [theme.breakpoints.down('tablet')]: {
    gap: 0
  }
}));

export default StepperContent;
