import { AxiosError } from 'axios';

import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  VerifyFullIdentityResponse,
  VerifyFullIdentity
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';
import ValidationError from 'src/lib/validationError';
import { InvalidInput } from 'src/types/validation';

const putVerifyFullIdentity = async (
  request: VerifyFullIdentity
): Promise<VerifyFullIdentityResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  try {
    const { data: response } = await apiClient.put<VerifyFullIdentityResponse>(
      apiRoutes.verifyFullIdentity,
      request
    );

    return response;
  } catch (err) {
    const error = err as AxiosError<{
      statusCode: string;
      errorInfo: InvalidInput;
    }>;
    if (!error.response?.data) {
      throw error;
    }
    const {
      response: { data }
    } = error;

    if (data.statusCode === 'VALIDATION_ERROR') {
      throw new ValidationError(data.statusCode, data.errorInfo);
    }
    if (data.statusCode) {
      throw new ApiError(data.statusCode, data.statusCode);
    }

    throw error;
  }
};

export default putVerifyFullIdentity;
