import { Components, Theme } from '@mui/material';

import hasFeature from 'src/lib/hasFeature';

const componentConfiguration: Components<Theme>['MuiStepIcon'] = {
  styleOverrides: {
    root: ({ theme }) =>
      hasFeature('tauron')
        ? {
            width: '50px',
            height: '50px',
            color: 'transparent',
            '&.Mui-active': {
              color: theme.palette.custom.neutral[400],

              border: `1px solid ${theme.palette.primary.main}`,
              '& text': {
                fill: theme.palette.primary.main
              }
            },
            borderRadius: '50%',
            border: `1px solid grey`
          }
        : {
            width: '24px',
            height: '24px',
            color: theme.palette.custom.neutral[400],
            '&.Mui-active': {
              color: theme.palette.custom.primary[700]
            }
          },
    text: () =>
      hasFeature('tauron') && {
        fill: 'grey',
        fontWeight: 700
      }
  }
};

export default componentConfiguration;
