import { Card as MuiCard } from '@mui/material';
import { PropsWithChildren } from 'react';

import CardProps from './types';
import PrimaryCardContent from './components/primaryCardContent';
import SecondaryCardContent from './components/secondaryCardContent';

const Card = ({
  title,
  children,
  buttonText,
  onButtonClick,
  ...muiCardProps
}: PropsWithChildren<CardProps>) => (
  <MuiCard {...muiCardProps}>
    {muiCardProps.variant === 'secondary' ? (
      <SecondaryCardContent
        title={title}
        onButtonClick={onButtonClick}
        buttonText={buttonText}
      >
        {children}
      </SecondaryCardContent>
    ) : (
      <PrimaryCardContent
        title={title}
        onButtonClick={onButtonClick}
        buttonText={buttonText}
      >
        {children}
      </PrimaryCardContent>
    )}
  </MuiCard>
);

export default Card;
