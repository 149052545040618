import { styled } from '@mui/material';

const Container = styled('div')`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default Container;
