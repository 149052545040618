import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  AgreementConfirmationCodeRequest,
  AgreementConfirmationCodeResponse,
  AgreementConfirmationErrorType
} from 'src/modules/UserIdentity/types/api';
import postAgreementConfirmSmsCode from 'src/modules/UserIdentity/api/postAgreementConfirmSmsCode';

const useAgreementConfirmSmsCode = (
  options: UseMutationOptions<
    AgreementConfirmationCodeResponse,
    AxiosError<AgreementConfirmationErrorType>,
    AgreementConfirmationCodeRequest
  >
) =>
  useMutation<
    AgreementConfirmationCodeResponse,
    AxiosError<AgreementConfirmationErrorType>,
    AgreementConfirmationCodeRequest
  >(postAgreementConfirmSmsCode, options);

export default useAgreementConfirmSmsCode;
