import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      alignItems: 'flex-start',
      [theme.breakpoints.down('tablet')]: {
        '& .MuiTypography-root': {
          fontSize: theme.typography.body2.fontSize
        }
      }
    })
  }
};

export default componentConfiguration;
