import { FormControl, RadioGroup } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useContext } from 'react';

import RadioControlLabel from 'src/components/radioControlLabel';
import { FileInput, FileUploadStatus } from 'src/components/fileUpload';
import { getErrorText } from 'src/components/form/utils';
import useDocumentSettings from 'src/modules/DocumentsPrivate/hooks/useDocumentSettings';
import { VerifyDocumentEnum } from 'src/modules/DocumentsPrivate/constants/document';
import { VerifyDocumentForm } from 'src/modules/DocumentsPrivate/constants/form';
import StepTextFieldWithLabel from 'src/components/stepper/components/stepperTextFieldWithLabel';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { FileUploadStatuses } from 'src/components/fileUpload/constants';

import StyledContent from './styles';

const blockchainAddressFieldName = 'blockchainAddress';

type Props = {
  uploadProgress: number;
};
const SelectDocumentStep = ({ uploadProgress }: Props) => {
  const { t } = useTranslation();
  const { data } = useDocumentSettings();
  const { getValues, watch, formState, setValue, reset } =
    useFormContext<VerifyDocumentForm>();
  const { state } = useContext(PrivateDocumentContext);
  const fileError = getErrorText(formState.errors, 'file');

  watch(['verifyType', 'file']);

  if (!data) {
    return null;
  }

  const { verifyType, file } = getValues();

  return (
    <StyledContent>
      <FormControl>
        <Controller
          name="verifyType"
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              value={value}
              onChange={event => {
                reset();
                setTimeout(() => {
                  onChange(event);
                });
              }}
            >
              <RadioControlLabel
                disabled={Boolean(uploadProgress)}
                value={VerifyDocumentEnum.FILE}
                label={t(
                  'DOCUMENT_VERIFICATION_SIDEBAR_VERIFY_DOCUMENT_UPLOAD_FILE'
                )}
                name="verifyType"
              />
              <RadioControlLabel
                disabled={Boolean(uploadProgress)}
                value={VerifyDocumentEnum.BLOCKCHAIN_ADDRESS}
                label={t(
                  'DOCUMENT_VERIFICATION_SIDEBAR_VERIFY_DOCUMENT_BLOCKCHAIN_ADDRESS'
                )}
                name="verifyType"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      {verifyType === VerifyDocumentEnum.FILE && (
        <>
          {!file && (
            <FileInput
              errorMessage={t(fileError?.message as string) || undefined}
              onChange={file => {
                setValue('file', file);
              }}
              name="file"
              label={
                <Trans t={t}>
                  DOCUMENT_VERIFICATION_SIDEBAR_VERIFY_DOCUMENT_UPLOAD_FILE_INPUT
                </Trans>
              }
              fileTypes={['pdf']}
              maxSize={data.maxFileSize}
            />
          )}
          {file && (
            <FileUploadStatus
              loadingProgress={uploadProgress}
              status={
                uploadProgress
                  ? uploadProgress === 100
                    ? FileUploadStatuses.COMPLETE
                    : FileUploadStatuses.LOADING
                  : FileUploadStatuses.READY
              }
              file={file}
              onDelete={() => Promise.resolve(setValue('file', null))}
            />
          )}
        </>
      )}
      {verifyType === VerifyDocumentEnum.BLOCKCHAIN_ADDRESS && (
        <StepTextFieldWithLabel
          isDisabled={state.isLoading}
          fieldName={blockchainAddressFieldName}
          inputLabelKey="PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_BLOCKCHAIN_ADDRESS"
        />
      )}
    </StyledContent>
  );
};

export default SelectDocumentStep;
