import { styled } from '@mui/material';

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    gap: theme.spacing(6)
  },
  [theme.breakpoints.down('tablet')]: {
    gap: theme.spacing(4)
  }
}));

export default StyledContent;
