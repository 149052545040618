import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import 'dayjs/locale/pl';

import AuthorizedPage from 'src/components/authorizedPage/AuthorizedPage';
import DocumentListContent from 'src/modules/DocumentsPrivate/components/documentList/content';

import {
  StyledDocumentsListContent,
  StyledDocumentsListContainer
} from './styles';

const BlockchainAddressList = () => (
  <AuthorizedPage>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
      <StyledDocumentsListContainer>
        <StyledDocumentsListContent>
          <DocumentListContent />
        </StyledDocumentsListContent>
      </StyledDocumentsListContainer>
    </LocalizationProvider>
  </AuthorizedPage>
);

export default BlockchainAddressList;
