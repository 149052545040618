import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  StyledButtonsWrapper,
  StyledDescriptionList,
  StyledDocumentButton,
  StyledLinearProgress,
  StyledLinearProgressWrapper,
  StyledRefresh,
  StyledRejectIcon,
  StyledSignIcon,
  StyledSignatureProgressWrapper,
  StyledHeaderTypography
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/components/SignatureInProgress/styles';
import { SignatureProgressModesEnum } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/constants';
import useDocumentCatalog from 'src/modules/DocumentsPrivate/hooks/useDocumentCatalog';
import useDocumentHistoryContext from 'src/modules/DocumentsPrivate/hooks/useDocumentHistoryContext';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';
import useNavigateToDocument from 'src/modules/DocumentsPrivate/hooks/useNavigateToDocument';

type PropsType = {
  mode: SignatureProgressModesEnum;
  blockchainAddress: string;
  standalone?: boolean;
};

const SignatureProgressStatus = ({ mode, blockchainAddress }: PropsType) => {
  const [signedDocumentAddress, setSignedDocumentAddress] = useState<
    string | null
  >(null);
  const [isRefreshButtonClicked, setIsRefreshButtonClicked] =
    useState<boolean>(false);
  const [loaderDescriptionIndex, setLoaderDescriptionIndex] = useState(0);
  const navigateToDocument = useNavigateToDocument();
  const { t } = useTranslation();
  const { documentHistory, refetchDocumentHistory } =
    useDocumentHistoryContext();

  const { data: documentCatalog, refetch: refetchCatalog } = useDocumentCatalog(
    blockchainAddress as string,
    {
      refetchInterval: 15000
    }
  );

  const loaderDescriptions = t(
    'PRIVATE_DOCUMENTS_DETAILS_SIGNATURE_PROGRESS_LOADER_DESCRIPTION'
  ).split(' ');

  useEffect(() => {
    if (
      documentCatalog?.signingStatus ===
        AgreementStatusEnum.ACCEPTED_DEPRECATED ||
      documentCatalog?.signingStatus === AgreementStatusEnum.REJECTED_DEPRECATED
    ) {
      refetchDocumentHistory();
    }
  }, [documentCatalog, refetchDocumentHistory]);

  useEffect(() => {
    const lastElement = documentHistory[documentHistory.length - 1];
    if (
      lastElement?.signingStatus === AgreementStatusEnum.ACCEPTED ||
      lastElement?.signingStatus === AgreementStatusEnum.REJECTED
    ) {
      setSignedDocumentAddress(lastElement.blockchainAddress);
    }
  }, [documentHistory]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoaderDescriptionIndex(
        prevIndex => (prevIndex + 1) % loaderDescriptions.length
      );
    }, 2500);

    return () => {
      clearInterval(intervalId);
    };
  }, [loaderDescriptions.length]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isRefreshButtonClicked) {
      timeout = setTimeout(() => setIsRefreshButtonClicked(false), 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isRefreshButtonClicked]);

  const inProgressTitle =
    mode === SignatureProgressModesEnum.REJECTED
      ? 'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECTING_SUCCESSFULLY_REJECTED'
      : 'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_SUCCESSFULLY SIGNED';
  const successTitle =
    mode === SignatureProgressModesEnum.REJECTED
      ? 'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_REJECTED_AND_PUBLISHED'
      : 'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_SIGNED_AND_PUBLISHED';

  const inProgressBody =
    mode === SignatureProgressModesEnum.REJECTED
      ? 'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_REJECTED_BODY'
      : 'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_SIGNED_BODY';

  const headlineText = signedDocumentAddress ? successTitle : inProgressTitle;
  return (
    <>
      <StyledSignatureProgressWrapper>
        {mode === SignatureProgressModesEnum.REJECTED ? (
          <StyledRejectIcon />
        ) : (
          <StyledSignIcon />
        )}
        <StyledHeaderTypography variant="h5">
          <Trans>{headlineText}</Trans>
        </StyledHeaderTypography>
        {!signedDocumentAddress && (
          <>
            <Typography variant="subtitle2">
              <StyledDescriptionList>
                <Trans components={[<li></li>]}>{inProgressBody}</Trans>
              </StyledDescriptionList>
            </Typography>
            <StyledLinearProgressWrapper>
              <Typography noWrap variant="subtitle2" fontWeight={600}>
                {loaderDescriptions[loaderDescriptionIndex]}
              </Typography>
              <StyledLinearProgress />
            </StyledLinearProgressWrapper>
          </>
        )}
        <StyledButtonsWrapper isRefreshButton={!signedDocumentAddress}>
          {!signedDocumentAddress && (
            <Button
              startIcon={
                <StyledRefresh isRefreshing={isRefreshButtonClicked} />
              }
              onClick={() => {
                refetchCatalog();
                setIsRefreshButtonClicked(true);
              }}
            >
              {t('PRIVATE_DOCUMENTS_DETAILS_SIGNATURE_REFRESH')}
            </Button>
          )}
          <StyledDocumentButton
            onClick={e => {
              e.stopPropagation();
              navigateToDocument(signedDocumentAddress as string);
            }}
            variant="outlined"
            disabled={!signedDocumentAddress}
          >
            {t('PRIVATE_DOCUMENTS_DETAILS_SIGNATURE_GET_NEW_VERSION')}
          </StyledDocumentButton>
        </StyledButtonsWrapper>
      </StyledSignatureProgressWrapper>
    </>
  );
};

export default SignatureProgressStatus;
