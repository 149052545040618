import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { PublicDocumentHistoryEntry } from 'src/modules/DocumentsPrivate/types';
import getDocumentPublicHistory from 'src/modules/DocumentsPrivate/api/getDocumentPublicHistory';

const useDocumentPublicHistory = (
  blockchainAddress: string,
  publisherId: string,
  documentType: string,
  options?: UseQueryOptions<
    PublicDocumentHistoryEntry[] | undefined,
    AxiosError
  >
) =>
  useQuery<PublicDocumentHistoryEntry[] | undefined, AxiosError>(
    [queryKeys.useDocumentHistory, blockchainAddress],
    async () => {
      const { data: response } = await getDocumentPublicHistory({
        blockchainAddress,
        publisherId,
        documentType
      });

      return response.relatedDocuments;
    },
    options
  );

export default useDocumentPublicHistory;
