import { FormControl, FormHelperText, styled } from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '.MuiButtonBase-root': {
    paddingRight: theme.spacing(2)
  },
  svg: {
    color: theme.palette.custom.neutral[500]
  }
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));
