import hasFeature from 'src/lib/hasFeature';
import { DocumentNumberTypeEnum } from 'src/modules/UserIdentity/constants/identityParams';
import { appConfig } from 'src/constants/appConfig';
import {
  limitedNipSchema,
  limitedPostalCodeSchema,
  limitedPESELSchema,
  requiredSchema
} from 'src/modules/UserIdentity/utils/documentNumberUtils/documentNumberSchemas';

export const getDocumentNumberSchema = (
  dcumentNumberType: DocumentNumberTypeEnum
) => {
  if (hasFeature('tauron') && appConfig?.documentNumberAlgorithm) {
    if (dcumentNumberType === DocumentNumberTypeEnum.NIP) {
      return limitedNipSchema;
    }
    if (dcumentNumberType === DocumentNumberTypeEnum.POSTAL_CODE) {
      return limitedPostalCodeSchema;
    }
    return limitedPESELSchema;
  }
  return requiredSchema;
};
