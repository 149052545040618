/* eslint-disable */
export default s => {
  const add = (xVal, yVal) => {
    let c = 0;
    const r = [];
    const x = xVal.split('').map(Number);
    const y = yVal.split('').map(Number);

    while (x.length || y.length) {
      const s = (x.pop() || 0) + (y.pop() || 0) + c;
      r.unshift(s < 10 ? s : s - 10);
      c = s < 10 ? 0 : 1;
    }

    if (c) r.unshift(c);

    return r.join('');
  };

  let dec = '0';
  s.split('').forEach(chr => {
    let n = parseInt(chr, 16);
    for (let t = 8; t; t >>= 1) {
      dec = add(dec, dec);
      if (n & t) dec = add(dec, '1');
    }
  });

  return dec;
};
