import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const StyledPrimaryEmphasis = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const StyledErrorWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 'calc(100vh - 400px)',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('tablet')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '1rem'
  }
}));

export const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '550px',
  [theme.breakpoints.only('mobile')]: {
    width: '100%',
    marginTop: '1rem'
  }
}));
