import { RouterProvider } from 'react-router-dom';

import routers from 'src/lib/routers';
import useApiHealth from 'src/modules/DocumentsPrivate/hooks/useApiHealth';

const ApiCheck = () => {
  const { router, routerWithLoader, routerWithServiceOutage } = routers;

  const { data, error, isLoading } = useApiHealth({
    retry: false
  });

  if (isLoading) {
    return <RouterProvider router={routerWithLoader} />;
  }

  if (error || data?.status === 'error') {
    return <RouterProvider router={routerWithServiceOutage} />;
  }

  return <RouterProvider router={router} />;
};

export default ApiCheck;
