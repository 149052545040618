import scrypt from 'scrypt-async';

const getCEKKey = (iv, password) =>
  new Promise(resolve => {
    scrypt(
      password,
      iv,
      {
        N: 65536, // cost
        r: 8, // block size
        p: 1, // parallel
        dkLen: 32, // key length
        encoding: 'binary'
      },
      decoded => resolve(decoded)
    );
  });

export default getCEKKey;
