import { ListItemIcon, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import { ReactComponent as LogoutIcon } from 'src/assets/logout.svg';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import UserDetails from 'src/components/userDetails';
import routes from 'src/constants/routes';

import {
  StyledMenuItem,
  StyledButton,
  StyledDivider,
  StyledMenuContainer,
  StyledSkeletonContainer,
  StyledSkeletonColumn
} from './styles';

const UserMenu = () => {
  const { t } = useTranslation();
  const { state, logout } = useContext(UserIdentityContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    logout();
    navigate(routes.index);
    handleClose();
  }, [logout, navigate]);

  if (state.status !== 'AUTHORIZED' && state.isLoading) {
    return (
      <StyledSkeletonContainer>
        <Skeleton variant="circular" width={50} height={50} />
        <StyledSkeletonColumn>
          <Skeleton variant="rectangular" width={200} height={20} />
          <Skeleton variant="rectangular" width={250} height={10} />
        </StyledSkeletonColumn>
      </StyledSkeletonContainer>
    );
  }

  if (state.status === 'UNAUTHORIZED') {
    return null;
  }

  return (
    <>
      <StyledButton
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        color="secondary"
        onClick={handleClick}
      >
        <UserDetails />
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true
        }}
      >
        <StyledMenuContainer>
          <UserDetails />
        </StyledMenuContainer>
        <StyledDivider />
        <StyledMenuContainer onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <StyledMenuItem>{t('LOGOUT')}</StyledMenuItem>
        </StyledMenuContainer>
      </Menu>
    </>
  );
};

export default UserMenu;
