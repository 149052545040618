import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import routes from 'src/constants/routes';
import CardsRowComponent from 'src/components/CardsRow';
import Feature from 'src/components/feature';
import hasFeature from 'src/lib/hasFeature';
import { getPrivateDocumentIndexRoute } from 'src/modules/DocumentsPrivate/utils';

import {
  StyledMainLayout,
  StyledMainLayoutContent,
  StyledSubtitle,
  StyledTitle,
  StyledHeader,
  StyledCard
} from './styles';

const MainPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainFeaturesCount = [
    hasFeature('documentPrivate'),
    hasFeature('documentVerification')
  ]
    .map(Number)
    .reduce((prev, next) => prev + next, 0);

  const onPrivateDocumentClick = () => navigate(getPrivateDocumentIndexRoute());

  return (
    <StyledMainLayout>
      <StyledMainLayoutContent>
        <StyledHeader as="span">
          <StyledTitle variant="h1">
            {t('HOME_TRUSTED_DOCUMENT_REGISTER')}
          </StyledTitle>
          <StyledSubtitle variant="h6">
            {t('HOME_BILLON_UNIFIED_BLOCKCHAIN')}
          </StyledSubtitle>
        </StyledHeader>
        <CardsRowComponent featureCount={mainFeaturesCount}>
          <Feature feature="documentPublic">
            <StyledCard
              title={t('HOME_DOCUMENT_PUBLIC_DOCUMENT_LIST')}
              buttonText={t('HOME_SELECT_BUTTON')}
              onButtonClick={() => navigate(routes.publicDocumentList)}
            >
              {t('HOME_DOCUMENT_PUBLIC_DOCUMENT_LIST_DESCRIPTION')}
            </StyledCard>
          </Feature>
          <Feature feature="documentPrivate">
            <StyledCard
              title={t('HOME_DOCUMENT_DOWNLOAD')}
              buttonText={t('HOME_SELECT_BUTTON')}
              onButtonClick={onPrivateDocumentClick}
            >
              {t('DOCUMENT_VERIFICATION_DOWNLOAD_DOCUMENT_DESCRIPTION')}
            </StyledCard>
          </Feature>
          <Feature feature="documentVerification">
            <StyledCard
              title={t('HOME_DOCUMENT_VERIFICATION')}
              buttonText={t('HOME_SELECT_BUTTON')}
              onButtonClick={() => navigate(routes.verifyIndex)}
            >
              {t('DOCUMENT_VERIFICATION_VERIFY_DOCUMENT_DESCRIPTION')}
            </StyledCard>
          </Feature>
        </CardsRowComponent>
      </StyledMainLayoutContent>
    </StyledMainLayout>
  );
};

export default MainPage;
