import { createTheme } from '@mui/material/styles';

import { appConfig } from 'src/constants/appConfig';

import palette from './palette';
import breakpoints from './breakpoints';
import components from './components';

const defaultFontFamily = 'Montserrat';

const theme = createTheme({
  breakpoints,
  palette: { ...palette, ...appConfig?.palette },
  spacing: 8,
  typography: {
    fontFamily: appConfig?.fontFamily || defaultFontFamily
  },
  components
});

export default theme;
