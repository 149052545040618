const importKey = async (data, algorithm = 'AES-GCM') =>
  crypto.subtle.importKey(
    'raw',
    data,
    {
      name: algorithm
    },
    false,
    ['encrypt', 'decrypt']
  );

export default importKey;
