import { Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import ValidationError from 'src/lib/validationError';

import StyledAlert from './styles';

type FormAlertProps = {
  error?:
    | {
        message: string;
      }
    | AxiosError
    | null;
};

const FormAlert = ({ error }: FormAlertProps) => {
  const { t } = useTranslation();
  const isValidationError = error instanceof ValidationError;

  useEffect(() => {
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error]);

  if (!error) {
    return null;
  }
  return (
    <StyledAlert severity="error">
      <Typography
        variant="body1"
        fontWeight={600}
        sx={{ whiteSpace: 'pre-line' }}
      >
        {isValidationError
          ? (error as ValidationError).invalidInputs.map(errorCode =>
              t(errorCode)
            )
          : t(error.message)}
      </Typography>
    </StyledAlert>
  );
};

export default FormAlert;
