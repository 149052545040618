import { Button, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { plPL } from '@mui/x-data-grid';

import { ReactComponent as PictureAsPdf } from 'src/assets/pictureAsPdf.svg';
import hasFeature from 'src/lib/hasFeature';
import routes from 'src/constants/routes';
import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import { ReactComponent as RefreshIcon } from 'src/assets/refreshIcon.svg';
import { ReactComponent as FiltersIcon } from 'src/assets/filters.svg';
import FiltersCard from 'src/modules/DocumentsPrivate/components/documentList/filtersCard';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { TableLoader } from 'src/components/loader';
import useDownloadDocument from 'src/modules/DocumentsPrivate/hooks/useDownloadDocument';
import { SortModel } from 'src/modules/DocumentsPrivate/types';
import NoResults from 'src/modules/DocumentsPrivate/components/documentList/noResults';
import { UsePrivateDocumentListResponse } from 'src/modules/DocumentsPrivate/hooks/usePrivateDocumentList';
import useDocumentPublishers from 'src/modules/DocumentsPrivate/hooks/useDocumentPublishers';
import TableRowsLoader from 'src/modules/DocumentsPrivate/components/documentList/tableRowsLoader';

import {
  StyledCloseButton,
  StyledDataGrid,
  StyledDetailsLink,
  StyledHeaderContainer,
  StyledNoDataSpan,
  StyledRefreshText,
  StyledTableContainer,
  StyledTableContainerHeader
} from './styles';
import { columns } from './columns';
import StatusChip from './statusChip';

const filteredColumns = columns.filter(
  column =>
    column.field !== 'category' || hasFeature('documentPrivateCategories')
);

type Props = {
  onSearch: () => void;
  privateDocumentListData?: UsePrivateDocumentListResponse;
  isLoading: boolean;
  isFetching: boolean;
  sortModel: SortModel;
  paginationModel: GridPaginationModel;
  setSortModel: (model: SortModel) => void;
  setPaginationModel: (model: GridPaginationModel) => void;
};

const Table = ({
  onSearch,
  privateDocumentListData,
  isLoading,
  isFetching,
  sortModel,
  paginationModel,
  setPaginationModel,
  setSortModel
}: Props) => {
  const [downloadingList, setDownloadingList] = useState<string[]>([]);
  const { state } = useContext(UserIdentityContext);
  const {
    t,
    i18n: { resolvedLanguage }
  } = useTranslation();
  const navigate = useNavigate();
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const { mutate: handleDownloadDocument } = useDownloadDocument({
    onSuccess: blockchainAddress => {
      setDownloadingList(
        downloadingList.filter(item => item !== blockchainAddress)
      );
    }
  });
  const { data: publishers } = useDocumentPublishers({
    enabled: Boolean(privateDocumentListData)
  });
  const privateDocumentList = useMemo(
    () => privateDocumentListData?.documentList,
    [privateDocumentListData]
  );

  const isTableReady = useMemo(
    () =>
      !isLoading &&
      !isFetching &&
      !!state.uuid &&
      !!state.kycGwId &&
      !!state.keys,
    [isLoading, isFetching, state]
  );

  const documentListMap = useMemo(
    () =>
      (isTableReady &&
        !!privateDocumentList &&
        privateDocumentList
          ?.filter(document => Boolean(document.decryptedData))
          ?.map((document, index) => ({
            id: index,
            title: document.decryptedData?.title ?? (
              <StyledNoDataSpan>
                {t('DOCUMENT_LIST_NO_DATA_TITLE')}
              </StyledNoDataSpan>
            ),
            publishDate: dayjs(document.creationDate).format(
              'DD.MM.YYYY, HH:mm:ss'
            ),
            publicationDate: dayjs(document.publicationDate).format(
              'DD.MM.YYYY, HH:mm:ss'
            ),
            category: document.category,
            publisher: (publishers || []).find(
              publisher => publisher.publisherId === document.publisherId
            )?.name,
            status: (
              <StatusChip
                label={t('PRIVATE_DOCUMENT_LIST_STATUS_DEFAULT')}
                color="success"
                size="small"
                variant="outlined"
                signingStatus={document?.signingStatus}
              />
            ),
            download: (
              <LoadingButton
                disabled={document.decryptedData?.decryptionFailed}
                loading={downloadingList.includes(
                  document.decryptedData?.blockchainAddress as string
                )}
                onClick={e => {
                  if (!document.decryptedData?.decryptionFailed) {
                    e.stopPropagation();
                    const blockchainAddress = document.decryptedData
                      ?.blockchainAddress as string;
                    if (blockchainAddress) {
                      setDownloadingList([
                        ...downloadingList,
                        blockchainAddress
                      ]);
                      handleDownloadDocument({ blockchainAddress });
                    }
                  }
                }}
              >
                {!downloadingList.includes(
                  document.decryptedData?.blockchainAddress as string
                ) && (
                  <PictureAsPdf
                    style={{
                      opacity: document.decryptedData?.decryptionFailed
                        ? '0.25'
                        : 1
                    }}
                  />
                )}
              </LoadingButton>
            ),
            preview: (
              <StyledDetailsLink
                decryptionFailed={document.decryptedData?.decryptionFailed}
                variant="body2"
                onClick={e => {
                  if (!document.decryptedData?.decryptionFailed) {
                    e.stopPropagation();
                    navigate(
                      routes.documentBlockchainAddress
                        .replace(':uuid', state.uuid as string)
                        .replace(
                          ':address',
                          document.decryptedData?.blockchainAddress as string
                        )
                    );
                  }
                }}
              >
                {t('PRIVATE_DOCUMENTS_LIST_SEE_DEATAILS')}
              </StyledDetailsLink>
            )
          }))) ||
      [],
    [
      privateDocumentList,
      publishers,
      downloadingList,
      isTableReady,
      t,
      handleDownloadDocument,
      navigate,
      state.uuid
    ]
  );

  return (
    <StyledTableContainer>
      <StyledTableContainerHeader>
        <StyledHeaderContainer>
          <Typography variant="h6" fontWeight={600}>
            {t('PRIVATE_DOCUMENTS_LIST_TITLE')}
          </Typography>
          <Button
            onClick={() => onSearch()}
            variant="help"
            startIcon={<RefreshIcon width="24px" height="24px" />}
          >
            <StyledRefreshText variant="buttonSmall">
              {t('PRIVATE_DOCUMENTS_DETAILS_SIGNATURE_REFRESH')}
            </StyledRefreshText>
          </Button>
        </StyledHeaderContainer>
        <StyledCloseButton
          size="small"
          onClick={() => setIsFiltersVisible(prevState => !prevState)}
          startIcon={isFiltersVisible ? <CloseIcon /> : <FiltersIcon />}
          isOpened={isFiltersVisible}
          variant="text"
        >
          {t(
            isFiltersVisible
              ? 'PRIVATE_DOCUMENTS_LIST_FITERS_CLOSE_FILTERS'
              : 'PRIVATE_DOCUMENTS_LIST_FILTERS'
          )}
        </StyledCloseButton>
      </StyledTableContainerHeader>

      {isFiltersVisible && (
        <FiltersCard isLoading={isLoading || isFetching} onSearch={onSearch} />
      )}

      <StyledDataGrid
        slots={{
          noRowsOverlay: NoResults,
          noResultsOverlay: NoResults,
          loadingOverlay:
            isTableReady || documentListMap.length > 0
              ? TableLoader
              : TableRowsLoader
        }}
        localeText={
          resolvedLanguage === 'pl'
            ? {
                ...plPL.components.MuiDataGrid.defaultProps.localeText,
                columnMenuManageColumns: t('TABLE_MANAGE_COLUMNS')
              }
            : undefined
        }
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={privateDocumentListData?.count || 0}
        onSortModelChange={sort => {
          if (isLoading) {
            return;
          }

          const sortItem = sort[0];

          if (sortItem && sortItem.sort) {
            setSortModel({
              order: sortItem.sort,
              orderBy: sortItem.field
            });
          } else {
            setSortModel({
              order: sortModel.order === 'asc' ? 'desc' : 'asc',
              orderBy: sortModel.orderBy
            });
          }
        }}
        sortModel={[
          {
            sort: sortModel.order,
            field: sortModel.orderBy
          }
        ]}
        sortingMode="server"
        paginationMode="server"
        loading={!isTableReady}
        onRowClick={({ row }) => {
          if (
            privateDocumentList &&
            privateDocumentList[row.id] &&
            state.uuid
          ) {
            const document = privateDocumentList[row.id];
            if (!document?.decryptedData?.decryptionFailed) {
              navigate(
                routes.documentBlockchainAddress
                  .replace(':uuid', state.uuid)
                  .replace(
                    ':address',
                    document.decryptedData?.blockchainAddress as string
                  )
              );
            }
          }
        }}
        columns={filteredColumns}
        rows={documentListMap || []}
      />
    </StyledTableContainer>
  );
};

export default Table;
