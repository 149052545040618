import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { Publisher } from 'src/modules/DocumentsPrivate/types';
import getPublishers from 'src/modules/DocumentsPrivate/api/getPublishers';

const useDocumentPublishers = (
  options?: UseQueryOptions<Publisher[] | undefined, AxiosError>
) =>
  useQuery<Publisher[] | undefined, AxiosError>(
    [queryKeys.useDocumentPublishers],
    async () => {
      const { data: response } = await getPublishers();

      return response.publishers;
    },
    options
  );

export default useDocumentPublishers;
