import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiAccordionSummary'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.custom.textColors[100],
      borderBottom: `1px solid ${theme.palette.custom.neutral[200]}`,

      '&.Mui-expanded': {
        minHeight: 'unset'
      }
    }),
    content: ({ theme }) => ({
      display: 'flex',
      alignItems: 'center',

      '.MuiTypography-root': {
        flex: 0.5
      },
      '&.Mui-expanded': {
        margin: theme.spacing(2, 0)
      }
    })
  }
};

export default componentConfiguration;
