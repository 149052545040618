import { GridColDef } from '@mui/x-data-grid';
import i18next from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export const columns: GridColDef[] = [
  {
    field: 'publicationDate',
    sortable: true,
    hideSortIcons: true,
    flex: 1,
    renderHeader: () =>
      i18next.t('PRIVATE_DOCUMENTS_LIST_PUBLICATION_DATE_COLUMN')
  },
  {
    field: 'title',
    sortable: false,
    flex: 1,
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_TITLE_COLUMN'),
    renderCell: ({ value }) => value
  },
  {
    field: 'category',
    sortable: true,
    hideSortIcons: true,
    flex: 1,
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_CATEGORY_COLUMN')
  },
  {
    sortable: false,
    field: 'publisher',
    flex: 1,
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_PUBLISHER_COLUMN')
  },
  {
    sortable: false,
    flex: 1,
    field: 'status',
    renderHeader: () => i18next.t('PRIVATE_DOCUMENT_LIST_STATUS_COLUMN'),
    renderCell: ({ value }) => value
  },
  {
    disableColumnMenu: true,
    hideable: false,
    editable: false,
    hideSortIcons: true,
    field: 'download',
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_DOWNLOAD_COLUMN'),
    renderCell: ({ value }) => value,
    headerAlign: 'center',
    align: 'left',
    sortable: false
  },
  {
    disableColumnMenu: true,
    hideable: false,
    editable: false,
    hideSortIcons: true,
    field: 'preview',
    minWidth: 200,
    renderHeader: () => '',
    renderCell: ({ value }) => value,
    headerAlign: 'center',
    align: 'center',
    sortable: false
  }
];
