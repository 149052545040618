import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  AddFullIdentityStep1,
  AddFullIdentityStep1Response
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';

const postFullIdentityStep1 = async (
  request: AddFullIdentityStep1
): Promise<AddFullIdentityStep1Response> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } = await apiClient.post<AddFullIdentityStep1Response>(
    apiRoutes.addFullIdentityStep1,
    request
  );

  if (response.statusCode === 'ERROR') {
    throw new ApiError(response.statusDetails, response.statusDetails);
  }

  return response;
};

export default postFullIdentityStep1;
