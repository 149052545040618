import { Buffer } from 'buffer';

import base64ToHex from 'src/modules/Crypto/lib/base64ToHex';
import hexToUInt8Array from 'src/modules/Crypto/lib/hexToUInt8Array';
import ASN1Decoder from 'src/modules/Crypto/lib/ASN1Decoder';
import bufferToHex from 'src/modules/Crypto/lib/bufferToHex';
import decryptSafeContent from 'src/modules/Crypto/lib/decryptSafeContent';
import ObjectCaster from 'src/modules/Crypto/lib/objectCaster';

/* eslint-disable no-console */
const extractKey = async (privateKeys, encryptedKek, encryptedPassword) => {
  const keyData = {
    shortcutName: '',
    keyVersion: -1
  };
  //FORMATTING DATA
  const kek = hexToUInt8Array(base64ToHex(encryptedKek));

  const decryptedPassword = Buffer.from(encryptedPassword);

  const password = String.fromCharCode.apply(
    null,
    new Uint8Array(decryptedPassword)
  );

  //EXTRACTING KEK

  const kekNormalized = ASN1Decoder.decodeHexString(bufferToHex(kek));
  const kekIV = kekNormalized.SEQUENCE_0.OCTET_STRING_0;
  const kekKey = kekNormalized.SEQUENCE_0.OCTET_STRING_1;

  //decrypting safe content

  const decryptedPrivateKeys = await decryptSafeContent(
    privateKeys,
    hexToUInt8Array(kekIV),
    hexToUInt8Array(kekKey)
  );
  const decryptedPrivateKeysNormalized = ASN1Decoder.decodeHexString(
    decryptedPrivateKeys.decryptedHex
  );

  console.groupCollapsed('--decryptedPrivateKeysNormalized--');
  console.log('decryptedPrivateKeysNormalized', decryptedPrivateKeysNormalized);
  console.groupEnd();

  for (
    let i = 0;
    i < Object.keys(decryptedPrivateKeysNormalized.SEQUENCE_0).length;
    i++
  ) {
    const element = decryptedPrivateKeysNormalized.SEQUENCE_0[`SEQUENCE_${i}`];
    const keyName = element.SET_0.SEQUENCE_0.SET_0.IA5STRING_0;
    const keyHash =
      element.SET_0.SEQUENCE_1.SET_0.OCTET_STRING_0.toString().toLowerCase();
    const keyVersion = element.SET_0.SEQUENCE_2.SET_0.INTEGER_0;

    if (keyName.endsWith('ECDSA_50y') && keyVersion > keyData.keyVersion) {
      keyData.keyVersion = keyVersion;
      keyData.shortcutName = keyHash;
    }

    console.groupCollapsed(keyName);
    console.log(keyHash);
    console.log(element);
    console.log(keyVersion);
    console.groupEnd();
  }
  console.groupEnd();

  console.groupCollapsed('DERIVED KEY DATA');
  console.log(keyData);
  console.groupEnd();

  const foundSb = ObjectCaster.getSequenceByKeyName(
    decryptedPrivateKeysNormalized,
    keyData
  );

  const filteredSafebag =
    (await ObjectCaster.getFormattedDataFromSequence(foundSb, password)) || {};

  const safeBagNormalized = ASN1Decoder.decodeHexString(
    filteredSafebag.decPrivKeyHex
  );

  const privateKeyNormalsied = ASN1Decoder.decodeHexString(
    safeBagNormalized.SEQUENCE_0.OCTET_STRING_0
  );

  const curvature = filteredSafebag.curvatureType.curvature;

  const privateKey = privateKeyNormalsied.SEQUENCE_0.OCTET_STRING_0;

  const privateKeyBuffer = Buffer.from(privateKey, 'hex');

  const response = ObjectCaster.getCurvature(curvature, privateKeyBuffer);

  return bufferToHex(response.publicKey);
};

export default extractKey;
