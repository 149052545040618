/* eslint-disable */

export default (strOne, strTwo, forLoopLength) => {
  // str1 - first string as a hex
  // str2 - second string as a hex
  // forLoopLength - determines end condition in 'for' loop
  let result = '';
  for (let i = 0; i < forLoopLength; i++) {
    // for i = 0 get str[0] and str[1] element
    // for i = 1 get str[2] and str[3] etc
    let strOneHex = strOne[i * 2] + strOne[i * 2 + 1];
    let strTwoHex = strTwo[i * 2] + strTwo[i * 2 + 1];
    let strOneBin = ('00000000' + parseInt(strOneHex, 16).toString(2)).substr(
      -8
    );
    let strTwoBin = ('00000000' + parseInt(strTwoHex, 16).toString(2)).substr(
      -8
    );
    let calcBin = '';
    for (let j = 0; j < 8; j++) {
      calcBin += strOneBin[j] === strTwoBin[j] ? '0' : '1';
    }
    let tempRes = parseInt(calcBin, 2).toString(16);
    result += tempRes.length === 1 ? '0' + tempRes : tempRes;
  }
  return result.slice(0, forLoopLength);
};
