import { Box, Divider, styled } from '@mui/material';

export const StyledWidthContent = styled(Box)({
  width: '100%'
});

export const StyledColRow = styled(Box)<{
  displaySeparateViewerTab: boolean;
}>(({ displaySeparateViewerTab }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: displaySeparateViewerTab ? 'row' : 'column',
  gap: '25px'
}));

export const StyledDetailsColumn = styled(Box)<{
  displaySeparateViewerTab: boolean;
}>(({ displaySeparateViewerTab }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: !displaySeparateViewerTab ? '25px' : 'none',
  width: displaySeparateViewerTab ? '35%' : '100%',
  minHeight: displaySeparateViewerTab ? '510px' : '0',
  paddingRight: displaySeparateViewerTab ? '40px' : '0'
}));

export const StyledDetailsTitleDivider = styled(Divider)<{
  displaySeparateViewerTab: boolean;
}>(({ displaySeparateViewerTab }) => ({
  marginTop: displaySeparateViewerTab ? '25px' : '0',
  marginBottom: displaySeparateViewerTab ? '25px' : '0'
}));

export const StyledControlsWrapper = styled(Box)<{
  displaySeparateViewerTab: boolean;
}>(({ displaySeparateViewerTab }) => ({
  marginLeft: '-16px',
  marginTop: 'auto',
  marginBottom: displaySeparateViewerTab ? '-8px' : 0,
  marginRight: '-16px'
}));

export const StyledControlsDivider = styled(Divider)<{
  displaySeparateViewerTab: boolean;
}>(({ displaySeparateViewerTab }) => ({
  marginLeft: '16px',
  marginRight: '16px',
  marginTop: displaySeparateViewerTab ? '25px' : 0,
  marginBottom: displaySeparateViewerTab ? '25px' : 0
}));

export const StyledDocumentPreviewWrapper = styled(Box)<{
  displaySeparateViewerTab: boolean;
}>(({ displaySeparateViewerTab }) => ({
  width: displaySeparateViewerTab ? '65%' : '100%',
  minHeight: '510px'
}));
