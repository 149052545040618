import { Theme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import typography from './typography';

const createResponsiveFonts = (theme: Theme) => {
  const overridenTheme = typography(theme);

  for (const variant in overridenTheme) {
    const themeTypography = theme.typography;
    const typographyVariant = variant as Variant;

    themeTypography[typographyVariant] = {
      ...themeTypography[typographyVariant],
      ...overridenTheme[typographyVariant]
    };
  }
};

export default createResponsiveFonts;
