import { Typography, Stack, styled } from '@mui/material';

export const StyledContent = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  [theme.breakpoints.up('desktop')]: {
    [theme.breakpoints.between(1440, 1600)]: {
      gap: '0.35rem'
    }
  },
  [theme.breakpoints.down('desktop')]: {
    gap: '0.25rem',
    [theme.breakpoints.between(550, 768)]: {
      gap: '0.35rem'
    }
  }
}));

interface InputWithErrorProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error: boolean;
}

export const StyledInput: React.FC<InputWithErrorProps> = styled(
  'input'
)<InputWithErrorProps>(({ theme, disabled, error }) => ({
  textAlign: 'center',
  fontSize: '1rem',
  outlineColor: theme.palette.primary.main,
  backgroundColor: disabled ? theme.palette.custom.textColors[300] : 'white',
  borderRadius: '8px',
  border: disabled
    ? 'none'
    : error
    ? `solid 2px ${theme.palette.custom.error[600]}`
    : 'solid 2px grey',
  [theme.breakpoints.down('tablet')]: {
    height: '35px',
    width: '35px',
    [theme.breakpoints.between(600, 768)]: {
      height: '40px',
      width: '40px'
    }
  },
  [theme.breakpoints.up('tablet')]: {
    height: '50px',
    width: '50px'
  }
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export const StyledErrorLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.error[600],
  margin: '3px 14px 0 14px',
  fontSize: '12px'
}));
