import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { StyledCenterWrapper, StyledPrimaryEmphasis } from './styles';

const HistoryError = () => {
  const { t } = useTranslation();
  return (
    <StyledCenterWrapper marginTop="3rem">
      <Box>
        <Typography variant="h6" fontWeight={700} textAlign="center">
          <StyledPrimaryEmphasis>
            {t('PRIVATE_DOCUMENT_HISTORY_ERROR')}
          </StyledPrimaryEmphasis>
        </Typography>
        <Typography
          variant="h6"
          fontWeight={500}
          width="100%"
          textAlign="center"
          marginBottom="2rem"
        >
          {t('INCORRECT_URL_SUBTITLE')}
        </Typography>
      </Box>
    </StyledCenterWrapper>
  );
};

export default HistoryError;
