/* eslint-disable */

import ASN1Decoder from './ASN1Decoder';
import asn1creators from './asn1creators';
import bufferToHex from './bufferToHex';
import decryptSafeContent from './decryptSafeContent';
import hexToUInt8Array from './hexToUInt8Array';
import ObjectCaster from './objectCaster';
import generatePublicKeyWithDataWithKeyIdECIESECDSA from './generatePublicKeyWithDataWithKeyIdECIESECDSA';
import generatePublicKeyWithDataWithKeyIRSA from './generatePublicKeyWithDataWithKeyIRSA';
import signPublicKeyStructureECDSA from './signPublicKeyStructureECDSA';
import signPublicKeyStructureRSA from './signPublicKeyStructureRSA';

const createPublicKeysSequences = async (res, stateObject) => {
  if (res.curvatureType === 'ecies' || res.curvatureType === 'ecdsa') {
    stateObject.publicKeysSequences[res.curvatureType][res.curvatureName] =
      asn1creators.createDATAWITHKEYIDSequenceECIESECDSARSA({
        version: 1,
        counter: res.sequenceVersion,
        safebagSHA: res.safebagSHA,
        publicKeyInfo: generatePublicKeyWithDataWithKeyIdECIESECDSA(
          res,
          stateObject
        ).toBER()
      });
  } else {
    stateObject.publicKeysSequences[res.curvatureType][res.curvatureName] =
      asn1creators.createDATAWITHKEYIDSequenceECIESECDSARSA({
        null: null,
        version: 1,
        counter: res.sequenceVersion,
        safebagSHA: res.safebagSHA,
        publicKeyInfo: await generatePublicKeyWithDataWithKeyIRSA(
          res,
          stateObject
        )
      });
  }
};

const createPublicKeysFromSafeContent = async (
  normalized,
  password,
  stateObject
) => {
  for (let i = 0; i < Object.keys(normalized.SEQUENCE_0).length; i++) {
    const element = normalized.SEQUENCE_0[`SEQUENCE_${i}`];
    await ObjectCaster.getFormattedDataFromSequence(element, password).then(
      async res => {
        /* data:
         * curvature: <string>brainpool256/brainpool320/brainpool384/brainpool512/rsa2k }
         * rsa: boolean // informs about curvature, if it is not RSA we're checking if this is ECIES/ECDSA
         *  */
        const curvObject = ASN1Decoder.getCurvatureNameByPrivateKey(
          res.decPrivKeyHex
        );
        const sbName = res.safabagName.toLowerCase();
        res.curvatureType = sbName.includes('rsa')
          ? 'rsa'
          : sbName.includes('ecies')
          ? 'ecies'
          : 'ecdsa';
        res.curvatureName = curvObject.curvature;

        await createPublicKeysSequences(res, stateObject);
      }
    );
  }
};

const createPublicKeysSequence = stateObject => {
  stateObject.publicKeysStructure = bufferToHex(
    asn1creators
      .createPublicKeysDataWithIdSequence(stateObject.publicKeysSequences)
      .toBER()
  );
};

export default async (privateKey, kek, password) => {
  console.log('===== GENERATE PUBLIC KEYS =====');
  let normalized = ASN1Decoder.decodeHexString(bufferToHex(kek));
  const stateObject = {
    safeContent: {},
    sfKek: {
      IV: normalized.SEQUENCE_0.OCTET_STRING_0,
      key: normalized.SEQUENCE_0.OCTET_STRING_1
    },
    privateKeys: {
      ecies: {},
      rsa: {}
    },
    publicKeysSequences: {
      ecies: {},
      ecdsa: {},
      rsa: {},
      signs: {
        ECDSA: {},
        RSA: ''
      }
    }
  };

  console.log('------ 1. DECODING SAFECONTENT ------');
  let decrypted = await decryptSafeContent(
    privateKey,
    hexToUInt8Array(stateObject.sfKek.IV),
    hexToUInt8Array(stateObject.sfKek.key)
  );

  normalized = ASN1Decoder.decodeHexString(decrypted.decryptedHex);

  console.log('------ 2. CREATE PUBLIC KEYS FROM SAFECONTENT ------');
  await createPublicKeysFromSafeContent(normalized, password, stateObject);

  console.log('------ 3. CREATE PUBLIC KEYS SEQUENCE ------');
  createPublicKeysSequence(stateObject);

  console.log('------ 4. SIGN PUBLIC KEYS SEQUENCE ------');
  await signPublicKeyStructureECDSA(
    stateObject.publicKeysStructure,
    'brainpool256',
    stateObject
  );
  await signPublicKeyStructureECDSA(
    stateObject.publicKeysStructure,
    'brainpool320',
    stateObject
  );
  await signPublicKeyStructureECDSA(
    stateObject.publicKeysStructure,
    'brainpool384',
    stateObject
  );
  await signPublicKeyStructureECDSA(
    stateObject.publicKeysStructure,
    'brainpool512',
    stateObject
  );
  await signPublicKeyStructureRSA(stateObject.publicKeysStructure, stateObject);

  console.log('------ 5. GET PUBLIC KEYS WITH SIGNS SEQUENCE ------');
  stateObject.userPublicKeys = asn1creators
    .createUserPublicKeys(stateObject.publicKeysSequences)
    .toBER();
  console.log('------ 6. USERS PUBLIC KEYS GENERATED ------');

  return stateObject.userPublicKeys;
};
