import {
  PrivateDocumentsStepperState,
  PrivateDocumentsWizardActions
} from './types';

export const privateDocumentWizardReducer = (
  state: PrivateDocumentsStepperState,
  action: PrivateDocumentsWizardActions
): PrivateDocumentsStepperState => {
  switch (action.ACTION) {
    case 'SET_ERROR':
      return {
        ...state,

        isLoading: false,
        error: action.error
      };
    case 'LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'SET_BLOCKCHAIN_ADDRESS':
      return {
        ...state,
        blockchainAddress: action.blockchainAddress
      };
    case 'SET_ACCESS_CODE':
      return {
        ...state,
        accessCode: action.accessCode
      };
    case 'SET_VERIFICATION_RESULT':
      return {
        ...state,
        verificationResult: action.verificationResult,
        isLoading: false
      };
    case 'SET_CATALOG':
      return {
        ...state,
        catalog: action.catalog,
        isLoading: false
      };
    case 'SET_DOCUMENT':
      return {
        ...state,
        document: action.document,
        isLoading: false
      };
    case 'SET_DOCUMENT_NUMBER':
      return {
        ...state,
        documentNumber: action.documentNumber
      };
    case 'SET_SHARED_SECRET_TYPE':
      return {
        ...state,
        sharedSecretType: action.sharedSecretType
      };
    case 'SET_AUTH':
      return {
        ...state,
        auth: action.auth
      };
    case 'STOP_LOADING':
      return {
        ...state,
        isLoading: false
      };
    case 'SET_INFO':
      return {
        ...state,
        info: action.info
      };
  }

  return state;
};

export default privateDocumentWizardReducer;
