import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LANGUAGES from 'src/constants/i18n';
import Flag from 'src/components/flag';

import { StyledArrowIcon, StyledMenuItem } from './styles';

const SelectLanguage = () => {
  const { i18n } = useTranslation();

  if (!i18n.resolvedLanguage) {
    return null;
  }

  return (
    <Select
      variant="standard"
      value={i18n.resolvedLanguage as LANGUAGES}
      disableUnderline
      renderValue={value => <Flag language={value as LANGUAGES} />}
      IconComponent={StyledArrowIcon}
    >
      <MenuItem
        onClick={() => i18n.changeLanguage(LANGUAGES.PL)}
        value={LANGUAGES.PL}
      >
        <StyledMenuItem>
          <Flag language={LANGUAGES.PL} />
          <span className="menuItemLabel">Polish</span>
        </StyledMenuItem>
      </MenuItem>
      <MenuItem
        onClick={() => i18n.changeLanguage(LANGUAGES.EN)}
        value={LANGUAGES.EN}
      >
        <StyledMenuItem>
          <Flag language={LANGUAGES.EN} />
          <span className="menuItemLabel">English</span>
        </StyledMenuItem>
      </MenuItem>
    </Select>
  );
};

export default SelectLanguage;
