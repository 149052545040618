import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginTop: theme.typography.pxToRem(-9.5),
      marginBottom: theme.typography.pxToRem(9.5),
      color: theme.palette.custom.neutral[500],
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(24)
      }
    })
  }
};

export default componentConfiguration;
