import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SmsStatusEnum } from 'src/modules/UserIdentity/types/tauronSms';
import ApiError from 'src/lib/apiError';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';

export const useResponseStatusNotification = () => {
  const { t } = useTranslation();
  const { setError, setInfo } = useContext(PrivateDocumentContext);

  const setStatusNotification = (
    status: SmsStatusEnum,
    blockedUntil?: string
  ) => {
    if (status === SmsStatusEnum.SUCCESS) {
      setInfo(t('SMS_VERIFICATION_SUCCESS'));
      return;
    }

    const errorMessageSurfix =
      status in SmsStatusEnum ? status : SmsStatusEnum.STATUS_UNKNOWN;
    const errorMessage = t(`SMS_VERIFICATION_${errorMessageSurfix}`);
    if (blockedUntil) {
      setError(
        new ApiError(
          `${
            status !== SmsStatusEnum.VERIFICATION_BLOCKED
              ? `${errorMessage},\n`
              : ''
          }${t('SMS_VERIFICATION_BLOCKED_UNTIL')}: ${new Date(
            blockedUntil
          ).toLocaleString()}`
        )
      );
    } else {
      setError(new ApiError(errorMessage));
    }
  };

  return { setStatusNotification };
};
