import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import { SaveKeysStatusResponse } from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';

const getSaveKeysStatus = async (
  processId: string
): Promise<SaveKeysStatusResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } = await apiClient.get<SaveKeysStatusResponse>(
    apiRoutes.fullIdentitySaveKeysStatus.replace('{processId}', processId)
  );

  if (response.statusCode === 'SAVE_KEYS_ERROR') {
    throw new ApiError(response.statusDetails, response.statusDetails);
  }

  return response;
};

export default getSaveKeysStatus;
