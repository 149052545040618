import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { ReactComponent as CloseIcon } from 'src/assets/close.svg';
import List from 'src/components/navbar/components/list';
import { LayoutContext } from 'src/contexts/LayoutContext';
import BillonLogoLink from 'src/components/billonLogoLink';
import StyledGradient from 'src/components/layoutGradient';

import NavbarFooter from './components/footer';
import {
  StyledDrawer,
  MobileHeaderContainer,
  StyledIconButton,
  StyledDrawerContent
} from './styles';

const NavBar = () => {
  const theme = useTheme();
  const { isNavbarOpened, toggleNavbar } = useContext(LayoutContext);
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  return (
    <StyledDrawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isNavbarOpened}
      anchor="left"
    >
      <StyledDrawerContent>
        {isMobile && (
          <div>
            <StyledGradient />
            <MobileHeaderContainer>
              <BillonLogoLink />
              <StyledIconButton onClick={toggleNavbar}>
                <CloseIcon />
              </StyledIconButton>
            </MobileHeaderContainer>
          </div>
        )}
        <List />
      </StyledDrawerContent>
      <NavbarFooter />
    </StyledDrawer>
  );
};

export default NavBar;
