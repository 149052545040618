import { CheckCircleOutline, HighlightOff, Refresh } from '@mui/icons-material';
import { Button, LinearProgress, styled, Typography } from '@mui/material';

export const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.custom.neutral[900],
  i: {
    fontWeight: 600,
    fontStyle: 'normal',
    textAlign: 'center'
  }
}));

export const StyledLinearProgressWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(4)
}));

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: '100%'
}));

export const StyledSignatureProgressWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(6),
  [theme.breakpoints.up('tablet')]: {
    marginTop: theme.spacing(9)
  },
  alignItems: 'center',
  color: theme.palette.custom.textColors[800]
}));
export const StyledButtonsWrapper = styled('div')<{ isRefreshButton: boolean }>(
  ({ theme, isRefreshButton }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: isRefreshButton ? 'space-between' : 'center',
    width: '100%',
    marginTop: theme.spacing(5)
  })
);
export const StyledDocumentButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.custom.neutral[50]
  }
}));
export const StyledSignIcon = styled(CheckCircleOutline)(({ theme }) => ({
  color: theme.palette.success.main,
  width: 47,
  height: 47,
  marginBottom: theme.spacing(2.5)
}));

export const StyledDescriptionList = styled('ol')(({ theme }) => ({
  paddingLeft: theme.spacing(2)
}));

export const StyledRejectIcon = styled(HighlightOff)(({ theme }) => ({
  color: theme.palette.error.main,
  width: 47,
  height: 47,
  marginBottom: theme.spacing(2.5)
}));

export const StyledRefresh = styled(Refresh)<{ isRefreshing?: boolean }>(
  ({ isRefreshing }) => ({
    animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  })
);
