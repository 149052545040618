import { Components, Theme } from '@mui/material';

import MuiCssBaselineCustomized from './defaultComponents/MuiCssBaseline';
import MuiIconCustomized from './defaultComponents/MuiIcon';
import MuiCardCustomized from './defaultComponents/MuiCard';
import MuiButtonCustomized from './defaultComponents/MuiButton';
import MuiCardContentCustomized from './defaultComponents/MuiCardContent';
import MuiDividerCustomized from './defaultComponents/MuiDivider';
import MuiChipCustomized from './defaultComponents/MuiChip';
import MuiStepperCustomized from './defaultComponents/stepper/MuiStepper';
import MuiStepIconCustomized from './defaultComponents/stepper/MuiStepIcon';
import MuiStepLabelCustomized from './defaultComponents/stepper/MuiStepLabel';
import MuiStepCustomized from './defaultComponents/stepper/MuiStep';
import MuiStepConnectorCustomized from './defaultComponents/stepper/MuiStepConnector';
import MuiSelectCustomized from './defaultComponents/MuiSelect';
import MuiMenuItemSelectCustomized from './defaultComponents/MuiMenuItem';
import MuiTooltipCustomized from './defaultComponents/MuiTooltip';
import MuiRadioButtonCustomized from './defaultComponents/MuiRadio';
import MuiBreadcrumbsCustomized from './defaultComponents/MuiBreadcrumbs';
import MuiOutlinedInputCustomized from './defaultComponents/MuiOutlinedInput';
import MuiInputLabelCustomized from './defaultComponents/MuiInputLabel';
import MuiTabCustomized from './defaultComponents/tabs/MuiTab';
import MuiTabsCustomized from './defaultComponents/tabs/MuiTabs';
import MuiAppBarCustomized from './defaultComponents/MuiAppBar';
import MuiDrawerCustomized from './defaultComponents/MuiDrawer';
import MuiListItemIconCustomized from './defaultComponents/list/MuiListItemIcon';
import MuiListItemButtonCustomized from './defaultComponents/list/MuiListItemButton';
import MuiListItemCustomized from './defaultComponents/list/MuiListItem';
import MuiAlertCustomized from './defaultComponents/MuiAlert';
import MuiFormControlLabelCustomized from './defaultComponents/MuiFormControlLabel';
import MuiAccordionSummaryCustomized from './defaultComponents/MuiAccordionSummary';
import MuiDataGridCustomized from './defaultComponents/MuiDataGrid';
import MuiTablePaginationCustomized from './defaultComponents/MuiTablePagination';
import MuiIconButtonCustomized from './defaultComponents/MuiIconButton';
import MuiInputAdornmentCustomized from './defaultComponents/MuiInputAdornment';
import MuiSkeletonCustomized from './defaultComponents/MuiSkeleton';
import MuiCheckboxCustomized from './defaultComponents/MuiCheckbox';

const components: Components<Theme> = {
  MuiCssBaseline: MuiCssBaselineCustomized,
  MuiButton: MuiButtonCustomized,
  MuiIcon: MuiIconCustomized,
  MuiCard: MuiCardCustomized,
  MuiCardContent: MuiCardContentCustomized,
  MuiDivider: MuiDividerCustomized,
  MuiChip: MuiChipCustomized,
  MuiStepper: MuiStepperCustomized,
  MuiStepIcon: MuiStepIconCustomized,
  MuiStepLabel: MuiStepLabelCustomized,
  MuiStep: MuiStepCustomized,
  MuiStepConnector: MuiStepConnectorCustomized,
  MuiSelect: MuiSelectCustomized,
  MuiMenuItem: MuiMenuItemSelectCustomized,
  MuiTooltip: MuiTooltipCustomized,
  MuiRadio: MuiRadioButtonCustomized,
  MuiBreadcrumbs: MuiBreadcrumbsCustomized,
  MuiOutlinedInput: MuiOutlinedInputCustomized,
  MuiInputLabel: MuiInputLabelCustomized,
  MuiTab: MuiTabCustomized,
  MuiTabs: MuiTabsCustomized,
  MuiAlert: MuiAlertCustomized,
  MuiFormControlLabel: MuiFormControlLabelCustomized,
  MuiAppBar: MuiAppBarCustomized,
  MuiDrawer: MuiDrawerCustomized,
  MuiListItemIcon: MuiListItemIconCustomized,
  MuiListItem: MuiListItemCustomized,
  MuiListItemButton: MuiListItemButtonCustomized,
  MuiAccordionSummary: MuiAccordionSummaryCustomized,
  MuiDataGrid: MuiDataGridCustomized,
  MuiTablePagination: MuiTablePaginationCustomized,
  MuiIconButton: MuiIconButtonCustomized,
  MuiInputAdornment: MuiInputAdornmentCustomized,
  MuiSkeleton: MuiSkeletonCustomized,
  MuiCheckbox: MuiCheckboxCustomized
};

export default components;
