import { Divider } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { PropsWithChildren } from 'react';

import { CardContentProps } from 'src/components/card/types';

import {
  StyledCardContent,
  StyledButton,
  StyledActions,
  StyledTitle,
  StyledChildren
} from './styles';

const SecondaryCardContent = ({
  title,
  onButtonClick,
  buttonText,
  children
}: PropsWithChildren<CardContentProps>) => (
  <StyledCardContent>
    <StyledTitle variant="h5" fontWeight="600">
      {title}
    </StyledTitle>
    <Divider orientation="vertical" flexItem />
    <StyledActions>
      <StyledChildren>{children}</StyledChildren>
      <StyledButton
        color="primary"
        onClick={onButtonClick}
        endIcon={<KeyboardArrowRight />}
      >
        {buttonText}
      </StyledButton>
    </StyledActions>
  </StyledCardContent>
);
export default SecondaryCardContent;
