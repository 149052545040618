import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import {
  AgreementRejectRequestType,
  AgreementSignatureResponse
} from 'src/modules/DocumentsPrivate/types';

const postAgreementReject = async (
  request: AgreementRejectRequestType
): Promise<AgreementSignatureResponse> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const { data: response } = await apiClient.post<AgreementSignatureResponse>(
    apiRoutes.agreementReject,
    request
  );

  return response;
};

export default postAgreementReject;
