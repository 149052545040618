export enum SmsStatusEnum {
  SUCCESS = 'SUCCESS',
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  SHARED_SECRET_TYPE_WRONG = 'SHARED_SECRET_TYPE_WRONG',
  SHARED_SECRET_VALUE_WRONG = 'SHARED_SECRET_VALUE_WRONG',
  CKK_ERROR = 'CKK_ERROR',
  NOTIFICATION_TOOL_ERROR = 'NOTIFICATION_TOOL_ERROR',
  UNAVAILABLE = 'UNAVAILABLE',
  VERIFICATION_BLOCKED = 'VERIFICATION_BLOCKED',
  STATUS_UNKNOWN = 'STATUS_UNKNOWN'
}
