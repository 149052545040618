import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react';

type LayoutContextProps = {
  isNavbarOpened: boolean;
  toggleNavbar: () => void;
};

export const LayoutContext = createContext<LayoutContextProps>({
  toggleNavbar: () => undefined,
  isNavbarOpened: false
});

export const LayoutContextProvider = ({ children }: PropsWithChildren) => {
  const [isNavbarOpened, setIsNavbarOpened] = useState(false);
  const toggleNavbar = useCallback(
    () => setIsNavbarOpened(!isNavbarOpened),
    [isNavbarOpened]
  );

  const contextValue = useMemo(
    () => ({
      isNavbarOpened,
      toggleNavbar
    }),
    [toggleNavbar, isNavbarOpened]
  );

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};
