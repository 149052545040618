import { Typography } from '@mui/material';

import DownloadButton from './DownloadButton';
import { StyledRowWrapper, StyledTitleWrapper } from './styles';

type DocumentTitleSectionType = {
  documentBlob: Blob | undefined | null;
  documentTitle: string;
};

const DocumentTitleSection = ({
  documentBlob,
  documentTitle
}: DocumentTitleSectionType) => (
  <StyledRowWrapper>
    <StyledTitleWrapper>
      <Typography variant="h3" fontWeight={400}>
        {documentTitle}
      </Typography>
    </StyledTitleWrapper>
    <DownloadButton documentTitle={documentTitle} documentBlob={documentBlob} />
  </StyledRowWrapper>
);

export default DocumentTitleSection;
