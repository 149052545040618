import { Buffer } from 'buffer';

import {
  DecryptedCEK,
  EncryptedCekAuthorizationCode
} from 'src/modules/DocumentsPrivate/types';

import getCEKKey from './getCEKKey';
import decryptCEKLite from './decryptCEKLite';
import convertCEK from './convertCEK';
import decryptSensitiveData from './decryptSensitiveData';

const decryptSensitiveContent = async (
  encryptedSensitiveContent: string,
  { data, initializationVector }: EncryptedCekAuthorizationCode,
  password: string
) => {
  const iv = Buffer.from(initializationVector, 'base64');
  const encryptedCek = Buffer.from(data, 'base64');

  const key = await getCEKKey(iv, password);

  const cek = await decryptCEKLite(key, iv, encryptedCek);

  const convertedCEK = convertCEK(Buffer.from(cek));

  const buffer = Buffer.from(encryptedSensitiveContent, 'base64');

  return {
    sensitiveData: await decryptSensitiveData(convertedCEK, buffer),
    convertedCEK: convertedCEK as DecryptedCEK
  };
};

export default decryptSensitiveContent;
