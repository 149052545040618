/* eslint-disable */
import { Buffer } from 'buffer';

import importRSAKey from './importRSAKey';
import importRSAJWKKey from './importRSAJWKKey';

export default async (data, stateObject) => {
  const privCryptoKey = await importRSAKey(data.decPrivKeyHex);
  const publicKeyASN1Structure = await importRSAJWKKey(privCryptoKey);

  stateObject.privateKeys.rsa.bufferKey = Buffer.from(
    data.decPrivKeyHex,
    'hex'
  );
  stateObject.privateKeys.rsa.cryptoKey = privCryptoKey;
  stateObject.privateKeys.rsa.shaName = data.safebagSHA;

  return publicKeyASN1Structure;
};
