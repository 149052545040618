import { KeyboardArrowRight } from '@mui/icons-material';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  StyledCheckBoxContainer,
  StyledFormContainer,
  StyledButtonContainer,
  StyledButton,
  StyledCheckbox
} from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentActiveDeliveryForm/styles';

type DocumentActiveDeliveryFormPropsType = {
  sendAcknowledgementEvent: () => void;
  isDocumentAcknowledged: boolean;
  handleDownloadDocument: () => void;
};

const DocumentActiveDeliveryForm = ({
  sendAcknowledgementEvent,
  isDocumentAcknowledged,
  handleDownloadDocument
}: DocumentActiveDeliveryFormPropsType) => {
  const [checked, setChecked] = useState(isDocumentAcknowledged);

  const handleCheckboxChange = () => {
    if (!checked) {
      setChecked(true);
      sendAcknowledgementEvent();
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <StyledFormContainer>
        <Typography variant="subtitle1" fontWeight={600}>
          {t(
            'PRIVATE_DOCUMENTS_DETAILS_SIGNING_CONFIRMATION_OF_ACKNOWLEDGEMENT'
          )}
        </Typography>
        <StyledCheckBoxContainer>
          <StyledCheckbox checked={checked} onClick={handleCheckboxChange} />
          <Typography variant="body2" fontWeight={400}>
            {t(
              'PRIVATE_DOCUMENTS_DETAILS_SIGNING_CONFIRMATION_OF_ACKNOWLEDGEMENT_CHECKBOX'
            )}
          </Typography>
        </StyledCheckBoxContainer>
        <StyledButtonContainer>
          <StyledButton
            disabled={!checked}
            variant="contained"
            onClick={handleDownloadDocument}
            endIcon={<KeyboardArrowRight />}
          >
            {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON')}
          </StyledButton>
        </StyledButtonContainer>
      </StyledFormContainer>
    </>
  );
};

export default DocumentActiveDeliveryForm;
