import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.custom.neutral[900],
      backgroundColor: theme.palette.custom.textColors[50],
      boxShadow: 'none',
      height: '36px',
      zIndex: 200,
      '& .MuiToolbar-root': {
        backgroundColor: theme.palette.custom.textColors[50],
        minHeight: '36px'
      }
    })
  }
};

export default componentConfiguration;
