import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';

type InfoTooltipProps = {
  infoToolTipTitle: string;
};

const InfoTooltip = ({ infoToolTipTitle }: InfoTooltipProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(infoToolTipTitle)} placement="right-start">
      <IconButton sx={{ paddingTop: '4px' }}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
