import { Link, styled } from '@mui/material';

export const StyledLink = styled(Link)(() => ({
  alignSelf: 'end',
  cursor: 'pointer',
  fontWeight: 600,
  textDecoration: 'none'
}));

export const StyledLinkWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main[600],
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  svg: {
    color: theme.palette.primary.main[600]
  }
}));

export const StyledDownloadContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  '.MuiTypography-root': {
    fontSize: 14
  }
}));

export const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('tablet')]: {
    justifyContent: 'flex-end'
  },
  [theme.breakpoints.only('mobile')]: {
    button: {
      paddingLeft: theme.spacing(0)
    }
  },
  button: {
    paddingRight: theme.spacing(1)
  }
}));
