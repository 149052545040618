import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DocumentDetailsStep from 'src/modules/DocumentsPrivate/components/documentDetailsStep';
import routes from 'src/constants/routes';
import ActiveDeliveryStateProvider from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import {
  DocumentContainer,
  DocumentContent
} from 'src/modules/DocumentsPrivate/components/documentContent';
import { appConfig } from 'src/constants/appConfig';

const BlockchainAddressDetailsPage = () => {
  const navigate = useNavigate();

  const { state } = useContext(PrivateDocumentContext);

  const { address: blockchainAddress } = useParams<{ address: string }>();

  useEffect(() => {
    if (state.isLoading) {
      return;
    }
    if (!state.document) {
      if (blockchainAddress) {
        if (appConfig.diploma) {
          navigate(
            routes.diplomaBlockchainAddress.replace(
              ':address',
              blockchainAddress
            )
          );
        } else {
          navigate(
            routes.blockchainAddress.replace(':address', blockchainAddress)
          );
        }
      } else {
        if (appConfig.diploma) {
          navigate(routes.diplomaBlockchainIndex);
        } else {
          navigate(routes.blockchainIndex);
        }
      }
    }
  }, [blockchainAddress, navigate, state.document, state.isLoading]);

  return (
    <DocumentContainer>
      <DocumentContent>
        <ActiveDeliveryStateProvider>
          <DocumentDetailsStep
            document={state.document}
            catalog={state.catalog}
            blockchainAddress={state.blockchainAddress}
          />
        </ActiveDeliveryStateProvider>
      </DocumentContent>
    </DocumentContainer>
  );
};

export default BlockchainAddressDetailsPage;
