import { AxiosError } from 'axios';

import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  FullIdentityLoadUserKeys,
  FullIdentityLoadUserKeysResponse
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';

const wait = (time = 2000) =>
  new Promise(resolve => {
    setTimeout(resolve, time);
  });

const postFullIdentityLoadUserKeys = async (
  request: FullIdentityLoadUserKeys,
  retries: number = 5
): Promise<FullIdentityLoadUserKeysResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  try {
    const { data: response } =
      await apiClient.post<FullIdentityLoadUserKeysResponse>(
        apiRoutes.fullIdentityLoadUserKeys,
        request
      );

    // Ignore status = INITIATED.
    if (response.status === 'INITIATED' && retries > 0) {
      await wait();
      return postFullIdentityLoadUserKeys(request, retries - 1);
    }

    if (['INPUT_DATA_INVALID', 'ERROR'].includes(response.statusCode)) {
      throw new ApiError(response.statusDetails, response.errorKey);
    }

    if (response.status === 'INITIATED') {
      throw new ApiError(response.status, response.status);
    }

    return response;
  } catch (error) {
    const axiosError = error as AxiosError<{ statusCode: string }>;

    if (axiosError.response?.data.statusCode) {
      throw new ApiError(
        axiosError.response.data.statusCode === 'INVALID_CODE'
          ? 'ERROR_INVALID_CODE'
          : axiosError.response.data.statusCode
      );
    }

    throw error;
  }
};

export default postFullIdentityLoadUserKeys;
