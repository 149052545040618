import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Typography
} from '@mui/material';

import { MenuListItemProps } from './types';

export const MenuListItem = ({
  ItemIcon,
  itemText,
  active,
  onClick
}: MenuListItemProps) => (
  <ListItem disablePadding onClick={onClick}>
    <ListItemButton selected={active}>
      <ListItemIcon>
        <ItemIcon />
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography variant="body2" fontWeight={active ? 600 : 400}>
            {itemText}
          </Typography>
        }
      />
    </ListItemButton>
  </ListItem>
);

export default MenuListItem;
