import * as yup from 'yup';

import {
  ERROR_EMPTY_FIELD,
  ERROR_WRONG_EMAIL_INPUT,
  ERROR_WRONG_PHONE_NUMBER
} from 'src/modules/UserIdentity/constants/errors';
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX
} from 'src/modules/UserIdentity/constants/form';

const schema = yup.object({
  firstName: yup.string().required(ERROR_EMPTY_FIELD),
  lastName: yup.string().required(ERROR_EMPTY_FIELD),
  documentNumber: yup.string().required(ERROR_EMPTY_FIELD),
  email: yup
    .string()
    .matches(new RegExp(EMAIL_REGEX), { message: ERROR_WRONG_EMAIL_INPUT })
    .required(ERROR_EMPTY_FIELD),
  phoneNumber: yup
    .string()
    .matches(new RegExp(PHONE_NUMBER_REGEX), {
      message: ERROR_WRONG_PHONE_NUMBER
    })
    .required(ERROR_EMPTY_FIELD),
  terms: yup.bool().oneOf([true], ERROR_EMPTY_FIELD)
});

export default schema;
