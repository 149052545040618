import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useContext } from 'react';

import { LayoutContext } from 'src/contexts/LayoutContext';
import routes from 'src/constants/routes';

import { StyledRegisterLink, StyledContainer } from './styles';

const UserAccountActions = () => {
  const navigate = useNavigate();
  const { toggleNavbar } = useContext(LayoutContext);

  return (
    <StyledContainer onClick={toggleNavbar}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(routes.documentAccessTypeLogin)}
      >
        <Trans>LOG_IN_LOG_IN_BUTTON</Trans>
      </Button>
      <StyledRegisterLink to={routes.documentAccessTypeRegister}>
        <Typography variant="subtitle2">
          <Trans>LOG_IN_REGISTRATION</Trans>
        </Typography>
      </StyledRegisterLink>
    </StyledContainer>
  );
};

export default UserAccountActions;
