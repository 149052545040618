import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import Card from 'src/components/card';
import useQueryParam from 'src/hooks/userQueryParam';
import routes from 'src/constants/routes';
import { PrivacyPolicy, TermsOfUse } from 'src/components/regulations';
import { RegulationsContainer } from 'src/components/regulationContainer';

import { StyledHeader, StyledContent, StyledCardContainer } from './styles';

const AccessTypeIndexPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useContext(UserIdentityContext);
  const blockchainAddress = useQueryParam('blockchainAddress');

  useEffect(() => {
    if (state.keys && state.uuid) {
      navigate(routes.documentAccessUserIndex.replace(':uuid', state.uuid));
    }
  }, [navigate, state.keys, state.uuid]);

  if (state.keys && state.uuid) {
    return null;
  }

  return (
    <>
      <StyledContent>
        <StyledHeader>
          <Typography variant="h2" fontWeight="600">
            {t('SELECT_ACCESS_TYPE_TITLE')}
          </Typography>
          <Typography variant="h6">{t('HOME_PRIVATE_DOCUMENTS')}</Typography>
        </StyledHeader>
        <StyledCardContainer>
          <Card
            variant="secondary"
            title={t('SELECT_ACCESS_TYPE_ACCESS_CODE')}
            buttonText={t('HOME_SELECT_BUTTON')}
            onButtonClick={() =>
              navigate(
                blockchainAddress
                  ? routes.blockchainAddress.replace(
                      ':address',
                      blockchainAddress
                    )
                  : routes.blockchainIndex
              )
            }
          >
            <span>{t('SELECT_ACCESS_TYPE_ACCESS_CODE_DESCRIPTION')}</span>
          </Card>
          <Card
            variant="secondary"
            title={t('SELECT_ACCESS_TYPE_LOG_IN')}
            buttonText={t('HOME_SELECT_BUTTON')}
            onButtonClick={() =>
              navigate(
                blockchainAddress
                  ? `${routes.documentAccessTypeLogin}?blockchainAddress=${blockchainAddress}`
                  : routes.documentAccessTypeLogin
              )
            }
          >
            <span>{t('SELECT_ACCESS_TYPE_LOG_IN_DESCRIPTION_1')}</span>
            <i onClick={() => navigate(routes.documentAccessTypeRegister)}>
              {t('SELECT_ACCESS_TYPE_LOG_IN_DESCRIPTION_2')}
            </i>
            .
          </Card>
        </StyledCardContainer>
      </StyledContent>
      <RegulationsContainer>
        <PrivacyPolicy /> <TermsOfUse />
      </RegulationsContainer>
    </>
  );
};

export default AccessTypeIndexPage;
