/* eslint-disable */

import decimalToHex from './decimalToHex';

const addMissingPaddingValue = value => {
  let hexVal = decimalToHex(value);
  while (hexVal.length % 8) {
    hexVal = `0${hexVal}`;
  }
  return hexVal;
};

export default responseAsn => {
  const firstSignResInteger = addMissingPaddingValue(
    responseAsn.SEQUENCE_0.INTEGER_0
  );
  const secondSignResInteger = addMissingPaddingValue(
    responseAsn.SEQUENCE_0.INTEGER_1
  );
  return { firstSignResInteger, secondSignResInteger };
};
