import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from 'src/assets/success.svg';
import { VerifyDocumentResponse } from 'src/modules/DocumentsPrivate/types';
import useDocumentPublisher from 'src/modules/DocumentsPrivate/hooks/useDocumentPublisher';
import DocumentHashTabs from 'src/modules/DocumentsPrivate/components/documentHashTabs';

import {
  StyledItemTitle,
  StyledContainer,
  StyledVerificationResultInfo,
  StyledDocumentPropertySection,
  StyledPropertyName,
  StyledPropertyValue
} from './styles';

type DocumentVerificationPositiveBannerProps = {
  document: VerifyDocumentResponse;
};

const DocumentVerificationPositiveBanner = ({
  document
}: DocumentVerificationPositiveBannerProps) => {
  const { t } = useTranslation();
  const { data: publisher } = useDocumentPublisher({
    publisherId: document.publisherId
  });

  return (
    <StyledContainer>
      <StyledVerificationResultInfo>
        <SuccessIcon />
        <StyledDocumentPropertySection>
          <StyledItemTitle>
            {t('DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_FIRST_CHECK')}
          </StyledItemTitle>
          <DocumentHashTabs document={document} />
        </StyledDocumentPropertySection>
      </StyledVerificationResultInfo>
      <StyledVerificationResultInfo>
        <SuccessIcon />
        <StyledDocumentPropertySection>
          <StyledItemTitle>
            {t(
              'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_SECOND_CHECK'
            )}
          </StyledItemTitle>
          <span>
            <StyledPropertyName variant="caption1">
              {t(
                'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_SECOND_CHECK_PUBLICATION_DATE'
              )}
              :{' '}
            </StyledPropertyName>
            <StyledPropertyValue variant="caption1">
              {new Date(document.publicationDate).toLocaleString()}
            </StyledPropertyValue>
          </span>
        </StyledDocumentPropertySection>
      </StyledVerificationResultInfo>
      <StyledVerificationResultInfo>
        <SuccessIcon />
        <StyledDocumentPropertySection>
          <StyledItemTitle>
            {t(
              'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_THIRD_CHECK'
            )}
          </StyledItemTitle>
          <span>
            <StyledPropertyName variant="caption1">
              {t(
                'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_THIRD_CHECK_PUBLISHER'
              )}
              :{' '}
            </StyledPropertyName>
            <StyledPropertyValue variant="caption1">
              {publisher ? publisher.name : ''}
            </StyledPropertyValue>
          </span>
        </StyledDocumentPropertySection>
      </StyledVerificationResultInfo>
    </StyledContainer>
  );
};

export default DocumentVerificationPositiveBanner;
