import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiTablePagination'] = {
  styleOverrides: {
    root: {
      '.MuiTablePagination-toolbar .MuiTablePagination-selectLabel': {
        display: 'flex'
      }
    },
    selectLabel: ({ theme }) => ({
      ...theme.typography.caption1,
      color: theme.palette.custom.neutral[800]
    }),
    select: ({ theme }) => ({
      ...theme.typography.caption1,
      fontWeight: 600,
      '&.MuiSelect-standard.MuiInputBase-input': {
        padding: theme.spacing(0, 1, 0, 0)
      }
    }),
    actions: ({ theme }) => ({
      '.MuiSvgIcon-root': {
        color: theme.palette.custom.neutral[700]
      }
    }),
    selectRoot: ({ theme }) => ({
      margin: theme.spacing(0, 3.25, 0, 1)
    }),
    displayedRows: ({ theme }) => ({
      ...theme.typography.caption1,
      color: theme.palette.custom.neutral[900]
    }),
    selectIcon: ({ theme }) => ({
      color: theme.palette.custom.neutral[700]
    }),
    menuItem: ({ theme }) => ({
      '&:hover': {
        background: theme.palette.custom.secondary[50]
      },
      '&.Mui-selected': {
        background: theme.palette.custom.secondary[50]
      }
    })
  }
};

export default componentConfiguration;
