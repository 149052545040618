import { useContext } from 'react';

import {
  DocumentPublicHistoryContext,
  DocumentPublicHistoryContextValue
} from 'src/modules/DocumentsPrivate/contexts/DocumentPublicHistoryContext';

const useDocumentPublicHistoryContext =
  (): DocumentPublicHistoryContextValue => {
    const context = useContext(DocumentPublicHistoryContext);
    if (!context) {
      throw new Error(
        'useDocumentPublicHistoryContext must be used within a DocumentHistoryProvider'
      );
    }
    return context;
  };

export default useDocumentPublicHistoryContext;
