import TauronHeightContainer from 'src/components/tauronHeightContainer';

import { ChainLoader } from '..';

const TauronLoader = () => (
  <TauronHeightContainer>
    <ChainLoader />
  </TauronHeightContainer>
);

export default TauronLoader;
