import { styled, keyframes } from '@mui/material';

const spin = keyframes`
  from {
    transform:rotate(360deg);
  }
  to {
    transform:rotate(0deg);
  }
`;

export const StyledAnimation = styled('div')<{
  width: number;
  height: number;
}>(({ width, height }) => ({
  position: 'relative',
  img: {
    width: width,
    height: height
  },
  div: {
    width: width * 1.2,
    height: height * 1.2,
    top: -width / 8,
    left: -width / 8
  }
}));

export const StyledAnimationDiv = styled('div')`
  border-radius: 50%;
  background: conic-gradient(from 0deg at 50% 50%, rgba(255, 0, 55, 0), white);
  position: absolute;

  animation-name: ${spin};
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
