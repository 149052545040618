import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import {
  DOCUMENT_TYPE,
  GetPrivateDocumentHistoryResponse
} from 'src/modules/DocumentsPrivate/types';

type GetDocumentHistoryParams = {
  blockchainAddress: string;
  publisherId: string;
  documentType: string;
};

export const getDocumentHistory = ({
  blockchainAddress,
  publisherId,
  documentType
}: GetDocumentHistoryParams) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetPrivateDocumentHistoryResponse>(
    documentType === DOCUMENT_TYPE.PUBLIC
      ? `${apiRoutes.publicDocumentHistory.replace(
          '{blockchainAddress}',
          blockchainAddress
        )}?publisherId=${encodeURIComponent(publisherId)}`
      : `${apiRoutes.privateDocumentHistory.replace(
          '{blockchainAddress}',
          blockchainAddress
        )}?publisherId=${encodeURIComponent(publisherId)}`
  );
};

export default getDocumentHistory;
