import { Box, Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import FormAlert from 'src/components/formAlert';
import {
  PrivateDocumentStepKeys,
  PrivateDocumentWizardSteps
} from 'src/modules/DocumentsPrivate/constants/stepper';
import SetBlockchainAddressPasswordStep from 'src/modules/DocumentsPrivate/components/setBlockchainAddressPasswordStep';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import routes from 'src/constants/routes';
import {
  Stepper,
  StepperHeader,
  StepperControls,
  StepperContainer,
  StepperHeaderContainer,
  StepperContent
} from 'src/components/stepper';
import useDocumentCatalog from 'src/modules/DocumentsPrivate/hooks/useDocumentCatalog';
import { useHandleDecryptDocument } from 'src/modules/DocumentsPrivate/hooks/handleDecryptDocument';
import { SelectBlockchainAddressPasswordForm } from 'src/types/form';
import { blockchainAddressPasswordSchema } from 'src/constants/form';
import { appConfig } from 'src/constants/appConfig';
import TauronLoader from 'src/components/loader/tauronLoader';
import CatalogError from 'src/components/returnableError/catalogError';
import EmptyResponseSuccess from 'src/components/returnableError/emptyResponseSuccess';

const BlockchainAddressPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));
  const [isDecryptionImpossible, setIsDecryptionImpossible] = useState(false);
  const { state, setBlockchainAddress, setCatalog } = useContext(
    PrivateDocumentContext
  );
  const { address: blockchainAddress } = useParams<{ address: string }>();

  const {
    isLoading: isLoadingDocumentCatalog,
    isError: isDocumentCatalogError
  } = useDocumentCatalog(blockchainAddress as string, {
    onSuccess: data => {
      if (blockchainAddress) {
        setBlockchainAddress(blockchainAddress);
        setCatalog(data);
      }
      if (appConfig?.diploma && !data?.encryptedSensitiveContent) {
        setIsDecryptionImpossible(true);
      }
    },
    enabled: !Boolean(state.blockchainAddress)
  });

  const formProps = useForm<SelectBlockchainAddressPasswordForm>({
    resolver: yupResolver(blockchainAddressPasswordSchema),
    defaultValues: {
      blockchainAddressPassword: state.accessCode
    },
    mode: 'onChange'
  });

  const handleDecryptDocument = useHandleDecryptDocument(formProps.getValues);

  const handleBackClick = () => {
    if (appConfig.diploma) {
      navigate(routes.diplomaBlockchainIndex);
    } else {
      navigate(routes.blockchainIndex);
    }
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleDecryptDocument)}>
        <FormAlert error={state.error} />
        <StepperContainer>
          {!isHorizontalStepper && (
            <Stepper
              stepTitleKeys={PrivateDocumentStepKeys}
              orientation="vertical"
              activeStep={PrivateDocumentWizardSteps.DOCUMENT_ACCESS_CODE}
            />
          )}
          <StepperContent>
            {isLoadingDocumentCatalog ? (
              <TauronLoader />
            ) : isDocumentCatalogError ? (
              <CatalogError onBackClick={handleBackClick} />
            ) : isDecryptionImpossible ? (
              <EmptyResponseSuccess onBackClick={handleBackClick} />
            ) : (
              <>
                <Box>
                  <StepperHeaderContainer>
                    <StepperHeader
                      subtitleKey="PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON"
                      titleKey="HOME_PRIVATE_DOCUMENTS"
                    />
                    {isHorizontalStepper && (
                      <Stepper
                        stepTitleKeys={PrivateDocumentStepKeys}
                        orientation="horizontal"
                        activeStep={
                          PrivateDocumentWizardSteps.DOCUMENT_ACCESS_CODE
                        }
                      />
                    )}
                  </StepperHeaderContainer>
                  <SetBlockchainAddressPasswordStep />
                </Box>
                <StepperControls>
                  <Button
                    onClick={handleBackClick}
                    disabled={state.isLoading}
                    startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
                    color="secondary"
                  >
                    {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
                  </Button>

                  <LoadingButton
                    type="submit"
                    disabled={state.isLoading || !state.blockchainAddress}
                    loading={state.isLoading}
                    loadingPosition="end"
                    endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                    variant="contained"
                  >
                    <span>
                      {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}
                    </span>
                  </LoadingButton>
                </StepperControls>
              </>
            )}
          </StepperContent>
        </StepperContainer>
      </form>
    </FormProvider>
  );
};

export default BlockchainAddressPage;
