import { SCROLL_DEBOUNCE } from './constants';

export const isInVerticalViewport = (
  viewportTop: number,
  viewportBottom: number,
  elementTop: number,
  elementBottom: number
) =>
  (elementTop >= viewportTop && elementTop < viewportBottom) ||
  (elementBottom > viewportTop && elementBottom <= viewportBottom) ||
  (elementTop < viewportTop && elementBottom > viewportBottom);

export const elementPercentageInVerticalViewport = (
  viewportTop: number,
  viewportBottom: number,
  elementTop: number,
  elementBottom: number
) => {
  if (
    !isInVerticalViewport(
      viewportTop,
      viewportBottom,
      elementTop,
      elementBottom
    )
  ) {
    return 0;
  }

  const viewportHeight = viewportBottom - viewportTop;
  const elementTopInViewport =
    elementTop < viewportTop ? viewportTop : elementTop;
  const elementBottomInViewport =
    elementBottom > viewportBottom ? viewportBottom : elementBottom;
  const elementHeightInViewport =
    elementBottomInViewport - elementTopInViewport;

  return elementHeightInViewport / viewportHeight;
};

export const getVisiblePageNumber = (
  rootSelect: string,
  pageSelector: string
) => {
  const rootBounds = document
    .querySelector(rootSelect)
    ?.getBoundingClientRect();
  const elementBounds = [
    ...(document.querySelectorAll(pageSelector) || [])
  ].map(element => element.getBoundingClientRect());

  if (!rootBounds || elementBounds.length === 0) {
    return null;
  }

  const result = elementBounds
    .map((element, index) => ({
      value: elementPercentageInVerticalViewport(
        rootBounds.top,
        rootBounds.bottom,
        element.top,
        element.bottom
      ),
      index
    }))
    .sort((a, b) => b.value - a.value);

  return result[0].index + 1;
};

let intervalId: number | undefined;

export const handleOnScrollDebounced = (
  callback: (page: number | null) => void
) => {
  if (intervalId) {
    clearTimeout(intervalId);
  }

  // @ts-ignore
  intervalId = setTimeout(() => {
    callback(getVisiblePageNumber('#pdf-preview', '.react-pdf__Page'));
  }, SCROLL_DEBOUNCE);
};

export const getFileSizeInKB = (digit?: number | null) => {
  if (digit && digit > 0) {
    return (digit / 1000).toFixed(2);
  }
  return 0;
};
