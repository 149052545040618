const transformArrayIntoObject = (array = '') =>
  JSON.parse(
    JSON.stringify(
      [...array].reduce(
        (prev, value, i) => ({
          ...prev,
          [i]: value
        }),
        {}
      )
    )
  );

export default transformArrayIntoObject;
