/* eslint-disable */

import importKey from './importKey';
import decrypt from './decrypt';
import bufferToHex from './bufferToHex';

export default async (encrypted, iv, key) => {
  const decryptedObject = {};
  const cryptoKey = await importKey(key);
  const dec = await decrypt(encrypted, iv, cryptoKey);

  decryptedObject.decryptedBuffer = dec;
  decryptedObject.decryptedHex = bufferToHex(dec);

  return decryptedObject;
};
