import { Buffer } from 'buffer';

import * as asn1js from 'asn1js';

import asn1jsDecoder from 'src/modules/Crypto/lib/ASN1Decoder';
import base64ToHex from 'src/modules/Crypto/lib/base64ToHex';
import { encodeEcies } from 'src/modules/Crypto/lib/ciphers/ecies-customized/ecies';

const encryptEventData = async ({
  publisherPublicKey,
  data,
  keyId,
  keyNumber
}) => {
  const encryptionANS1key_hex = base64ToHex(publisherPublicKey);

  const encryption_key_decoded = asn1jsDecoder.decodeHexString(
    encryptionANS1key_hex
  );

  const encryption_key =
    encryption_key_decoded.SEQUENCE_0.BIT_STRING_0.slice(2);

  const encrypted = await encodeEcies({
    plainData: data, // serialized file
    publicKey: encryption_key
  });

  const ans1struct = new asn1js.Sequence({
    name: 'Certificate',
    value: [
      new asn1js.Integer({
        name: 'tbsCertificate',
        value: 1
      }),

      new asn1js.Sequence({
        name: 'signatureAlgorithm',
        value: [
          new asn1js.OctetString({
            name: 'algorithm',
            valueHex: Buffer.from(keyId, 'base64')
          }),
          new asn1js.Integer({
            name: 'parameters',
            value: parseInt(keyNumber)
          })
        ]
      }),
      new asn1js.OctetString({
        name: 'signature',
        valueHex: Buffer.from(encrypted, 'hex')
      })
    ]
  });

  return Buffer.from(ans1struct.toBER(false)).toString('base64');
};

export default encryptEventData;
