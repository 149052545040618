import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import putVerifyFullIdentity from 'src/modules/UserIdentity/api/putVerifyFullIdentity';
import {
  VerifyFullIdentity,
  VerifyFullIdentityResponse
} from 'src/modules/UserIdentity/types/api';

const useVerifyUser = (
  options: UseMutationOptions<
    VerifyFullIdentityResponse,
    AxiosError,
    VerifyFullIdentity
  >
) =>
  useMutation<VerifyFullIdentityResponse, AxiosError, VerifyFullIdentity>(
    putVerifyFullIdentity,
    options
  );

export default useVerifyUser;
