import { useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from '@mui/material';

import { isDecryptCekFailedError } from 'src/modules/Crypto/lib/errors';
import AuthorizedPage from 'src/components/authorizedPage/AuthorizedPage';
import DocumentDetailsStep from 'src/modules/DocumentsPrivate/components/documentDetailsStep';
import ActiveDeliveryStateProvider from 'src/modules/DocumentsPrivate/components/documentSignatureForm/context/ActiveDeliveryStateProvider';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import useDocumentByKeys from 'src/modules/DocumentsPrivate/hooks/useDocumentByKeys';
import routes from 'src/constants/routes';
import {
  DocumentContainer,
  DocumentContent
} from 'src/modules/DocumentsPrivate/components/documentContent';
import { ReactComponent as AccessErrorIcon } from 'src/assets/accessError.svg';

import {
  StyledErrorContainer,
  StyledGoBackButton,
  AccessErrorTitle,
  AccessErrorDescription
} from './styles';

const UserDocumentBlockchainAddressDetailsPage = () => {
  const { t } = useTranslation();
  const { state, logout } = useContext(UserIdentityContext);
  const { address } = useParams<{ address: string }>();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logout();
    navigate(routes.documentAccessTypeLogin);
  }, [logout, navigate]);

  const { data, error, isError } = useDocumentByKeys(
    {
      keys: state.keys,
      blockchainAddress: address,
      hashFromHashFromPassword: state.hashFromHashFromPassword
    },
    {
      enabled: Boolean(state.keys && address),
      retry: false
    }
  );

  const GoBackButton = () => (
    <StyledGoBackButton
      color="secondary"
      startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
      onClick={() => {
        if (state.uuid) {
          navigate(routes.documentAccessUserIndex.replace(':uuid', state.uuid));
        }
      }}
    >
      {t('DOCUMENT_DETAILS_GO_BACK')}
    </StyledGoBackButton>
  );

  if (isDecryptCekFailedError(error) || isError) {
    return (
      <>
        {isError && <GoBackButton />}
        <StyledErrorContainer>
          <AccessErrorIcon />
          <AccessErrorTitle variant="h4">
            {t('ACCESS_ERROR_TITLE')}
          </AccessErrorTitle>
          <AccessErrorDescription variant="subtitle1">
            <Trans i18nKey="ACCESS_ERROR_DESCRIPTION">
              Ten dokument przeznaczony jest dla innej osoby. Aby uzyskać dostęp
              do tego dokumentu <Link onClick={handleLogout}>zaloguj</Link> na
              odpowiednie konto lub skontaktuj się z naszym zespołem pomocy
              technicznej{' '}
              <a href="mailto:support@billongroup.com">
                support@billongroup.com
              </a>
            </Trans>
          </AccessErrorDescription>
        </StyledErrorContainer>
      </>
    );
  }

  return (
    <AuthorizedPage>
      <GoBackButton />
      <DocumentContainer>
        <DocumentContent>
          <ActiveDeliveryStateProvider>
            <DocumentDetailsStep
              document={data?.document}
              catalog={data?.catalog}
              blockchainAddress={address}
            />
          </ActiveDeliveryStateProvider>
        </DocumentContent>
      </DocumentContainer>
    </AuthorizedPage>
  );
};

export default UserDocumentBlockchainAddressDetailsPage;
