/* eslint-disable */
import asn1creators from './asn1creators';
import encrypt from './encrypt';
import generateIv from './generateIv';
import generateRSAKey from './generateRSAKey';
import createRsaPkcs8Seq from './createRsaPkcs8Seq';
import createRsaSafeBag from './createRsaSafeBag';
import getSafeBagSequences from './getSafeBagSequences';
import generateKey from './generateKey';

export default async (password, kycGwId) => {
  console.log('===== GENERATE PRIVATE KEYS =====');
  console.log('----- 1. GENERATE RSA KEY -----');
  const rsaKey = await generateRSAKey();

  const res = await crypto.subtle.exportKey('pkcs8', rsaKey.privateKey);

  const rsaPKCS8Seq = await createRsaPkcs8Seq(res, password);
  const rsaSafebag = await createRsaSafeBag(rsaPKCS8Seq, kycGwId);
  const safebagSequences = await getSafeBagSequences(password, kycGwId);

  console.log('----- 2. GENERATE KEY SEQUENCE -----');
  const keySequence = asn1creators.createSAFECONTENTSequence([
    safebagSequences.safebagB256,
    safebagSequences.safebagB320,
    safebagSequences.safebagB384,
    safebagSequences.safebagB512,
    safebagSequences.safebagB256_ecies,
    safebagSequences.safebagB320_ecies,
    safebagSequences.safebagB384_ecies,
    safebagSequences.safebagB512_ecies,
    rsaSafebag
  ]);

  const keySequenceAsArrayBuffer = keySequence.toBER(false);

  const key = await generateKey();
  const iv = await generateIv();

  console.log('----- 3. GENERATE PRIVATE KEY -----');
  const privateKeys = await encrypt(
    iv,
    key,
    keySequenceAsArrayBuffer,
    'AES-GCM',
    true
  );

  console.log('----- 4. GENERATE KEK -----');
  const kekKey = await crypto.subtle.exportKey('raw', key);
  const kek = asn1creators.createKEKSequence(kekKey, iv);

  return {
    privateKeys,
    kek: kek.toBER(false)
  };
};
