/* eslint-disable */
import { Buffer } from 'buffer';
import signRSAMessage from './signRSAMessage';
import asn1creators from './asn1creators';

export default async (structure, stateObject) =>
  signRSAMessage(
    stateObject.privateKeys.rsa.cryptoKey,
    Buffer.from(structure, 'hex')
  ).then(res => {
    stateObject.publicKeysSequences.signs.RSA = asn1creators.createSignSequence(
      {
        sign: res,
        version: 1,
        shaName: Buffer.from(stateObject.privateKeys.rsa.shaName, 'hex')
      }
    );
  });
