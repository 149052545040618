/* eslint-disable unused-imports/no-unused-imports */
import * as asn1js from 'asn1js';

/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable */
import { Buffer } from 'buffer';

import { sha256 } from 'js-sha256';
import asn1jsDecoder from './ASN1Decoder';
import base64ToHex from './base64ToHex';
import { encodeEcies } from './ciphers/ecies-customized/ecies';
import { runECCrypto } from './ciphers/ecies/eccrypto';
import { protoSerializer } from './protocolbuffersRemoteSignature/protoSerializer';

/*
  TODO: Remove promises, refactor code to TS.
 */

const signatureStatesEnum = {
  UNDEFINED: 0,
  OK: 1,
  REJECTED: 2
};

const signatureTypesEnum = {
  UNDEFINED: 0,
  QUALIFIED: 1,
  ADVANCED: 2,
  OTHER: 3
};

const signatureAuthenticationTypesEnum = {
  UNDEFINED: 0,
  SMS: 1,
  TWO_FACTOR: 2,
  PASSWORD: 3,
  BIOMETRICS: 4
};

const encryptSignByECDSA = async (
  resolve,
  encryptedSignedDocument,
  decryptedPrivKey
) => {
  const eccrypto = runECCrypto('BrainpoolP512r1');
  const encryptedSign = await eccrypto.sign(
    Buffer.from(decryptedPrivKey, 'hex'),
    Buffer.from(sha256(encryptedSignedDocument), 'hex')
  );
  resolve(encryptedSign);
};

const signDocument = async ({
  blockchainAddress,
  document,
  user,
  keyId,
  keyNumber,
  publisherId,
  action,
  publisherPublicKey,
  signatureKey,
  deviceIp = '0.0.0.0'
}) => {
  deviceIp = deviceIp.split('.').map(Number);

  const protoObj = {
    errorCode:
      action === 'accept'
        ? signatureStatesEnum.OK
        : signatureStatesEnum.REJECTED, //UNDEFINED: 0, OK: 1, REJECTED: 2
    documentAddress: blockchainAddress,
    forwardedDocument: null,
    signerName: user.firstName || '',
    signerSurname: user.lastName || '',
    deviceIp: new Uint8Array(deviceIp),
    deviceId: '00:1B:44:11:3A:B7',
    date: Math.floor(Date.now() / 1000),
    signatureType: signatureTypesEnum.ADVANCED, //UNDEFINED: 0,  QUALIFIED: 1,  ADVANCED: 2,    OTHER: 3
    publicKey: Buffer.from(signatureKey),
    signatureAuthenticationType: signatureAuthenticationTypesEnum.TWO_FACTOR //UNDEFINED: 0, SMS: 1, TWO_FACTOR: 2, PASSWORD: 3, BIOMETRICS: 4
  };

  ///Decoding ANS.1 encoded encryption key
  const encryptionANS1key_hex = base64ToHex(publisherPublicKey);

  const encryption_key_decoded = asn1jsDecoder.decodeHexString(
    encryptionANS1key_hex
  );

  const encryption_key =
    encryption_key_decoded.SEQUENCE_0.BIT_STRING_0.slice(2);

  const generateDocumentInProtobuffPromise = new Promise(resolve => {
    protoSerializer.readForwardedDocumentRedux(protoObj, document, resolve);
  });

  const generateDocumentInProtobuff = await generateDocumentInProtobuffPromise;

  const encrypted = await encodeEcies({
    plainData: generateDocumentInProtobuff, // serialized file
    publicKey: encryption_key
  });

  const ans1struct = new asn1js.Sequence({
    name: 'Certificate',
    value: [
      new asn1js.Integer({
        name: 'tbsCertificate',
        value: 1
      }),

      new asn1js.Sequence({
        name: 'signatureAlgorithm',
        value: [
          new asn1js.OctetString({
            name: 'algorithm',
            valueHex: Buffer.from(keyId, 'base64')
          }),
          new asn1js.Integer({
            name: 'parameters',
            value: parseInt(keyNumber)
          })
        ]
      }),
      new asn1js.OctetString({
        name: 'signature',
        valueHex: Buffer.from(encrypted, 'hex')
      })
    ]
  });

  console.groupCollapsed(
    'Encrypted document ( ASN.1 -> BASE64) - WYSYŁANE DO API'
  );
  console.log(Buffer.from(ans1struct.toBER(false)).toString('base64'));
  console.groupEnd();

  return {
    responseSignature: 'ThyR1dkYWp4UHE5SE14RUM3NXNUV',
    encryptedSignedDocument: Buffer.from(ans1struct.toBER(false)).toString(
      'base64'
    ),
    publisherId: publisherId,
    blockchainAddress
  };
};

export default signDocument;
