import { generatePasswordEmailHash } from 'src/modules/Crypto/lib/ciphers/scryptWithASCII';
import generatePrivateKeys from 'src/modules/Crypto/lib/generatePrivateKeys';
import getMaintenanceSelfTest from 'src/modules/UserIdentity/api/getMaintenanceSelfTest';
import { ServicePayloadResponse } from 'src/modules/UserIdentity/types/api';
import generatePublicKeys from 'src/modules/Crypto/lib/generatePublicKeys';

type UseGenerateKeysFromPasswordProps = {
  email: string;
  password: string;
};

type UseGenerateKeysFromPasswordResult = {
  hashFromHashFromPassword: string;
  kek: ArrayBuffer;
  privateKeys: ArrayBuffer;
  publicKeys: ArrayBuffer;
} | null;

const generateKeysFromPassword = async ({
  email,
  password
}: UseGenerateKeysFromPasswordProps): Promise<UseGenerateKeysFromPasswordResult> => {
  const {
    data: { service }
  } = await getMaintenanceSelfTest();
  const kycGwId = (
    service.payload.find(
      s => s.name === 'KYCIdentityManagementService'
    ) as ServicePayloadResponse
  )?.id;

  if (!kycGwId) {
    return null;
  }

  const passwordHash = generatePasswordEmailHash(
    email,
    password
  ) as unknown as string;
  const { kek, privateKeys } = await generatePrivateKeys(passwordHash, kycGwId);
  const publicKeys = await generatePublicKeys(privateKeys, kek, passwordHash);

  return {
    kek,
    privateKeys,
    publicKeys,
    hashFromHashFromPassword: passwordHash
  };
};

export default generateKeysFromPassword;
