import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiStep'] = {
  styleOverrides: {
    horizontal: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        alignSelf: 'start'
      },
      [theme.breakpoints.only('mobile')]: {
        flex: 0
      }
    }),
    root: ({ theme }) => ({
      [theme.breakpoints.down('desktop')]: {
        padding: 0
      }
    })
  }
};

export default componentConfiguration;
