import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: theme => ({
    a: {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  })
};

export default componentConfiguration;
