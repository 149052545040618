import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  SendTauronSmsCode,
  SendTauronSmsCodeResponse
} from 'src/modules/UserIdentity/types/api';
import postSendTauronSmsCode from 'src/modules/UserIdentity/api/postSendTauronSmsCode';

const useSendTauronSmsCode = (
  options: UseMutationOptions<
    SendTauronSmsCodeResponse,
    AxiosError,
    SendTauronSmsCode
  >
) =>
  useMutation<SendTauronSmsCodeResponse, AxiosError, SendTauronSmsCode>(
    postSendTauronSmsCode,
    options
  );

export default useSendTauronSmsCode;
