import { styled } from '@mui/material';

export const StyledRegulations = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  gap: theme.spacing(4),
  fontSize: theme.typography.subtitle2.fontSize,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.spacing(13),
    gap: theme.spacing(3)
  },
  [theme.breakpoints.down('tablet')]: {
    marginTop: theme.spacing(5),
    gap: theme.spacing(1)
  }
}));
