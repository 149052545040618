import { AxiosRequestConfig } from 'axios';

import getApiClient from 'src/lib/getApiClient';
import { VerifyDocumentResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const postVerifyDocumentByFile = (
  file: File | null,
  config: AxiosRequestConfig
) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);
  const formData = new FormData();

  formData.append('documentFile', file || '');

  return apiClient.post<VerifyDocumentResponse>(
    apiRoutes.verifyDocumentWithFile,
    formData,
    config
  );
};

export default postVerifyDocumentByFile;
