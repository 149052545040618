import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  NationalNodeLogoutResponse,
  NationalNodeLogoutRequest
} from 'src/modules/UserIdentity/types/api';
import postNationalNodeLogout from 'src/modules/UserIdentity/api/postNationalNodeLogout';

const useNationalNodeLogoutUser = (
  options: UseMutationOptions<
    NationalNodeLogoutResponse,
    AxiosError,
    NationalNodeLogoutRequest
  > = {}
) =>
  useMutation<
    NationalNodeLogoutResponse,
    AxiosError,
    NationalNodeLogoutRequest
  >(postNationalNodeLogout, options);

export default useNationalNodeLogoutUser;
