/* eslint-disable */
import protobuf from 'protobufjs';

import decrypt from './decrypt';
import importKey from './importKey';
import stringToByteArray from './stringToByteArray';

export default async (convertCEK, encryptedSensitiveData) => {
  const key = await importKey(convertCEK.key);

  const decryptedData = await decrypt(
    encryptedSensitiveData,
    stringToByteArray(convertCEK.IVinDoubleSHA256),
    key
  );

  const root = await protobuf.load('/assets/UserIndexRecord.proto');
  const MetadataMessage = root.lookupType('Billon.UserIndexRecordProto');

  const buffer = new Uint8Array(decryptedData);

  let temp = MetadataMessage.decode(buffer);
  temp = temp.toJSON();
  return {
    blockchainAddress: temp.documentAddressString,
    title: temp.documentTitle,
    categoryHash: temp.categoryHash
  };
};
