import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  AgreementSendConfirmationCodeResponse,
  AgreementSendConfirmationCodeRequest
} from 'src/modules/UserIdentity/types/api';
import postAgreementSendConfirmationCode from 'src/modules/UserIdentity/api/postAgreementSendConfirmationCode';

const useAgreementSendCode = (
  options: UseMutationOptions<
    AgreementSendConfirmationCodeResponse,
    AxiosError,
    AgreementSendConfirmationCodeRequest
  >
) =>
  useMutation<
    AgreementSendConfirmationCodeResponse,
    AxiosError,
    AgreementSendConfirmationCodeRequest
  >(postAgreementSendConfirmationCode, options);

export default useAgreementSendCode;
