import { CardContent, Button, Typography, styled } from '@mui/material';

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2)
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('desktop')]: {
    alignSelf: 'flex-end'
  }
}));

export const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  [theme.breakpoints.down('desktop')]: {
    width: '100%'
  }
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  minWidth: theme.spacing(20),
  color: theme.palette.custom.neutral[900]
}));

export const StyledChildren = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[700]
}));
