export enum PrivateDocumentWizardSteps {
  DOCUMENT_BLOCKCHAIN_ADDRESS,
  DOCUMENT_ACCESS_CODE,
  DOCUMENT_DETAILS
}

export enum TauronPrivateDocumentWizardSteps {
  DOCUMENT_NUMBER,
  DOCUMENT_ACCESS_CODE,
  DOCUMENT_DETAILS
}

export const PrivateDocumentStepKeys = [
  'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_THE_DOCUMENT_BLOCKCHAIN_ADDRESS',
  'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_THE_ACCESS_CODE',
  'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_SHOW_DETAILS'
];

export enum TauronPrivateDocumentFirstStepKeyEnum {
  PESEL = 'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_PESEL',
  NIP = 'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_NIP',
  POSTAL_CODE = 'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_POSTAL_CODE'
}

export enum TauronPrivateDocumentStepInputLabel {
  PESEL = 'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_YOUR_PESEL',
  NIP = 'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_YOUR_NIP',
  POSTAL_CODE = 'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_YOUR_POSTAL_CODE'
}

export const TauronEnterAccessCodeFromSmsEmailLabel =
  'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_ENTER_THE_ACCESS_CODE_FROM_SMS_EMAIL';

export const TauronPrivateDocumentStepKeys = [
  TauronEnterAccessCodeFromSmsEmailLabel,
  'PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_SIDEBAR_SHOW_DOCUMENT'
];

export enum VerifyDocumentWizardSteps {
  VERIFY_DOCUMENT,
  VERIFICATION_RESULT_NEGATIVE,
  VERIFICATION_RESULT_POSITIVE,
  DOWNLOAD_DOCUMENT
}

export const VerifyDocumentStepKeysInitial = [
  'DOCUMENT_VERIFICATION_SIDEBAR_VERIFY_DOCUMENT',
  'DOCUMENT_VERIFICATION_SIDEBAR_VERIFCIATION_RESULT',
  'DOCUMENT_VERIFICATION_SIDEBAR_DOWNLOAD_DOCUMENT'
];

export const VerifyDocumentStepKeysPositive = [
  'DOCUMENT_VERIFICATION_SIDEBAR_VERIFY_DOCUMENT',
  'DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_TITLE',
  'DOCUMENT_VERIFICATION_SIDEBAR_DOWNLOAD_DOCUMENT'
];
export const VerifyDocumentStepKeysNegative = [
  'DOCUMENT_VERIFICATION_SIDEBAR_VERIFY_DOCUMENT',
  'DOCUMENT_VERIFICATION_NEGATIVE_VERIFICATION_RESULT_TITLE'
];
