import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiSkeleton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.custom.neutral[50]
    }),
    rectangular: ({ theme }) => ({
      borderRadius: theme.spacing(0.5)
    })
  }
};

export default componentConfiguration;
