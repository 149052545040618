import { PropsWithChildren } from 'react';

import AppConfiguration from 'src/types/appConfiguration';
import hasFeature from 'src/lib/hasFeature';

type FeatureProps = {
  feature: keyof AppConfiguration;
};

const Feature = ({ feature, children }: PropsWithChildren<FeatureProps>) =>
  hasFeature(feature) ? <>{children}</> : null;

export default Feature;
