import getApiClient from 'src/lib/getApiClient';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import {
  GetPartnerEncryptionKeyParams,
  GetPartnerEncryptionKeyResponse
} from 'src/modules/DocumentsPrivate/types';

const getPartnerEncryptionKey = ({
  publisherId
}: GetPartnerEncryptionKeyParams) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const params = {
    publisherId,
    referenceId: 'empty',
    keyMinValidityDate: new Date(
      new Date().setFullYear(new Date().getFullYear() + 3)
    ).toISOString()
  };

  return apiClient.get<GetPartnerEncryptionKeyResponse>(
    apiRoutes.partnerEncryptionKey,
    {
      params: params
    }
  );
};

export default getPartnerEncryptionKey;
