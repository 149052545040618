import { createContext, ReactNode } from 'react';

import useDocumentHistory from 'src/modules/DocumentsPrivate/hooks/useDocumentHistory';
import {
  DOCUMENT_TYPE,
  DocumentHistoryEntry
} from 'src/modules/DocumentsPrivate/types';

export interface DocumentHistoryContextValue {
  documentHistory: DocumentHistoryEntry[] | []; // Replace 'any' with your data type
  isLoading: boolean;
  isError: boolean;
  refetchDocumentHistory: () => void;
}

export const DocumentHistoryContext = createContext<
  DocumentHistoryContextValue | undefined
>(undefined);

interface DocumentHistoryProviderProps {
  blockchainAddress: string | undefined;
  publisherId: string | undefined;
  documentType?: 'PUBLIC' | 'PRIVATE';
  children: ReactNode;
}

export const DocumentHistoryProvider = ({
  children,
  blockchainAddress,
  publisherId,
  documentType = DOCUMENT_TYPE.PRIVATE
}: DocumentHistoryProviderProps) => {
  const {
    data: documentHistory = [],
    refetch: refetchDocumentHistory,
    isLoading,
    isError
  } = useDocumentHistory(
    blockchainAddress || '',
    publisherId || '',
    documentType,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!blockchainAddress
    }
  );

  return (
    <DocumentHistoryContext.Provider
      value={{ documentHistory, refetchDocumentHistory, isLoading, isError }}
    >
      {children}
    </DocumentHistoryContext.Provider>
  );
};
