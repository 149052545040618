import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornmentProps } from '@mui/material/InputAdornment/InputAdornment';

import hasFeature from 'src/lib/hasFeature';
import { ReactComponent as FilterListIcon } from 'src/assets/filterList.svg';
import useDocumentCategories from 'src/modules/DocumentsPrivate/hooks/useDocumentCategories';
import {
  AgreementStatusEnum,
  mapPropsToSignStatusTranslations
} from 'src/modules/DocumentsPrivate/constants/document';

import {
  StyledBlockchainAddressFilter,
  StyledClearButton,
  StyledClearIcon,
  StyledFiltersButtonsContainer,
  StyledFiltersCard,
  StyledFiltersRow,
  StyledPublicationDateIcon,
  StyledSearchButton
} from './styles';
import { PrivateDocumentListForm } from './form';

type FiltersCardProps = {
  onSearch: () => void;
  isLoading: boolean;
};

const DatePickerInputAdornment = ({
  children,
  ...props
}: InputAdornmentProps) => {
  const { watch, setValue } = useFormContext<PrivateDocumentListForm>();
  const publicationDate = watch('publicationDate');

  return (
    <InputAdornment {...props}>
      {publicationDate && (
        <StyledPublicationDateIcon
          onClick={() => setValue('publicationDate', '')}
        >
          <CloseIcon />
        </StyledPublicationDateIcon>
      )}
      {children}
    </InputAdornment>
  );
};

// const Test = () => <div>test</div>;
const FiltersCard = ({ onSearch, isLoading }: FiltersCardProps) => {
  const { t } = useTranslation();
  const { data: categories } = useDocumentCategories();
  const { watch, control, setValue, reset, resetField, register } =
    useFormContext<PrivateDocumentListForm>();
  const blockchainAddressValue = watch('blockchainAddress');

  return (
    <StyledFiltersCard>
      <StyledFiltersRow>
        <Controller
          control={control}
          name="publicationDate"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              slots={{
                inputAdornment: DatePickerInputAdornment
              }}
              label={t('PRIVATE_DOCUMENT_LIST_INPUT_PUBLISH_DATE')}
              value={value ? dayjs(value) : null}
              onChange={(newValue: Dayjs | null) => {
                if (newValue) {
                  onChange(newValue?.format('YYYY-MM-DD'));
                }
              }}
            />
          )}
        />

        {hasFeature('documentPrivateCategories') && (
          <FormControl>
            <InputLabel>
              {t('PRIVATE_DOCUMENT_LIST_SELECT_CATEGORY')}
            </InputLabel>
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value, onBlur, name } }) => (
                <Select
                  onClose={() => {
                    setTimeout(() => {
                      // @ts-ignore
                      document.activeElement?.blur();
                      onBlur();
                    }, 0);
                  }}
                  name={name}
                  onBlur={onBlur}
                  onChange={e => {
                    setTimeout(() => {
                      onChange(e.target.value);
                    }, 0);
                  }}
                  value={value}
                  label={t('PRIVATE_DOCUMENT_LIST_SELECT_CATEGORY')}
                  endAdornment={
                    value && (
                      <StyledClearIcon onClick={() => setValue('category', '')}>
                        <CloseIcon />
                      </StyledClearIcon>
                    )
                  }
                >
                  {(Array.isArray(categories) ? categories : []).map(
                    category => (
                      <MenuItem key={category.hash} value={category.name}>
                        {category.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        )}
        <FormControl>
          <InputLabel>{t('PRIVATE_DOCUMENT_LIST_SELECT_STATUS')}</InputLabel>
          <Controller
            control={control}
            name="status"
            render={({ field: { value, onChange, name, onBlur } }) => (
              <Select
                onClose={() => {
                  setTimeout(() => {
                    // @ts-ignore
                    document.activeElement?.blur();
                    onBlur();
                  }, 0);
                }}
                onBlur={onBlur}
                name={name}
                label={t('PRIVATE_DOCUMENT_LIST_SELECT_STATUS')}
                defaultValue=""
                value={value}
                onChange={e => {
                  setTimeout(() => {
                    onChange(e.target.value as AgreementStatusEnum | '');
                  }, 0);
                }}
                endAdornment={
                  value && (
                    <StyledClearIcon
                      onClick={() => {
                        resetField('status');
                      }}
                    >
                      <CloseIcon />
                    </StyledClearIcon>
                  )
                }
              >
                {Object.keys(AgreementStatusEnum)
                  .filter(k => k !== 'DEFAULT')
                  .map(key => (
                    <MenuItem value={key} key={key}>
                      {key}{' '}
                      {t(
                        mapPropsToSignStatusTranslations[
                          key as AgreementStatusEnum
                        ]
                      )}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </FormControl>
      </StyledFiltersRow>
      <StyledFiltersRow>
        <StyledBlockchainAddressFilter
          label={t('PRIVATE_DOCUMENT_DETAILS_BLOCKCHAIN_ADDRESS')}
          placeholder={
            t('PRIVATE_DOCUMENT_LIST_INPUT_BLOCKCHAIN_ADDRESS') || ''
          }
          variant="outlined"
          InputProps={{
            ...register('blockchainAddress'),
            endAdornment: blockchainAddressValue && (
              <StyledClearIcon
                onClick={() => {
                  resetField('blockchainAddress');
                }}
              >
                <CloseIcon />
              </StyledClearIcon>
            )
          }}
        />
      </StyledFiltersRow>
      <StyledFiltersButtonsContainer>
        <StyledClearButton
          disabled={isLoading}
          size="medium"
          onClick={() => {
            reset({
              publicationDate: '',
              status: '',
              category: '',
              blockchainAddress: ''
            });
            onSearch();
          }}
        >
          {t('PRIVATE_DOCUMENT_LIST_CLEAR_ALL')}
        </StyledClearButton>
        <StyledSearchButton
          disabled={isLoading}
          onClick={onSearch}
          startIcon={<FilterListIcon />}
          size="medium"
          variant="outlined"
        >
          {t('PRIVATE_DOCUMENT_LIST_FILTERS_SEARCH_BUTTON')}
        </StyledSearchButton>
      </StyledFiltersButtonsContainer>
    </StyledFiltersCard>
  );
};

export default FiltersCard;
