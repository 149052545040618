import { useTranslation } from 'react-i18next';

import { StyledLink } from './styles';

type Props = {
  linkKey: string;
  labelKey: string;
};

const RegulationLink = ({ linkKey, labelKey }: Props) => {
  const { t } = useTranslation();
  const link = t(linkKey);

  if (!link) {
    return null;
  }

  return (
    <StyledLink target="_blank" href={link}>
      {t(labelKey)}
    </StyledLink>
  );
};

export default RegulationLink;
