import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import {
  Stepper,
  StepperContainer,
  StepperContent
} from 'src/components/stepper';
import CenterContainer from 'src/components/centerContainer';
import { ChainLoader } from 'src/components/loader';
import { StyledHeader } from 'src/components/pageBanner/styles';
import useNationalNodeLoginUser from 'src/modules/UserIdentity/hooks/useNationalNodeLoginUser';
import routes from 'src/constants/routes';
import { NationalNodeResponseStatus } from 'src/modules/UserIdentity/constants/errors';
import { IDENTITY_SESSION_KEY } from 'src/modules/UserIdentity/constants/session';

import RedirectFormWrapper from './redirect-form-wrapper';

const NationalNodeLaunch = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sessionId, setSessionId] = useSessionStorage<string | null>(
    IDENTITY_SESSION_KEY,
    null
  );

  useEffect(() => {
    if (!sessionId) {
      setSessionId(uuid());
    }
  }, [sessionId, setSessionId]);

  const navigateToNationalNodeFailure = () =>
    navigate(`${routes.documentAccessTypeNationalNodeFailure}?reason=launch`);

  const { data } = useNationalNodeLoginUser(sessionId as string, {
    enabled: !!sessionId,
    onSuccess: response => {
      if (response?.status !== NationalNodeResponseStatus.SUCCESS) {
        navigateToNationalNodeFailure();
      }
    },
    onError: navigateToNationalNodeFailure
  });

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep:
      RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION
  };

  return (
    <StepperContainer>
      {!isHorizontalStepper && (
        <Stepper {...stepperProps} orientation="vertical" />
      )}
      <StepperContent>
        {isHorizontalStepper && (
          <Stepper {...stepperProps} orientation="horizontal" />
        )}

        <CenterContainer>
          <ChainLoader width={156} height={152} />
          <StyledHeader variant="h4">
            {t('NATIONAL_NODE_IN_PROGRESS')}
          </StyledHeader>
          {data?.request && (
            <RedirectFormWrapper responseHtmlForm={data.request} />
          )}
        </CenterContainer>
      </StepperContent>
    </StepperContainer>
  );
};

export default NationalNodeLaunch;
