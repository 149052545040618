import { useTranslation } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';

import { localeDateFormatter } from 'src/lib/formatters';
import {
  DecodedDocument,
  GetPrivateDocumentCatalogResponse
} from 'src/modules/DocumentsPrivate/types';
import useTauronDocumentPublisher from 'src/modules/DocumentsPrivate/hooks/useTauronDocumentPublisher';
import usePostVerifyDocumentByBlockchainAddress from 'src/modules/DocumentsPrivate/hooks/usePostVerifyDocumentByBlockchainAddress';
import { getFileSizeInKB } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/utils';

import TauronDocumentDetails from './tauronDocumentDetails';
import { DetailsElementType } from './tauronDocumentDetails/components/detailDataSection/DetailDataSection';

type DocumentDetailsStepType = {
  document?: DecodedDocument;
  catalog?: GetPrivateDocumentCatalogResponse;
  blockchainAddress?: string;
};

const TauronPrivateDocumentDetails = ({
  document,
  catalog,
  blockchainAddress
}: DocumentDetailsStepType) => {
  const { t } = useTranslation();
  const [documentBlob, setDocumentBlob] = useState<Blob | null>(null);
  const [documentTitle, setDocumentTitle] = useState<string>('');

  const {
    data: verificationData,
    isSuccess: isVerificationSuccess,
    isLoading: isVeryifyBlockchainAddress
  } = usePostVerifyDocumentByBlockchainAddress(
    { blockchainAddress },
    {
      enabled: !!blockchainAddress,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const { data: publisher, isLoading: isLoadingPublisher } =
    useTauronDocumentPublisher(
      {
        publisherId: verificationData?.publisherId || ''
      },
      {
        retry: false,
        enabled: isVerificationSuccess,
        refetchOnWindowFocus: false
      }
    );

  const publicationDate = useMemo(
    () => catalog?.documentInfo.documentBlockchainData?.publicationDate || '',
    [catalog]
  );

  useEffect(() => {
    if (document?.documentData && blockchainAddress) {
      setDocumentBlob(
        new Blob([new Uint8Array(document?.documentData).buffer], {
          type: `application/${document.documentType.toLowerCase()}`
        })
      );
      setDocumentTitle(document?.title);
    }
  }, [document, blockchainAddress]);

  const isLoading =
    !document || !catalog || isVeryifyBlockchainAddress || isLoadingPublisher;

  const detailsGroup: Array<DetailsElementType> = [
    {
      title: t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_CATEGORY'),
      value: document?.mainCategory
    },
    {
      title: t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_PUBLICATION_DATE'),
      value: localeDateFormatter(publicationDate)
    },
    {
      title: t('PRIVATE_DOCUMENT_PUBLISHED_BY'),
      value: publisher?.name
    },
    {
      title: t('PRIVATE_DOCUMENT_SOURCE_DOCUMENT'),
      value: document?.documentType
    },
    {
      title: t('PRIVATE_DOCUMENT_FILE_SIZE'),
      value: `${getFileSizeInKB(documentBlob?.size)} KB`
    }
  ];

  return (
    <TauronDocumentDetails
      isLoading={isLoading}
      detailsGroup={detailsGroup}
      documentTitle={documentTitle}
      documentBlob={documentBlob}
      isPrivate
    />
  );
};

export default TauronPrivateDocumentDetails;
