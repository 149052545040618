import { styled, Typography } from '@mui/material';

import BackgroundImage from 'src/assets/mainBackground.svg';
import Card from 'src/components/card';

export const StyledMainLayout = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  backgroundImage: `url('${BackgroundImage}')`,
  backgroundSize: 'cover',
  backgroundPositionY: '200px',
  backgroundRepeat: 'no-repeat',
  minHeight: '100%',
  flex: 1,
  display: 'flex',
  justifyContent: 'center'
}));

export const StyledMainLayoutContent = styled('div')(({ theme }) => ({
  width: '70%',
  marginTop: theme.spacing(10),
  [theme.breakpoints.only('tablet')]: {
    width: '100%'
  },
  [theme.breakpoints.only('mobile')]: {
    marginTop: theme.spacing(2),
    width: '100%'
  }
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  color: theme.palette.custom.neutral[900],
  marginBottom: theme.spacing(1),
  maxWidth: theme.spacing(85)
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.custom.textColors[700],
  [theme.breakpoints.only('mobile')]: {
    fontSize: theme.typography.subtitle2.fontSize
  }
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.only('mobile')]: {
    marginBottom: theme.spacing(3)
  },
  h1: {
    [theme.breakpoints.only('mobile')]: {
      fontSize: '26px'
    }
  }
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    maxWidth: theme.spacing(60)
  }
}));
