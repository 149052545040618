import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(8, 7.25),
      '&:last-child': {
        paddingBottom: theme.spacing(8)
      },

      [theme.breakpoints.down('desktop')]: {
        padding: theme.spacing(7.25, 9.25),

        '&:last-child': {
          paddingBottom: theme.spacing(7.25)
        }
      },
      [theme.breakpoints.down('tablet')]: {
        padding: theme.spacing(4),

        '&:last-child': {
          paddingBottom: theme.spacing(4)
        }
      }
    })
  }
};

export default componentConfiguration;
