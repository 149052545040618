import { styled, Box } from '@mui/material';

export const StyledContent = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '1400px',
    [theme.breakpoints.up(1440)]: {
      minWidth: '1400px'
    }
  }
}));
