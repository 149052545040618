import { FormProvider, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';

import { PrivateDocumentListForm } from 'src/modules/DocumentsPrivate/components/documentList/filtersCard/form';
import { mapSearchParamsToDefaultFormParams } from 'src/modules/DocumentsPrivate/components/documentList/utils';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';
import usePrivateDocumentList from 'src/modules/DocumentsPrivate/hooks/usePrivateDocumentList';
import Table from 'src/modules/DocumentsPrivate/components/documentList/table';
import Header from 'src/modules/DocumentsPrivate/components/documentList/header';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { SortModel } from 'src/modules/DocumentsPrivate/types';

const DocumentListContent = () => {
  const { state } = useContext(UserIdentityContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchDefaultParams = mapSearchParamsToDefaultFormParams(searchParams);
  const [searchForm, setSearchForm] = useState<PrivateDocumentListForm>({
    publisherName: searchDefaultParams.form.publisherName || '',
    publicationDate: searchDefaultParams.form.publicationDate || '',
    blockchainAddress: searchDefaultParams.form.blockchainAddress || '',
    status: searchDefaultParams.form.status || '',
    category: searchDefaultParams.form.category || ''
  });
  const [sortModel, setSortModel] = useState<SortModel>(
    searchDefaultParams.sort
  );
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    searchDefaultParams.pagination
  );
  const {
    data: privateDocumentListData,
    isLoading,
    isFetching
  } = usePrivateDocumentList(
    {
      uuid: state.uuid,
      kycGatewayId: state.kycGwId,
      keys: state.keys,
      mergedKeys: state.mergedKeys,
      page: paginationModel.page,
      limit: paginationModel.pageSize,
      signingStatus: searchForm.status as AgreementStatusEnum,
      publicationDate: searchForm.publicationDate,
      blockchainAddress: searchForm.blockchainAddress,
      publisherName: searchForm.publisherName,
      category: searchForm.category,
      order: sortModel.order,
      orderBy: sortModel.orderBy,
      hashFromHashFromPassword: state.hashFromHashFromPassword
    },
    {
      keepPreviousData: true,
      retry: false,
      onSuccess: () => {
        const query = {
          page: paginationModel.page.toString(),
          limit: paginationModel.pageSize.toString(),
          order: sortModel.order,
          orderBy: sortModel.orderBy,
          ...searchForm
        };
        const params = new URLSearchParams(query);

        Object.entries(query).forEach(([key, value]) => {
          if (value === '' || typeof value === 'undefined') {
            params.delete(key);
          }
        });
        setSearchParams(params);
      },
      enabled: Boolean(state.uuid && state.kycGwId && state.mergedKeys)
    }
  );

  const formProps = useForm<PrivateDocumentListForm>({
    defaultValues: searchDefaultParams.form
  });

  return (
    <FormProvider {...formProps}>
      <Header
        onSearch={() => setSearchForm(formProps.getValues())}
        isLoading={isLoading || isFetching}
      />
      <Table
        setPaginationModel={setPaginationModel}
        setSortModel={setSortModel}
        paginationModel={paginationModel}
        sortModel={sortModel}
        onSearch={() => setSearchForm(formProps.getValues())}
        privateDocumentListData={privateDocumentListData}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </FormProvider>
  );
};

export default DocumentListContent;
