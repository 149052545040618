import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

import FileUploadIcon from 'src/components/fileUpload/components/fileUploadIcon';
import { MAX_FILE_SIZE_MB } from 'src/components/fileUpload/constants';

import { FileInputProps } from './types';
import {
  StyledFileInput,
  StyledFileExtensionLabel,
  StyledFileLabel,
  StyledErrorMessage
} from './styles';

const FileInput = ({
  onChange,
  name,
  fileTypes = ['pdf'],
  maxSize = MAX_FILE_SIZE_MB,
  label,
  errorMessage
}: FileInputProps) => {
  const [file, setFile] = useState<File>();
  const [isDragging, setIsDragging] = useState(false);

  return (
    <FileUploader
      onDraggingStateChange={setIsDragging}
      name={name}
      handleChange={(file: File) => {
        setFile(file);
        onChange(file);
      }}
      fileOrFiles={file}
      types={fileTypes}
      dropMessageStyle={{ display: 'none' }}
    >
      <StyledFileInput isDragging={isDragging} isError={Boolean(errorMessage)}>
        <FileUploadIcon />

        <StyledFileLabel variant="body1">{label}</StyledFileLabel>
        {!errorMessage && (
          <StyledFileExtensionLabel variant="body2">
            {fileTypes.join(',').toUpperCase()} (max. {maxSize}MB)
          </StyledFileExtensionLabel>
        )}
        {errorMessage && (
          <StyledErrorMessage variant="body2">
            {errorMessage}
          </StyledErrorMessage>
        )}
      </StyledFileInput>
    </FileUploader>
  );
};

export default FileInput;
