import { styled, IconButton, ListItemButton } from '@mui/material';

export const StyledFullListContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(16.5),
  [theme.breakpoints.only('mobile')]: {
    marginTop: theme.spacing(0),
    '.MuiListItem-root': {
      height: theme.spacing(7.5)
    }
  }
}));

export const StyleClosedListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(17.2),
  marginLeft: theme.spacing(0.8)
}));

export const StyledIconButton = styled(IconButton)<{ active?: boolean }>(
  ({ theme, active }) => ({
    width: theme.spacing(6),
    backgroundColor: active ? theme.palette.custom.secondary[50] : 'inherit'
  })
);

export const StyledSubListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  maxWidth: theme.spacing(26),
  whiteSpace: 'break-spaces'
}));
