import getApiClient from 'src/lib/getApiClient';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { GetApiHealthCheck } from 'src/modules/DocumentsPrivate/types';

const getApiHealth = () => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetApiHealthCheck>(apiRoutes.health);
};

export default getApiHealth;
