import { Button, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import routes from 'src/constants/routes';
import UserAccountActions from 'src/components/navbar/components/userAccoutActions';
import SelectLanguage from 'src/components/selectLanguage';
import { LayoutContext } from 'src/contexts/LayoutContext';

import {
  HelpIcon,
  StyledDivider as Divider,
  StyledDrawerFooter,
  StyledActions,
  StyledClosedFooter,
  StyledOpenedFooter
} from './styles';

const NavbarFooter = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isNavbarOpened } = useContext(LayoutContext);
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));
  const navigate = useNavigate();

  return (
    <StyledDrawerFooter>
      {isNavbarOpened ? (
        <>
          {isMobile && <UserAccountActions />}
          <Divider variant="middle" />
          <StyledOpenedFooter>
            <StyledActions>
              <Button
                onClick={() => {
                  navigate(routes.help);
                }}
                variant="help"
                startIcon={<HelpIcon width="24px" height="24px" />}
              >
                {t('HOME_HELP')}
              </Button>
              {isMobile && <SelectLanguage />}
            </StyledActions>
          </StyledOpenedFooter>
        </>
      ) : (
        <Tooltip title={t('HOME_HELP')} placement="right">
          <StyledClosedFooter>
            <IconButton
              size="large"
              onClick={() => {
                navigate(routes.help);
              }}
            >
              <HelpIcon />
            </IconButton>
          </StyledClosedFooter>
        </Tooltip>
      )}
    </StyledDrawerFooter>
  );
};

export default NavbarFooter;
