import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  AgreementSendConfirmationCodeResponse,
  AgreementSendConfirmationCodeRequest
} from 'src/modules/UserIdentity/types/api';

const postAgreementSendConfirmationCode = async (
  request: AgreementSendConfirmationCodeRequest
): Promise<AgreementSendConfirmationCodeResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } =
    await apiClient.post<AgreementSendConfirmationCodeResponse>(
      apiRoutes.fullIdentityTwoFactorAuthorizationSend,
      request
    );

  return response;
};

export const postFullIdentityTwoFactorAuthorizationSend =
  postAgreementSendConfirmationCode;

export default postAgreementSendConfirmationCode;
