import { AccordionSummary, StepLabel, Stepper, styled } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import { StepConnector } from '@mui/material';

export const StyledSignIcon = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.success.main
}));

export const StyledRejectIcon = styled(Error)(({ theme }) => ({
  color: theme.palette.error.main
}));

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  margin: theme.spacing(4, 0, 6, 0)
}));

export const StyledStepConnector = styled(StepConnector)({
  '& .MuiStepConnector-lineVertical': {
    height: '72px'
  }
});

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    maxWidth: '100%',
    [theme.breakpoints.down('desktop')]: {
      textAlign: 'left'
    }
  }
}));

export const StyledWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const StyledAccordionWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
  '.MuiTypography-root': {
    flex: 0.3
  }
});
