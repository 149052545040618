import { Box, Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  VerifyDocumentStepKeysPositive,
  VerifyDocumentWizardSteps
} from 'src/modules/DocumentsPrivate/constants/stepper';
import SetBlockchainAddressPasswordStep from 'src/modules/DocumentsPrivate/components/setBlockchainAddressPasswordStep';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import routes from 'src/constants/routes';
import {
  Stepper,
  StepperHeaderContainer,
  StepperControls,
  StepperContainer,
  StepperContent,
  StepperHeader
} from 'src/components/stepper';
import FormAlert from 'src/components/formAlert';
import { useHandleDecryptDocument } from 'src/modules/DocumentsPrivate/hooks/handleDecryptDocument';
import { SelectBlockchainAddressPasswordForm } from 'src/types/form';
import { blockchainAddressPasswordSchema } from 'src/constants/form';

const VerifyBlockchainAddressAccessCodePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const { state } = useContext(PrivateDocumentContext);

  const formProps = useForm<SelectBlockchainAddressPasswordForm>({
    resolver: yupResolver(blockchainAddressPasswordSchema),
    defaultValues: {
      blockchainAddressPassword: state.accessCode
    }
  });

  useEffect(() => {
    if (state.isLoading) {
      return;
    }
    if (!state.blockchainAddress) {
      navigate(routes.verifyIndex);
    }
  }, [navigate, state.blockchainAddress, state.isLoading]);

  const handleDecryptDocument = useHandleDecryptDocument(formProps.getValues);

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleDecryptDocument)}>
        <FormAlert error={state.error} />
        <StepperContainer>
          {!isHorizontalStepper && (
            <Stepper
              stepTitleKeys={VerifyDocumentStepKeysPositive}
              orientation="vertical"
              activeStep={
                VerifyDocumentWizardSteps.VERIFICATION_RESULT_POSITIVE
              }
            />
          )}
          <StepperContent>
            <Box>
              <StepperHeaderContainer>
                <StepperHeader
                  subtitleKey="DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_DESCRIPTION"
                  titleKey="DOCUMENT_VERIFICATION_POSITIVE_VERIFICATION_RESULT_TITLE"
                />
                {isHorizontalStepper && (
                  <Stepper
                    stepTitleKeys={VerifyDocumentStepKeysPositive}
                    orientation="horizontal"
                    activeStep={
                      VerifyDocumentWizardSteps.VERIFICATION_RESULT_POSITIVE
                    }
                  />
                )}
              </StepperHeaderContainer>
              <SetBlockchainAddressPasswordStep />
            </Box>
            <StepperControls>
              <Button
                onClick={() => navigate(routes.index)}
                disabled={state.isLoading}
                color="secondary"
              >
                {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_CANCEL_BUTTON')}
              </Button>

              <LoadingButton
                disabled={state.isLoading}
                loading={state.isLoading}
                loadingPosition="end"
                type="submit"
                endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                variant="contained"
              >
                <span>
                  {t(
                    'PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON'
                  )}
                </span>
              </LoadingButton>
            </StepperControls>
          </StepperContent>
        </StepperContainer>
      </form>
    </FormProvider>
  );
};

export default VerifyBlockchainAddressAccessCodePage;
