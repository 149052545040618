import { useTranslation } from 'react-i18next';

import useKeyLock from 'src/hooks/useCapsLock';

import { StyledWrapper, StyledTypography } from './styles';

const CapsLockLabel = () => {
  const { t } = useTranslation();
  const capsLock = useKeyLock();

  return (
    <StyledWrapper>
      {capsLock && (
        <StyledTypography variant="buttonMedium" fontWeight="200">
          {t('FORM_CAPSLOCK_ON')}
        </StyledTypography>
      )}
    </StyledWrapper>
  );
};

export default CapsLockLabel;
