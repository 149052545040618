import { styled } from '@mui/material';

import hasFeature from 'src/lib/hasFeature';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.only('tablet')]: {
    marginBottom: theme.spacing(0)
  },
  [theme.breakpoints.only('mobile')]: {
    marginBottom: theme.spacing(0),
    textAlign: 'center'
  },
  h6: {
    color: theme.palette.custom.textColors[800]
  }
}));

export const StyledEmphazise = styled('span')(
  ({ theme }) =>
    hasFeature('tauron') && {
      color: theme.palette.primary.main
    }
);
