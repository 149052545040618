import { Buffer } from 'buffer';

import { DecryptedCEK } from 'src/modules/DocumentsPrivate/types';

import decryptDocumentContent from './/decryptDocumentContent';

const decodeDocument = async (
  convertedCEK: DecryptedCEK,
  encryptedDocumentContent: string
) => {
  const documentBuffer = Buffer.from(encryptedDocumentContent, 'base64');

  return await decryptDocumentContent(convertedCEK, documentBuffer);
};

export default decodeDocument;
