import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import theme from 'src/constants/theme';

import ApiCheck from './components/apiCheck';
import createResponsiveFonts from './constants/theme/utils';
import './fonts';

import './App.css';

createResponsiveFonts(theme);

const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('SITE_TITLE_TAURON_POLISH_ENERGY');
  }, [t]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ApiCheck />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
