import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { GetSettingsResponse } from 'src/modules/DocumentsPrivate/types';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const getDocumentSettings = () => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetSettingsResponse>(apiRoutes.settings);
};

const useSettings = (
  options?: UseQueryOptions<GetSettingsResponse, AxiosError>
) =>
  useQuery<GetSettingsResponse, AxiosError>(
    [queryKeys.getSettings],
    async () => {
      const { data: response } = await getDocumentSettings();

      return response;
    },
    options
  );

export default useSettings;
