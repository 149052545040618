/* eslint-disable */
import oids from './oids';
import Int10 from './Int10';
import stringCut from './stringCut';

export default class Stream {
  hexDigits = '0123456789ABCDEF';

  static b64Safe =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  static ellipsis = '\u2026';
  static reTimeS =
    /^(\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([01]\d|2[0-3])(?:([0-5]\d)(?:([0-5]\d)(?:[.,](\d{1,3}))?)?)?(Z|[-+](?:[0]\d|1[0-2])([0-5]\d)?)?$/;
  static reTimeL =
    /^(\d\d\d\d)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([01]\d|2[0-3])(?:([0-5]\d)(?:([0-5]\d)(?:[.,](\d{1,3}))?)?)?(Z|[-+](?:[0]\d|1[0-2])([0-5]\d)?)?$/;

  constructor(enc, pos) {
    if (enc instanceof Stream) {
      this.enc = enc.enc;
      this.pos = enc.pos;
    } else {
      // enc should be an array or a binary string
      this.enc = enc;
      this.pos = pos;
    }
  }

  get(pos) {
    if (pos === undefined) pos = this.pos++;
    if (pos >= this.enc.length)
      throw (
        'Requesting byte offset ' +
        pos +
        ' on a stream of length ' +
        this.enc.length
      );
    return typeof this.enc == 'string'
      ? this.enc.charCodeAt(pos)
      : this.enc[pos];
  }

  hexByte(b) {
    return (
      this.hexDigits.charAt((b >> 4) & 0xf) + this.hexDigits.charAt(b & 0xf)
    );
  }

  hexDump(start, end, raw) {
    let s = '';
    for (let i = start; i < end; ++i) {
      s += this.hexByte(this.get(i));
      if (raw !== true)
        switch (i & 0xf) {
          case 0x7:
            s += '  ';
            break;
          case 0xf:
            s += '\n';
            break;
          default:
            s += ' ';
        }
    }
    return s;
  }

  b64Dump(start, end) {
    const extra = (end - start) % 3;
    let s = '';
    let i;
    let c;

    for (i = start; i + 2 < end; i += 3) {
      c = (this.get(i) << 16) | (this.get(i + 1) << 8) | this.get(i + 2);
      s += Stream.b64Safe.charAt((c >> 18) & 0x3f);
      s += Stream.b64Safe.charAt((c >> 12) & 0x3f);
      s += Stream.b64Safe.charAt((c >> 6) & 0x3f);
      s += Stream.b64Safe.charAt(c & 0x3f);
    }

    if (extra > 0) {
      c = this.get(i) << 16;
      if (extra > 1) c |= this.get(i + 1) << 8;
      s += Stream.b64Safe.charAt((c >> 18) & 0x3f);
      s += Stream.b64Safe.charAt((c >> 12) & 0x3f);
      if (extra === 2) s += Stream.b64Safe.charAt((c >> 6) & 0x3f);
    }

    return s;
  }

  isASCII(start, end) {
    for (let i = start; i < end; ++i) {
      let c = this.get(i);
      if (c < 32 || c > 176) return false;
    }

    return true;
  }

  parseStringISO(start, end) {
    let s = '';
    for (let i = start; i < end; ++i) s += String.fromCharCode(this.get(i));

    return s;
  }

  parseStringUTF(start, end) {
    function ex(c) {
      // must be 10xxxxxx
      if (c < 0x80 || c >= 0xc0)
        throw new Error('Invalid UTF-8 continuation byte: ' + c);
      return c & 0x3f;
    }

    function surrogate(cp) {
      if (cp < 0x10000)
        throw new Error(
          'UTF-8 overlong encoding, codepoint encoded in 4 bytes: ' + cp
        );
      // we could use String.fromCodePoint(cp) but let's be nice to older browsers and use surrogate pairs
      cp -= 0x10000;
      return String.fromCharCode((cp >> 10) + 0xd800, (cp & 0x3ff) + 0xdc00);
    }

    let s = '';
    for (let i = start; i < end; ) {
      let c = this.get(i++);
      if (c < 0x80)
        // 0xxxxxxx (7 bit)
        s += String.fromCharCode(c);
      else if (c < 0xc0) throw new Error('Invalid UTF-8 starting byte: ' + c);
      else if (c < 0xe0)
        // 110xxxxx 10xxxxxx (11 bit)
        s += String.fromCharCode(((c & 0x1f) << 6) | ex(this.get(i++)));
      else if (c < 0xf0)
        // 1110xxxx 10xxxxxx 10xxxxxx (16 bit)
        s += String.fromCharCode(
          ((c & 0x0f) << 12) | (ex(this.get(i++)) << 6) | ex(this.get(i++))
        );
      else if (c < 0xf8)
        // 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx (21 bit)
        s += surrogate(
          ((c & 0x07) << 18) |
            (ex(this.get(i++)) << 12) |
            (ex(this.get(i++)) << 6) |
            ex(this.get(i++))
        );
      else
        throw new Error(
          'Invalid UTF-8 starting byte (since 2003 it is restricted to 4 bytes): ' +
            c
        );
    }

    return s;
  }

  parseStringBMP(start, end) {
    let str = '';
    for (let i = start; i < end; ) {
      const hi = this.get(i++);
      const lo = this.get(i++);
      str += String.fromCharCode((hi << 8) | lo);
    }
    return str;
  }

  parseTime(start, end, shortYear) {
    let s = this.parseStringISO(start, end);
    const m = (shortYear ? Stream.reTimeS : Stream.reTimeL).exec(s);
    if (!m) return 'Unrecognized time: ' + s;
    if (shortYear) {
      // to avoid querying the timer, use the fixed range [1970, 2069]
      // it will conform with ITU X.400 [-10, +40] sliding window until 2030
      m[1] = +m[1];
      m[1] += m[1] < 70 ? 2000 : 1900;
    }
    s = m[1] + '-' + m[2] + '-' + m[3] + ' ' + m[4];
    if (m[5]) {
      s += ':' + m[5];
      if (m[6]) {
        s += ':' + m[6];
        if (m[7]) s += '.' + m[7];
      }
    }
    if (m[8]) {
      s += ' UTC';
      if (m[8] !== 'Z') {
        s += m[8];
        if (m[9]) s += ':' + m[9];
      }
    }
    return s;
  }

  parseInteger(start, end) {
    let v = this.get(start);
    const neg = v > 127;
    const pad = neg ? 255 : 0;
    let len;
    let s = '';
    // skip unuseful bits (not allowed in DER)
    while (v === pad && ++start < end) v = this.get(start);
    len = end - start;
    if (len === 0) return neg ? '-1' : '0';
    // show bit length of huge integers
    if (len > 4) {
      s = v;
      len <<= 3;
      while (((s ^ pad) & 0x80) === 0) {
        s <<= 1;
        --len;
      }
      s = '(' + len + ' bit)\n';
    }
    // decode the integer
    if (neg) v = v - 256;
    const n = new Int10(v);
    for (let i = start + 1; i < end; ++i) n.mulAdd(256, this.get(i));
    return s + n.toString();
  }

  parseBitString(start, end, maxLength) {
    let unusedBit = this.get(start);
    let lenBit = ((end - start - 1) << 3) - unusedBit;
    let intro = '(' + lenBit + ' bit)\n';
    let s = '';
    for (let i = start + 1; i < end; ++i) {
      const b = this.get(i),
        skip = i === end - 1 ? unusedBit : 0;
      //eslint-disable-next-line no-bitwise
      for (let j = 7; j >= skip; --j) s += (b >> j) & 1 ? '1' : '0'; //eslint-disable-line no-bitwise
      if (s.length > maxLength) return intro + stringCut(s, maxLength);
    }
    return intro + s;
  }

  parseOctetString(start, end, maxLength) {
    if (this.isASCII(start, end))
      return stringCut(this.parseStringISO(start, end), maxLength);
    let len = end - start;
    let s = '(' + len + ' byte)\n';
    maxLength /= 2; // we work in bytes
    if (len > maxLength) end = start + maxLength;
    for (let i = start; i < end; ++i) s += this.hexByte(this.get(i));
    if (len > maxLength) s += Stream.ellipsis;
    return s;
  }

  parseOID(start, end, maxLength) {
    let s = '';
    let n = new Int10();
    let bits = 0;

    for (let i = start; i < end; ++i) {
      let v = this.get(i);
      n.mulAdd(128, v & 0x7f);
      bits += 7;
      // noinspection ExceptionCaughtLocallyJS
      if (!(v & 0x80)) {
        // finished
        if (s === '') {
          n = n.simplify();
          if (n instanceof Int10) {
            n.sub(80);
            s = '2.' + n.toString();
          } else {
            let m = n < 80 ? (n < 40 ? 0 : 1) : 2;
            s = m + '.' + (n - m * 40);
          }
        } else s += '.' + n.toString();
        if (s.length > maxLength) return stringCut(s, maxLength);
        n = new Int10();
        bits = 0;
      }
    }
    if (bits > 0) s += '.incomplete';
    if (typeof oids === 'object') {
      let oid = oids[s];
      if (oid) {
        if (oid.d) s += '\n' + oid.d;
        if (oid.c) s += '\n' + oid.c;
        if (oid.w) s += '\n(warning!)';
      }
    }
    return s;
  }
}
