import { Stack, Typography } from '@mui/material';

import { appConfig } from 'src/constants/appConfig';
import TauronWidthContainer from 'src/components/tauronWidthContainer';

import { StyledContentWrapper } from './styles';

const AppFooterTauron = () => (
  <StyledContentWrapper>
    <TauronWidthContainer>
      <Stack alignItems="flex-end">
        <Typography variant="caption2">{appConfig.copyright || ''}</Typography>
      </Stack>
    </TauronWidthContainer>
  </StyledContentWrapper>
);

export default AppFooterTauron;
