import { styled } from '@mui/material';

export const StyledContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(9),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(11),

  [theme.breakpoints.only('mobile')]: {
    gap: theme.spacing(4),
    marginTop: theme.spacing(2)
  }
}));

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
  h2: {
    color: theme.palette.custom.neutral[900]
  },
  h6: {
    color: theme.palette.custom.textColors[800],
    [theme.breakpoints.only('mobile')]: {
      fontSize: theme.typography.subtitle2.fontSize
    }
  }
}));

export const StyledCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(4),
  [theme.breakpoints.down('desktop')]: {
    padding: 0
  },
  i: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontStyle: 'normal',
    color: theme.palette.primary.main
  }
}));
