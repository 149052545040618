import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiListItemButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-selected': {
        backgroundColor: theme.palette.custom.secondary[50]
      }
    })
  }
};

export default componentConfiguration;
