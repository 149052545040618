import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { PublicDocument } from 'src/modules/DocumentsPrivate/publicTypes';
import getPublicDocumentByBlockChainAddress from 'src/modules/DocumentsPrivate/api/getPublicDocumentByBlockchainAddress';

const usePublicDocument = (
  blockchainAddress: string,
  token: string,
  sum: string,
  fetchSourceDocument: boolean,
  options?: UseQueryOptions<PublicDocument | undefined, AxiosError>
) =>
  useQuery<PublicDocument | undefined, AxiosError>(
    [queryKeys.usePublicDocument, { blockchainAddress, token, sum }],
    async () => {
      const { data: response } = await getPublicDocumentByBlockChainAddress({
        blockchainAddress,
        token,
        sum,
        fetchSourceDocument
      });

      return response;
    },
    options
  );

export default usePublicDocument;
