import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/UserIdentity/constants/queryKeys';
import { MaintenanceSettingsResponse } from 'src/modules/UserIdentity/types/api';
import getMaintenanceSettings from 'src/modules/UserIdentity/api/getMaintenanceSettings';

const useMaintenanceSettings = (
  options?: UseQueryOptions<MaintenanceSettingsResponse, AxiosError>
) =>
  useQuery<MaintenanceSettingsResponse, AxiosError>(
    [queryKeys.maintenanceSettings],
    async () => {
      const { data: response } = await getMaintenanceSettings();

      return response;
    },
    options
  );

export default useMaintenanceSettings;
