import { Button, Card, IconButton, styled, TextField } from '@mui/material';

export const StyledFiltersCard = styled(Card)(({ theme }) => ({
  height: '280px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  minHeight: 'unset'
}));

export const StyledFiltersRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),

  '& > div': {
    flex: 0.333333
  }
}));

export const StyledFiltersButtonsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const StyledBlockchainAddressFilter = styled(TextField)(({ theme }) => ({
  marginRight: theme.spacing(4),
  '.MuiButtonBase-root.MuiIconButton-root': {
    marginRight: '-2px',
    paddingRight: 0
  }
}));

export const StyledClearButton = styled(Button)(({ theme }) => ({
  ...theme.typography.buttonMedium,
  fontWeight: 600,
  padding: theme.spacing(0.75, 1),
  color: theme.palette.custom.neutral[900]
}));

export const StyledClearIcon = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.custom.neutral[700]
}));

export const StyledSearchButton = styled(Button)(({ theme }) => ({
  ...theme.typography.buttonMedium,
  fontWeight: 600,
  padding: theme.spacing(0.75, 2),
  color: theme.palette.custom.primary[600],
  borderColor: theme.palette.custom.primary[400]
}));

export const StyledPublicationDateIcon = styled(StyledClearIcon)(() => ({
  margin: 0,
  paddingRight: 0
}));
