import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  AgreementResendConfirmationCodeResponse,
  AgreementResendConfirmationCodeRequest
} from 'src/modules/UserIdentity/types/api';

const postAgreementConfirmSmsCode = async (
  request: AgreementResendConfirmationCodeRequest
): Promise<AgreementResendConfirmationCodeResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } =
    await apiClient.post<AgreementResendConfirmationCodeResponse>(
      apiRoutes.fullIdentityTwoFactorAuthorizationResend,
      request
    );

  return response;
};

export default postAgreementConfirmSmsCode;
