import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import getPartnerEncryptionKey from 'src/modules/DocumentsPrivate/api/getPartnerEncryptionKey';
import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { GetPartnerEncryptionKeyResponse } from 'src/modules/DocumentsPrivate/types';

const usePartnerEncryptionKey = (
  publisherId: string,
  options?: UseQueryOptions<
    GetPartnerEncryptionKeyResponse | undefined,
    AxiosError
  >
) =>
  useQuery<GetPartnerEncryptionKeyResponse | undefined, AxiosError>(
    [queryKeys.useDocumentCategories],
    async () => {
      const { data: response } = await getPartnerEncryptionKey({ publisherId });

      return response;
    },
    options
  );

export default usePartnerEncryptionKey;
