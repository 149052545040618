import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import PasswordStrengthIndicator from 'src/modules/UserIdentity/components/passwordStrengthIndicator';

const FormPasswordStrengthIndicator = ({
  children,
  name
}: PropsWithChildren<{ name: string }>) => {
  const { watch } = useFormContext();
  const value = watch(name);

  return (
    <>
      {children}
      {value && <PasswordStrengthIndicator value={value} />}
    </>
  );
};

export default FormPasswordStrengthIndicator;
