import { Divider, useMediaQuery, useTheme } from '@mui/material';

import {
  Stepper,
  StepperHeaderContainer,
  StepperHeader
} from 'src/components/stepper';
import {
  TauronPrivateDocumentStepKeys,
  TauronPrivateDocumentWizardSteps
} from 'src/modules/DocumentsPrivate/constants/stepper';
import {
  DocumentNumberTypeEnum,
  parseDocumentNumberTypeToStepTitle
} from 'src/modules/UserIdentity/constants/identityParams';

import { StyledStepperHeaderWrapper } from './styles';

type ResponsiveStepperProps = {
  documentNumberType: DocumentNumberTypeEnum;
  activeStep: TauronPrivateDocumentWizardSteps;
  orientation: 'horizontal' | 'vertical';
};

const ResponsiveStepper = ({
  documentNumberType,
  activeStep,
  orientation
}: ResponsiveStepperProps) => {
  const theme = useTheme();
  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const stepTitleKeys = [
    parseDocumentNumberTypeToStepTitle(documentNumberType),
    ...TauronPrivateDocumentStepKeys
  ];

  if (orientation === 'horizontal') {
    return (
      <StepperHeaderContainer>
        {!isHorizontalStepper && (
          <StepperHeader titleKey="PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_LOGGING" />
        )}
        {isHorizontalStepper && (
          <>
            <Stepper
              stepTitleKeys={stepTitleKeys}
              orientation={orientation}
              activeStep={activeStep}
            />
            <Divider orientation={orientation} flexItem />
          </>
        )}
        {isHorizontalStepper && (
          <StyledStepperHeaderWrapper>
            <StepperHeader titleKey="PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_LOGGING" />
          </StyledStepperHeaderWrapper>
        )}
      </StepperHeaderContainer>
    );
  }

  return (
    <>
      {!isHorizontalStepper && (
        <Stepper
          stepTitleKeys={stepTitleKeys}
          orientation={orientation}
          activeStep={activeStep}
        />
      )}
      {!isHorizontalStepper && <Divider orientation={orientation} flexItem />}
    </>
  );
};

export default ResponsiveStepper;
