import { styled, Typography } from '@mui/material';

export const StyledDetailGroup = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));

export const StyledDocumentInformation = styled('div')({
  display: 'flex',
  whiteSpace: 'pre-wrap'
});

export const StyledDocumentInformationTitle = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.custom.textColors[800],
    marginRight: theme.spacing(1)
  })
);

export const StyledDocumentInformationValue = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.custom.neutral[900]
  })
);
