import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import TauronPublicDocumentDetails from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/TauronPublicDocumentDetails';
import routes from 'src/constants/routes';
import { PublicDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PublicDocumentContext';
import { DocumentContainer } from 'src/modules/DocumentsPrivate/components/documentContent';
import useQueryParam from 'src/hooks/userQueryParam';
import DocumentLoader from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/documentLoader';

const BlockchainAddressDetailsPage = () => {
  const navigate = useNavigate();
  const { state, setBlockchainAddress, setToken, setSum } = useContext(
    PublicDocumentContext
  );
  const { address: blockchainAddress } = useParams<{ address: string }>();
  const token = useQueryParam('token');
  const sum = useQueryParam('sum');

  useEffect(() => {
    if (!blockchainAddress || !sum || !token) {
      navigate(routes.publicDocumentIncorrectURL);
    }

    if (blockchainAddress) {
      setBlockchainAddress(blockchainAddress);
    }
    if (sum) {
      setSum(sum);
    }
    if (token) {
      setToken(token);
    }
  }, [
    blockchainAddress,
    token,
    sum,
    state.isLoading,
    setBlockchainAddress,
    setSum,
    setToken,
    navigate
  ]);

  if (blockchainAddress && token && sum) {
    return (
      <DocumentContainer>
        <TauronPublicDocumentDetails
          blockchainAddress={blockchainAddress}
          token={token}
          sum={sum}
        />
      </DocumentContainer>
    );
  }

  return DocumentLoader;
};

export default BlockchainAddressDetailsPage;
