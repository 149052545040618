import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { DocumentList } from 'src/modules/DocumentsPrivate/types';
import { FullIdentityLoadUserKeysResponse } from 'src/modules/UserIdentity/types/api';
import getDocumentList from 'src/modules/DocumentsPrivate/api/getDocumentList';
import { decryptDocumentMeta } from 'src/modules/DocumentsPrivate/utils';
import hasFeature from 'src/lib/hasFeature';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';

type UsePrivateDocumentListParams = {
  uuid?: string;
  kycGatewayId?: string;
  keys?: FullIdentityLoadUserKeysResponse;
  mergedKeys?: ArrayBuffer;
  page?: number;
  limit?: number;
  signingStatus?: AgreementStatusEnum;
  publicationDate: string;
  blockchainAddress: string;
  publisherName: string;
  category: string;
  order: string;
  orderBy: string;
  hashFromHashFromPassword?: string;
};

export type UsePrivateDocumentListResponse = {
  documentList: DocumentList;
  count: number;
};

const usePrivateDocumentList = (
  {
    uuid,
    kycGatewayId,
    keys,
    hashFromHashFromPassword,
    mergedKeys,
    page = 1,
    limit = 5,
    signingStatus,
    publicationDate,
    blockchainAddress,
    publisherName,
    category,
    order,
    orderBy
  }: UsePrivateDocumentListParams,
  options?: UseQueryOptions<UsePrivateDocumentListResponse, AxiosError>
) =>
  useQuery<UsePrivateDocumentListResponse, AxiosError>(
    [
      queryKeys.usePrivateDocumentList,
      {
        uuid,
        kycGatewayId,
        page,
        limit,
        signingStatus,
        publicationDate,
        blockchainAddress,
        category,
        order,
        orderBy,
        publisherName
      }
    ],
    async () => {
      if (!uuid || !kycGatewayId) {
        throw new Error('UUID_OR_KYC_GATEWAY_ID_NOT_SET');
      }

      const {
        data: { indexEntriesList: documentList, count }
      } = await getDocumentList({
        uuid,
        kycGatewayId,
        limit,
        page,
        signingStatus,
        publicationDate,
        blockchainAddress,
        category,
        order,
        orderBy,
        publisherName
      });

      if (hasFeature('mockEndpoints')) {
        return {
          count,
          documentList
        };
      }

      if (keys) {
        await Promise.all(
          documentList.map(async document => {
            if (keys && mergedKeys && hashFromHashFromPassword) {
              document.decryptedData = await decryptDocumentMeta({
                mergedKeys,
                document,
                userKeys: keys,
                hashFromHashFromPassword
              });
            }
          })
        );
      }

      return {
        count,
        documentList
      };
    },
    options
  );

export default usePrivateDocumentList;
