export const ERROR_WRONG_EMAIL_INPUT = 'ERROR_WRONG_EMAIL_INPUT';
export const ERROR_EMPTY_FIELD = 'ERROR_EMPTY_FIELD';

export const ERROR_WRONG_PHONE_NUMBER = 'PHONE_NUMBER_INVALID';

export const ERROR_PESEL_NUMBER_INVALID = 'PESEL_NUMBER_INVALID';

export const PRIVATE_DOCUMENT_PESEL_FIVE_LAST_CHARS =
  'PRIVATE_DOCUMENT_PESEL_FIVE_LAST_CHARS';

export const PRIVATE_DOCUMENT_NIP_STRUCTURE = 'PRIVATE_DOCUMENT_NIP_STRUCTURE';

export const PRIVATE_DOCUMENT_POSTAL_CODE = 'PRIVATE_DOCUMENT_POSTAL_CODE';

export const enum NationalNodeResponseStatus {
  ERROR = 'ERROR',
  FINISHED_ERR = 'FINISHED_ERR',
  FINISHED_OK = 'FINISHED_OK',
  INVALID_REQUEST = 'INVALID_REQUEST',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS'
}
