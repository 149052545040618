import { Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useCallback, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AxiosError } from 'axios';

import { Stepper, StepperContent, StepperHeader } from 'src/components/stepper';
import SetBlockchainAddressStep from 'src/modules/DocumentsPrivate/components/setBlockchainAddressStep';
import {
  PrivateDocumentStepKeys,
  PrivateDocumentWizardSteps
} from 'src/modules/DocumentsPrivate/constants/stepper';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import StepperControls from 'src/components/stepper/components/stepperControls';
import getCatalogByBlockchainAddress from 'src/modules/DocumentsPrivate/api/getCatalogByBlockchainAddress';
import { ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND } from 'src/modules/DocumentsPrivate/constants/errors';
import { validatedBlockChainAddress } from 'src/modules/DocumentsPrivate/constants/form';
import {
  StepperContainer,
  StepperHeaderContainer
} from 'src/components/stepper';
import routes from 'src/constants/routes';
import ApiError from 'src/lib/apiError';
import FormAlert from 'src/components/formAlert';
import { appConfig } from 'src/constants/appConfig';

interface SelectBlockchainAddressForm {
  blockchainAddress: string;
}

const schema = yup.object({
  blockchainAddress: validatedBlockChainAddress
});

const BlockchainAddressIndexPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));
  const { state, setBlockchainAddress, startLoading, setCatalog, setError } =
    useContext(PrivateDocumentContext);
  const { t } = useTranslation();
  const formProps = useForm<SelectBlockchainAddressForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      blockchainAddress: state.blockchainAddress
    }
  });
  const handleLoadDocumentCatalogClick = useCallback(() => {
    const { blockchainAddress } = formProps.getValues();

    (async () => {
      if (!blockchainAddress) {
        return;
      }

      if (state.error) {
        setError();
      }

      startLoading();

      try {
        const { data } = await getCatalogByBlockchainAddress(blockchainAddress);
        if (!data?.encryptedSensitiveContent) {
          setError(new ApiError(ERROR_BLOCKCHAIN_ADDRESS_NOT_FOUND));

          return;
        }

        setBlockchainAddress(blockchainAddress);
        setCatalog(data);

        if (appConfig.diploma) {
          navigate(
            routes.diplomaBlockchainAddress.replace(
              ':address',
              blockchainAddress
            )
          );
        } else {
          navigate(
            routes.blockchainAddress.replace(':address', blockchainAddress)
          );
        }
      } catch (err) {
        const error = err as AxiosError;
        setError(new ApiError(error.message));
      }
    })();
  }, [
    formProps,
    navigate,
    setBlockchainAddress,
    setCatalog,
    setError,
    startLoading,
    state.error
  ]);

  useEffect(() => {
    setError();
  }, [setError]);

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleLoadDocumentCatalogClick)}>
        <FormAlert error={state.error} />
        <StepperContainer>
          {!isHorizontalStepper && (
            <Stepper
              stepTitleKeys={PrivateDocumentStepKeys}
              orientation="vertical"
              activeStep={
                PrivateDocumentWizardSteps.DOCUMENT_BLOCKCHAIN_ADDRESS
              }
            />
          )}
          <StepperContent>
            <Box>
              <StepperHeaderContainer>
                <StepperHeader
                  subtitleKey="PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON"
                  titleKey="HOME_PRIVATE_DOCUMENTS"
                />
                {isHorizontalStepper && (
                  <Stepper
                    stepTitleKeys={PrivateDocumentStepKeys}
                    orientation="horizontal"
                    activeStep={
                      PrivateDocumentWizardSteps.DOCUMENT_BLOCKCHAIN_ADDRESS
                    }
                  />
                )}
              </StepperHeaderContainer>
              <SetBlockchainAddressStep />
            </Box>
            <StepperControls>
              <LoadingButton
                type="submit"
                loadingPosition="end"
                loading={state.isLoading}
                disabled={state.isLoading}
                endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                variant="contained"
              >
                <span>
                  {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}
                </span>
              </LoadingButton>
            </StepperControls>
          </StepperContent>
        </StepperContainer>
      </form>
    </FormProvider>
  );
};

export default BlockchainAddressIndexPage;
