/* eslint-disable */

export default async (alghoritm = 'AES-GCM') =>
  crypto.subtle.generateKey(
    {
      name: alghoritm,
      length: 256
    },
    true,
    ['encrypt', 'decrypt']
  );
