import { FieldErrors } from 'react-hook-form/dist/types/errors';

export const getErrorText = (errors?: FieldErrors, name?: string) => {
  if (!errors || !name) {
    return null;
  }

  if (typeof errors[name] !== 'undefined') {
    return errors[name];
  }

  return null;
};
