import { Divider, styled } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export const StyledDrawerFooter = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

export const HelpIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  color: theme.palette.custom.neutral[700]
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.custom.neutral[100],
  [theme.breakpoints.only('mobile')]: {
    margin: theme.spacing(0, 1)
  }
}));

export const StyledActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(2),
  svg: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));

export const StyledClosedFooter = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  marginLeft: theme.spacing(0.8)
}));

export const StyledOpenedFooter = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}));
