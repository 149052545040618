import getApiClient from 'src/lib/getApiClient';
import { GetPrivateDocumentResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

type GetCatalogByBlockchainAddressParams = {
  blockchainAddress: string;
  catalogOfDocumentContent: string;
};

const getDocumentByBlockChainAddress = ({
  catalogOfDocumentContent,
  blockchainAddress
}: GetCatalogByBlockchainAddressParams) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetPrivateDocumentResponse>(
    `${apiRoutes.privateDocument.replace(
      '{blockchainAddress}',
      blockchainAddress
    )}?catalogOfDocumentContent=${encodeURIComponent(catalogOfDocumentContent)}`
  );
};

export default getDocumentByBlockChainAddress;
