import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  primary: {
    main: '#e2007a'
  },
  secondary: {
    main: '#011627'
  },
  warning: {
    main: '#E58300'
  },
  success: {
    main: '#00B769'
  },
  error: {
    main: '#FC4A4A'
  },
  custom: {
    gradient: {
      50: '#67C75E',
      900: '#5CA8E1'
    },
    primary: {
      50: '#F0F6FF',
      100: '#E1EBFE',
      200: '#C3D7FC',
      300: '#88AFFA',
      400: '#6A9AF8',
      500: '#457DFB',
      600: '#0059ff',
      700: '#1B35B3',
      800: '#0F2573',
      900: '#061536'
    },
    secondary: {
      50: '#EEF4FB',
      100: '#CBDFF2',
      200: '#97C0E5',
      300: '#63A0D7',
      400: '#408BCE',
      500: '#2E75B5',
      600: '#006181',
      700: '#004961',
      800: '#003041',
      900: '#0A0C11'
    },
    success: {
      50: '#EBFFF6',
      100: '#C0FFE6',
      200: '#97FED6',
      300: '#71FEAE',
      400: '#53DF73',
      500: '#3AC256',
      600: '#00B769',
      700: '#00894F',
      800: '#005C35',
      900: '#002E1A'
    },
    warning: {
      50: '#FFF8EF',
      100: '#FFE4C1',
      200: '#FFD095',
      300: '#FFC37A',
      400: '#FFA946',
      500: '#F88E00',
      600: '#E58300',
      700: '#AC6200',
      800: '#8F5200',
      900: '#563100'
    },
    error: {
      50: '#FFEEEE',
      100: '#FFD8D8',
      200: '#FFD8D8',
      300: '#FFA6A6',
      400: '#FF8888',
      500: '#FF7172',
      600: '#FC4A4A',
      700: '#DC0000',
      800: '#991B1B',
      900: '#7F1D1D'
    },
    shades: {
      0: '#FFFFFF',
      100: '#000000',
      200: '#FFFFFF'
    },
    textColors: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    },
    darkMode: {
      50: '#E9EFF4',
      100: '#A3B5C2',
      200: '#677F8F',
      300: '#4A5D6A',
      400: '#2F485A',
      500: '#273B49',
      600: '#223441',
      700: '#132839',
      800: '#092135',
      900: '#011627'
    },
    neutral: {
      50: '#EFF1F5',
      100: '#DFE3E8',
      200: '#CFD6E0',
      300: '#C1C9D5',
      400: '#ffeef7',
      500: '#7E8DA6',
      600: '#627692',
      700: '#435B86',
      800: '#002B57',
      900: '#092135'
    }
  }
};

export default palette;
