import { useFormContext } from 'react-hook-form';
import { useContext, useEffect } from 'react';

import StepTextFieldWithLabel from 'src/components/stepper/components/stepperTextFieldWithLabel';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import CapsLockLabel from 'src/components/capsLockLabel';
import { TauronEnterAccessCodeFromSmsEmailLabel } from 'src/modules/DocumentsPrivate/constants/stepper';

const blockchainAddressPasswordFieldName = 'blockchainAddressPassword';

type SetAccessCodeStepProps = {
  orderNumber?: string;
};

const SetAccessCodeStep = ({ orderNumber }: SetAccessCodeStepProps) => {
  const { setAccessCode, state } = useContext(PrivateDocumentContext);
  const { getValues, formState } = useFormContext();

  useEffect(() => {
    if (formState.isValid) {
      const { blockchainAddressPassword } = getValues();
      setAccessCode(blockchainAddressPassword);
    }
  }, [getValues, formState.isValid, setAccessCode]);

  return (
    <>
      <StepTextFieldWithLabel
        isDisabled={state.isLoading}
        fieldName={blockchainAddressPasswordFieldName}
        labelKey={TauronEnterAccessCodeFromSmsEmailLabel}
        inputLabelKey={TauronEnterAccessCodeFromSmsEmailLabel}
        orderNumber={orderNumber}
        trim
        autoCompleteDisabled
      />
      <CapsLockLabel />
    </>
  );
};

export default SetAccessCodeStep;
