import { AxiosError } from 'axios';

import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  VerifySecretCodeResponse,
  VerifySecretCode
} from 'src/modules/UserIdentity/types/api';

const putVerifySecretCode = async (
  request: VerifySecretCode
): Promise<VerifySecretCodeResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  try {
    const { data: response } = await apiClient.put<VerifySecretCodeResponse>(
      apiRoutes.verifySecretCode,
      request
    );

    return response;
  } catch (err) {
    const error = err as AxiosError<{
      statusCode: string;
    }>;

    if (error.response?.data.statusCode) {
      throw new Error(error.response?.data.statusCode);
    }

    throw error;
  }
};

export default putVerifySecretCode;
