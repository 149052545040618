import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import getPublicDocumentList from 'src/modules/DocumentsPrivate/api/getPublicDocumentList';
import hasFeature from 'src/lib/hasFeature';
import {
  PublicDocumentResponse,
  UsePublicDocumentListParams,
  UsePublicDocumentListResponse
} from 'src/modules/DocumentsPrivate/types';
import { default as mockPublicDocumentList } from 'src/modules/DocumentsPrivate/mockResponses/publicDocumentList.json';

const usePublicDocumentList = (
  {
    page = 1,
    limit = 5,
    publisherName,
    publicationDateFrom,
    publicationDateTo,
    title,
    category,
    blockchainAddress,
    validSince,
    validUntil,
    order,
    orderBy
  }: UsePublicDocumentListParams,
  options?: UseQueryOptions<UsePublicDocumentListResponse, AxiosError>
) =>
  useQuery<UsePublicDocumentListResponse, AxiosError>(
    [
      queryKeys.usePublicDocumentList,
      {
        page,
        limit,
        publisherName,
        publicationDateFrom,
        publicationDateTo,
        title,
        category,
        blockchainAddress,
        validSince,
        validUntil,
        order,
        orderBy
      }
    ],
    async () => {
      if (hasFeature('mockEndpoints')) {
        return {
          rows: mockPublicDocumentList.rows as PublicDocumentResponse[],
          count: mockPublicDocumentList.count
        };
      }

      const {
        data: { rows, count }
      } = await getPublicDocumentList({
        page,
        limit,
        publisherName,
        publicationDateFrom,
        publicationDateTo,
        title,
        category,
        blockchainAddress,
        validSince,
        validUntil,
        order,
        orderBy
      });

      return {
        rows,
        count
      };
    },
    options
  );

export default usePublicDocumentList;
