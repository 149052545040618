/* eslint-disable */
import { Buffer } from 'buffer';
import scrypt from 'scrypt-async';
import { sha256 } from 'js-sha256';

export const makeHashFromPassword = (password, salt) => {
  let hash;
  scrypt(
    password,
    salt,
    {
      N: 65536,
      r: 8,
      p: 1,
      dkLen: 32,
      encoding: 'base64'
    },
    derivedKey => {
      hash = derivedKey;
    }
  );
  return hash;
};

export const generatePasswordEmailHash = (email, password) =>
  makeHashFromPassword(email, Buffer.from(sha256(password), 'hex'));
