import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';

import RootPage from 'src/pages';
import routes from 'src/constants/routes';
import BlockchainAddressIndexPage from 'src/pages/blockchain-address';
import BlockchainAddressPage from 'src/pages/blockchain-address/address';
import BlockchainAddressDetailsPage from 'src/pages/blockchain-address/address/details';
import VerifyPageIndex from 'src/pages/verify';
import VerifyDocumentAddressPage from 'src/pages/verify/address';
import VerifyDocumentAccessCodePage from 'src/pages/verify/address/accessCode';
import AccessTypeIndexPage from 'src/pages/user-identity';
import BaseLayout from 'src/components/baseLayout';
import { PrivateDocumentContextProvider } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import AccessTypeLoginPage from 'src/pages/user-identity/login';
import AccessTypeRegisterPage from 'src/pages/user-identity/register';
import AccessTypeNationalNodeLaunchPage from 'src/pages/user-identity/national-node-launch';
import AccessTypeNationalNodeSuccessPage from 'src/pages/user-identity/national-node-success';
import AccessTypeNationalNodeFailurePage from 'src/pages/user-identity/national-node-failure';
import AccessTypeRegisterDetailsPage from 'src/pages/user-identity/register-details';
import AccessTypeVerifyPage from 'src/pages/user-identity/verify';
import AccessTypePasswordPage from 'src/pages/user-identity/password';
import RegistrationSuccessPage from 'src/pages/user-identity/success';
import RegistrationFailurePage from 'src/pages/user-identity/failure';
import LoginWithDocumentPage from 'src/pages/user-identity/login-select-document';
import BlockchainAddressList from 'src/pages/user-identity/documents';
import UserDocumentBlockchainAddressDetailsPage from 'src/pages/user-identity/documents/details/details';
import SmsVerificationPage from 'src/pages/user-identity/sms';
import CipherTest from 'src/pages/cipher-test';
import ServiceOutage from 'src/components/serviceOutage';
import HelpPage from 'src/components/help';
import { SuspenseLoader } from 'src/components/loader';
import PublicDocumentListPage from 'src/pages/document-list/publicList';
import BlockchainPublicAddressDetailsPage from 'src/pages/blockchain-address/address/public-details';
import { PublicDocumentContextProvider } from 'src/modules/DocumentsPrivate/contexts/PublicDocumentContext';
import { appConfig } from 'src/constants/appConfig';
import DefaultIncorrectDocumentPage from 'src/pages/default404/incorrectUrl';
import Default404Page from 'src/pages/default404/404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route path={routes.cipherTest} element={<CipherTest />} />
      <Route index element={<RootPage />} />
      <Route path={routes.help} element={<HelpPage />} />
      <Route path={routes.documentAccessType}>
        <Route index element={<AccessTypeIndexPage />} />
        <Route
          path={routes.documentAccessTypeLogin}
          element={<AccessTypeLoginPage />}
        />
        <Route
          path={routes.documentAccessType2Sms}
          element={<SmsVerificationPage />}
        />
        <Route
          path={routes.documentAccessTypeLoginSelectDocument}
          element={<LoginWithDocumentPage />}
        />
        <Route
          path={routes.documentAccessTypeRegister}
          element={<AccessTypeRegisterPage />}
        />
        <Route
          path={routes.documentAccessTypeNationalNodeLaunch}
          element={<AccessTypeNationalNodeLaunchPage />}
        />
        <Route
          path={routes.documentAccessTypeNationalNodeSuccess}
          element={<AccessTypeNationalNodeSuccessPage />}
        />
        <Route
          path={routes.documentAccessTypeNationalNodeFailure}
          element={<AccessTypeNationalNodeFailurePage />}
        />
        <Route
          path={routes.documentAccessTypeRegisterDetails}
          element={<AccessTypeRegisterDetailsPage />}
        />
        <Route
          path={routes.documentAccessTypeVerify}
          element={<AccessTypeVerifyPage />}
        />
        <Route
          path={routes.documentAccessTypePassword}
          element={<AccessTypePasswordPage />}
        />
        <Route
          path={routes.documentAccessTypeSuccess}
          element={<RegistrationSuccessPage />}
        />
        <Route
          path={routes.documentAccessTypeFailure}
          element={<RegistrationFailurePage />}
        />
        <Route
          path={routes.documentAccessUserIndex}
          element={<BlockchainAddressList />}
        />

        <Route
          path={routes.documentBlockchainAddress}
          element={<UserDocumentBlockchainAddressDetailsPage />}
        />
      </Route>
      <Route
        path={routes.verifyIndex}
        element={<PrivateDocumentContextProvider />}
      >
        <Route index element={<VerifyPageIndex />} />
        <Route
          path={routes.verifyBlockchainAddressAccessCode}
          element={<VerifyDocumentAccessCodePage />}
        />
        <Route
          path={routes.verifyBlockchainAddressResult}
          element={<VerifyDocumentAddressPage />}
        />
      </Route>

      {appConfig.diploma ? (
        <Route
          path={routes.diplomaBlockchainIndex}
          element={<PrivateDocumentContextProvider />}
        >
          <Route index element={<BlockchainAddressIndexPage />} />
          <Route
            path={routes.diplomaBlockchainAddress}
            element={<BlockchainAddressPage />}
          />
          <Route
            path={routes.diplomaBlockchainAddressDetails}
            element={<BlockchainAddressDetailsPage />}
          />
          <Route
            path={routes.diplomaIncorrectURL}
            element={<DefaultIncorrectDocumentPage />}
          />
        </Route>
      ) : (
        <Route
          path={routes.blockchainIndex}
          element={<PrivateDocumentContextProvider />}
        >
          <Route index element={<BlockchainAddressIndexPage />} />
          <Route
            path={routes.blockchainAddress}
            element={<BlockchainAddressPage />}
          />
          <Route
            path={routes.blockchainAddressDetails}
            element={<BlockchainAddressDetailsPage />}
          />
        </Route>
      )}

      {appConfig.documentPublic && (
        <Route
          path={routes.publicDocumentList}
          element={<PublicDocumentListPage />}
        />
      )}
      {appConfig.documentPublic && (
        <Route
          path={routes.publicDocumentBlockchainAddress}
          element={<PublicDocumentContextProvider />}
        >
          <Route index element={<BlockchainPublicAddressDetailsPage />} />
        </Route>
      )}
      {appConfig.documentPublic && (
        <Route
          path={routes.publicDocumentBlockchainAddressAlternative}
          element={<PublicDocumentContextProvider />}
        >
          <Route index element={<BlockchainPublicAddressDetailsPage />} />
        </Route>
      )}

      <Route path="*" element={<Default404Page />} />
    </Route>
  )
);

const routerWithServiceOutage = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<ServiceOutage />} />
      <Route path="*" element={<ServiceOutage />} />
    </Route>
  )
);
const routerWithLoader = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<SuspenseLoader />} />
      <Route path="*" element={<SuspenseLoader />} />
    </Route>
  )
);

export { router, routerWithServiceOutage, routerWithLoader };
