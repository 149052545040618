import { Theme, Typography, useMediaQuery } from '@mui/material';

import { StyledCardTitle, StyledDivider } from './styles';
import CardTitleProps from './types';

const CardTitle = ({ title }: CardTitleProps) => {
  const titleWords = title.split(' ');
  const smallerThanTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('tablet')
  );

  return (
    <>
      <StyledCardTitle>
        <Typography
          variant={smallerThanTablet ? 'h5' : 'h3'}
          fontWeight={600}
          component="span"
        >{`${titleWords[0]}`}</Typography>{' '}
        <Typography
          variant={smallerThanTablet ? 'h5' : 'h3'}
          fontWeight={400}
          component="span"
        >
          {titleWords[1]}
        </Typography>
      </StyledCardTitle>
      <StyledDivider />
    </>
  );
};

export default CardTitle;
