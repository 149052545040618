/* eslint-disable unused-imports/no-unused-imports */
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import routes from 'src/constants/routes';
import { PublicDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PublicDocumentContext';
import {
  DocumentContainer,
  DocumentContent
} from 'src/modules/DocumentsPrivate/components/documentContent';
import DocumentDetailsPublicStep from 'src/modules/DocumentsPrivate/components/documentDetailsStep/DocumentDetailsPublicStep';
import DocumentLoader from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/documentLoader';

const BlockchainPublicAddressDetailsPage = () => {
  const navigate = useNavigate();

  const { state, setBlockchainAddress } = useContext(PublicDocumentContext);

  const { address: blockchainAddress } = useParams<{ address: string }>();

  useEffect(() => {
    if (!blockchainAddress) {
      navigate(routes.publicDocumentIncorrectURL);
    }
    if (blockchainAddress) {
      setBlockchainAddress(blockchainAddress);
    }
  }, [
    blockchainAddress,
    navigate,
    setBlockchainAddress,
    state.document,
    state.isLoading
  ]);

  if (blockchainAddress) {
    return (
      <DocumentContainer>
        <DocumentContent>
          <DocumentDetailsPublicStep blockchainAddress={blockchainAddress} />
        </DocumentContent>
      </DocumentContainer>
    );
  }

  return DocumentLoader;
};

export default BlockchainPublicAddressDetailsPage;
