import { styled } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(1 / 2),
  marginTop: theme.spacing(20),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  'span:nth-of-type(even)': {
    backgroundColor: theme.palette.custom.textColors[50]
  },
  'span:nth-of-type(odd)': {
    backgroundColor: theme.palette.custom.textColors[100]
  }
}));
