import { AppConfigMapping, AppName } from 'src/constants/appConfig';
import hasFeature from 'src/lib/hasFeature';

const appName = process.env.REACT_APP_CONFIG as AppName;
const appConfig = AppConfigMapping[appName];

export const blockchainDocumentPasswordLength =
  hasFeature('tauron') && appConfig.blockchainDocumentPasswordLength;

export const PeselRegex =
  '^[0-9]{2}([02468]1|[13579][012])(0[1-9]|1[0-9]|2[0-9]|3[01])[0-9]{5}$';
export const BlockchainDocumentAddressRegex = /(^\w{69}$)|(^\w{113}$)/; // 69 or 113 address length
export const BlockchainDocumentPasswordLength =
  blockchainDocumentPasswordLength || 28;
export const DocumentLengthLength = 11;
export enum DocumentTypes {
  PDF = 'PDF'
}

export enum AgreementStatusEnum {
  ERROR = 'ERROR',
  WAITING_FOR_RECEIVER = 'WAITING_FOR_RECEIVER',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_DEPRECATED = 'ACCEPTED_DEPRECATED',
  REJECTED = 'REJECTED',
  REJECTED_DEPRECATED = 'REJECTED_DEPRECATED',
  ACCEPTED_WAITING_FOR_PUBLISHING = 'ACCEPTED_WAITING_FOR_PUBLISHING',
  REJECTED_WAITING_FOR_PUBLISHING = 'REJECTED_WAITING_FOR_PUBLISHING',
  DEFAULT = 'APPROVED'
}

export const mapPropsToSignStatusTranslations = {
  [AgreementStatusEnum.WAITING_FOR_RECEIVER]:
    'PRIVATE_DOCUMENT_LIST_STATUS_TO_SIGN',
  [AgreementStatusEnum.ACCEPTED_DEPRECATED]:
    'PRIVATE_DOCUMENT_LIST_STATUS_TO_SIGN',
  [AgreementStatusEnum.REJECTED_DEPRECATED]:
    'PRIVATE_DOCUMENT_LIST_STATUS_TO_SIGN',
  [AgreementStatusEnum.ACCEPTED]: 'PRIVATE_DOCUMENT_LIST_STATUS_ACCEPTED',
  [AgreementStatusEnum.REJECTED]: 'PRIVATE_DOCUMENT_LIST_STATUS_REJECTED',
  [AgreementStatusEnum.ACCEPTED_WAITING_FOR_PUBLISHING]:
    'ACCEPTED_WAITING_FOR_PUBLISHING',
  [AgreementStatusEnum.REJECTED_WAITING_FOR_PUBLISHING]:
    'REJECTED_WAITING_FOR_PUBLISHING',
  [AgreementStatusEnum.ERROR]: 'PRIVATE_DOCUMENT_LIST_STATUS_ERROR',
  [AgreementStatusEnum.DEFAULT]: 'PRIVATE_DOCUMENT_LIST_STATUS_DEFAULT'
};

export enum VerifyDocumentEnum {
  FILE = 'FILE',
  BLOCKCHAIN_ADDRESS = 'BLOCKCHAIN_ADDRESS'
}

export enum DocumentHashTabEnum {
  base58 = 'base58',
  base64 = 'base64',
  hex = 'hex'
}
