import { Typography, styled } from '@mui/material';

export const StyledWrapper = styled('div')({
  marginTop: '20px',
  paddingLeft: '14px',
  minHeight: '24px'
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main
}));

export default StyledWrapper;
