import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TauronPrivateDocumentDetails from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/TauronPrivateDocumentDetails';
import routes from 'src/constants/routes';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { DocumentContainer } from 'src/modules/DocumentsPrivate/components/documentContent';

const BlockchainAddressDetailsPrivatePage = () => {
  const navigate = useNavigate();

  const { state } = useContext(PrivateDocumentContext);

  useEffect(() => {
    if (!state.isLoading && !state.document) {
      navigate(routes.blockchainIndex);
    }
  }, [navigate, state.document, state.isLoading]);

  return (
    <DocumentContainer>
      <TauronPrivateDocumentDetails
        document={state.document}
        catalog={state.catalog}
        blockchainAddress={state.blockchainAddress}
      />
    </DocumentContainer>
  );
};

export default BlockchainAddressDetailsPrivatePage;
