import { Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Stepper,
  StepperHeader,
  StepperContainer,
  StepperContent,
  StepperControls,
  StepperHeaderContainer
} from 'src/components/stepper';
import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import routes from 'src/constants/routes';
import PageBanner from 'src/components/pageBanner';
import { ReactComponent as WarningIcon } from 'src/assets/warning.svg';
import useSkippedSteps from 'src/modules/UserIdentity/hooks/useSkippedSteps';

const RegistrationFailurePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const skippedSteps = useSkippedSteps();

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep: RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_CONFIGURATION,
    erroredSteps: [
      RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_CONFIGURATION
    ],
    skippedSteps
  };

  return (
    <>
      <StepperContainer>
        {!isHorizontalStepper && (
          <Stepper {...stepperProps} orientation="vertical" />
        )}
        <StepperContent>
          {isHorizontalStepper && (
            <>
              <StepperHeaderContainer>
                <StepperHeader
                  subtitleKey="REGISTRATION_ENTER_DATA_DESCRIPTION"
                  titleKey="USER_REGISTER"
                />
                <Stepper {...stepperProps} orientation="horizontal" />
              </StepperHeaderContainer>
            </>
          )}
          <PageBanner
            icon={<WarningIcon />}
            subtitleKey="REGISTRATION_NEGATIVE_RESULT"
            titleKey="REGISTRATION_NEGATIVE_RESULT_HEADER"
          />

          <StepperControls>
            <Button
              onClick={() => navigate(routes.documentAccessTypeLogin)}
              variant="contained"
            >
              {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_CANCEL_BUTTON')}
            </Button>
          </StepperControls>
        </StepperContent>
      </StepperContainer>
    </>
  );
};

export default RegistrationFailurePage;
