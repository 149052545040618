import Skeleton from '@mui/material/Skeleton';

import { TableLoader } from 'src/components/loader';

import { StyledContainer } from './styles';

const HEIGHT = 48;

const TableRowsLoader = () => (
  <StyledContainer>
    <Skeleton variant="rectangular" height={HEIGHT} />
    <Skeleton variant="rectangular" height={HEIGHT} />
    <Skeleton variant="rectangular" height={HEIGHT} />
    <Skeleton variant="rectangular" height={HEIGHT} />
    <Skeleton variant="rectangular" height={HEIGHT} />
    <TableLoader />
  </StyledContainer>
);

export default TableRowsLoader;
