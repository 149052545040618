import { FormControlLabel, FormHelperText, styled } from '@mui/material';

export const StyledUserNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(2),
    flexDirection: 'column'
  }
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  span: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.custom.textColors[800]
  }
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginTop: theme.spacing(-3),
  marginLeft: theme.spacing(2)
}));
