import getFullIdentityStep1Status from 'src/modules/UserIdentity/api/getFullIdentityStep1Status';
import {
  AddFullIdentityStep1StatusResponse,
  ProcessId
} from 'src/modules/UserIdentity/types/api';
import INTERVAL_THRESHOLD_MS from 'src/modules/UserIdentity/constants/api';
import ApiError from 'src/lib/apiError';

const tick = (
  processId: ProcessId,
  resolve: (
    value:
      | PromiseLike<AddFullIdentityStep1StatusResponse>
      | AddFullIdentityStep1StatusResponse
  ) => void,
  reject: (reason?: unknown) => void
) => {
  const timeout = setTimeout(async () => {
    try {
      const { data: response } = await getFullIdentityStep1Status(processId);

      if (response.status === 'ADD_FULL_ID_STEP1_FINISHED') {
        clearTimeout(timeout);
        resolve(response);
      } else if (
        ['ADD_FULL_ID_STEP1_ERROR', 'ERROR'].includes(response.status)
      ) {
        clearTimeout(timeout);
        reject(new ApiError(response.status, response.status));
      } else {
        tick(processId, resolve, reject);
      }
    } catch (error) {
      clearTimeout(timeout);
      reject(error);
    }
  }, INTERVAL_THRESHOLD_MS);
};

const waitForBinaryDataToSign = (processId: ProcessId) =>
  new Promise<AddFullIdentityStep1StatusResponse>((resolve, reject) => {
    tick(processId, resolve, reject);
  });

export default waitForBinaryDataToSign;
