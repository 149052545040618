import { Box } from '@mui/material';

import { ChainLoader } from 'src/components/loader';

import { StyledLoader } from './styles';

const DocumentLoader = (
  <Box
    sx={{
      width: '100%',
      minHeight: '50vh'
    }}
  >
    <StyledLoader>
      <ChainLoader />
    </StyledLoader>
  </Box>
);

export default DocumentLoader;
