import { styled } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  '.MuiAvatar-root': {
    fontSize: theme.typography.body2.fontSize
  },
  alignItems: 'center',
  textAlign: 'left'
}));

export const StyledTypographyContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));
