import { SstEnum } from 'src/modules/UserIdentity/constants/identityParams';
import transformArrayIntoObject from 'src/modules/UserIdentity/utils/transformMapIntoStringIndicesMap';
import transformStringIntoMap from 'src/modules/UserIdentity/utils/transformStringIntoMap';

export const parseDocumentNumber = (
  documentNumber: string,
  sharedSecretType: string
) => {
  if (sharedSecretType === SstEnum.P) {
    return transformArrayIntoObject(documentNumber);
  } else {
    return transformStringIntoMap(String(documentNumber).split('-').join(''));
  }
};
