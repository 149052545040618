import { styled, Typography } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center'
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.neutral[900],
  fontWeight: 600
}));
