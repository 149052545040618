import { styled, Theme } from '@mui/material';
import { Link } from 'react-router-dom';

import FormContainer from 'src/components/form/formContainer';
import { StepperControls } from 'src/components/stepper';

const maxWidthMixin = (theme: Theme) => ({
  width: '100%',
  [theme.breakpoints.up('desktop')]: {
    maxWidth: theme.spacing(69)
  }
});

export const StyledFormContainer = styled(FormContainer)(({ theme }) =>
  maxWidthMixin(theme)
);

export const StyledStepperControls = styled(StepperControls)(({ theme }) =>
  maxWidthMixin(theme)
);

export const StyledHeaderContainer = styled('div')(() => ({
  textAlign: 'center'
}));

export const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(9),
  gap: theme.spacing(7),
  [theme.breakpoints.only('mobile')]: {
    gap: theme.spacing(4),
    marginTop: theme.spacing(5)
  },
  [theme.breakpoints.only('tablet')]: {
    gap: theme.spacing(7)
  }
}));

export const StyledRegisterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1)
}));

export const StyledDividerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  ...maxWidthMixin(theme),
  '.MuiDivider-root': {
    backgroundColor: theme.palette.custom.neutral[200]
  },
  [theme.breakpoints.only('tablet')]: {
    marginTop: theme.spacing(5)
  }
}));

export const StyledRegisterLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  gap: theme.spacing(1),
  color: theme.palette.custom.neutral[900],
  i: {
    fontStyle: 'normal',
    textDecoration: 'underline',
    color: theme.palette.primary.main
  }
}));

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
