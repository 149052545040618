import { Button, useMediaQuery, Box, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as SmsSentSvg } from 'src/assets/smsSent.svg';
import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import FormAlert from 'src/components/formAlert';
import routes from 'src/constants/routes';
import useSendSmsCode from 'src/modules/UserIdentity/hooks/useSendSmsCode';
import FormTextField from 'src/components/form/formTextField';
import FormContainer from 'src/components/form/formContainer';
import {
  Stepper,
  StepperHeader,
  StepperContainer,
  StepperContent,
  StepperControls,
  StepperHeaderContainer
} from 'src/components/stepper';
import useResendSmsCode from 'src/modules/UserIdentity/hooks/useResendSmsCode';
import { SMS_CODE_THRESHOLD_MS } from 'src/modules/UserIdentity/constants/api';
import { ERROR_EMPTY_FIELD } from 'src/modules/UserIdentity/constants/errors';
import StyleFormContainer from 'src/components/stepper/components/stepperFormContent';
import useSkippedSteps from 'src/modules/UserIdentity/hooks/useSkippedSteps';

import { StyledResendCode, StyledSmsSendContainer } from './styles';

type VerifyForm = {
  smsCode: string;
};

const schema = yup.object({
  smsCode: yup.string().required(ERROR_EMPTY_FIELD)
});

const PasswordPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { processId } = useParams<{ processId: string }>();
  const [isSmsLocked, setSmsIsLocked] = useState(false);
  const formProps = useForm<VerifyForm>({
    resolver: yupResolver(schema)
  });
  const {
    mutate: resendSmsCode,
    error: resetPasswordError,
    isLoading: isLoadingSmsCode
  } = useResendSmsCode({
    onSuccess: () => {
      setSmsIsLocked(true);
      setTimeout(() => {
        setSmsIsLocked(false);
      }, SMS_CODE_THRESHOLD_MS);
    }
  });
  const { mutate, isLoading, error } = useSendSmsCode({
    onSuccess: () => {
      if (processId) {
        navigate(
          `${routes.documentAccessTypePassword.replace(
            ':processId',
            processId
          )}`
        );
      }
    }
  });

  const handleResendSmsCode = useCallback(() => {
    if (isLoadingSmsCode) {
      return;
    }

    if (processId) {
      resendSmsCode({ processId });
    }
  }, [processId, resendSmsCode, isLoadingSmsCode]);

  const { register } = formProps;
  const handleVerifySmsClick = useCallback(
    (values: VerifyForm) => {
      if (processId) {
        mutate({
          processId,
          secretCode: values.smsCode
        });
      }
    },
    [mutate, processId]
  );

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  const skippedSteps = useSkippedSteps();

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep: RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_VERIFICATION,
    skippedSteps
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleVerifySmsClick)}>
        <StyleFormContainer>
          <FormAlert error={error || resetPasswordError} />
          <StepperContainer>
            {!isHorizontalStepper && (
              <Stepper {...stepperProps} orientation="vertical" />
            )}
            <StepperContent>
              <Box>
                <StepperHeaderContainer>
                  <StepperHeader
                    subtitleKey="REGISTRATION_VERIFICATION_DESCRIPTION"
                    titleKey="USER_REGISTER"
                  />
                  {isHorizontalStepper && (
                    <Stepper {...stepperProps} orientation="horizontal" />
                  )}
                </StepperHeaderContainer>
                <FormContainer>
                  <FormTextField
                    fullWidth
                    variant="outlined"
                    label={t('REGISTRATION_ENTER_DATA_RESET_PASSWORD_SMS')}
                    InputProps={{ ...register('smsCode') }}
                  />
                  {isSmsLocked && (
                    <StyledSmsSendContainer>
                      <SmsSentSvg />
                      <Typography variant="subtitle2">
                        {t('REGISTRATION_VERIFICATION_RESEND_INFO')}
                      </Typography>
                    </StyledSmsSendContainer>
                  )}
                  {!isSmsLocked && (
                    <StyledResendCode
                      variant="body2"
                      onClick={handleResendSmsCode}
                    >
                      {t('REGISTRATION_VERIFICATION_RESEND')}
                    </StyledResendCode>
                  )}
                </FormContainer>
              </Box>
              <StepperControls>
                <Button
                  disabled={isLoading}
                  onClick={() =>
                    navigate(routes.documentAccessTypeRegisterDetails)
                  }
                  color="secondary"
                >
                  {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
                </Button>

                <LoadingButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="end"
                  endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                  type="submit"
                  variant="contained"
                >
                  <span>
                    {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}
                  </span>
                </LoadingButton>
              </StepperControls>
            </StepperContent>
          </StepperContainer>
        </StyleFormContainer>
      </form>
    </FormProvider>
  );
};

export default PasswordPage;
