import { AppBar, styled, Button, Box } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  minWidth: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down(970)]: {
    minHeight: '50px'
  },
  [theme.breakpoints.up(970)]: {
    minHeight: '100px'
  },
  boxShadow: '0 1px 5px 0 rgba(31, 31, 31, .2)'
}));

export const StyledContent = styled(Box)`
  align-self: center;
  display: flex;
  justify-content: space-between;
`;

export const StyleHelpBtn = styled(Button)(({ theme }) => ({
  transition: theme.transitions.create('hover-transition', {
    duration: theme.transitions.duration.shortest
  }),
  paddingRight: 0,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '& svg path': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  },
  [theme.breakpoints.down(970)]: { margin: 0, height: '50px' },
  [theme.breakpoints.up(970)]: {
    marginTop: '30px',
    height: '45px'
  }
}));
