/* eslint-disable */

import { Buffer } from 'buffer';
import xor from '../../xor';
import ObjectCaster from '../../objectCaster';
import bufferToHex from '../../bufferToHex';
import UInt8ArrayToHex from "../../UInt8ArrayToHex";

const sha256 = require('js-sha256').sha256;
const sha320 = require('js-sha512').sha384;
const sha384 = require('js-sha512').sha384;
const sha512 = require('js-sha512').sha512;

/* for decoding purposes only */
export function keyDerivation(data) {
  /* data: {
   * keyLength<integer> - keyDerivationLength
   * curveType<string> - name of the curve, for example 'brainpool256', 'brainpool384' etc
   * keyAgreement<string> - derive result from provided key and completely random one
   * tempPublicKey<string> - temporaryPublicKey(public key from completely random curvature)
   * }
   * */
  let keyAgrmnt = data.keyAgreement; // Z - value
  let tempPubKey = data.tempPublicKey; // Q - value

  let secretBasic = tempPubKey;
  console.log('keyAgrmnt', keyAgrmnt);
  console.log('keyAgrmnt.length', keyAgrmnt.length);
  //flagged
  if (keyAgrmnt.length >= 130) {
    switch (keyAgrmnt.length) {
      case 130:
        secretBasic += keyAgrmnt.slice(2, 66);
        break;
      case 162:
        secretBasic += keyAgrmnt.slice(2, 82);
        break;
      case 194:
        secretBasic += keyAgrmnt.slice(2, 98);
        break;
      case 258:
        secretBasic += keyAgrmnt.slice(2, 130);
        break;
    }
  } else {
    secretBasic += keyAgrmnt;
  }

  let counter = 1;
  let offset = 0;
  let result = '0'.repeat(data.keyLength);

  while (offset !== result.length) {
    let h = null;
    switch (data.curveType) {
      case 'brainpool256':
        h = sha256.create();
        break;
      case 'brainpool320':
        h = sha320.create();
        break;
      case 'brainpool384':
        h = sha384.create();
        break;
      case 'brainpool512':
        h = sha512.create();
        break;
      default:
        h = sha256.create();
        break;
    }
    h.update(Buffer.from(secretBasic, 'hex'));
    h.update(
      Buffer.from(
        '00000000'.substring(0, 8 - counter.toString(16).length) +
          counter.toString(16),
        'hex'
      )
    );
    let hash_result = h.hex();
    counter++;
    let added = Math.min(hash_result.length, result.length - offset);
    result = result.slice(0, offset) + result.slice(offset + added);
    result =
      result.slice(0, offset) +
      hash_result.slice(0, added) +
      result.slice(offset);
    offset += added;
  }
  return result;
}

const getHMac = data => {
  /* data {
   *  curveType<string> - for example 'brainpool256
   *  macKey<string> - macKey in a hex
   * }
   *
   * result: {
   *   n - hmac.create result
   *   verifySliceLength - sometimes we use it to cut unnecessary data from key
   * }
   * */
  let verifySliceLength = -1;
  let n = null;
  switch (data.curveType) {
    case 'brainpool256':
      n = sha256.hmac.create(Buffer.from(data.macKey, 'hex'));
      verifySliceLength = 64;
      break;
    case 'brainpool320':
      n = sha320.hmac.create(Buffer.from(data.macKey, 'hex'));
      verifySliceLength = 96;
      break;
    case 'brainpool384':
      n = sha384.hmac.create(Buffer.from(data.macKey, 'hex'));
      verifySliceLength = 96;
      break;
    case 'brainpool512':
      n = sha512.hmac.create(Buffer.from(data.macKey, 'hex'));
      verifySliceLength = 128;
      break;
    default:
      n = sha256.hmac.create(Buffer.from(data.macKey, 'hex'));
      verifySliceLength = 64;
      break;
  }
  return {
    n: n,
    verifySliceLength: verifySliceLength
  };
};

/* Split cipher string hex to the temporaryPublic and Cipher itself */
const splitECIESCipher = (cipherkey, curveType) => {
  let hexBitsLengthParam;
  switch (curveType) {
    case 'brainpool256':
    default:
      hexBitsLengthParam = 128;
      break;
    case 'brainpool320':
      hexBitsLengthParam = 160;
      break;
    case 'brainpool384':
      hexBitsLengthParam = 192;
      break;
    case 'brainpool512':
      hexBitsLengthParam = 256;
      break;
  }
  const temporaryPublic = cipherkey.substring(0, hexBitsLengthParam + 2);
  const cipher = cipherkey.substring(hexBitsLengthParam + 2);
  const checkLength = temporaryPublic.length + cipher.length;

  return {
    temporaryPublic,
    cipher,
    checkLength
  };
};

const determineCurvatureViaKeyLength = key => {
  /* key<string> - hex string of key(public or private)
   * */
  let type = '';
  switch (key.length) {
    case 64:
    case 130:
      type = 'brainpool256';
      break;
    case 80:
    case 162:
      type = 'brainpool320';
      break;
    case 96:
    case 194:
      type = 'brainpool384';
      break;
    case 128:
    case 258:
      type = 'brainpool512';
      break;
  }
  return type;
};

const getKeyDerivationSize = (curveType, plainTextLength) => {
  let constSize = -1;
  switch (curveType) {
    default:
    case 'brainpool256':
      constSize = 64;
      break;
    case 'brainpool320':
    case 'brainpool384':
      constSize = 96;
      break;
    case 'brainpool512':
      constSize = 128;
      break;
  }
  return constSize + plainTextLength;
};

export async function encodeEcies(data) {
  /* data {
   * plainData<string || uInt8Array> - hex string of data to be encoded
   * curveType: for example 'brainpool256', 'brainpool384'
   * publicKey<string> - hex string of publicKey
   * privateKey<string> - only for test purposes, for rand curvature
   * }
   * */
  let plain =
    typeof data.plainData === 'string'
      ? data.plainData
      : bufferToHex(data.plainData);

  console.group("ENCRYPTION DEBUG DATA");

  console.groupCollapsed("plain");
  console.log('%cLength:', "font-weight: bold", plain.length);
  console.log('%cContent:', "font-weight: bold", plain);
  console.groupEnd();

  let curveType =
    data.curveType || determineCurvatureViaKeyLength(data.publicKey);

  // console.log(curveType, 'type');
  //
  // console.log(determineCurvatureViaKeyLength(data.publicKey), 'curveType');
  // debugger;
  let curvature = data.privateKey
    ? ObjectCaster.getCurvature(curveType, data.privateKey)
    : ObjectCaster.getCurvature(curveType);



  let keyAgreement = await curvature.curvature
    .derive(curvature.privateKey, Buffer.from(data.publicKey, 'hex'))
    .then(res => {
      return res;
    });

  console.groupCollapsed("keyAgreement");
  console.info('%cLength:', "font-weight: bold", keyAgreement.length);
  console.log('%cContent:', "font-weight: bold", UInt8ArrayToHex(keyAgreement));
  console.groupEnd();

  let keyDerivationResult = keyDerivation({
    keyLength: getKeyDerivationSize(curveType, plain.length),
    curveType: curveType,
    keyAgreement: bufferToHex(keyAgreement),
    tempPublicKey: bufferToHex(curvature.publicKey)
  });

  console.groupCollapsed("keyDerivationResult");
  console.log('%cLength:', "font-weight: bold", keyDerivationResult.length);
  console.log('%cContent:', "font-weight: bold", keyDerivationResult);
  console.groupEnd()

  let macKey = keyDerivationResult.slice(0, 32);

  console.groupCollapsed("macKey")
  console.log('%cLength:', "font-weight: bold", macKey.length);
  console.log('%cContent:', "font-weight: bold", macKey);
  console.groupEnd()

  let cipherKey = keyDerivationResult.slice(32);

  console.groupCollapsed("cipherKey");
  console.log('%cLength:', "font-weight: bold", cipherKey.length);
  console.log('%cContent:', "font-weight: bold", cipherKey);
  console.groupEnd();
  console.groupEnd();

  let cipherText = xor(plain, cipherKey, plain.length);
  let hMac = getHMac({
    curveType: curveType,
    macKey: macKey
  });
  let n = hMac.n;
  n.update(Buffer.from(cipherText, 'hex'));
  n.update(new ArrayBuffer(8));
  const macRes = n.hex('');

  return bufferToHex(curvature.publicKey) + cipherText + macRes;
}

export async function decodeEcies(data) {
  /*
   * data:
   * cipherHex<string> - hex encoded cipher
   * privateKey<string> - hex encoded private key ONLY, without a asn1n structure
   * curveType<string> - determines curvature; for example - 'brainpool256', 'brainpool384' etc, optional variable
   *
   * */
  const curveType =
    data.curveType || determineCurvatureViaKeyLength(data.privateKey);
  const splittedEciesData = splitECIESCipher(data.cipherHex, curveType);
  const tempPubKey = splittedEciesData.temporaryPublic;
  const cipherText = splittedEciesData.cipher;
  const curvature = ObjectCaster.getCurvature(curveType, data.privateKey);

  console.group("DECRYPTION DEBUG DATA");

  const derive = await curvature.curvature
    .derive(Buffer.from(data.privateKey, 'hex'), Buffer.from(tempPubKey, 'hex'))
    .then(res => {
      return res;
    });

  console.groupCollapsed("derive");
  console.log('%cLength:', "font-weight: bold", derive.length);
  console.log('%cContent:', "font-weight: bold", UInt8ArrayToHex(derive));
  console.groupEnd()

  let keyDerivationResult = await keyDerivation({
    keyLength: cipherText.length,
    curveType: curveType,
    keyAgreement:
      derive.length % 8 !== 0
        ? '00' + bufferToHex(derive)
        : bufferToHex(derive),
    tempPublicKey: tempPubKey
  });

  console.groupCollapsed("keyDerivationResult");
  console.log('%cLength:', "font-weight: bold", keyDerivationResult.length);
  console.log('%cContent:', "font-weight: bold", keyDerivationResult);
  console.groupEnd()

  const macKey = keyDerivationResult.slice(0, 32);

  console.groupCollapsed("macKey")
  console.log('%cLength:', "font-weight: bold", macKey.length);
  console.log('%cContent:', "font-weight: bold", macKey);
  console.groupEnd()

  const cipherKey = keyDerivationResult.slice(32);

  console.groupCollapsed("cipherKey");
  console.log('%cLength:', "font-weight: bold", cipherKey.length);
  console.log('%cContent:', "font-weight: bold", cipherKey);
  console.groupEnd();

  let hMac = getHMac({
    curveType: curveType,
    macKey: macKey
  });

  console.groupCollapsed("hMac");
  console.log('%cLength:', "font-weight: bold", hMac.length);
  console.log('%cContent:', "font-weight: bold", hMac);
  console.groupEnd();

  let verifySliceLength = hMac.verifySliceLength;
  let n = hMac.n;
  const macInput = cipherText.slice(0, cipherText.length - verifySliceLength);

  console.groupCollapsed("macInput");
  console.log('%cLength:', "font-weight: bold", macInput.length);
  console.log('%cContent:', "font-weight: bold", macInput);
  console.groupEnd();

  const macVerify = cipherText.slice(cipherText.length - verifySliceLength);

  console.groupCollapsed("macVerify");
  console.log('%cLength:', "font-weight: bold", macVerify.length);
  console.log('%cContent:', "font-weight: bold", macVerify);
  console.groupEnd();
  console.groupEnd();

  n.update(Buffer.from(macInput, 'hex'));
  n.update(new ArrayBuffer(8));
  const res = n.hex('');

  let result;
  if (res.toLowerCase() === macVerify.toLowerCase()) {
    result = xor(macInput, cipherKey, macInput.length);
  }
  return result;
}
