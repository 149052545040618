import { useTranslation } from 'react-i18next';
import { SelectChangeEvent, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState, useRef } from 'react';

import theme from 'src/constants/theme';
import DocumentScaleControll from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/DocumentControllsComponent';
import DocumentLoader from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/documentLoader';
import DocumentPreview from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/documentPreview/DocumentPreview';
import DocumentTitleSection from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/documentTitleSection/DocumentTitleSection';
import DetailDataSection from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/detailDataSection';
import DetailsBadge from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/detailsBadge';
import { DetailsElementType } from 'src/modules/DocumentsPrivate/components/documentDetailsStep/components/tauronDocumentDetails/components/detailDataSection/DetailDataSection';

import {
  StyledColRow,
  StyledControlsDivider,
  StyledControlsWrapper,
  StyledDetailsColumn,
  StyledDetailsTitleDivider,
  StyledDocumentPreviewWrapper,
  StyledWidthContent
} from './styles';
import DownloadingEventTitleSection from './components/documentTitleSection/DownloadingEventTitleSection';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

type DocumentDetailsStepType = {
  detailsGroup: Array<DetailsElementType>;
  isLoading: boolean;
  documentTitle: string;
  documentBlob: Blob | undefined | null;
  isPrivate?: boolean;
};

const TauronDocumentDetails = ({
  detailsGroup,
  isLoading,
  documentTitle,
  documentBlob,
  isPrivate
}: DocumentDetailsStepType) => {
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();

  const [documentScale, setDocumentScale] = useState<number>(1.0);
  const [pagesTotalNumber, setPagesTotalNumber] = useState<number>(0);

  const downscaleDocument = useMediaQuery(theme.breakpoints.down('tablet'));
  const displaySeparateViewerTab = useMediaQuery(
    theme.breakpoints.up('desktop')
  );

  useEffect(() => {
    if (downscaleDocument) {
      setDocumentScale(0.5);
    }
  }, [downscaleDocument]);

  const handleScaleChange = useCallback(
    (event: SelectChangeEvent<number>) =>
      setDocumentScale(event.target.value as number),
    []
  );

  if (isLoading) {
    return DocumentLoader;
  }

  return (
    <StyledWidthContent>
      {isPrivate ? (
        <DownloadingEventTitleSection
          documentTitle={documentTitle}
          documentBlob={documentBlob}
        />
      ) : (
        <DocumentTitleSection
          documentTitle={documentTitle}
          documentBlob={documentBlob}
        />
      )}

      <StyledColRow displaySeparateViewerTab={displaySeparateViewerTab}>
        <StyledDetailsColumn
          displaySeparateViewerTab={displaySeparateViewerTab}
        >
          <DetailsBadge t={t('HOME_PRIVATE_DOCUMENTS_DETAILS')} />
          <StyledDetailsTitleDivider
            flexItem
            orientation="vertical"
            displaySeparateViewerTab={displaySeparateViewerTab}
          />
          <DetailDataSection detailsGroup={detailsGroup} />
          <StyledControlsWrapper
            displaySeparateViewerTab={displaySeparateViewerTab}
          >
            <StyledControlsDivider
              displaySeparateViewerTab={displaySeparateViewerTab}
              flexItem
              orientation="vertical"
            />
            <DocumentScaleControll
              t={t('PRIVATE_DOCUMENT_PREVIEW_PAGE')}
              inputRef={inputRef}
              max={pagesTotalNumber}
              value={documentScale}
              handleScaleChange={handleScaleChange}
              borderDisabled
              scaleValues={[0.25, 0.5, 0.75, 1, 1.5]}
              bold
            />
          </StyledControlsWrapper>
        </StyledDetailsColumn>
        <StyledDocumentPreviewWrapper
          displaySeparateViewerTab={displaySeparateViewerTab}
        >
          <DocumentPreview
            documentBlob={documentBlob}
            inputRef={inputRef}
            documentScale={documentScale}
            pagesTotalNumber={pagesTotalNumber}
            setPagesTotalNumber={setPagesTotalNumber}
          />
        </StyledDocumentPreviewWrapper>
      </StyledColRow>
    </StyledWidthContent>
  );
};

export default TauronDocumentDetails;
