import Loader from './Loader';
import Animation from './animated/Animation';
import SuspenseContainer from './suspenseContainer';
import TableLoaderAnimation from './tableLoader';

export default Loader;

export const ChainLoader = Animation;
export const SuspenseLoader = SuspenseContainer;
export const TableLoader = TableLoaderAnimation;
