import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiCard'] = {
  variants: [
    {
      props: { variant: 'secondary' },
      style: ({ theme }) => ({
        minHeight: 'unset',
        '.MuiCardContent-root': {
          padding: theme.spacing(4),
          minHeight: theme.spacing(20),
          [theme.breakpoints.only('tablet')]: {
            padding: theme.spacing(6, 8)
          }
        },
        '.MuiCardContent-root:last-child': {
          padding: theme.spacing(4),
          [theme.breakpoints.only('tablet')]: {
            padding: theme.spacing(6, 8)
          }
        },
        '.MuiDivider-root': {
          borderColor: theme.palette.custom.primary[200],
          [theme.breakpoints.down('desktop')]: {
            maxWidth: theme.spacing(12)
          }
        }
      })
    }
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      width: '100%',
      borderRadius: '16px',
      background: theme.palette.custom.textColors[50],
      border: `1px solid ${theme.palette.custom.primary[50]}`,
      /* TODO: Move shadow to theme */
      boxShadow: '0px 0px 20px rgba(67, 91, 134, 0.2)',
      minHeight: '400px',

      [theme.breakpoints.down('desktop')]: {
        minHeight: 'unset'
      },
      [theme.breakpoints.only('mobile')]: {}
    })
  }
};

export default componentConfiguration;
