import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  AddFullIdentityStep1StatusResponse,
  ProcessId
} from 'src/modules/UserIdentity/types/api';
import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';

const getFullIdentityStep1Status = (processId: ProcessId) => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  return apiClient.get<AddFullIdentityStep1StatusResponse>(
    apiRoutes.fullIdentityStep1Status.replace('{processId}', processId)
  );
};

export default getFullIdentityStep1Status;
