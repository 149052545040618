import getApiClient from 'src/lib/getApiClient';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { GetApiDeviceIp } from 'src/modules/DocumentsPrivate/types';

const getDeviceIp = () => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetApiDeviceIp>(apiRoutes.deviceIp);
};

export default getDeviceIp;
