import { InvalidInput } from 'src/types/validation';

import ApiError from './apiError';

class ValidationError extends ApiError {
  invalidInputs: string[];

  constructor(code: string, invalidInputs: InvalidInput) {
    super('Validation error', code);

    Object.setPrototypeOf(this, ValidationError.prototype);

    this.invalidInputs = Object.entries(invalidInputs).map(
      ([key, value]) => `${key}_${value}`
    );
  }
}

export default ValidationError;
