import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as HelpIcon } from 'src/assets/help.svg';
import useSettings from 'src/modules/DocumentsPrivate/hooks/useSettings';

import { StyledContainer, StyledTitle, StyledDescription } from './styles';

const Help = () => {
  const { t } = useTranslation();
  const { data } = useSettings();

  return (
    <StyledContainer>
      <HelpIcon />
      <StyledTitle variant="h4">{t('HELP_TITLE')}</StyledTitle>
      <StyledDescription variant="subtitle1">
        <Trans i18nKey="HELP_DESCRIPTION">
          Write to us{' '}
          <a href={`mailto:${data?.SUPPORT_EMAIL}`}>{data?.SUPPORT_EMAIL}</a> we
          can help you find a solution.
        </Trans>
      </StyledDescription>
    </StyledContainer>
  );
};

export default Help;
