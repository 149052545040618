import { EncryptedCekAuthorizationCode } from 'src/modules/DocumentsPrivate/types';

import decryptSensitiveContent from './decryptSensitiveContent';

type DecryptProps = {
  encryptedSensitiveContent: string;
  encryptedCekAuthorizationCode: EncryptedCekAuthorizationCode;
  authorizedCode: string;
};

const getPrivateDocument = async ({
  encryptedCekAuthorizationCode,
  authorizedCode,
  encryptedSensitiveContent
}: DecryptProps) => {
  const { sensitiveData, convertedCEK } = await decryptSensitiveContent(
    encryptedSensitiveContent,
    encryptedCekAuthorizationCode,
    authorizedCode
  );

  return {
    sensitiveData,
    convertedCEK
  };
};

export default getPrivateDocument;
