import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiSelect'] = {
  styleOverrides: {
    select: ({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1.25),
      ':focus': {
        backgroundColor: 'transparent'
      }
    }),
    iconOpen: ({ theme }) => ({
      color: theme.palette.primary.main
    }),
    icon: ({ theme }) => ({
      color: theme.palette.custom.neutral[700]
    })
  }
};

export default componentConfiguration;
