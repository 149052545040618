import { useEffect, useState } from 'react';

import {
  StyledCheckIcon,
  StyledCopyIcon,
  StyledDescription,
  StyledWrapper
} from 'src/components/copyToClipboard/styles';

type CopyToClipboardProps = {
  text: string;
  description?: string | null;
};

const CopyToClipboard = ({ text, description }: CopyToClipboardProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  });

  const handleCopy = () => {
    if (!copied) {
      navigator.clipboard.writeText(text);
      setCopied(true);
    }
  };

  return (
    <StyledWrapper onClick={handleCopy}>
      {copied ? <StyledCheckIcon /> : <StyledCopyIcon />}
      <StyledDescription variant="caption1" fontWeight={600}>
        {description}
      </StyledDescription>
    </StyledWrapper>
  );
};

export default CopyToClipboard;
