import { RegulationLink } from 'src/components/regulations';

const PrivacyPolicy = () => (
  <RegulationLink
    linkKey="TERMS_PRIVACY_POLICY_URL"
    labelKey="TERMS_PRIVACY_POLICY"
  />
);

export default PrivacyPolicy;
