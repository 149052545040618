export default class Hex {
  static decode(a) {
    let i;

    let hex = '0123456789ABCDEF';
    const ignore = ' \f\n\r\t\u00A0\u2028\u2029';
    const decoder = [];
    for (i = 0; i < 16; ++i) {
      decoder[hex.charAt(i)] = i;
    }
    hex = hex.toLowerCase();
    for (i = 10; i < 16; ++i) {
      decoder[hex.charAt(i)] = i;
    }
    for (i = 0; i < ignore.length; ++i) {
      decoder[ignore.charAt(i)] = -1;
    }

    let out = new Uint8Array(a.length >> 1);
    let bits = 0;
    let char_count = 0;
    let len = 0;

    for (i = 0; i < a.length; ++i) {
      let c = a.charAt(i);
      if (c === '=') {
        break;
      }
      c = decoder[c];
      if (c === -1) {
        continue;
      }
      if (c === undefined) {
        throw new Error(`Illegal character at offset ${i}`);
      }
      bits |= c;
      if (++char_count >= 2) {
        out[len++] = bits;
        bits = 0;
        char_count = 0;
      } else {
        bits <<= 4;
      }
    }
    if (char_count) {
      throw new Error('Hex encoding incomplete: 4 bits missing');
    }
    if (out.length > len) {
      // in case it was originally longer because of ignored characters
      out = out.subarray(0, len);
    }

    return out;
  }
}
