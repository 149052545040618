import { PropsWithChildren } from 'react';

import StyledCardsRow from './styles';

const CardsRow = ({
  featureCount,
  children
}: PropsWithChildren<{ featureCount?: number }>) => (
  <StyledCardsRow featureCount={featureCount}>{children}</StyledCardsRow>
);

export default CardsRow;
