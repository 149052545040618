import { useTranslation } from 'react-i18next';

import TauronHeightContainer from 'src/components/tauronHeightContainer';
import { ReactComponent as OutageIcon } from 'src/assets/outage.svg';

import { StyledContainer, StyledTitle, StyledDescription } from './styles';

const ServiceOutageTauron = () => {
  const { t } = useTranslation();

  return (
    <TauronHeightContainer>
      <StyledContainer>
        <OutageIcon />
        <StyledTitle variant="h3">{t('OUTAGE_TITLE')}</StyledTitle>
        <StyledDescription variant="h6">
          {t('OUTAGE_DESCRIPTION')}
        </StyledDescription>
      </StyledContainer>
    </TauronHeightContainer>
  );
};

export default ServiceOutageTauron;
