import { AxiosError } from 'axios';

import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  FullIdentityGetUserUuidResponse,
  GetFullUuid
} from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';

const postFullIdentityUserUuid = async (
  request: GetFullUuid
): Promise<FullIdentityGetUserUuidResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);
  try {
    const { data: response } =
      await apiClient.post<FullIdentityGetUserUuidResponse>(
        apiRoutes.fullIdentityUserUuid,
        request
      );

    return response;
  } catch (err) {
    const error = err as AxiosError<{ statusCode: string }>;

    if (error.response?.data?.statusCode) {
      throw new ApiError(
        error.response.data?.statusCode,
        error.response.data?.statusCode
      );
    }

    throw error;
  }
};

export default postFullIdentityUserUuid;
