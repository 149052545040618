import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiDrawer'] = {
  styleOverrides: {
    root: {
      '& .MuiDrawer-paper': {
        border: 'none',
        zIndex: 100
      }
    }
  }
};

export default componentConfiguration;
