import {
  FormGroup,
  FormHelperText,
  Link,
  styled,
  Typography
} from '@mui/material';

import FormContainer from 'src/components/form/formContainer';

export const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(6),
  gap: theme.spacing(2),
  justifyContent: 'start',
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'space-between'
  }
}));

export const StyledFormHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column'
}));

export const StyledFieldsSection = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(5),
  flexDirection: 'column'
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  alignSelf: 'end',
  cursor: 'pointer',
  margin: theme.spacing(1, 0, 2, 0)
}));

export const StyledSignTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.custom.neutral[900]
}));

export const StyledSignatureLoaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(9),
  alignItems: 'center',
  color: theme.palette.custom.textColors[800]
}));

export const StyledSignatureProgressMessageSection = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('tablet')]: {
      marginTop: theme.spacing(9)
    }
  })
);

export const StyledSignatureProgressMessageTitle = styled(Typography)(() => ({
  fontWeight: 400,
  i: {
    fontStyle: 'normal',
    fontWeight: 600
  }
}));

export const StyledInitialStepTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.neutral[900],
  fontSize: theme.spacing(2.25)
}));

export const StyledFormContainer = styled(FormContainer)(({ theme }) => ({
  color: theme.palette.custom.textColors[800],
  marginBottom: theme.spacing(11),
  [theme.breakpoints.up('tablet')]: {
    marginBottom: 0
  }
}));
