import { styled } from '@mui/material';

const StyledStepperControls = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  [theme.breakpoints.only('mobile')]: {
    marginTop: theme.spacing(5),
    flexDirection: 'column-reverse',
    width: '100%'
  },
  [theme.breakpoints.only('tablet')]: {
    marginTop: theme.spacing(2)
  }
}));

export default StyledStepperControls;
