export enum RegisterUserStepWizardKeys {
  REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION,
  REGISTRATION_SIDEBAR_ENTER_DATA,
  REGISTRATION_SIDEBAR_VERIFICATION,
  REGISTRATION_SIDEBAR_CREATE_PASSWORD,
  REGISTRATION_SIDEBAR_CONFIGURATION
}

export const RegisterUserStepKeys = [
  'REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION',
  'REGISTRATION_SIDEBAR_ENTER_DATA',
  'REGISTRATION_SIDEBAR_VERIFICATION',
  'REGISTRATION_SIDEBAR_CREATE_PASSWORD',
  'REGISTRATION_SIDEBAR_CONFIGURATION'
];

export const RegisterUserStepSuccessKeys = [
  'REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION',
  'REGISTRATION_SIDEBAR_ENTER_DATA',
  'REGISTRATION_SIDEBAR_VERIFICATION',
  'REGISTRATION_SIDEBAR_CREATE_PASSWORD',
  'REGISTRATION_SIDEBAR_CONFIGURATION_SUCCESS'
];
