import {
  styled,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Tab
} from '@mui/material';
import { TabList, TabPanel } from '@mui/lab';

export const StyledPropertyName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.custom.textColors[800]
}));

export const StyledPropertyValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.neutral[900],
  wordBreak: 'break-all',
  fontSize: '14px'
}));

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing(2.5)};
`;

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.custom.secondary[500]
}));
export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  marginLeft: theme.spacing(1.25)
}));

export const StyledHashTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.25)
}));

export const StyledTitleGroup = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start'
}));

export const StyledBlockchainHashSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5)
}));

export const StyledCopyWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse'
}));

export const StyledTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.custom.secondary[400]
  }
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  '&.Mui-selected': {
    color: theme.palette.custom.secondary[400]
  }
}));
