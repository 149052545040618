import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      fontWeight: 600,
      color: theme.palette.custom.textColors[600],
      padding: theme.spacing(1.125, 2),
      '&.Mui-selected': {
        color: theme.palette.primary.main
      },
      '&.MuiButtonBase-root': {
        minHeight: 'auto'
      }
    })
  }
};

export default componentConfiguration;
