import { Components, Theme } from '@mui/material';

import { fontSizeBigger } from 'src/constants/theme/fonts';

const componentConfiguration: Components<Theme>['MuiIcon'] = {
  styleOverrides: {
    root: {
      fontSize: fontSizeBigger
    }
  }
};

export default componentConfiguration;
