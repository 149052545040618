import { ContentCopy, Check } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';

export const StyledCopyIcon = styled(ContentCopy)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  cursor: 'pointer',
  color: theme.palette.custom.secondary[500]
}));

export const StyledCheckIcon = styled(Check)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  cursor: 'pointer',
  color: theme.palette.custom.success[500]
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.secondary[500]
}));

export const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer'
}));
