import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  NationalNodeLoginResponse,
  SessionId
} from 'src/modules/UserIdentity/types/api';
import { NATIONAL_NODE_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';

const getNationalNodeLogin = (sessionId: SessionId) => {
  const apiClient = getApiClient(NATIONAL_NODE_API_URL);

  return apiClient.get<NationalNodeLoginResponse>(apiRoutes.nationalNodeLogin, {
    params: {
      sessionId
    }
  });
};

export default getNationalNodeLogin;
