import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { GetPrivateDocumentCatalogResponse } from 'src/modules/DocumentsPrivate/types';
import getCatalogByBlockchainAddress from 'src/modules/DocumentsPrivate/api/getCatalogByBlockchainAddress';

const useDocumentCatalog = (
  blockchainAddress: string,
  options?: UseQueryOptions<GetPrivateDocumentCatalogResponse, AxiosError>
) =>
  useQuery<GetPrivateDocumentCatalogResponse, AxiosError>(
    [queryKeys.getDocumentCatalog, { blockchainAddress }],
    async () => {
      const { data: response } = await getCatalogByBlockchainAddress(
        blockchainAddress
      );

      return response;
    },
    options
  );

export default useDocumentCatalog;
