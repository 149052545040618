import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

import AppBarTauron from 'src/components/appBarTauron';
import ContentTauron from 'src/components/contentTauron';
import { LayoutContextProvider } from 'src/contexts/LayoutContext';
import { UserIdentityContextProvider } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import AppFooterTauron from 'src/components/appFooterTauron';

import { StyledContentContainerTauron, StyledBaseLayoutTauron } from './styles';

const TauronBaseLayout = () => (
  <LayoutContextProvider>
    <StyledBaseLayoutTauron>
      <UserIdentityContextProvider>
        <CssBaseline />
        <AppBarTauron />
        <StyledContentContainerTauron>
          <ContentTauron>
            <Outlet />
          </ContentTauron>
          <AppFooterTauron />
        </StyledContentContainerTauron>
      </UserIdentityContextProvider>
    </StyledBaseLayoutTauron>
  </LayoutContextProvider>
);

export default TauronBaseLayout;
