import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import postSendDownloadingEvent from 'src/modules/DocumentsPrivate/api/postSendDownloadingEvent';
import {
  SendDownloadingEventResponse,
  UseSendDownloadingEventParams
} from 'src/modules/DocumentsPrivate/types/downloadingEvent';

const useSendDownloadingEvent = (
  options: UseMutationOptions<
    SendDownloadingEventResponse,
    AxiosError,
    UseSendDownloadingEventParams
  >
) =>
  useMutation<
    SendDownloadingEventResponse,
    AxiosError,
    UseSendDownloadingEventParams
  >(postSendDownloadingEvent, options);

export default useSendDownloadingEvent;
