import React from 'react';
import ReactDOM from 'react-dom/client';

import './lib/i18n';
import App from './App';
import { SuspenseLoader } from './components/loader';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<SuspenseLoader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>
);
