import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from 'react';

import { TokenEntry } from 'src/modules/DocumentsPrivate/types';

export type TokenData = {
  tokenIdBase58: string;
  tokenBody: string;
};

type ActiveDeliveryStateContextType = {
  isDocumentAcknowledged: boolean;
  confirmDocumentAcknowledged: () => void;
  isDocumentReceived: boolean;
  confirmDocumentReceived: () => void;
  activeDeliveryTokens: TokenEntry[] | undefined;
  setActiveDeliveryTokens: (tokens: TokenEntry[] | undefined) => void;
  isConfirmationOfReceiptEnabled: boolean;
  isConfirmationOfAcknowledgementEnabled: boolean;
  getConfirmationOfReceiptTokenData: () => null | TokenData;
  getConfirmationOfAcknowledgementTokenData: () => null | TokenData;
};

const ActiveDeliveryStateContext = createContext(
  {} as ActiveDeliveryStateContextType
);

export const useActiveDeliveryState = () =>
  useContext(ActiveDeliveryStateContext);

const ActiveDeliveryStateProvider = ({ children }: PropsWithChildren) => {
  const [isDocumentAcknowledged, setIsDocumentAcknowledged] = useState(false);
  const [activeDeliveryTokens, setActiveDeliveryTokens] = useState<
    TokenEntry[] | undefined
  >(undefined);
  const [isDocumentReceived, setIsDocumentReceived] = useState(false);

  const getTokenByNameAndParentPath = useCallback(
    (name: string, parentPath: string) => {
      if (!activeDeliveryTokens) {
        return null;
      }

      const targetToken = activeDeliveryTokens.find(
        token =>
          token.tokenCategory.name.value === name &&
          token.tokenCategory.parentPath.value === parentPath
      );

      if (targetToken) {
        return {
          tokenIdBase58: targetToken.tokenIdBase58.value,
          tokenBody: targetToken.tokenBody.value
        };
      }
      return null;
    },
    [activeDeliveryTokens]
  );

  const getConfirmationOfReceiptTokenData = useCallback(
    () =>
      getTokenByNameAndParentPath('CONFIRMATION_OF_RECEIPT', 'READER_EVENTS'),
    [getTokenByNameAndParentPath]
  );

  const getConfirmationOfAcknowledgementTokenData = useCallback(
    () =>
      getTokenByNameAndParentPath(
        'CONFIRMATION_OF_ACKNOWLEDGEMENT',
        'READER_EVENTS'
      ),
    [getTokenByNameAndParentPath]
  );

  return (
    <ActiveDeliveryStateContext.Provider
      value={{
        isDocumentAcknowledged,
        confirmDocumentAcknowledged: () => setIsDocumentAcknowledged(true),
        isDocumentReceived,
        confirmDocumentReceived: () => setIsDocumentReceived(true),
        setActiveDeliveryTokens,
        isConfirmationOfReceiptEnabled:
          getConfirmationOfReceiptTokenData() !== null,
        isConfirmationOfAcknowledgementEnabled:
          getConfirmationOfAcknowledgementTokenData() !== null,
        getConfirmationOfReceiptTokenData,
        getConfirmationOfAcknowledgementTokenData,
        activeDeliveryTokens
      }}
    >
      {children}
    </ActiveDeliveryStateContext.Provider>
  );
};

export default ActiveDeliveryStateProvider;
