import { Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StepperControls from 'src/components/stepper/components/stepperControls';
import routes from 'src/constants/routes';

const DocumentVerificationNegativeStepperControls = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <StepperControls>
      <Button onClick={() => navigate(routes.index)} color="secondary">
        {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_CANCEL_BUTTON')}
      </Button>
      <Button
        onClick={() => navigate(routes.verifyIndex)}
        endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
        variant="contained"
      >
        <span>
          {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_VERIFY_OTHER_DOCUMENT')}
        </span>
      </Button>
    </StepperControls>
  );
};

export default DocumentVerificationNegativeStepperControls;
