import getApiClient from 'src/lib/getApiClient';
import { GetPublisherResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';

const getPublishers = () => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<GetPublisherResponse>(apiRoutes.publishers);
};

export default getPublishers;
