import { LoadingButton } from '@mui/lab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

type SubmitButtonProps = {
  loading: boolean;
  disabled: boolean;
};

const SubmitButton = ({ loading, disabled }: SubmitButtonProps) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      type="submit"
      loadingPosition="end"
      loading={loading}
      disabled={disabled}
      endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
      variant="contained"
    >
      <span>{t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}</span>
    </LoadingButton>
  );
};

export default SubmitButton;
