import { Button, styled, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledTableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  maxWidth: '100%'
}));

export const StyledDetailsLink = styled(Typography)<{
  decryptionFailed?: boolean;
}>(({ theme, decryptionFailed }) => ({
  color: decryptionFailed
    ? theme.palette.custom.textColors[500]
    : theme.palette.primary.main,
  textDecoration: 'underline'
}));

export const StyledTableContainerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3)
}));

export const StyledRefreshText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600
}));

export const StyledHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const StyledCloseButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isOpened'
})<{ isOpened: boolean }>(({ theme, isOpened }) => ({
  ...theme.typography.buttonSmall,
  fontWeight: 600,
  padding: theme.spacing(0.5),
  color: isOpened
    ? theme.palette.custom.neutral[900]
    : theme.palette.primary.main,
  path: {
    color: theme.palette.custom.neutral[900]
  }
}));

export const StyledDataGrid = styled(DataGrid)(() => ({
  '.MuiDataGrid-row': {
    cursor: 'pointer'
  },
  '.MuiDataGrid-overlayWrapper': {
    width: '100%',
    backgroundColor: '#ffffffaa',
    height: '100%',
    position: 'absolute',
    minHeight: '260px'
  },
  '.MuiDataGrid-overlayWrapperInner ': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '.MuiDataGrid-virtualScroller': {
    minHeight: '260px'
  },

  '.MuiDataGrid-columnHeaderTitleContainer': {
    flex: 'unset',
    marginRight: '8px'
  },
  '.MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
    flex: 1
  }
}));

export const StyledNoDataSpan = styled('span')(({ theme }) => ({
  color: theme.palette.custom.textColors[500],
  fontWeight: 400
}));
