import {
  useReducer,
  createContext,
  useContext,
  PropsWithChildren,
  Dispatch
} from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import {
  ActionStatesEnum,
  SignatureFormStepsEnum
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/constants';
import {
  signFormReducer,
  SignatureFormStateType,
  ActionType
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/reducers/SignFormReducer';
import { useDocumentSignatureSmsCode } from 'src/modules/DocumentsPrivate/hooks/useDocumentSignatureSmsCode';

const initialState: SignatureFormStateType = {
  step: SignatureFormStepsEnum.INITIAL,
  action: ActionStatesEnum.NONE,
  signatureError: null
};
type SignatureFormStateContextType = {
  formProps: UseFormReturn;
  signatureFormState: {
    signatureProcessState: SignatureFormStateType;
    dispatch: Dispatch<ActionType>;
  };
  documentSignatureSmsCode: ReturnType<typeof useDocumentSignatureSmsCode>;
};

const SignatureFormContext = createContext({} as SignatureFormStateContextType);

export const useSignatureForm = () => useContext(SignatureFormContext);

export const SignatureFormProvider = ({ children }: PropsWithChildren) => {
  const formProps = useForm();
  const [signatureProcessState, dispatch] = useReducer(
    signFormReducer,
    initialState
  );

  const {
    requestSmsCode,
    resendSmsCode,
    confirmSmsCode,
    processStatus,
    resetSmsVerificationProcess,
    isResendTimeout,
    resendTimeoutDuration
  } = useDocumentSignatureSmsCode();

  const value: SignatureFormStateContextType = {
    formProps,
    signatureFormState: {
      signatureProcessState,
      dispatch
    },
    documentSignatureSmsCode: {
      requestSmsCode,
      resendSmsCode,
      confirmSmsCode,
      processStatus,
      resetSmsVerificationProcess,
      isResendTimeout,
      resendTimeoutDuration
    }
  };

  return (
    <SignatureFormContext.Provider value={value}>
      {children}
    </SignatureFormContext.Provider>
  );
};
