const stringCut = (str, len) => {
  const ellipsis = '\u2026';
  if (str.length > len) {
    str = str.substring(0, len) + ellipsis;
  }

  return str;
};

export default stringCut;
