import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import { useSessionStorage } from 'usehooks-ts';

import {
  UserIdentityContextProps,
  UserIdentityState,
  UserIdentityActions
} from 'src/modules/UserIdentity/types/context';
import {
  FullIdentityLoadUserKeysResponse,
  UserUuid
} from 'src/modules/UserIdentity/types/api';
import mergeKeys from 'src/modules/Crypto/lib/mergeKeys';
import hexToUInt8Array from 'src/modules/Crypto/lib/hexToUInt8Array';
import base64ToHex from 'src/modules/Crypto/lib/base64ToHex';
import useRestoreSession from 'src/modules/UserIdentity/hooks/useRestoreUserSession';
import { UserCredentials } from 'src/modules/UserIdentity/types/session';
import { CREDENTIALS_SESSION_KEY } from 'src/modules/UserIdentity/constants/session';

export const UserIdentityContext = createContext<UserIdentityContextProps>({
  state: {
    status: 'UNAUTHORIZED',
    isLoading: false
  },
  saveLogin: () => undefined,
  saveKeys: () => undefined,
  logout: () => undefined
});

const useUserIdentityContextReducer = (
  state: UserIdentityState,
  action: UserIdentityActions
): UserIdentityState => {
  switch (action.ACTION) {
    case 'LOGOUT':
      return {
        status: 'UNAUTHORIZED',
        isLoading: false
      };
    case 'SAVE_LOGIN':
      return {
        ...state,
        status: 'SAVE_LOGIN',
        login: {
          password: action.password,
          email: action.email,
          document: action.document
        }
      };
    case 'RESTORE_SESSION': {
      return {
        ...state,
        isLoading: true
      };
    }
    case 'SAVE_KEYS':
      return {
        ...state,
        isLoading: false,
        login: undefined,
        keys: action.keys,
        uuid: action.uuid,
        kycGwId: action.kycGwId,
        mergedKeys: action.mergedKeys,
        status: 'AUTHORIZED',
        hashFromHashFromPassword: action.hashFromHashFromPassword,
        user: {
          firstName: action.firstName,
          lastName: action.lastName,
          email: action.email
        }
      };
  }

  return state;
};

const useUserIdentityContext = (initialState: UserIdentityState) =>
  useReducer<
    (
      prevState: UserIdentityState,
      action: UserIdentityActions
    ) => UserIdentityState
  >(useUserIdentityContextReducer, initialState);

type UserIdentityContextProviderProps = {
  children: ReactNode;
};

export const UserIdentityContextProvider = ({
  children
}: UserIdentityContextProviderProps) => {
  const [state, dispatch] = useUserIdentityContext({ status: 'UNAUTHORIZED' });
  const { mutate: restoreSession } = useRestoreSession({
    onSuccess: async data => {
      await saveKeys({
        firstName: data.firstName,
        lastName: data.lastName,
        keys: data.keys,
        uuid: data.uuid,
        kycGwId: data.kycGwId,
        email: data.email,
        hashFromHashFromPassword: data.hashFromHashFromPassword
      });
    }
  });
  const [sessionCredentials, setSessionCredentials] =
    useSessionStorage<UserCredentials | null>(CREDENTIALS_SESSION_KEY, null);

  const saveLogin = useCallback(
    (email: string, password: string, document?: string) => {
      dispatch({
        ACTION: 'SAVE_LOGIN',
        password,
        email,
        document
      });
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    setSessionCredentials(null);
    dispatch({
      ACTION: 'LOGOUT'
    });
  }, [dispatch, setSessionCredentials]);

  const saveKeys = useCallback(
    async (data: {
      email: string;
      uuid: UserUuid;
      keys: FullIdentityLoadUserKeysResponse;
      kycGwId: string;
      firstName: string;
      lastName: string;
      hashFromHashFromPassword?: string;
    }) => {
      const key275 = data.keys.semiFullIdentityKeysList[0];
      const key25 = data.keys.semiFullIdentityKeysList[1];
      const mergedKeys = await mergeKeys(
        key25.encryptedKek,
        key25.encryptedPrivateKeys,
        key25.encryptedPassword,
        hexToUInt8Array(base64ToHex(key275.encryptedPrivateKeys)),
        hexToUInt8Array(base64ToHex(key275.encryptedKek)),
        key275.encryptedPassword || data.hashFromHashFromPassword
      );

      dispatch({
        ACTION: 'SAVE_KEYS',
        ...data,
        hashFromHashFromPassword: data.hashFromHashFromPassword,
        mergedKeys
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (state.status === 'UNAUTHORIZED') {
      if (sessionCredentials) {
        dispatch({
          ACTION: 'RESTORE_SESSION'
        });
        restoreSession(sessionCredentials);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserIdentityContext.Provider
      value={{
        logout,
        saveKeys,
        saveLogin,
        state
      }}
    >
      {children}
    </UserIdentityContext.Provider>
  );
};
