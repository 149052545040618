import { useFormContext } from 'react-hook-form';
import { useContext, useEffect } from 'react';

import StepTextFieldWithLabel from 'src/components/stepper/components/stepperTextFieldWithLabel';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import CapsLockLabel from 'src/components/capsLockLabel';
import {
  DocumentNumberTypeEnum,
  parseDocumentNumberTypeToStepTitle,
  parseDocumentNumberTypeToInputLabel
} from 'src/modules/UserIdentity/constants/identityParams';
import { getDocumentNumberTypeLength } from 'src/modules/UserIdentity/utils/documentNumberUtils/getDocumentNumberTypeLength';

const DocumentNumberFieldName = 'documentNumber';

type SetDocumentNumberStepProps = {
  orderNumber?: string;
  documentNumberType?: DocumentNumberTypeEnum;
};

const SetDocumentNumberStep = ({
  orderNumber,
  documentNumberType
}: SetDocumentNumberStepProps) => {
  const { setDocumentNumber, state } = useContext(PrivateDocumentContext);
  const { getValues, formState } = useFormContext();

  useEffect(() => {
    if (formState.isValid) {
      const { documentNumber } = getValues();

      state.documentNumber !== documentNumber &&
        setDocumentNumber(documentNumber);
    }
  }, [getValues, formState.isValid, setDocumentNumber, state.documentNumber]);

  return (
    <>
      <StepTextFieldWithLabel
        isDisabled={state.isLoading}
        fieldName={DocumentNumberFieldName}
        labelKey={parseDocumentNumberTypeToStepTitle(
          documentNumberType || null
        )}
        inputLabelKey={parseDocumentNumberTypeToInputLabel(
          documentNumberType || null
        )}
        orderNumber={orderNumber}
        autoCompleteDisabled
        inputMaxlength={
          documentNumberType
            ? getDocumentNumberTypeLength(documentNumberType)
            : undefined
        }
      />
      <CapsLockLabel />
    </>
  );
};

export default SetDocumentNumberStep;
