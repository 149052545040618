const apiRoutes = {
  agreementAccept: '/documents/private/agreement/accept',
  agreementReject: '/documents/private/agreement/reject',
  addEvent: '/documents/private/addEvent',
  privateDocument: '/documents/private/{blockchainAddress}',
  privateDocumentList: '/documents/private/{uuid}/documentList',
  privateDocumentCatalog: '/documents/private/{blockchainAddress}/catalog',
  privateDocumentHistory: '/documents/private/{blockchainAddress}/history',
  privateDocumentDownloadEvent:
    '/documents/private/{blockchainAddress}/downloadEvent',
  publicDocument: '/documents/public/{blockchainAddress}',
  publicDocuments: '/documents/public',
  publicDocumentFileDownload: '/documents/public/{blockchainAddress}/download',
  publicDocumentHistory: '/documents/public/{blockchainAddress}/history',
  settings: '/settings',
  verifyDocument: '/documents/verify/{blockchainAddress}',
  verifyDocumentWithFile: '/documents/verify/file',
  publishers: '/publishers',
  categories: '/categories',
  partnerEncryptionKey: '/partnerEncryptionKey',
  health: '/health',
  deviceIp: '/deviceIP'
};

export default apiRoutes;
