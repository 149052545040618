import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  StepperHeader,
  StepperContainer,
  StepperHeaderContainer
} from 'src/components/stepper';
import CenterContainer from 'src/components/centerContainer';
import routes from 'src/constants/routes';
import { ReactComponent as LoginGovIcon } from 'src/assets/loginGov.svg';
import { IdentityVerificationMethodsEnum } from 'src/modules/UserIdentity/constants/identityVerification';
import { IDENTITY_METHOD_KEY } from 'src/modules/UserIdentity/constants/session';

import { StyledButton, StyledStepperControls } from './styles';

const AccessTypeRegisterPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [_, setIdentityMethod] = useSessionStorage<string | null>(
    IDENTITY_METHOD_KEY,
    null
  );

  const handleChooseIdentityMethod = useCallback(
    (method: IdentityVerificationMethodsEnum) => () => {
      setIdentityMethod(method);

      if (method === IdentityVerificationMethodsEnum.NATIONAL_NODE) {
        navigate(`${routes.documentAccessTypeNationalNodeLaunch}`);
        return;
      }
      navigate(`${routes.documentAccessTypeRegisterDetails}`);
    },
    [navigate, setIdentityMethod]
  );

  return (
    <StepperContainer>
      <CenterContainer spacing={0} maxWidth={551}>
        <StepperHeaderContainer>
          <StepperHeader
            subtitleKey="REGISTRATION_CHOOSE_IDENTITY_VERIFICATION_METHOD"
            titleKey="USER_IDENTITY_VERIFICATION"
            align="center"
          />
        </StepperHeaderContainer>

        <StyledStepperControls>
          <StyledButton
            fullWidth
            onClick={handleChooseIdentityMethod(
              IdentityVerificationMethodsEnum.BASIC
            )}
            variant="contained"
            endIcon={
              <Tooltip
                placement="right"
                title={t('BASIC_VERIFICATION_DESCRIPTION')}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            }
          >
            {t('BASIC_VERIFICATION')}
          </StyledButton>
          <StyledButton
            fullWidth
            onClick={handleChooseIdentityMethod(
              IdentityVerificationMethodsEnum.NATIONAL_NODE
            )}
            variant="contained"
            endIcon={
              <Tooltip
                placement="right"
                title={t('NATIONAL_NODE_VERIFICATION_DESCRIPTION')}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            }
          >
            {t('LOGIN_GOV_VERIFICATION')}
            <LoginGovIcon />
          </StyledButton>
          <StyledButton
            fullWidth
            onClick={handleChooseIdentityMethod(
              IdentityVerificationMethodsEnum.ONLINE
            )}
            variant="contained"
            endIcon={
              <Tooltip
                placement="right"
                title={t('ONLINE_VERIFICATION_DESCRIPTION')}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            }
            disabled
          >
            {t('ONLINE_VERIFICATION')}
          </StyledButton>
        </StyledStepperControls>
      </CenterContainer>
    </StepperContainer>
  );
};

export default AccessTypeRegisterPage;
