import getApiClient from 'src/lib/getApiClient';
import { GetPrivateDocumentListResponse } from 'src/modules/DocumentsPrivate/types';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';

type GetDocumentListParams = {
  uuid: string;
  kycGatewayId: string;
  publicationDate: string;
  blockchainAddress: string;
  category: string;
  limit: number;
  page: number;
  signingStatus?: AgreementStatusEnum;
  order: string;
  orderBy: string;
  publisherName: string;
};

const getDocumentList = async ({
  kycGatewayId,
  uuid,
  page,
  limit,
  signingStatus,
  publicationDate,
  blockchainAddress,
  category,
  orderBy,
  order,
  publisherName
}: GetDocumentListParams) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);
  const query = {
    kycGatewayId: encodeURIComponent(kycGatewayId),
    newVersionsOnly: 'true',
    page: page.toString(),
    limit: limit.toString(),
    publicationDate,
    blockchainAddress,
    category,
    orderBy,
    order,
    publisherName,
    signingStatus: signingStatus || ''
  };
  const params = new URLSearchParams(query);
  Object.entries(query).forEach(([key, value]) => {
    if (value === '' || typeof value === 'undefined') {
      params.delete(key);
    }
  });

  return apiClient.get<GetPrivateDocumentListResponse>(
    `${apiRoutes.privateDocumentList.replace(
      '{uuid}',
      uuid
    )}?${params.toString()}`
  );
};

export default getDocumentList;
