import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';

import { PublicDocumentListForm } from 'src/modules/DocumentsPrivate/components/documentListPublic/filtersCard/form';
import { mapSearchParamsToDefaultFormParams } from 'src/modules/DocumentsPrivate/components/documentListPublic/utils';
import Table from 'src/modules/DocumentsPrivate/components/documentListPublic/table';
import Header from 'src/modules/DocumentsPrivate/components/documentListPublic/header';
import { SortModel } from 'src/modules/DocumentsPrivate/types';
import usePublicDocumentList from 'src/modules/DocumentsPrivate/hooks/usePublicDocumentList';
import { parseDateFilter } from 'src/modules/DocumentsPrivate/utils';
import { PUBLICATION_DATE_FROM_ZERO_VALUE } from 'src/modules/DocumentsPrivate/components/documentListPublic/utils';

const DocumentListContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchDefaultParams = mapSearchParamsToDefaultFormParams(searchParams);
  const [searchForm, setSearchForm] = useState<PublicDocumentListForm>({
    publisherName: searchDefaultParams.form.publisherName || '',
    publicationDateFrom:
      searchDefaultParams.form.publicationDateFrom ||
      PUBLICATION_DATE_FROM_ZERO_VALUE,
    publicationDateTo: searchDefaultParams.form.publicationDateTo || '',
    title: searchDefaultParams.form.title || '',
    category: searchDefaultParams.form.category || '',
    blockchainAddress: searchDefaultParams.form.blockchainAddress || '',
    validSince: searchDefaultParams.form.validSince || '',
    validUntil: searchDefaultParams.form.validUntil || ''
  });
  const [sortModel, setSortModel] = useState<SortModel>(
    searchDefaultParams.sort
  );
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    searchDefaultParams.pagination
  );
  const {
    data: publicDocumentListData,
    isLoading,
    isFetching
  } = usePublicDocumentList(
    {
      page: paginationModel.page,
      limit: paginationModel.pageSize,
      publicationDateFrom: parseDateFilter(
        searchForm.publicationDateFrom ?? PUBLICATION_DATE_FROM_ZERO_VALUE
      ),
      publicationDateTo: parseDateFilter(searchForm.publicationDateTo),
      blockchainAddress: searchForm.blockchainAddress,
      publisherName: searchForm.publisherName,
      category: searchForm.category,
      title: searchForm.title,
      validSince: parseDateFilter(searchForm.validSince),
      validUntil: parseDateFilter(searchForm.validUntil),
      order: sortModel.order,
      orderBy: sortModel.orderBy
    },
    {
      keepPreviousData: true,
      retry: false,
      onSuccess: () => {
        const query = {
          page: paginationModel.page.toString(),
          limit: paginationModel.pageSize.toString(),
          order: sortModel.order,
          orderBy: sortModel.orderBy,
          ...searchForm
        };
        const params = new URLSearchParams(query);

        Object.entries(query).forEach(([key, value]) => {
          if (value === '' || typeof value === 'undefined') {
            params.delete(key);
          }
        });
        setSearchParams(params);
      }
    }
  );

  const formProps = useForm<PublicDocumentListForm>({
    defaultValues: searchDefaultParams.form
  });

  const onSearch = () => setSearchForm(formProps.getValues());

  return (
    <FormProvider {...formProps}>
      <Header onSearch={onSearch} isLoading={isLoading || isFetching} />
      <Table
        setPaginationModel={setPaginationModel}
        setSortModel={setSortModel}
        paginationModel={paginationModel}
        searchForm={searchForm}
        sortModel={sortModel}
        onSearch={onSearch}
        documentListData={publicDocumentListData}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </FormProvider>
  );
};

export default DocumentListContent;
