/* eslint-disable */

import asn1creators from './asn1creators';

export default async (rsaPKCS8Seq, kycGwId) =>
  asn1creators.createSAFEBAGSequence(
    {
      name: `${kycGwId}_RSA`,
      version: 1
    },
    rsaPKCS8Seq
  );
