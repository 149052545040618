import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, LinearProgress } from '@mui/material';

import FileUploadIcon from 'src/components/fileUpload/components/fileUploadIcon';
import { FileUploadStatuses } from 'src/components/fileUpload/constants';

import {
  StyledCircularProgress,
  StyledFileInfo,
  StyledFileInfoMetadata,
  StyledFileInfoMetadataText,
  StyledFileInfoName,
  StyledFileUploadStatus,
  StyledFileUploadStatusContent
} from './styles';
import { FileUploadStatusProps } from './types';
import formatSize from './utils';

const FileUploadStatus = ({
  file,
  onDelete,
  loadingProgress,
  status = FileUploadStatuses.READY
}: FileUploadStatusProps) => {
  const { t } = useTranslation();

  if (!file) {
    return null;
  }

  const isError = status === FileUploadStatuses.FAILED;
  const isLoading = status === FileUploadStatuses.LOADING;

  return (
    <StyledFileUploadStatus isError={isError}>
      <StyledFileUploadStatusContent>
        <FileUploadIcon isError={isError} />
        <StyledFileInfo>
          <StyledFileInfoName variant="body1">{file.name}</StyledFileInfoName>
          {file && (
            <StyledFileInfoMetadata>
              <StyledFileInfoMetadataText
                error={isError || undefined}
                variant="body2"
              >
                {formatSize(file.size)} KB
              </StyledFileInfoMetadataText>
              {status !== FileUploadStatuses.READY && (
                <>
                  <StyledFileInfoMetadataText
                    error={isError || undefined}
                    variant="body2"
                  >
                    •
                  </StyledFileInfoMetadataText>

                  <StyledFileInfoMetadataText
                    error={isError || undefined}
                    variant="body2"
                  >
                    {t(`FILE_UPLOAD_STATUS_${status}`)}
                  </StyledFileInfoMetadataText>
                </>
              )}
            </StyledFileInfoMetadata>
          )}
          {isLoading && loadingProgress && loadingProgress >= 0 && (
            <LinearProgress variant="determinate" value={loadingProgress} />
          )}
        </StyledFileInfo>
      </StyledFileUploadStatusContent>
      {!isLoading && onDelete && file && (
        <IconButton
          onClick={() => {
            if (document) {
              const element = document.querySelector(
                '[name=file]'
              ) as HTMLInputElement;

              if (element) {
                // remove file reference from input field
                element.value = '';
              }
            }

            onDelete(file);
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {isLoading && <StyledCircularProgress />}
    </StyledFileUploadStatus>
  );
};

export default FileUploadStatus;
