import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import { SaveKeys, SaveKeysResponse } from 'src/modules/UserIdentity/types/api';
import ApiError from 'src/lib/apiError';

const postSaveKeys = async (request: SaveKeys): Promise<SaveKeysResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } = await apiClient.post<SaveKeysResponse>(
    apiRoutes.fullIdentitySaveKeys,
    request
  );

  if (response.status === 'ERROR') {
    throw new ApiError(response.statusDetails, response.statusDetails);
  }

  return response;
};

export default postSaveKeys;
