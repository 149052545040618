import { Divider, styled } from '@mui/material';

export const StyledCardTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('desktop')]: {
    display: 'inline-block'
  }
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100px'
}));
