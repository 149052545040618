import axios, { AxiosRequestConfig } from 'axios';

import hasFeature from './hasFeature';
import { mockApi } from './apiMock';

const getApiClient = (baseUrl: string, config: AxiosRequestConfig = {}) => {
  const instance = axios.create({
    baseURL: baseUrl,
    ...config
  });

  if (hasFeature('mockEndpoints')) {
    mockApi(instance);
  }

  return instance;
};

export default getApiClient;
