/* eslint-disable */

import { Buffer } from 'buffer';
import scrypt from 'scrypt-async';
import { runECCrypto } from './ciphers/ecies/eccrypto';
import bufferToHex from './bufferToHex';
import hexToUInt8Array from './hexToUInt8Array';
import importKey from './importKey';
import UInt8ArrayToHex from './UInt8ArrayToHex';
import decrypt from './decrypt';
import ASN1Decoder from './ASN1Decoder';

export default class ObjectCaster {
  static generateScryptKey(data) {
    let key = null;
    scrypt(
      data.password,
      typeof data.salt === 'string' ? Buffer.from(data.salt, 'hex') : data.salt,
      {
        N: data.cost,
        r: data.blockSize,
        p: data.parallel,
        encoding: 'binary'
      },
      derivedKey => {
        key = derivedKey;
      }
    );
    return key;
  }
  static getSequenceByKeyName(keySequence, data) {
    /* data:
     * shortcutName: first 8 bytes of sha from the name
     * keyVersion: key version
     *  */
    let result;
    for (let i = 0; i < Object.keys(keySequence.SEQUENCE_0).length; i++) {
      const element = keySequence.SEQUENCE_0[`SEQUENCE_${i}`];
      if (
        element.SET_0.SEQUENCE_1.SET_0.OCTET_STRING_0.toString().toLowerCase() ===
          data.shortcutName.toString().toLowerCase() &&
        element.SET_0.SEQUENCE_2.SET_0.INTEGER_0 === data.keyVersion
      ) {
        result = element;
      }
    }
    return result;
  }
  static async getFormattedDataFromSequence(sequence, scryptPassword) {
    const data = {
      scryptData: {
        scryptPassword: scryptPassword || null,
        salt: sequence.APPLICATION_CONTEXT_0.SEQUENCE_0.SEQUENCE_0.SEQUENCE_0
          .SEQUENCE_0.SEQUENCE_0.OCTET_STRING_0,
        cost: sequence.APPLICATION_CONTEXT_0.SEQUENCE_0.SEQUENCE_0.SEQUENCE_0
          .SEQUENCE_0.SEQUENCE_0.INTEGER_0,
        blockSize:
          sequence.APPLICATION_CONTEXT_0.SEQUENCE_0.SEQUENCE_0.SEQUENCE_0
            .SEQUENCE_0.SEQUENCE_0.INTEGER_1,
        parallel:
          sequence.APPLICATION_CONTEXT_0.SEQUENCE_0.SEQUENCE_0.SEQUENCE_0
            .SEQUENCE_0.SEQUENCE_0.INTEGER_2
      },
      iv: sequence.APPLICATION_CONTEXT_0.SEQUENCE_0.SEQUENCE_0.SEQUENCE_0
        .SEQUENCE_1.OCTET_STRING_0,
      encPrivKey: sequence.APPLICATION_CONTEXT_0.SEQUENCE_0.OCTET_STRING_0,
      safabagName: sequence.SET_0.SEQUENCE_0.SET_0.IA5STRING_0,
      safebagSHA: sequence.SET_0.SEQUENCE_1.SET_0.OCTET_STRING_0,
      sequenceVersion: sequence.SET_0.SEQUENCE_2.SET_0.INTEGER_0,
      creationDate: sequence.SET_0.SEQUENCE_3.SET_0.UTCTIME_0
    };

    if (scryptPassword) {
      data.scryptKey = this.generateScryptKey({
        ...data.scryptData,
        password: scryptPassword,
        salt: hexToUInt8Array(data.scryptData.salt)
      });

      const cryptoKey = await importKey(data.scryptKey, 'AES-CBC');

      const decodedPrivKey = await decrypt(
        hexToUInt8Array(data.encPrivKey),
        hexToUInt8Array(data.iv),
        cryptoKey,
        'AES-CBC'
      );

      data.decPrivKey = decodedPrivKey;
      data.decPrivKeyHex = bufferToHex(decodedPrivKey);

      data.scryptKeyHex = UInt8ArrayToHex(data.scryptKey);
      data.curvatureType = ASN1Decoder.getCurvatureNameByPrivateKey(
        data.decPrivKeyHex
      );
    }

    return data;
  }

  static getDataWithKeyIdFormattedData(decodedHexString) {
    /* key should be decoded first */
    /* version - version of structure
     *  shaFromName - sha256 from the name, 8 first bytes
     *  keyNumber - version of a key
     *  key - key itself
     *  */
    return {
      version: decodedHexString.SEQUENCE_0.INTEGER_0,
      shortcutName: decodedHexString.SEQUENCE_0.SEQUENCE_0.OCTET_STRING_0,
      keyVersion: decodedHexString.SEQUENCE_0.SEQUENCE_0.INTEGER_0,
      key: decodedHexString.SEQUENCE_0.OCTET_STRING_0
    };
  }

  /* Returns brainpool curvature as UIntArray */
  static getCurvature(curvatureName, privKey) {
    let curvature,
      privateKey,
      publicKey = null;
    switch (curvatureName) {
      case 'p192':
        curvature = runECCrypto('p192');
        privateKey = Buffer.from(privKey, 'hex') || curvature.generatePrivate();
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'p224':
        curvature = runECCrypto('p224');
        privateKey = privKey || curvature.generatePrivate();
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'p256':
      default:
        curvature = runECCrypto('p256');
        privateKey = curvature.generatePrivate();
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'p384':
        curvature = runECCrypto('p384');
        privateKey = privKey || curvature.generatePrivate(48);
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'p521':
        curvature = runECCrypto('p521');
        privateKey = privKey || curvature.generatePrivate(64);
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'brainpool256':
        curvature = runECCrypto('BrainpoolP256r1');
        privateKey = privKey
          ? Buffer.from(privKey, 'hex')
          : curvature.generatePrivate(32);
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'brainpool320':
        curvature = runECCrypto('BrainpoolP320r1');
        privateKey = privKey
          ? Buffer.from(privKey, 'hex')
          : curvature.generatePrivate(40);
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'brainpool384':
        curvature = runECCrypto('BrainpoolP384r1');
        privateKey = privKey
          ? Buffer.from(privKey, 'hex')
          : curvature.generatePrivate(48);
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
      case 'brainpool512':
        curvature = runECCrypto('BrainpoolP512r1');
        privateKey = privKey
          ? Buffer.from(privKey, 'hex')
          : curvature.generatePrivate(64);
        publicKey = curvature.getPublic(privateKey);
        return {
          curvature,
          privateKey,
          publicKey
        };
    }
  }
  static renderCurveByName(curveName) {
    return this.getCurvature(curveName);
  }
}
