import { GridColDef } from '@mui/x-data-grid';
import i18next from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export const columns: GridColDef[] = [
  {
    field: 'publicationDate',
    sortable: true,
    flex: 1,
    renderHeader: () =>
      i18next.t('PRIVATE_DOCUMENTS_LIST_PUBLICATION_DATE_COLUMN')
  },
  {
    field: 'title',
    sortable: true,
    flex: 1,
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_TITLE_COLUMN')
  },
  {
    field: 'category',
    sortable: true,
    flex: 1,
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_CATEGORY_COLUMN')
  },
  {
    sortable: true,
    field: 'publisher',
    flex: 1,
    renderHeader: () => i18next.t('PRIVATE_DOCUMENTS_LIST_PUBLISHER_COLUMN')
  },
  {
    sortable: true,
    field: 'validSince',
    flex: 1,
    renderHeader: () => i18next.t('DOCUMENT_LIST_VALID_SINCE_COLUMN')
  },
  {
    sortable: true,
    field: 'validUntil',
    flex: 1,
    renderHeader: () => i18next.t('DOCUMENT_LIST_VALID_UNTIL_COLUMN')
  },
  {
    disableColumnMenu: true,
    hideable: false,
    editable: false,
    hideSortIcons: true,
    field: 'preview',
    minWidth: 200,
    renderHeader: () => '',
    renderCell: ({ value }) => value,
    headerAlign: 'center',
    align: 'center',
    sortable: false
  }
];
