import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '100px',
      padding: theme.spacing('7px', '10px'),
      maxWidth: 'fit-content'
    }),
    label: ({ theme }) => ({
      ...theme.typography.caption2,
      fontWeight: 400,
      padding: theme.spacing(0),
      display: 'flex'
    })
  },
  variants: [
    {
      props: { variant: 'approved' },
      style: ({ theme }) => ({
        border: `1px solid ${theme.palette.custom.success[700]}`,
        backgroundColor: theme.palette.custom.primary[50],
        color: theme.palette.custom.success[700]
      })
    },
    {
      props: { variant: 'outdated' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.custom.darkMode[50],
        color: theme.palette.custom.darkMode[300]
      })
    },
    {
      props: { variant: 'errata' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.custom.neutral[100],
        color: theme.palette.grey[700]
      })
    },
    {
      props: { variant: 'success' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.custom.success[100],
        color: theme.palette.custom.success[700]
      })
    },
    {
      props: { variant: 'warning' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.custom.warning[100],
        color: theme.palette.custom.warning[700]
      })
    }
  ]
};

export default componentConfiguration;
