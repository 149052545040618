import { InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornmentProps } from '@mui/material/InputAdornment/InputAdornment';

import { StyledPublicationDateIcon } from 'src/modules/DocumentsPrivate/components//documentList/filtersCard/styles';
import { PUBLICATION_DATE_FROM_ZERO_VALUE } from 'src/modules/DocumentsPrivate/components/documentListPublic/utils';

import { PublicDocumentListForm } from './form';

export const DatePickerInputAdornmentPublicationDateFrom = ({
  children,
  ...props
}: InputAdornmentProps) => {
  const { watch, setValue } = useFormContext<PublicDocumentListForm>();
  const publicationDate = watch(`publicationDateFrom`);
  return (
    <InputAdornment {...props}>
      {publicationDate && (
        <StyledPublicationDateIcon
          onClick={() =>
            setValue('publicationDateFrom', PUBLICATION_DATE_FROM_ZERO_VALUE)
          }
        >
          <CloseIcon />
        </StyledPublicationDateIcon>
      )}
      {children}
    </InputAdornment>
  );
};

export const DatePickerInputAdornmentPublicationDateTo = ({
  children,
  ...props
}: InputAdornmentProps) => {
  const { watch, setValue } = useFormContext<PublicDocumentListForm>();
  const publicationDate = watch(`publicationDateTo`);

  return (
    <InputAdornment {...props}>
      {publicationDate && (
        <StyledPublicationDateIcon
          onClick={() => setValue('publicationDateTo', '')}
        >
          <CloseIcon />
        </StyledPublicationDateIcon>
      )}
      {children}
    </InputAdornment>
  );
};

export const DatePickerInputAdornmentValidSince = ({
  children,
  ...props
}: InputAdornmentProps) => {
  const { watch, setValue } = useFormContext<PublicDocumentListForm>();
  const publicationDate = watch(`validSince`);

  return (
    <InputAdornment {...props}>
      {publicationDate && (
        <StyledPublicationDateIcon onClick={() => setValue('validSince', '')}>
          <CloseIcon />
        </StyledPublicationDateIcon>
      )}
      {children}
    </InputAdornment>
  );
};

export const DatePickerInputAdornmentValidUntil = ({
  children,
  ...props
}: InputAdornmentProps) => {
  const { watch, setValue } = useFormContext<PublicDocumentListForm>();
  const publicationDate = watch(`validUntil`);

  return (
    <InputAdornment {...props}>
      {publicationDate && (
        <StyledPublicationDateIcon onClick={() => setValue('validUntil', '')}>
          <CloseIcon />
        </StyledPublicationDateIcon>
      )}
      {children}
    </InputAdornment>
  );
};
