import { USER_IDENTITY_API_URL } from 'src/modules/UserIdentity/constants/api';
import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/UserIdentity/constants/apiRoutes';
import {
  AgreementConfirmationCodeRequest,
  AgreementConfirmationCodeResponse
} from 'src/modules/UserIdentity/types/api';

const getAgreementConfirmationCode = async (
  request: AgreementConfirmationCodeRequest
): Promise<AgreementConfirmationCodeResponse> => {
  const apiClient = getApiClient(USER_IDENTITY_API_URL);

  const { data: response } =
    await apiClient.post<AgreementConfirmationCodeResponse>(
      apiRoutes.fullIdentityTwoFactorAuthorizationCheck,
      request
    );

  return response;
};

export default getAgreementConfirmationCode;
