import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import postAgreementAccept from 'src/modules/DocumentsPrivate/api/postAgreementAccept';
import {
  AgreementAcceptRequestType,
  AgreementSignatureResponse
} from 'src/modules/DocumentsPrivate/types';

const useAcceptAgreement = (
  options: UseMutationOptions<
    AgreementSignatureResponse,
    AxiosError,
    AgreementAcceptRequestType
  >
) =>
  useMutation<
    AgreementSignatureResponse,
    AxiosError,
    AgreementAcceptRequestType
  >(postAgreementAccept, options);

export default useAcceptAgreement;
