import {
  List as MuiList,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import ListItem from 'src/components/navbar/components/listItem';
import {
  AccessIcon,
  VerificationIcon,
  PublicDocumentIcon
} from 'src/components/navbar/styles';
import routes from 'src/constants/routes';
import { LayoutContext } from 'src/contexts/LayoutContext';
import hasFeature from 'src/lib/hasFeature';
import AppConfiguration from 'src/types/appConfiguration';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { getPrivateDocumentIndexRoute } from 'src/modules/DocumentsPrivate/utils';

import {
  StyleClosedListContainer,
  StyledFullListContainer,
  StyledIconButton,
  StyledSubListItemButton
} from './styled';

const navigationList = [
  {
    titleKey: 'HOME_DOCUMENT_PUBLIC_DOCUMENT_LIST',
    route: routes.publicDocumentList,
    icon: PublicDocumentIcon,
    feature: 'documentPublic'
  },
  {
    titleKey: 'HOME_DOCUMENT_DOWNLOAD',
    route: getPrivateDocumentIndexRoute(),
    icon: AccessIcon,
    feature: 'documentPrivate',
    loginList: [
      {
        titleKey: 'HOME_DOCUMENT_PRIVATE_MY_DOCUMENT_LIST', // HOME_DOCUMENT_PRIVATE_MY_DOCUMENT_LIST
        route: routes.documentAccessUserIndex
      },
      {
        titleKey: 'HOME_DOCUMENT_PRIVATE_DOCUMENT_BY_CODE', // HOME_DOCUMENT_PRIVATE_DOCUMENT_BY_CODE
        route: routes.blockchainIndex
      }
    ]
  },
  {
    titleKey: 'HOME_DOCUMENT_VERIFICATION',
    route: routes.verifyIndex,
    icon: VerificationIcon,
    feature: 'documentVerification'
  }
];

export const List = () => {
  const { state } = useContext(UserIdentityContext);
  const { isNavbarOpened } = useContext(LayoutContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationListFiltered = navigationList.filter(f =>
    hasFeature(f.feature as keyof AppConfiguration)
  );

  return isNavbarOpened ? (
    <StyledFullListContainer>
      <MuiList>
        {navigationListFiltered.map(navigationItem => (
          <div key={navigationItem.titleKey}>
            <ListItem
              ItemIcon={navigationItem.icon}
              itemText={t(navigationItem.titleKey)}
              onClick={() => navigate(navigationItem.route)}
              active={
                matchPath(location.pathname, navigationItem.route)
                  ? true
                  : undefined
              }
            />
            {state.status === 'AUTHORIZED' &&
              navigationItem.loginList?.map(subItem => (
                <StyledSubListItemButton
                  selected={
                    matchPath({ path: subItem.route }, location.pathname)
                      ? true
                      : undefined
                  }
                  key={subItem.titleKey}
                  onClick={() =>
                    state.uuid &&
                    navigate(subItem.route.replace(':uuid', state.uuid))
                  }
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        fontWeight={
                          matchPath({ path: subItem.route }, location.pathname)
                            ? 600
                            : 400
                        }
                      >
                        {t(subItem.titleKey)}
                      </Typography>
                    }
                  />
                </StyledSubListItemButton>
              ))}
          </div>
        ))}
      </MuiList>
    </StyledFullListContainer>
  ) : (
    <StyleClosedListContainer>
      {navigationListFiltered.map(navigationItem => (
        <Tooltip
          title={t(navigationItem.titleKey)}
          placement="right"
          key={`button-${navigationItem.titleKey}`}
        >
          <StyledIconButton
            active={
              matchPath(location.pathname, navigationItem.route)
                ? true
                : undefined
            }
            size="large"
            onClick={() => navigate(navigationItem.route)}
          >
            <navigationItem.icon />
          </StyledIconButton>
        </Tooltip>
      ))}
    </StyleClosedListContainer>
  );
};

export default List;
