import { useTranslation } from 'react-i18next';

import {
  DocumentNumberTypeEnum,
  parseDocumentNumberTypeToStepTitle
} from 'src/modules/UserIdentity/constants/identityParams';
import hasFeature from 'src/lib/hasFeature';
import InfoTooltip from 'src/components/infoTooltip';

import { StyledTypography } from './styles';

type SetDocumentNumberStepProps = {
  orderNumber?: string;
  documentNumberType?: DocumentNumberTypeEnum;
  infoToolTipTitle?: string;
};

const InputTitle = ({
  orderNumber,
  documentNumberType,
  infoToolTipTitle
}: SetDocumentNumberStepProps) => {
  const { t } = useTranslation();

  const labelVariant = hasFeature('tauron') ? 'h6' : 'subtitle1';
  const labelFontWeight = hasFeature('tauron') ? '400' : '600';

  const labelKey = parseDocumentNumberTypeToStepTitle(
    documentNumberType || null
  );

  if (!labelKey) {
    return null;
  }

  return (
    <StyledTypography variant={labelVariant} fontWeight={labelFontWeight}>
      {orderNumber} {t(labelKey)}{' '}
      {!!infoToolTipTitle && (
        <InfoTooltip infoToolTipTitle={infoToolTipTitle} />
      )}
    </StyledTypography>
  );
};

export default InputTitle;
