import { styled } from '@mui/material';

import StepperContainer from 'src/components/stepper/components/stepperContainer';
import StepperContent from 'src/components/stepper/components/stepperContent';

export const StyledDocumentContainer = styled(StepperContainer)({
  marginTop: 0,
  height: 'unset',
  padding: 0
});

export const StyledDocumentContent = styled(StepperContent)(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(3)
  },
  [theme.breakpoints.up('desktop')]: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));
