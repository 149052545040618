import { useSessionStorage } from 'usehooks-ts';

import { IDENTITY_METHOD_KEY } from 'src/modules/UserIdentity/constants/session';
import { RegisterUserStepWizardKeys } from 'src/modules/UserIdentity/constants/stepper';
import { IdentityVerificationMethodsEnum } from 'src/modules/UserIdentity/constants/identityVerification';

const useSkippedSteps = () => {
  const [identityMethod] = useSessionStorage<string | null>(
    IDENTITY_METHOD_KEY,
    null
  );

  if (identityMethod === IdentityVerificationMethodsEnum.BASIC) {
    return [
      RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_IDENTITY_VERIFICATION
    ];
  }

  return [];
};

export default useSkippedSteps;
