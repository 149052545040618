import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';

import { SortModel } from 'src/modules/DocumentsPrivate/types';
import { AgreementStatusEnum } from 'src/modules/DocumentsPrivate/constants/document';

import { PrivateDocumentListForm } from './filtersCard/form';

export const parseNumber = (
  searchParams: URLSearchParams,
  name: string,
  defaultValue: number
): number => {
  const searchParamsValue = searchParams.get(name);
  const searchParamMaybeNumber = Number(searchParamsValue);

  return !searchParamsValue || isNaN(searchParamMaybeNumber)
    ? defaultValue
    : searchParamMaybeNumber;
};

export const mapSearchParamsToDefaultFormParams = (
  searchParams: URLSearchParams
): {
  form: Partial<PrivateDocumentListForm>;
  pagination: GridPaginationModel;
  sort: SortModel;
} => ({
  sort: {
    order: (searchParams.get('order') as SortModel['order']) || 'desc',
    orderBy: searchParams.get('orderBy') || 'publicationDate'
  },
  pagination: {
    page: parseNumber(searchParams, 'page', 0),
    pageSize: parseNumber(searchParams, 'limit', 5)
  },
  form: {
    status: (searchParams.get('status') as AgreementStatusEnum) || '',
    category: searchParams.get('category') || '',
    blockchainAddress: searchParams.get('blockchainAddress') || '',
    publicationDate: searchParams.get('publicationDate') || ''
  }
});
