import { useMediaQuery, Box, Checkbox, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useTheme } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { AcceptTermsLabel } from 'src/components/regulations';
import {
  RegisterUserStepKeys,
  RegisterUserStepWizardKeys
} from 'src/modules/UserIdentity/constants/stepper';
import useVerifyUser from 'src/modules/UserIdentity/hooks/useVerifyUser';
import FormAlert from 'src/components/formAlert';
import routes from 'src/constants/routes';
import {
  EMAIL_SESSION_KEY,
  IDENTITY_DETAILS_KEY,
  IDENTITY_METHOD_KEY
} from 'src/modules/UserIdentity/constants/session';
import FormTextField from 'src/components/form/formTextField';
import FormContainer from 'src/components/form/formContainer';
import {
  Stepper,
  StepperHeader,
  StepperContainer,
  StepperContent,
  StepperControls,
  StepperHeaderContainer
} from 'src/components/stepper';
import StyleFormContainer from 'src/components/stepper/components/stepperFormContent';
import useSkippedSteps from 'src/modules/UserIdentity/hooks/useSkippedSteps';
import { NationalNodeIdentityDetails } from 'src/modules/UserIdentity/types/session';
import { IdentityVerificationMethodsEnum } from 'src/modules/UserIdentity/constants/identityVerification';
import useQueryParams from 'src/hooks/useQueryParams';

import schema from './schema';
import {
  StyledFormControlLabel,
  StyledFormHelperText,
  StyledUserNameContainer
} from './styles';

type RegisterForm = {
  firstName: string;
  lastName: string;
  documentNumber: string;
  email: string;
  phoneNumber: string;
  terms?: boolean;
};

const AccessTypeRegisterDetailsPage = () => {
  const { sessionId, SAMLart } = useQueryParams();
  const [identityMethod] = useSessionStorage<string | null>(
    IDENTITY_METHOD_KEY,
    null
  );
  const [identityDetails] =
    useSessionStorage<NationalNodeIdentityDetails | null>(
      IDENTITY_DETAILS_KEY,
      null
    );
  const [_, setSessionEmail] = useSessionStorage<string | null>(
    EMAIL_SESSION_KEY,
    null
  );

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formProps = useForm<RegisterForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      firstName: identityDetails?.firstName || '',
      lastName: identityDetails?.lastName || '',
      documentNumber: identityDetails?.documentNumber || '',
      phoneNumber: '+48'
    }
  });
  const { mutate, isLoading, error } = useVerifyUser({
    onSuccess: data => {
      setSessionEmail(formProps.getValues().email);
      navigate(
        `${routes.documentAccessTypeVerify.replace(
          ':processId',
          data.processId
        )}`
      );
    }
  });

  const { register, handleSubmit } = formProps;

  const handleRegisterClick = useCallback(
    (values: RegisterForm) => {
      mutate({
        documentNumber: values.documentNumber,
        emailAddress: values.email?.trim(),
        firstName: values.firstName,
        lastName: values.lastName,
        passwordResetType: 'PHONE_NUMBER',
        phoneNumber: values.phoneNumber,
        sessionId,
        SAMLart
      });
    },
    [SAMLart, mutate, sessionId]
  );

  const skippedSteps = useSkippedSteps();

  const stepperProps = {
    stepTitleKeys: RegisterUserStepKeys,
    activeStep: RegisterUserStepWizardKeys.REGISTRATION_SIDEBAR_ENTER_DATA,
    skippedSteps
  };

  const isHorizontalStepper = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleRegisterClick)}>
        <StyleFormContainer>
          <FormAlert error={error} />
          <StepperContainer>
            {!isHorizontalStepper && (
              <Stepper {...stepperProps} orientation="vertical" />
            )}
            <StepperContent>
              <Box>
                <StepperHeaderContainer>
                  <StepperHeader
                    subtitleKey="REGISTRATION_ENTER_DATA_DESCRIPTION"
                    titleKey="USER_REGISTER"
                  />
                  {isHorizontalStepper && (
                    <Stepper {...stepperProps} orientation="horizontal" />
                  )}
                </StepperHeaderContainer>
                <FormContainer>
                  <StyledUserNameContainer>
                    <FormTextField
                      trim
                      fullWidth
                      variant="outlined"
                      label={t('REGISTRATION_ENTER_DATA_FIRST_NAME')}
                      InputProps={{ ...register('firstName') }}
                      disabled={!!identityDetails?.firstName}
                    />
                    <FormTextField
                      trim
                      fullWidth
                      variant="outlined"
                      label={t('REGISTRATION_ENTER_DATA_SURNAME')}
                      InputProps={{ ...register('lastName') }}
                      disabled={!!identityDetails?.lastName}
                    />
                  </StyledUserNameContainer>
                  <FormTextField
                    trim
                    fullWidth
                    variant="outlined"
                    label={t('REGISTRATION_ENTER_DATA_PESEL')}
                    InputProps={{ ...register('documentNumber') }}
                    disabled={!!identityDetails?.documentNumber}
                  />
                  <FormTextField
                    trim
                    fullWidth
                    variant="outlined"
                    label={t('REGISTRATION_ENTER_DATA_EMAIL_ADDRESS')}
                    InputProps={{ ...register('email') }}
                  />
                  <FormTextField
                    trim
                    fullWidth
                    variant="outlined"
                    label={t('REGISTRATION_ENTER_DATA_PHONE_NUMBER')}
                    InputProps={{ ...register('phoneNumber') }}
                  />

                  <StyledFormControlLabel
                    label={<AcceptTermsLabel />}
                    control={<Checkbox {...register('terms')} />}
                  />
                  {formProps.formState.errors.terms && (
                    <StyledFormHelperText error>
                      {t(formProps.formState.errors.terms.message as string)}
                    </StyledFormHelperText>
                  )}
                </FormContainer>
              </Box>
              <StepperControls>
                {identityMethod !==
                  IdentityVerificationMethodsEnum.NATIONAL_NODE && (
                  <Button
                    onClick={() => navigate(routes.documentAccessTypeRegister)}
                    startIcon={<KeyboardArrowLeftIcon viewBox="6 0 24 24" />}
                    color="secondary"
                  >
                    {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_GO_BACK_BUTTON')}
                  </Button>
                )}

                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="end"
                  endIcon={<KeyboardArrowRightIcon viewBox="-6 0 24 24" />}
                  variant="contained"
                >
                  <span>
                    {t('PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_NEXT_BUTTON')}
                  </span>
                </LoadingButton>
              </StepperControls>
            </StepperContent>
          </StepperContainer>
        </StyleFormContainer>
      </form>
    </FormProvider>
  );
};

export default AccessTypeRegisterDetailsPage;
