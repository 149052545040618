import { createContext, ReactNode } from 'react';

import useDocumentPublicHistory from 'src/modules/DocumentsPrivate/hooks/useDocumentPublicHistory';
import {
  DOCUMENT_TYPE,
  PublicDocumentHistoryEntry
} from 'src/modules/DocumentsPrivate/types';

export interface DocumentPublicHistoryContextValue {
  documentHistory: PublicDocumentHistoryEntry[] | [];
  refetchDocumentHistory: () => void;
}

export const DocumentPublicHistoryContext = createContext<
  DocumentPublicHistoryContextValue | undefined
>(undefined);

interface DocumentHistoryProviderProps {
  blockchainAddress: string | undefined;
  publisherId: string | undefined;
  documentType?: 'PUBLIC' | 'PRIVATE';
  children: ReactNode;
}

export const DocumentPublicHistoryProvider = ({
  children,
  blockchainAddress,
  publisherId,
  documentType = DOCUMENT_TYPE.PRIVATE
}: DocumentHistoryProviderProps) => {
  const { data: documentHistory = [], refetch: refetchDocumentHistory } =
    useDocumentPublicHistory(
      blockchainAddress || '',
      publisherId || '',
      documentType,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!blockchainAddress
      }
    );

  return (
    <DocumentPublicHistoryContext.Provider
      value={{ documentHistory, refetchDocumentHistory }}
    >
      {children}
    </DocumentPublicHistoryContext.Provider>
  );
};
