import { Buffer } from 'buffer';

import * as asn1js from 'asn1js';

import { encodeEcies } from 'src/modules/Crypto/lib/ciphers/ecies-customized/ecies';
import asn1jsDecoder from 'src/modules/Crypto/lib/ASN1Decoder';

const handleCipher = async ({ encryptionANS1key, document, hexKey }) => {
  const decodeASN1Key = key => {
    const encryptionANS1key_hex = Buffer.from(key, 'binary').toString('hex');

    const encryption_key_decoded = asn1jsDecoder.decodeHexString(
      encryptionANS1key_hex
    );

    return encryption_key_decoded.SEQUENCE_0.BIT_STRING_0.slice(2);
  };

  const encryption_key = encryptionANS1key
    ? decodeASN1Key(encryptionANS1key)
    : hexKey;

  const encrypted = await encodeEcies({
    plainData: new Uint8Array(document), // serialized file
    publicKey: encryption_key
  });

  // eslint-disable-next-line no-console
  console.groupCollapsed('SZYFROGRAM W HEX');
  // eslint-disable-next-line no-console
  console.log(encrypted);
  // eslint-disable-next-line no-console
  console.groupEnd();

  const ans1struct = new asn1js.Sequence({
    name: 'Certificate',
    value: [
      new asn1js.Integer({
        name: 'tbsCertificate',
        value: 1
      }),

      new asn1js.Sequence({
        name: 'signatureAlgorithm',
        value: [
          new asn1js.OctetString({
            name: 'algorithm',
            valueHex: Buffer.from('88602E176A7173AF', 'hex')
          }),
          new asn1js.Integer({
            name: 'parameters',
            value: 1
          })
        ]
      }),
      new asn1js.OctetString({
        name: 'signature',
        valueHex: Buffer.from(encrypted, 'hex')
      })
    ]
  });

  const res = Buffer.from(ans1struct.toBER(false)).toString('base64');

  // eslint-disable-next-line no-console
  console.groupCollapsed('SZYFROGRAM W BASE64');
  // eslint-disable-next-line no-console
  console.log(res);
  // eslint-disable-next-line no-console
  console.groupEnd();

  return ans1struct.toBER(false);
};
export default handleCipher;
