import { Step, StepLabel } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Orientation } from '@mui/material/Stepper/Stepper';

import { ReactComponent as ErrorIcon } from 'src/assets/error.svg';

import { StyledStepper } from './styles';

type DocumentStepperProps = {
  orientation: Orientation;
  activeStep: number;
  stepTitleKeys: string[];
  erroredSteps?: number[];
  skippedSteps?: number[];
};

const Stepper = ({
  orientation,
  activeStep,
  stepTitleKeys,
  erroredSteps = [],
  skippedSteps = []
}: DocumentStepperProps) => {
  const { t } = useTranslation();
  const steps = useMemo(
    () =>
      stepTitleKeys.map(stepKey => ({
        label: t(stepKey)
      })),
    [stepTitleKeys, t]
  );

  return (
    <StyledStepper orientation={orientation} activeStep={activeStep}>
      {steps.map((step, index) => {
        if (!skippedSteps.includes(index)) {
          return (
            <Step key={step.label}>
              <StepLabel
                error={erroredSteps.includes(index)}
                icon={erroredSteps.includes(index) && <ErrorIcon />}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        }

        return null;
      })}
    </StyledStepper>
  );
};

export default Stepper;
