import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import hasFeature from 'src/lib/hasFeature';

import { StyledContainer, StyledEmphazise } from './styles';

type StepHeaderProps = {
  titleKey: string;
  subtitleKey?: string;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
};

const StepperHeader = ({
  subtitleKey,
  titleKey,
  align = 'inherit'
}: StepHeaderProps) => {
  const { t } = useTranslation();

  const headerVariant = hasFeature('tauron') ? 'h3' : 'h2';
  const headerWeight = hasFeature('tauron') ? '200' : '600';

  return (
    <StyledContainer>
      <Typography
        variant={headerVariant}
        fontWeight={headerWeight}
        align={align}
      >
        <StyledEmphazise>{t(titleKey)}</StyledEmphazise>
      </Typography>
      {subtitleKey && (
        <Typography variant="subtitle2" align={align}>
          {t(subtitleKey)}
        </Typography>
      )}
    </StyledContainer>
  );
};

export default StepperHeader;
