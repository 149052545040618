/* eslint-disable */

import remoteSignatureResponseProto from './RemoteSignatureResponse_pb';

const wrappers = require('./wrappers_pb.js');

const createBlobAndReadItAsArrayBuffer = (fileProto, reader) => {
  const blob = new Blob([fileProto], { type: 'application/octet-stream' });
  reader.readAsArrayBuffer(blob);
};

const readForwardedDocument = (obj, self) => {
  const fileSerializeInput = document.querySelector('.protoSerializeButton');
  const decodedSerializedBlobReader = new FileReader();
  decodedSerializedBlobReader.onload = e => {
    obj.forwardedDocument = new Uint8Array(e.target.result);
    const serializedProto = protoSerializer.serializeProto(obj);
    self.setState({ serializedFile: serializedProto });
  };

  if (fileSerializeInput) {
    fileSerializeInput.onchange = e => {
      createBlobAndReadItAsArrayBuffer(
        e.target.files[0],
        decodedSerializedBlobReader
      );
    };
  }
};

const readForwardedDocumentRedux = (obj, file, resolve) => {
  const decodedSerializedBlobReader = new FileReader();
  decodedSerializedBlobReader.onload = e => {
    obj.forwardedDocument = new Uint8Array(e.target.result);
    const serializedProto = protoSerializer.serializeProto(obj);
    resolve(serializedProto)
  };

  if (file) {
    createBlobAndReadItAsArrayBuffer(file, decodedSerializedBlobReader);
  }
};

const runProtoDeserialization = () => {
  const fileDeserializeInput = document.querySelector(
    '.protoDeserializeButton'
  );
  const decodedDeserializedBlobReader = new FileReader();
  decodedDeserializedBlobReader.onload = e => {
    const a =
      remoteSignatureResponseProto.RemoteSignatureResponseProto.deserializeBinary(
        e.target.result
      );
  };

  if (fileDeserializeInput) {
    fileDeserializeInput.onchange = e => {
      createBlobAndReadItAsArrayBuffer(
        e.target.files[0],
        decodedDeserializedBlobReader
      );
    };
  }
};

const runProtoSerialization = obj => {
  const proto = new remoteSignatureResponseProto.RemoteSignatureResponseProto();
  proto.setDocumentAddress(
    new wrappers.StringValue().setValue(obj.documentAddress)
  );
  proto.setForwardedDocument(
    new wrappers.BytesValue().setValue(obj.forwardedDocument)
  );
  proto.setErrorCode(obj.errorCode);
  proto.setDate(new wrappers.Int64Value().setValue(obj.date));
  proto.setSignerName(new wrappers.StringValue().setValue(obj.signerName));
  proto.setSignerSurname(
    new wrappers.StringValue().setValue(obj.signerSurname)
  );
  proto.setDeviceIp(new wrappers.BytesValue().setValue(obj.deviceIp));
  proto.setDeviceId(new wrappers.StringValue().setValue(obj.deviceId));
  proto.setSignatureType(obj.signatureType);
  proto.setPublicKey(new wrappers.BytesValue().setValue(obj.publicKey));
  proto.setSignatureAuthenticationType(obj.signatureAuthenticationType);

  return proto.serializeBinary();
};

export const protoSerializer = {
  serializeProto: runProtoSerialization,
  deserializeProto: runProtoDeserialization,
  readForwardedDocument,
  readForwardedDocumentRedux
};
