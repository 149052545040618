import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { StyledErrorWrapper, StyledPrimaryEmphasis } from './styles';

const Default404Page = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  return (
    <StyledErrorWrapper>
      <Box>
        <Typography
          variant="h2"
          fontWeight={600}
          fontSize="4.5rem"
          paddingBottom={isMobile ? '1rem' : '1.5rem'}
        >
          {t('ERROR_NOT_FOUND_404')}{' '}
          <StyledPrimaryEmphasis>404</StyledPrimaryEmphasis>
        </Typography>
        <Typography variant="h2" fontWeight={500}>
          {t('PAGE_NOT_FOUND')}
        </Typography>
      </Box>
    </StyledErrorWrapper>
  );
};

export default Default404Page;
