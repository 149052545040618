import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import {
  StyledButtonContainer,
  StyledFormHeader,
  StyledInitialStepTitle
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/styles';
import {
  ActionStatesEnum,
  SignatureFormStepsEnum
} from 'src/modules/DocumentsPrivate/components/documentSignatureForm/constants';
import { HandleStepType } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/types';

type SignatureStepInitialPropsType = {
  handleStep: HandleStepType;
  requestSmsCode: () => void;
};

const SignatureStepInitial = ({
  handleStep,
  requestSmsCode
}: SignatureStepInitialPropsType) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledFormHeader>
        <StyledInitialStepTitle variant="h6" fontWeight={600}>
          {t('PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_TITLE')}
        </StyledInitialStepTitle>
      </StyledFormHeader>
      <StyledButtonContainer>
        <Button
          onClick={() => {
            requestSmsCode();
            handleStep({
              step: SignatureFormStepsEnum.ACTION,
              action: ActionStatesEnum.REJECT
            });
          }}
          color="error"
        >
          {t(
            'PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_REJECT_BUTTON'
          )}
        </Button>
        <Button
          onClick={() => {
            requestSmsCode();
            handleStep({
              step: SignatureFormStepsEnum.ACTION,
              action: ActionStatesEnum.ACCEPT
            });
          }}
          variant="outlined"
        >
          {t('PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_SIGN_BUTTON')}
        </Button>
      </StyledButtonContainer>
    </>
  );
};

export default SignatureStepInitial;
