/* eslint-disable */

export default async privateKey => {
  const jwk = await crypto.subtle.exportKey('jwk', privateKey);
  delete jwk.d;
  delete jwk.dp;
  delete jwk.dq;
  delete jwk.q;
  delete jwk.qi;
  jwk.key_ops = ['verify'];

  const publicKey = await crypto.subtle.importKey(
    'jwk',
    jwk,
    { name: 'RSA-PSS', hash: 'SHA-256' },
    true,
    ['verify']
  );

  return crypto.subtle.exportKey('spki', publicKey);
};
