import * as yup from 'yup';

import { appConfig } from 'src/constants/appConfig';
import {
  ERROR_EMPTY_FIELD,
  PRIVATE_DOCUMENT_NIP_STRUCTURE,
  PRIVATE_DOCUMENT_POSTAL_CODE
} from 'src/modules/UserIdentity/constants/errors';
import { validateNIP } from 'src/modules/UserIdentity/validators/validateNIP';

export const requiredSchema = yup.string().required(ERROR_EMPTY_FIELD);

export const limitedPESELSchema = appConfig?.documentNumberAlgorithm
  ? yup
      .array()
      .test(
        'required enabled fields',
        ERROR_EMPTY_FIELD,
        v =>
          !!v &&
          v.filter(e => !!e).length ===
            appConfig?.documentNumberAlgorithm?.PESEL.codeLength
      )
  : requiredSchema;

export const limitedNipSchema = appConfig?.documentNumberAlgorithm
  ? yup
      .string()
      .test('nip structure', PRIVATE_DOCUMENT_NIP_STRUCTURE, nip =>
        validateNIP(nip)
      )
      .required(ERROR_EMPTY_FIELD)
  : requiredSchema;

export const limitedPostalCodeSchema = appConfig?.documentNumberAlgorithm
  ? yup
      .string()
      .matches(/^[0-9]{2}-?[0-9]{3}$/, PRIVATE_DOCUMENT_POSTAL_CODE)
      .required(ERROR_EMPTY_FIELD)
  : requiredSchema;
