import { LoadingButton } from '@mui/lab';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReplayIcon } from 'src/assets/tauron/replay.svg';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { parseSstToRequestData } from 'src/modules/UserIdentity/constants/identityParams';
import useMaintenanceSettings from 'src/modules/UserIdentity/hooks/useMaintenanceSettings';
import useSendTauronSmsCode from 'src/modules/UserIdentity/hooks/useSendTauronSmsCode';
import { parseDocumentNumber } from 'src/modules/UserIdentity/utils/documentNumberUtils/parseDocumentNumber';
import { useResponseStatusNotification } from 'src/modules/DocumentsPrivate/hooks/useResponseStatusNotification';

const ResendAccessButton = () => {
  const { t } = useTranslation();
  const [isSmsLocked, setIsSmsLocked] = useState(false);
  const { state } = useContext(PrivateDocumentContext);
  const { data: maintenanceSettings } = useMaintenanceSettings();
  const { setStatusNotification } = useResponseStatusNotification();

  const { mutate: sendSmsCode, isLoading: isSmsSending } = useSendTauronSmsCode(
    {
      onSuccess: ({ status, blockedUntil }) => {
        setStatusNotification(status, blockedUntil);
        setIsSmsLocked(true);
        const oneMinute = 1000 * 60;
        const timeout =
          Number(
            maintenanceSettings?.parameters.VERIFICATION_CODE_EXPIRATION_TIME
          ) * oneMinute;

        setTimeout(() => {
          setIsSmsLocked(false);
        }, timeout);
      },
      onError: () => {
        setIsSmsLocked(false);
      }
    }
  );

  const handleResendAccessCodeClick = () => {
    if (
      state.blockchainAddress &&
      state.documentNumber &&
      state.sharedSecretType
    ) {
      sendSmsCode({
        blockchainAddress: state.blockchainAddress,
        type: parseSstToRequestData(state.sharedSecretType),
        indexCharacterMap: parseDocumentNumber(
          state.documentNumber,
          state.sharedSecretType
        )
      });
    }
  };

  return (
    <LoadingButton
      onClick={handleResendAccessCodeClick}
      disabled={isSmsLocked || state.isLoading || !state.blockchainAddress}
      loading={isSmsSending || state.isLoading}
      startIcon={!state.isLoading && <ReplayIcon />}
      loadingPosition="start"
      variant="text"
      sx={{
        paddingLeft: state.isLoading ? '32px' : '8px',
        paddingRight: state.isLoading ? '22px' : '8px'
      }}
    >
      <span>{t('RESEND_SMS_EMAIL_CODE')}</span>
    </LoadingButton>
  );
};

export default ResendAccessButton;
