import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import postAgreementReject from 'src/modules/DocumentsPrivate/api/postAgreementReject';
import {
  AgreementRejectRequestType,
  AgreementSignatureResponse
} from 'src/modules/DocumentsPrivate/types';

const useRejectAgreement = (
  options: UseMutationOptions<
    AgreementSignatureResponse,
    AxiosError,
    AgreementRejectRequestType
  >
) =>
  useMutation<
    AgreementSignatureResponse,
    AxiosError,
    AgreementRejectRequestType
  >(postAgreementReject, options);

export default useRejectAgreement;
