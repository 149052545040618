import { useFormContext } from 'react-hook-form';
import { useContext, useEffect } from 'react';

import StepTextFieldWithLabel from 'src/components/stepper/components/stepperTextFieldWithLabel';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import { BlockchainDocumentPasswordLength } from 'src/modules/DocumentsPrivate/constants/document';

const blockchainAddressPasswordFieldName = 'blockchainAddressPassword';

const SetBlockchainAddressStep = () => {
  const { setAccessCode, state } = useContext(PrivateDocumentContext);
  const { getValues, formState } = useFormContext();

  useEffect(() => {
    if (formState.isValid) {
      const { blockchainAddressPassword } = getValues();
      setAccessCode(blockchainAddressPassword);
    }
  }, [getValues, formState.isValid, setAccessCode]);

  return (
    <StepTextFieldWithLabel
      isDisabled={state.isLoading}
      fieldName={blockchainAddressPasswordFieldName}
      labelKey="PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_ENTER_ACCESS_CODE"
      inputLabelKey="PRIVATE_DOCUMENT_DOWNLOAD_DOCUMENT_ACCESS_CODE"
      inputMaxlength={BlockchainDocumentPasswordLength}
    />
  );
};

export default SetBlockchainAddressStep;
