import { styled, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.custom.secondary[500],
  width: '20px',
  height: '20px'
}));

export const StyledPasswordInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  justifyContent: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  h6: {
    color: theme.palette.custom.textColors[800]
  }
}));

export const StyledLoadingInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.neutral[900],
  maxWidth: 500,
  i: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 600
  }
}));

export const StyledBannerContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(10, 0),
  display: 'flex',
  justifyContent: 'center'
}));

export const StyledPasswordContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(1),
  flexDirection: 'column',
  display: 'flex',
  width: '100%'
}));
