import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import {
  AddEventRequestType,
  AddEventResponseType
} from 'src/modules/DocumentsPrivate/types';

const postAddEvent = async (
  request: AddEventRequestType
): Promise<AddEventResponseType> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const { data: response } = await apiClient.post<AddEventResponseType>(
    apiRoutes.addEvent,
    request
  );

  return response;
};

export default postAddEvent;
