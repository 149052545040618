import { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import routes from 'src/constants/routes';
import { UserIdentityContext } from 'src/modules/UserIdentity/contexts/UserIdentityContext';
import { UserCredentials } from 'src/modules/UserIdentity/types/session';
import { CREDENTIALS_SESSION_KEY } from 'src/modules/UserIdentity/constants/session';

type AuthorizedPageType = {
  children: ReactNode;
};
const AuthorizedPage = ({ children }: AuthorizedPageType) => {
  const navigate = useNavigate();
  const [sessionCredentials] = useSessionStorage<UserCredentials | null>(
    CREDENTIALS_SESSION_KEY,
    null
  );
  const { state } = useContext(UserIdentityContext);
  useEffect(() => {
    if (state.status === 'UNAUTHORIZED') {
      if (!sessionCredentials) {
        navigate(routes.index);
      }
    }
  }, [state.status, sessionCredentials, navigate]);

  return <>{children}</>;
};

export default AuthorizedPage;
