import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import routes from 'src/constants/routes';
import { StyledButtonContainer } from 'src/modules/DocumentsPrivate/components/documentSignatureForm/styles';

const AuthorizationRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { address } = useParams<{ address: string }>();

  return (
    <div>
      <Typography variant="h6" fontWeight={600}>
        {t('PRIVATE_DOCUMENTS_DETAILS_SIGNING_DOCUMENT_TO_SIGN_UNLOGGED_USER')}
      </Typography>
      <StyledButtonContainer>
        <Button
          onClick={() =>
            navigate(
              `${routes.documentAccessTypeLogin}?blockchainAddress=${address}`
            )
          }
          variant="outlined"
        >
          {t('SELECT_ACCESS_TYPE_LOG_IN')}
        </Button>
      </StyledButtonContainer>
    </div>
  );
};

export default AuthorizationRequest;
