import ChainLoader from 'src/components/loader/animated/Animation';

import Container from './styled';

const SuspenseContainer = () => (
  <Container>
    <ChainLoader />
  </Container>
);

export default SuspenseContainer;
