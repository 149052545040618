import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import Image404 from 'src/assets/tauron/404.png';

import {
  StyledErrorWrapper,
  StyledPrimaryEmphasis,
  StyledImage
} from './styles';

const Tauron404Page = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  return (
    <StyledErrorWrapper>
      <Box style={{ maxWidth: isMobile ? '100%' : '40%' }}>
        <Typography
          variant="h2"
          fontWeight={800}
          fontSize="4.5rem"
          paddingBottom={isMobile ? '1rem' : '1.5rem'}
        >
          {t('ERROR_NOT_FOUND_404')}{' '}
          <StyledPrimaryEmphasis color="red">404</StyledPrimaryEmphasis>
        </Typography>
        <Typography variant="h2" fontWeight={500}>
          {t('PAGE_NOT_FOUND')}
        </Typography>
      </Box>

      <StyledImage src={Image404} alt={t('PAGE_NOT_FOUND') || ''} />
    </StyledErrorWrapper>
  );
};

export default Tauron404Page;
