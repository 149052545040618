import { Typography, styled } from '@mui/material';

export const StyledDateWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(4)
}));

export const StyledBlockchainAddressTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'baseline'
}));

export const StyledDetailsLink = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer'
}));
