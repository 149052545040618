import { Components, Theme } from '@mui/material';

import hasFeature from 'src/lib/hasFeature';

const componentConfiguration: Components<Theme>['MuiStepper'] = {
  styleOverrides: {
    vertical: () => hasFeature('tauron') && { maxWidth: '180px' },
    root: ({ theme }) =>
      hasFeature('tauron')
        ? {
            borderRadius: theme.spacing(2),
            backgroundColor: 'transparent',
            border: 0,
            padding: '0 0 0 45px',
            [theme.breakpoints.down('desktop')]: {
              gap: theme.spacing(1)
            }
          }
        : {
            backgroundColor: theme.palette.custom.secondary[50],
            borderRadius: theme.spacing(2),
            border: `1px solid ${theme.palette.custom.secondary[100]}`,
            padding: '80px 30px 80px 58px',
            [theme.breakpoints.down('desktop')]: {
              gap: theme.spacing(1)
            }
          }
  }
};

export default componentConfiguration;
