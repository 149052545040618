/* eslint-disable */
import asn1creators from './asn1creators';
import ObjectCaster from './objectCaster';

export default async (password, kycGwId) => {
  const b256 = ObjectCaster.getCurvature('brainpool256');
  const b320 = ObjectCaster.getCurvature('brainpool320');
  const b384 = ObjectCaster.getCurvature('brainpool384');
  const b512 = ObjectCaster.getCurvature('brainpool512');

  const privateKeySequence = {
    PRIVATE_KEY_B256: asn1creators.createPRIVPASSSequence(
      0,
      b256.privateKey,
      1,
      'b256'
    ),
    PRIVATE_KEY_B320: asn1creators.createPRIVPASSSequence(
      0,
      b320.privateKey,
      1,
      'b320'
    ),
    PRIVATE_KEY_B384: asn1creators.createPRIVPASSSequence(
      0,
      b384.privateKey,
      1,
      'b384'
    ),
    PRIVATE_KEY_B512: asn1creators.createPRIVPASSSequence(
      0,
      b512.privateKey,
      1,
      'b512'
    )
  };

  const PKCS8Sequences = {
    PKCS8_B256: await asn1creators.encryptedPrivateKeyInfo(
      {
        password,
        salt: crypto.getRandomValues(new Uint8Array(8)),
        cost: 16384,
        blockSize: 8,
        parallel: 1,
        convertToBER: true,
        nakedPrivateKeyStructure: privateKeySequence.PRIVATE_KEY_B256
      },
      'brainpool256'
    ),
    PUBLIC_KEY_B256: asn1creators.createSUBJECTPUBLICKEYSequence(
      b256.publicKey,
      'b256'
    ),
    PKCS8_B320: await asn1creators.encryptedPrivateKeyInfo(
      {
        password,
        salt: crypto.getRandomValues(new Uint8Array(8)),
        cost: 16384,
        blockSize: 8,
        parallel: 1,
        convertToBER: true,
        nakedPrivateKeyStructure: privateKeySequence.PRIVATE_KEY_B320
      },
      'brainpool320'
    ),
    PUBLIC_KEY_B320: asn1creators.createSUBJECTPUBLICKEYSequence(
      b320.publicKey,
      'b320'
    ),
    PKCS8_B384: await asn1creators.encryptedPrivateKeyInfo(
      {
        password,
        salt: crypto.getRandomValues(new Uint8Array(8)),
        cost: 16384,
        blockSize: 8,
        parallel: 1,
        convertToBER: true,
        nakedPrivateKeyStructure: privateKeySequence.PRIVATE_KEY_B384
      },
      'brainpool384'
    ),
    PUBLIC_KEY_B384: asn1creators.createSUBJECTPUBLICKEYSequence(
      b384.publicKey,
      'b384'
    ),
    PKCS8_B512: await asn1creators.encryptedPrivateKeyInfo(
      {
        password,
        salt: crypto.getRandomValues(new Uint8Array(8)),
        cost: 16384,
        blockSize: 8,
        parallel: 1,
        convertToBER: true,
        nakedPrivateKeyStructure: privateKeySequence.PRIVATE_KEY_B512
      },
      'brainpool512'
    ),
    PUBLIC_KEY_B512: asn1creators.createSUBJECTPUBLICKEYSequence(
      b512.publicKey,
      'b512'
    )
  };

  return {
    safebagB256: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECDSA_1y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B256
    ),
    safebagB320: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECDSA_6y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B320
    ),
    safebagB384: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECDSA_20y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B384
    ),
    safebagB512: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECDSA_50y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B512
    ),
    safebagB256_ecies: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECIES_1y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B256
    ),
    safebagB320_ecies: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECIES_6y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B320
    ),
    safebagB384_ecies: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECIES_20y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B384
    ),
    safebagB512_ecies: await asn1creators.createSAFEBAGSequence(
      {
        name: `${kycGwId}_ECIES_50y`,
        version: 1
      },
      PKCS8Sequences.PKCS8_B512
    )
  };
};
