import { PropsWithChildren } from 'react';

import TauronWidthContainer from 'src/components/tauronWidthContainer';

import { StyledContent, StyledCard, StyledCardContent } from './styles';

const ContentTauron = ({ children }: PropsWithChildren) => (
  <StyledContent>
    <TauronWidthContainer>
      <StyledCard sx={{ boxShadow: 0 }}>
        <StyledCardContent>{children}</StyledCardContent>
      </StyledCard>
    </TauronWidthContainer>
  </StyledContent>
);

export default ContentTauron;
