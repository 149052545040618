import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { ReactComponent as DownloadIcon } from 'src/assets/tauron/downloadBtn.svg';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import useSendDownloadingEvent from 'src/modules/DocumentsPrivate/hooks/useSendDownloadingEvent';
import { DOWNLOADING_EVENT_STATUS } from 'src/modules/DocumentsPrivate/types';

import {
  StyledDownloadingButton,
  StyledRowWrapper,
  StyledTitleWrapper
} from './styles';

type DownloadingEventTitleSectionPropsType = {
  documentBlob: Blob | undefined | null;
  documentTitle: string;
};

const DownloadingEventTitleSection = ({
  documentBlob,
  documentTitle
}: DownloadingEventTitleSectionPropsType) => {
  const { t } = useTranslation();

  const { state } = useContext(PrivateDocumentContext);
  const { catalog, blockchainAddress } = state || {};

  const {
    mutate: sendDownloadingEvent,

    isLoading: isDocumentDownloading
  } = useSendDownloadingEvent({
    onSuccess: ({ status }) => {
      if (status === DOWNLOADING_EVENT_STATUS.OK) {
        download();
      }
    }
  });

  const handleDownloadClick = useCallback(() => {
    if (catalog?.downloadToken && blockchainAddress) {
      sendDownloadingEvent({
        downloadToken: catalog.downloadToken,
        blockchainAddress
      });
    }
  }, [catalog, blockchainAddress, sendDownloadingEvent]);

  const download = useCallback(() => {
    if (!documentBlob) {
      return;
    }

    const link = window.document.createElement('a');

    link.href = URL.createObjectURL(documentBlob);
    link.download = documentTitle;
    window.document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href));
  }, [documentBlob, documentTitle]);

  return (
    <Stack direction="column" spacing={2} sx={{ marginBottom: '35px' }}>
      <StyledRowWrapper sx={{ marginBottom: '0px' }}>
        <StyledTitleWrapper>
          <Typography variant="h3" fontWeight={400}>
            {documentTitle}
          </Typography>
        </StyledTitleWrapper>
        <Stack direction="column" spacing={2}>
          <StyledDownloadingButton
            size="large"
            variant="contained"
            onClick={handleDownloadClick}
            startIcon={<DownloadIcon />}
            loading={isDocumentDownloading}
          >
            &nbsp;
            {t('PRIVATE_DOCUMENT_DETAILS_DESCRIPTION_DOWNLOAD_DOCUMENT_BUTTON')}
          </StyledDownloadingButton>
        </Stack>
      </StyledRowWrapper>
    </Stack>
  );
};

export default DownloadingEventTitleSection;
