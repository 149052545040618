import { Typography, styled } from '@mui/material';

export const StyledResendCode = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.custom.primary[600],
  alignSelf: 'flex-end',
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const StyledSmsSendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  h6: {
    color: theme.palette.custom.textColors[800]
  },
  svg: {
    minWidth: 20,
    minHeight: 20
  }
}));
