import { styled, Typography } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(12),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  maxWidth: theme.spacing(45),
  textAlign: 'center'
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.neutral[900],
  margin: theme.spacing(0.5, 0, 0, 0),
  fontWeight: 600
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[800]
}));
