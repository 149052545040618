import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: '0',
      marginRight: theme.spacing(1)
    })
  }
};

export default componentConfiguration;
