import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/modules/DocumentsPrivate/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/modules/DocumentsPrivate/constants/api';
import { PublicDocument } from 'src/modules/DocumentsPrivate/publicTypes';

type GetDocumentByBlockChainAddressParams = {
  blockchainAddress: string;
  token?: string;
  sum?: string;
  fetchSourceDocument: boolean;
};

const getPublicDocumentByBlockChainAddress = ({
  blockchainAddress,
  token,
  sum,
  fetchSourceDocument
}: GetDocumentByBlockChainAddressParams) => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  return apiClient.get<PublicDocument>(
    !!token && !!sum
      ? `${apiRoutes.publicDocument.replace(
          '{blockchainAddress}',
          blockchainAddress
        )}?token=${encodeURIComponent(token)}&sum=${encodeURIComponent(
          sum
        )}&fetchSourceDocument=${encodeURIComponent(fetchSourceDocument)}`
      : `${apiRoutes.publicDocument.replace(
          '{blockchainAddress}',
          blockchainAddress
        )}?fetchSourceDocument=${encodeURIComponent(fetchSourceDocument)}`
  );
};

export default getPublicDocumentByBlockChainAddress;
