/* eslint-disable */

import ASN1Decoder from './ASN1Decoder';
import bufferToHex from './bufferToHex';
import decrypt from './decrypt';
import hexToUInt8Array from './hexToUInt8Array';
import importKey from './importKey';

export default async (encryptedPassword, kek) => {
  const kekNormalized = ASN1Decoder.decodeHexString(bufferToHex(kek));

  const kekIV = kekNormalized.SEQUENCE_0.OCTET_STRING_0;
  const kekKey = kekNormalized.SEQUENCE_0.OCTET_STRING_1;

  const key = await importKey(hexToUInt8Array(kekKey));

  return decrypt(encryptedPassword, hexToUInt8Array(kekIV), key);
};
