import AppConfiguration from 'src/types/appConfiguration';

import diplomaConfig from './diploma/dev';
import diplomaDemoConfig from './diploma/demo';
import mvpDev from './mvp/dev';
import mvpStaging from './mvp/staging';
import mvpDemo from './mvp/demo';
import tauron_TS_TSGZE_Config from './tauron/TS_TSGZE';
import tauron_TD_Config from './tauron/TD';
import sandbox_config from './mvp/sandbox';

export enum AppName {
  DIPLOMA = 'DIPLOMA', //DIPLOMA UAT
  DIPLOMA_DEMO = 'DIPLOMA_DEMO',
  MVP_REMOTE_SIGNATURE = 'MVP_REMOTE_SIGNATURE',
  MVP_REMOTE_SIGNATURE_STAGING = 'MVP_REMOTE_SIGNATURE_STAGING',
  MVP_REMOTE_SIGNATURE_DEMO = 'MVP_REMOTE_SIGNATURE_DEMO',
  MVP_REMOTE_SIGNATURE_PROD = 'MVP_REMOTE_SIGNATURE_PROD',
  TAURON_TS_TSGZE = 'TAURON_TS_TSGZE',
  TAURON_TD = 'TAURON_TD',
  SANDBOX = 'SANDBOX'
}

export const AppConfigMapping: {
  [k in AppName]: AppConfiguration;
} = {
  [AppName.DIPLOMA]: diplomaConfig,
  [AppName.DIPLOMA_DEMO]: diplomaDemoConfig,
  [AppName.MVP_REMOTE_SIGNATURE]: mvpDev,
  [AppName.MVP_REMOTE_SIGNATURE_STAGING]: mvpStaging,
  [AppName.MVP_REMOTE_SIGNATURE_DEMO]: mvpDemo,
  [AppName.MVP_REMOTE_SIGNATURE_PROD]: mvpDemo,
  [AppName.TAURON_TS_TSGZE]: tauron_TS_TSGZE_Config,
  [AppName.SANDBOX]: sandbox_config,
  [AppName.TAURON_TD]: tauron_TD_Config
};

const appName = process.env.REACT_APP_CONFIG as AppName;
export const appConfig = AppConfigMapping[appName];
