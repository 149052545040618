/* eslint-disable */
import { Buffer } from 'buffer';
import { sha256 } from 'js-sha256';

import ObjectCaster from './objectCaster';
import asn1jsDecoder from './ASN1Decoder';
import bufferToHex from './bufferToHex';
import asn1creators from './asn1creators';
import renderSignIntegers from './renderSignIntegers';

export default async (hexStructure, curveName, stateObject) => {
  const curve = ObjectCaster.renderCurveByName(curveName);
  const privKey = stateObject.privateKeys.ecies[curveName].value;
  const sign = curve.curvature.sign;
  const shaStructure = sha256.digest(Buffer.from(hexStructure, 'hex'));
  const ECDSAsigns = stateObject.publicKeysSequences.signs.ECDSA;
  sign(privKey, Buffer.from(shaStructure, 'hex')).then(res => {
    const responseAsn = asn1jsDecoder.decodeHexString(bufferToHex(res));
    const { firstSignResInteger, secondSignResInteger } =
      renderSignIntegers(responseAsn);
    const octetstring = firstSignResInteger + secondSignResInteger;
    ECDSAsigns[curveName] = asn1creators.createSignSequence({
      sign: Buffer.from(octetstring, 'hex'),
      version: 1,
      shaName: Buffer.from(
        stateObject.privateKeys.ecies[curveName].shaName,
        'hex'
      )
    });
  });
};
