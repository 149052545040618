import { Components, Theme } from '@mui/material';

const componentConfiguration: Components<Theme>['MuiListItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      margin: theme.spacing(0, 1),
      width: `calc(100% - ${theme.spacing(2)})`,
      boxSizing: 'border-box',
      color: theme.palette.custom.neutral[900]
    })
  }
};

export default componentConfiguration;
