import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import 'dayjs/locale/pl';

import DocumentListContent from 'src/modules/DocumentsPrivate/components/documentListPublic/content';

import {
  StyledDocumentsListContent,
  StyledDocumentsListContainer
} from './styles';

const PublicDocumentListPage = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
    <StyledDocumentsListContainer>
      <StyledDocumentsListContent>
        <DocumentListContent />
      </StyledDocumentsListContent>
    </StyledDocumentsListContainer>
  </LocalizationProvider>
);

export default PublicDocumentListPage;
