import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createSearchParams, useNavigate } from 'react-router-dom';

import FormAlert from 'src/components/formAlert';
import { TauronPrivateDocumentWizardSteps } from 'src/modules/DocumentsPrivate/constants/stepper';
import SetAccessCodeStep from 'src/modules/DocumentsPrivate/components/setAccessCodeStep';
import { PrivateDocumentContext } from 'src/modules/DocumentsPrivate/contexts/PrivateDocumentContext';
import routes from 'src/constants/routes';
import {
  StepperControls,
  StepperContainer,
  StepperContent
} from 'src/components/stepper';
import useDocumentCatalog from 'src/modules/DocumentsPrivate/hooks/useDocumentCatalog';
import { useHandleDecryptDocument } from 'src/modules/DocumentsPrivate/hooks/handleDecryptDocument';
import { SelectBlockchainAddressPasswordForm } from 'src/types/form';
import { tauronBlockchainAddressPasswordSchema } from 'src/constants/form';
import { parseSstToRequestData } from 'src/modules/UserIdentity/constants/identityParams';
import useQueryParam from 'src/hooks/userQueryParam';
import FormNotifier from 'src/components/formAlert/FormNotifier';
import SubmitButton from 'src/modules/DocumentsPrivate/components/documentAccess/components/submitButton';
import BackButton from 'src/modules/DocumentsPrivate/components/documentAccess/components/backButton';
import ResendAccessButton from 'src/modules/DocumentsPrivate/components/documentAccess/components/resendAccessButton';
import ResponsiveStepper from 'src/modules/DocumentsPrivate/components/documentAccess/components/responsiveStepper';

const BlockchainAddressAccessCodePage = () => {
  const navigate = useNavigate();
  const {
    state,
    setBlockchainAddress,
    setCatalog,
    setAuth,
    setSharedSecretType
  } = useContext(PrivateDocumentContext);

  const blockchainAddress = useQueryParam('blockchainAddress');
  const sharedSecretType = useQueryParam('sst');
  const auth = useQueryParam('auth');

  const documentNumberType = useMemo(
    () => parseSstToRequestData(sharedSecretType),
    [sharedSecretType]
  );

  useDocumentCatalog(blockchainAddress as string, {
    onSuccess: data => {
      if (auth) {
        setAuth(auth);
      }
      if (sharedSecretType) {
        setSharedSecretType(sharedSecretType);
      }
      if (blockchainAddress) {
        setBlockchainAddress(blockchainAddress);
        setCatalog(data);
      }
    },
    enabled: !Boolean(state.blockchainAddress)
  });

  const formProps = useForm<SelectBlockchainAddressPasswordForm>({
    resolver: yupResolver(tauronBlockchainAddressPasswordSchema),
    mode: 'onChange'
  });

  const handleDecryptDocument = useHandleDecryptDocument(formProps.getValues);

  const handleBackClick = () => {
    if (blockchainAddress && sharedSecretType && auth) {
      navigate({
        pathname: routes.documentAccessType,
        search: createSearchParams({
          blockchainAddress,
          sst: sharedSecretType,
          auth
        }).toString()
      });
    }
    if (blockchainAddress && !sharedSecretType && !auth) {
      navigate({
        pathname: routes.documentAccessType,
        search: createSearchParams({ blockchainAddress }).toString()
      });
    }
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(handleDecryptDocument)}>
        <FormAlert error={state.error} />
        <StepperContainer>
          <ResponsiveStepper
            documentNumberType={documentNumberType}
            activeStep={TauronPrivateDocumentWizardSteps.DOCUMENT_ACCESS_CODE}
            orientation="vertical"
          />
          <StepperContent>
            <Box>
              <ResponsiveStepper
                documentNumberType={documentNumberType}
                activeStep={
                  TauronPrivateDocumentWizardSteps.DOCUMENT_ACCESS_CODE
                }
                orientation="horizontal"
              />
              <SetAccessCodeStep orderNumber="2" />
            </Box>
            <Box
              sx={{ display: 'inline-flex', justifyContent: 'space-between' }}
            >
              <StepperControls>
                <ResendAccessButton />
              </StepperControls>
              <StepperControls>
                <BackButton
                  handleBackClick={handleBackClick}
                  disabled={state.isLoading}
                />
                <SubmitButton
                  loading={state.isLoading}
                  disabled={state.isLoading || !state.blockchainAddress}
                />
              </StepperControls>
            </Box>
            <FormNotifier info={state.info} severity="info" />
          </StepperContent>
        </StepperContainer>
      </form>
    </FormProvider>
  );
};

export default BlockchainAddressAccessCodePage;
