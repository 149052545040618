import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  NationalNodeResolveResponse,
  NationalNodeResolveRequest
} from 'src/modules/UserIdentity/types/api';
import postNationalNodeResolve from 'src/modules/UserIdentity/api/postNationalNodeResolve';

const useNationalNodeResolve = (
  options: UseMutationOptions<
    NationalNodeResolveResponse,
    AxiosError,
    NationalNodeResolveRequest
  > = {}
) =>
  useMutation<
    NationalNodeResolveResponse,
    AxiosError,
    NationalNodeResolveRequest
  >(postNationalNodeResolve, options);

export default useNationalNodeResolve;
