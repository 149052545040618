import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import queryKeys from 'src/modules/DocumentsPrivate/constants/queryKeys';
import { Publisher } from 'src/modules/DocumentsPrivate/types';
import getPublishers from 'src/modules/DocumentsPrivate/api/getPublishers';

type UseDocumentSettingsProps = {
  publisherId: string;
};

const useTauronDocumentPublisher = (
  { publisherId }: UseDocumentSettingsProps,
  options?: UseQueryOptions<Publisher | undefined, AxiosError>
) =>
  useQuery<Publisher | undefined, AxiosError>(
    [queryKeys.getDocumentPublishers],
    async () => {
      const { data: response } = await getPublishers();
      return response.publishers.find(p => p.publisherId === publisherId);
    },
    options
  );

export default useTauronDocumentPublisher;
